import { useCallback, useState } from "react";

export const DEFAULT_ZOOM_VALUE = 1.0;
const MOVING_VALUE = 0.25;

export function usePDFZoom() {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM_VALUE);
  const isMinusEnabled = zoom > DEFAULT_ZOOM_VALUE;

  const increaseZoom = useCallback(() => {
    setZoom((prevValue) => prevValue + MOVING_VALUE);
  }, []);

  const decreaseZoom = useCallback(() => {
    setZoom((prevValue) => prevValue - MOVING_VALUE);
  }, []);

  return { zoom, isMinusEnabled, increaseZoom, decreaseZoom };
}
