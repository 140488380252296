import { makeStyles } from "@material-ui/core/styles";

export const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 3,
};

export const commonFilter = {
  border: "none",
  borderRadius: 5,
  padding: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  color: "#FFFFFF",
  height: 30,
  marginRight: 12,
  marginTop: 8,
};

export const useStyles = makeStyles((theme) => ({
  filterButton: {
    ...commonFilter,
    backgroundColor: `${theme.palette.content.mainColor}99`,
  },
  filterButtonWithValue: {
    ...commonFilter,
    border: "2px solid #FFFFFF",
    backgroundColor: `${theme.palette.content.mainColor}99`,
  },
  filterButtonWithValueBlocked: {
    ...commonFilter,
    border: `2px solid ${theme.palette.text.primary}DD`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    cursor: "default",
  },
  filterButtonDisabled: {
    ...commonFilter,
    cursor: "not-allowed",
    backgroundColor: `${theme.palette.content.mainColor}55`,
  },
  removeFilterButton: {
    padding: 2,
    marginLeft: 6,
    color: "darkorange",
  },
  paperMenu: {
    border: `1px solid ${theme.palette.content.mainColor}`,
  },
  listItemMultiple: {
    padding: "3px 16px 3px 3px",
  },
  requiredMissing: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
    backgroundColor: `${theme.palette.error.main}11`,
  },
}));
