import { useCallback, useContext } from "react";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import useEmployeeStoreState from "../EmployeeStoreState";
import { useTranslation } from "react-i18next";

const useCart = () => {
  const {
    emsCartId,
    loadingCart,
    cartItems,
    addCartItem,
    removeCartItem,
    clearCartItems,
    fetchCart,
    clearCartStateOnlyFrontend
  } = useEmployeeStoreState((state) => ({
    emsCartId: state.emsCartId,
    loadingCart: state.loadingCart,
    cartItems: state.cartItems,
    addCartItem: state.addCartItem,
    removeCartItem: state.removeCartItem,
    clearCartItems: state.clearCartItems,
    fetchCart: state.fetchCart,
    clearCartStateOnlyFrontend: state.clearCartStateOnlyFrontend
  }));

  const { t } = useTranslation();
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const handleFetchCart = useCallback(() => {
    fetchCart({ t, headers, logout });
  }, [fetchCart, headers, logout, t]);

  const handleAddCartItem = useCallback(
    (params) => {
      addCartItem({
        t,
        headers,
        logout,
        ...params,
      });
    },
    [addCartItem, t, headers, logout]
  );

  const handleRemoveCartItem = useCallback(
    (params) => {
      removeCartItem({
        t,
        headers,
        logout,
        ...params,
      });
    },
    [removeCartItem, t, headers, logout]
  );

  const handleClearCart = useCallback(() => {
    clearCartItems({
      t,
      headers,
      logout,
    });
  }, [clearCartItems, t, headers, logout]);

  return {
    emsCartId,
    loadingCart,
    handleFetchCart,
    cartItems,
    handleAddCartItem,
    handleRemoveCartItem,
    handleClearCart,
    clearCartStateOnlyFrontend
  };
};

export default useCart;
