import React from "react";
import {
  Modal,
  makeStyles,
  Backdrop,
  Fade,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { isEmpty, isNil, isString } from "lodash";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    border: `2px solid ${theme.palette.content.mainColor}`,
    borderRadius: 15,
    gap: 10,
  },
}));

const QueryActionLoaderModal = ({ open, msg }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <CircularProgress />
          {!isNil(msg) && isString(msg) && !isEmpty(msg) && (
            <Typography variant="h6">{msg}</Typography>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default QueryActionLoaderModal;
