import { makeStyles } from "@material-ui/core/styles";

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  margin: "0px 5px",
};

export const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    width: "100%",
    backgroundColor: `${theme.palette.content.backgroundColor}`,
    position: "relative",
    padding: "20px 10px",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    left: 10,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  titleTypoAndIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    width: "100%",
    backgroundColor: `${theme.palette.content.backgroundColor}AA`,
  },
  listItem: {
    backgroundColor: `${theme.palette.content.mainColor}AA`,
    borderRadius: 5,
  },
  emptyNotisContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyNotisBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
