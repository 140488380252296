import React, { useContext, useState } from "react";
import { Icon } from "@material-ui/core";
import { find, isEmpty, isNil, replace, some, startsWith } from "lodash";
import { useHistory } from "react-router-dom";

import { useStyles, iconAuto, closeIcon } from "./TabStyles";
import { useInteractiveItemStore } from "../../../../core/stores/InteractiveItemStore";
import { WORKSPACE_TAB } from "../../../../util/Constants";
import { LayoutContext } from "../../../../core/providers/LayoutContext";
import CloseSubTabDialog from "./dialogs/CloseSubTabDialog";
import SubHasChangedIcon from "./inner/SubHasChangedIcon";
import { notifyServerCleanFormInstanceId } from "../../../../util/UtilForm";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";

const ICONS_OPS = {
  new: "fas fa-plus",
  upd: "fas fa-edit",
  del: "fas fa-trash-alt",
  vie: "fas fa-eye",
};

const INITIAL_DIALOG_STATE = {
  index: null,
  sub: null,
  leave: false,
};

function getIconNameByOperation(operation) {
  if (isNil(operation) || isNil(ICONS_OPS[operation])) {
    return "fas fa-stream";
  }

  return ICONS_OPS[operation];
}

export default function SubTabsContainer() {
  const {
    subItems,
    activeTabItem,
    changeActiveSubItem,
    clearActiveSubItem,
    activeSubItem,
    removeSubTabItem,
    activeSubItemObject,
  } = useInteractiveItemStore();

  const { activeTab, showSubItemsInForm } = useContext(LayoutContext);
  const history = useHistory();
  const [leaveWork, setLeaveWork] = useState(INITIAL_DIALOG_STATE);

  const classes = useStyles({ showSubItemsInForm });

  const requestHeaders = useRequestHeaders();

  function isSubTabTheActiveOne(sub) {
    const activeInMap = activeSubItem.get(sub?.parentId);

    return (
      activeInMap?.id === sub?.id &&
      activeInMap?.count_number === sub?.count_number
    );
  }

  function isSubTabChanged(sub) {
    let result = false;
    if (!isNil(sub) && !isNil(sub.parentId) && !isNil(sub.entityName)) {
      const keyToSearch = `${sub.parentId}_${sub.entityName}`;
      const subToClose =
        !isNil(activeSubItemObject) && !isEmpty(activeSubItemObject)
          ? find(activeSubItemObject, {
              key: keyToSearch,
            })
          : null;

      result =
        !isNil(subToClose) &&
        !isNil(subToClose.data) &&
        !isNil(subToClose.data.formChanged) &&
        subToClose.data.formChanged === true;
    }

    return result;
  }

  function handleSubTabClick(index, sub) {
    if (index === 0) {
      clearActiveSubItem(sub);
      history.push(sub?.parentId, { ...sub });
    } else {
      const routeToMove = sub?.id || sub?.route;
      const finalRouteToMove = `/state/cleaner/middleware/${replace(
        startsWith(routeToMove, "/") ? routeToMove.substring(1) : routeToMove,
        new RegExp("/", "g"),
        "@"
      )}`;
      changeActiveSubItem(sub);
      history.push(finalRouteToMove, { ...sub });
    }
  }

  function handleSubTabCloseClick(index, sub) {
    let calculatedLeave = false;

    if (!isNil(sub) && !isNil(sub.parentId) && !isNil(sub.entityName)) {
      const keyToSearch = `${sub.parentId}_${sub.entityName}`;
      const subToClose =
        !isNil(activeSubItemObject) && !isEmpty(activeSubItemObject)
          ? find(activeSubItemObject, {
              key: keyToSearch,
            })
          : null;

      if (!isNil(subToClose)) {
        calculatedLeave =
          subToClose &&
          !isNil(subToClose.data) &&
          !isNil(subToClose.data.formChanged) &&
          subToClose.data.formChanged === true;
      }
    }

    //final decision, show dialog or close sub-tab directly
    if (calculatedLeave) {
      setLeaveWork({
        sub,
        index,
        leave: true,
      });
    } else {
      closeSubTab(index, sub);
    }
  }

  function closeSubTab(index, sub) {
    removeSubTabItem(sub);
    notifyServerCleanFormInstanceId({
      formInstanceId: find(activeSubItemObject, {
        key: `${sub?.parentId}_${sub?.entityName}`,
      })?.formInstanceId,
      requestHeaders,
    });
    if (isSubTabTheActiveOne(sub)) {
      const subItemsOfParent = subItems.filter(
        (x) => x?.parentId === sub?.parentId
      );
      if (!isNil(subItemsOfParent) && !isEmpty(subItemsOfParent)) {
        const indexToMove = index - 1;
        const subSelectedToMove =
          indexToMove > -1
            ? subItemsOfParent[indexToMove]
            : subItemsOfParent[0];
        if (!isNil(subSelectedToMove)) {
          const routeToMove = subSelectedToMove?.id || subSelectedToMove?.route;
          changeActiveSubItem(subSelectedToMove);
          history.push(routeToMove, { ...subSelectedToMove });
        }
      } else {
        const indexToMove = index - 1;
        const subSelectedToMove = subItems[indexToMove];
        if (!isNil(subSelectedToMove)) {
          const routeToMove = subSelectedToMove?.id || subSelectedToMove?.route;
          changeActiveSubItem(subSelectedToMove);
          history.push(routeToMove, { ...subSelectedToMove });
        }
      }
    }
  }

  function handleLeaveWorkAction(leave) {
    if (leave) {
      closeSubTab(leaveWork.index, leaveWork.sub);
    }
    setLeaveWork(INITIAL_DIALOG_STATE);
  }

  if (
    isNil(subItems) ||
    isEmpty(subItems) ||
    !some(subItems, { parentId: activeTabItem }) ||
    activeTab !== WORKSPACE_TAB
  ) {
    return <div />;
  }

  return (
    <>
      <CloseSubTabDialog
        open={leaveWork.leave}
        handleAction={handleLeaveWorkAction}
      />
      <div className={classes.subTabItemsContainer}>
        {subItems
          .filter((x) => x?.parentId === activeTabItem)
          .map((sub, index) => {
            const textToShow = sub?.propText || sub?.entityName;
            const hasChanged = isSubTabChanged(sub);
            const isTheActiveOne = isSubTabTheActiveOne(sub);
            const keyRoute = sub?.route || sub?.id;
            return (
              <div
                key={`${keyRoute}-${index}`}
                className={
                  isTheActiveOne ? classes.subItemActive : classes.subItem
                }
              >
                <button
                  className={classes.subTabButton}
                  onClick={() => handleSubTabClick(index, sub)}
                  style={{ fontWeight: isTheActiveOne && "bold" }}
                >
                  <Icon
                    className={getIconNameByOperation(sub?.operation)}
                    style={iconAuto}
                  />
                  {textToShow}
                  {hasChanged && <SubHasChangedIcon />}
                </button>
                {index > 0 && (
                  <button
                    onClick={() => handleSubTabCloseClick(index, sub)}
                    className={classes.closeButton}
                  >
                    <Icon className="fas fa-times" style={closeIcon} />
                  </button>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}
