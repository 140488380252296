import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  detailImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: 5,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      height: 508,
      minHeight: 508,
      width: 508,
      minWidth: 508,
    },
    [theme.breakpoints.up("xl")]: {
      height: 608,
      minHeight: 608,
      width: 608,
      minWidth: 608,
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: 120,
    objectFit: "contain",
  },
  detailImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  loadingSkeleton: {
    marginRight: 16,
  },
}));

const ProductImage = ({
  imageUrl,
  title,
  firm,
  isDetailImage,
  isCaruselLeftBarImage,
  isCarouselBigImage,
  onClick,
  className,
}) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(src);
        img.onerror = () => reject();
        img.src = src;
      });
    };

    const tryLoadImage = async () => {
      setIsLoading(true);
      const genericPlaceholder = `${process.env.PUBLIC_URL}/assets/img/employee-store/placeholders/placeholder-product-generic.webp`;

      // Plan A - The real image
      try {
        if (imageUrl) {
          await loadImage(imageUrl);
          setImageSrc(imageUrl);
          setIsLoading(false);
          return;
        }
      } catch (_) {}

      // Plan B - The placeholder for the brand
      try {
        if (firm) {
          const brandPlaceholder = `${process.env.PUBLIC_URL}/assets/img/employee-store/placeholders/brands/placeholder-${firm}.webp`;
          await loadImage(brandPlaceholder);
          setImageSrc(brandPlaceholder);
          setIsLoading(false);
          return;
        }
      } catch (_) {}

      // Plan C - Use generic placeholder that is always present
      setImageSrc(genericPlaceholder);
      setIsLoading(false);
    };

    tryLoadImage();
  }, [imageUrl, firm]);

  if (isLoading && isCarouselBigImage) {
    return (
      <Skeleton
        variant="rect"
        width={isXlUp ? 608 : 508}
        height={isXlUp ? 608 : 508}
      />
    );
  }

  if (isLoading) {
    return (
      <Skeleton
        variant="rect"
        width={100}
        height={100}
        className={classes.loadingSkeleton}
      />
    );
  }

  return (
    <div
      className={
        isDetailImage === true
          ? classes.detailImageContainer
          : classes.imageContainer
      }
      style={
        isCaruselLeftBarImage && {
          alignItems: "flex-start",
          height: "auto",
        }
      }
    >
      <img
        src={imageSrc}
        alt={title}
        className={`${className} ${
          isDetailImage === true ? classes.detailImage : classes.image
        } `}
        onClick={onClick}
      />
    </div>
  );
};

export default ProductImage;
