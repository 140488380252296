import React, { useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isNil, toLower, filter, isArray, isEmpty } from "lodash";
import { Icon, Paper, Typography } from "@material-ui/core";

import { IndeterminateCheckbox } from "./TableToolbarOptions";

const GRID_SPACE = 8;

export const useStyles = makeStyles(() => ({
  root: {
    fontSize: "3em",
  },
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    gap: 25,
  },
  itemLabel: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    gap: 5,
  },
}));

const commonColItemStyles = {
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  padding: GRID_SPACE / 2,
  margin: `0 0 ${GRID_SPACE}px 0`,
  borderRadius: 5,
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex + 1, 1);
  result.splice(endIndex + 1, 0, removed);

  const reorderedList =
    !isNil(result) && isArray(result) && !isEmpty(result)
      ? filter(result, function (x) {
          return (
            toLower(x?.id) !== "selection" &&
            toLower(x?.id) !== "selection-form"
          );
        })
      : [];

  return reorderedList;
};

function ColItem({ column, index, snapshot }) {
  const classes = useStyles();
  const materialTheme = useTheme();

  function getItemStyle(
    snapshot,
    isDraggingOver,
    draggableStyle,
    field,
    isDragDisabled
  ) {
    const isItemBeingDragged =
      isDraggingOver &&
      snapshot.draggingOverWith &&
      snapshot.draggingOverWith === field;

    return {
      // some basic styles to make the items look a bit nicer
      ...commonColItemStyles,
      // change background colour if dragging
      backgroundColor: isItemBeingDragged
        ? materialTheme.palette.genericTablePabel.panelConfigColumnBackgroundS
        : materialTheme.palette.genericTablePabel.panelConfigColumnBackground,
      borderLeft: isDragDisabled
        ? "grey"
        : `5px solid ${materialTheme.palette.content.mainColor}`,
      boxShadow:
        isDraggingOver &&
        snapshot.draggingOverWith &&
        snapshot.draggingOverWith === field
          ? "none"
          : "3px 3px 3px 0px rgba(50, 50, 50, 0.25)",
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }

  const isDragDisabled = useMemo(() => {
    return isNil(column?.field);
  }, [column]);

  return (
    <Draggable
      draggableId={column?.field || `col_${index}`}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div
          //Dnd props
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          //Custom style
          style={getItemStyle(
            snapshot,
            snapshot.isDraggingOver,
            provided.draggableProps.style,
            column?.field,
            isDragDisabled
          )}
        >
          <div key={`d_${column?.field}`} className={classes.itemContainer}>
            <div className={classes.itemLabel}>
              <input type="checkbox" {...column?.getToggleHiddenProps()} />
              <Typography>{column?.title}</Typography>
            </div>

            {!isDragDisabled ? (
              <Icon className="fas fa-arrows-alt" fontSize="small" />
            ) : (
              <Icon className="fas fa-lock" fontSize="small" />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}

function CustomPaperComponent(props) {
  return (
    <Paper
      {...props}
      style={{
        transform: "none",
        overflowY: "unset",
        margin: 32,
        position: "relative",
      }}
    />
  );
}

export default function ColsVisibilityDialog({
  open,
  handleClose,
  allColumns,
  getToggleHideAllColumnsProps,
  setColumnOrder,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const materialTheme = useTheme();

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    //Make new columns with new order
    const newColumns = reorder(
      allColumns,
      result.source.index,
      result.destination.index
    );

    setColumnOrder(newColumns.map((d) => d.id));
  }

  return (
    <Dialog open={open} PaperComponent={CustomPaperComponent}>
      <DialogTitle className={classes.root} id="create-report-view-dialog">
        {t("TABLE_PANEL_CONF_TAB_GROUPING_AC")}
      </DialogTitle>
      <DialogContent>
        <div>
          <div
            style={{
              ...commonColItemStyles,
              borderLeft: `5px solid ${materialTheme.palette.content.mainColor}`,
              backgroundColor: `${materialTheme.palette.genericTablePabel.panelConfigColumnBackground}`,
              boxShadow: "3px 3px 3px 0px rgba(50, 50, 50, 0.25)",
            }}
          >
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
            {t("REPORTS_VIEW_ALL")}
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable_cols_list_vis">
              {(provided, snapshot) => (
                <div
                  id={"droppable_cols_list_vis_provider"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: snapshot.isDraggingOver
                      ? `${materialTheme?.palette?.content?.mainColor}33`
                      : "transparent",
                    padding: GRID_SPACE,
                    width: "100%",
                    height: "100%",
                    borderRadius: 7,
                    overflowY: "auto",
                    paddingBottom: snapshot.isDraggingOver && 45,
                  }}
                >
                  {allColumns
                    .filter((col) => col?.id !== "selection")
                    .map((column, index) => (
                      <ColItem
                        key={column.id}
                        column={column}
                        snapshot={snapshot}
                        index={index}
                      />
                    ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
