import React, {
  useState,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Checkbox,
  Popover,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  filter,
  includes,
  isArray,
  isEmpty,
  isNil,
  isString,
  size,
  toUpper,
} from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { getLocaleText } from "../../../../../util/UtilTraduction";
import { flattenMenuItems } from "../../../../../util/UtilFormat";
import { DrawerContext } from "../../../../../core/providers/DrawerContext";
import FormHistorySelect from "../../../../../components/drawer/components/FormHistorySelect";
import { makeStyles } from "@material-ui/core";

const commonIcon = {
  width: "auto",
  height: "auto",
  fontSize: 12,
  padding: 1,
  marginRight: 3,
};

const DIALOG_ID = "app-bar-search-filter-dialog";

const NAME_ACC_PARENTS = "parents";
const NAME_ACC_TYPES = "types";

const ID_TYPE_ITEM_FORM = "1";
const ID_TYPE_ITEM_QUERY = "2";
const ID_TYPE_ITEM_MANAGER = "3";
const ID_TYPE_ITEM_APP = "4";
const ID_TYPE_ITEM_ANALYSIS = "5";

const MAP_TYPES_ITEM = {
  [ID_TYPE_ITEM_FORM]: "SEARCH_BAR_FILTER_TYPE_ITEM_FORM",
  [ID_TYPE_ITEM_QUERY]: "SEARCH_BAR_FILTER_TYPE_ITEM_QUERY",
  [ID_TYPE_ITEM_MANAGER]: "SEARCH_BAR_FILTER_TYPE_ITEM_MANAGER",
  [ID_TYPE_ITEM_APP]: "SEARCH_BAR_FILTER_TYPE_ITEM_APP",
  [ID_TYPE_ITEM_ANALYSIS]: "SEARCH_BAR_FILTER_TYPE_ITEM_ANALYSIS",
};

function arrayToObject(arr) {
  return arr.reduce(function (obj, item) {
    obj[item] = true;
    return obj;
  }, {});
}

function countCheckedProperty(obj) {
  let count = 0;
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === true) {
      count++;
    }
  }
  return count;
}

function foundItemType(item) {
  const { formName, formPath, route } = item;
  const hasRoute = !isNil(route) && isString(route) && !isEmpty(route);

  if (hasRoute && includes(toUpper(route), toUpper("consultas/v2"))) {
    return ID_TYPE_ITEM_QUERY;
  }

  if (hasRoute && includes(toUpper(route), toUpper("dashboard"))) {
    return ID_TYPE_ITEM_MANAGER;
  }

  if (hasRoute && includes(toUpper(route), toUpper("analysis"))) {
    return ID_TYPE_ITEM_ANALYSIS;
  }

  if (!isNil(formName) && !isNil(formPath)) {
    return ID_TYPE_ITEM_FORM;
  }

  // default
  return ID_TYPE_ITEM_APP;
}

const useStyles = makeStyles((theme) => ({
  searchBarContainer: {
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    [theme.breakpoints.down(1025)]: {
      maxWidth: 200,
    },
  },
  solutionP: {
    fontWeight: 500,
  },
  filterBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },
  filterBtnIcon: {
    ...commonIcon,
  },
  searchIcon: {
    ...commonIcon,
    color: "lightgrey",
  },
  popoverContainer: {
    padding: 6,
    zIndex: 9999999999,
    width: 375,
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 1,
  },
  dialogContent: {
    maxHeight: 600,
  },
  dialogAccordion: {
    marginBottom: 10,
  },
  formGroupGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
    paddingInline: 6,
  },
  commonActionsContainer: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    padding: "5px 0px",
  },
  accordionSummary: {
    border:
      theme.palette.type === "dark"
        ? `1px solid ${theme.palette.content.mainColor}22`
        : null,
    borderRadius: "inherit",
  },
  inputAdornmentStart: {
    [theme.breakpoints.down(1025)]: {
      marginRight: 0,
    },
  },
}));

export default function AppBarSearch({ menuItems, pushPage }) {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);
  const [checkedParents, setCheckedParents] = useState({});
  const [checkedTypes, setCheckedTypes] = useState({});
  const [expanded, setExpanded] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const [temporalCheckedParents, setTemporalCheckedParents] = useState({});
  const [temporalCheckedTypes, setTemporalCheckedTypes] = useState({});

  const { changeHeaderImg } = useContext(DrawerContext);
  const classes = useStyles();

  const handleSelectItemMenu = useCallback(
    (e, menuItem) => {
      if (menuItem && menuItem?.route && isOpen) {
        const {
          headerImg,
          route,
          propText,
          formPath,
          formName,
          name,
          isExecution,
          defaultPinned,
        } = menuItem;
        setValue(null);
        setInputValue("");
        changeHeaderImg(headerImg);
        pushPage({
          route,
          propText,
          path: formPath,
          idForm: formName,
          name,
          isExecution,
          defaultPinned,
        });
      }
    },
    [changeHeaderImg, isOpen, pushPage]
  );

  function handleStateSearchBarFilters({ e, open, inputValue, value }) {
    setOpenFiltersDialog(open);
    setAnchorEl(e.currentTarget);
    setTimeout(() => {
      setInputValue(inputValue);
      setValue(value);
    }, 100);
  }

  const flattenedMenuItems = useMemo(() => {
    if (!menuItems || !isArray(menuItems)) {
      return [];
    } else {
      return flattenMenuItems(menuItems);
    }
  }, [menuItems]);

  const parents = useMemo(() => {
    return isNil(flattenedMenuItems) ||
      isEmpty(flattenedMenuItems) ||
      !isArray(flattenedMenuItems)
      ? []
      : [
          ...new Set(
            filter(flattenedMenuItems, function (option) {
              return (
                !isNil(option.parent) &&
                !isEmpty(option.parent) &&
                isArray(option.parent)
              );
            }).map((x) => {
              return getLocaleText(x.parent[0], i18n);
            })
          ),
        ].sort((a, b) => a.localeCompare(b));
  }, [flattenedMenuItems, i18n]);

  const types = useMemo(() => {
    return isNil(flattenedMenuItems) ||
      isEmpty(flattenedMenuItems) ||
      !isArray(flattenedMenuItems)
      ? []
      : [
          ...new Set(
            flattenedMenuItems
              .filter((x) => !isNil(x))
              .map((x) => {
                return foundItemType(x);
              })
          ),
        ].sort((a, b) => a.localeCompare(b));
  }, [flattenedMenuItems]);

  const autoCompleteOptions = useMemo(() => {
    if (
      isNil(flattenedMenuItems) ||
      !isArray(flattenedMenuItems) ||
      isEmpty(flattenedMenuItems)
    ) {
      return [];
    }

    const typesFilterApplied = countCheckedProperty(checkedTypes) > 0;
    const parentsFilterApplied = countCheckedProperty(checkedParents) > 0;

    if (typesFilterApplied || parentsFilterApplied) {
      return filter(flattenedMenuItems, function (option) {
        let result = true;

        if (!isNil(option) && !isEmpty(option)) {
          if (
            parentsFilterApplied &&
            !isNil(option.parent) &&
            !isEmpty(option.parent) &&
            isArray(option.parent)
          ) {
            const textOfParent = getLocaleText(option.parent[0], i18n);
            result = checkedParents[textOfParent] === true;
          }

          if (typesFilterApplied && result === true) {
            const itemType = foundItemType(option);
            result = checkedTypes[itemType] === true;
          }
        } else {
          result = false;
        }

        return result;
      });
    }

    return flattenedMenuItems;
  }, [flattenedMenuItems, checkedTypes, checkedParents, i18n]);

  const handleClickParent = (event) => {
    setTemporalCheckedParents({
      ...temporalCheckedParents,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClickAll = (name) => {
    if (name === NAME_ACC_PARENTS) {
      const alreadyTemporal = countCheckedProperty(temporalCheckedParents);
      if (alreadyTemporal === size(parents)) {
        setTemporalCheckedParents({});
      } else {
        setTemporalCheckedParents(arrayToObject(parents));
      }
    } else if (name === NAME_ACC_TYPES) {
      const alreadyTemporal = countCheckedProperty(temporalCheckedTypes);
      if (alreadyTemporal === size(types)) {
        setTemporalCheckedTypes({});
      } else {
        setTemporalCheckedTypes(arrayToObject(types));
      }
    }
  };

  const handleClickTypes = (event) => {
    setTemporalCheckedTypes({
      ...temporalCheckedTypes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClickLevel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  function handleApplyFilters() {
    setCheckedParents(temporalCheckedParents);
    setCheckedTypes(temporalCheckedTypes);
    setOpenFiltersDialog(false);
    focusTextInput();
  }

  function handleCancelFilters() {
    setTemporalCheckedParents(checkedParents);
    setTemporalCheckedTypes(checkedTypes);
    setOpenFiltersDialog(false);
    focusTextInput();
  }

  function handleCleanFilters() {
    setOpenFiltersDialog(false);
    setCheckedParents({});
    setCheckedTypes({});
    setTemporalCheckedParents({});
    setTemporalCheckedTypes({});
    focusTextInput();
  }

  function focusTextInput() {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
    }
  }

  const badgeCount = useMemo(() => {
    let result = 0;
    const typesFilterApplied = countCheckedProperty(checkedTypes) > 0;
    const parentsFilterApplied = countCheckedProperty(checkedParents) > 0;

    if (typesFilterApplied) result++;
    if (parentsFilterApplied) result++;

    return result;
  }, [checkedTypes, checkedParents]);

  if (!menuItems || !isArray(menuItems)) {
    return null;
  }

  return (
    <div id="app_bar_search_container" className={classes.searchBarContainer}>
      <Autocomplete
        openOnFocus
        autoHighlight
        size="small"
        id="app_bar_search"
        value={value}
        options={autoCompleteOptions.filter(
          (x) => isNil(x?.isKpi) || x?.isKpi === false
        )}
        onChange={(e, m) => {
          handleSelectItemMenu(e, m);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => {
          return getLocaleText(option.propText, i18n);
        }}
        renderOption={(option) => (
          <div>
            <div>
              <Icon
                className={option.name || "fas fa-circle"}
                style={{
                  fontSize: 10,
                  marginRight: 5,
                  padding: 1,
                  width: "auto",
                  height: "auto",
                }}
              />
              {getLocaleText(option.propText, i18n)}
            </div>
            {!isNil(option.parent) &&
              !isEmpty(option.parent) &&
              isArray(option.parent) && (
                <label className={classes.solutionP}>
                  {getLocaleText(option.parent[0], i18n)}
                </label>
              )}
          </div>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputRef={inputRef}
              variant="outlined"
              onKeyDown={(e) => {
                if (e?.key === "F2") {
                  handleCleanFilters();
                }
              }}
              InputProps={{
                ...params?.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornmentStart}
                    >
                      <Icon className={`fas fa-search ${classes.searchIcon}`} />
                    </InputAdornment>
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornmentStart}
                    >
                      <IconButton
                        onClick={(e) => {
                          handleStateSearchBarFilters({
                            e,
                            open: true,
                            inputValue,
                            value,
                          });
                        }}
                        className={classes.filterBtn}
                      >
                        <Badge
                          variant="dot"
                          badgeContent={badgeCount}
                          color="primary"
                        >
                          <Icon
                            className={`fas fa-filter ${classes.filterBtnIcon}`}
                          />
                        </Badge>
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          );
        }}
        style={{
          width: 225,
          marginLeft: 10,
        }}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      <FormHistorySelect />
      <Popover
        aria-labelledby={DIALOG_ID}
        open={openFiltersDialog}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popoverContainer }}
      >
        <div id={`${DIALOG_ID}-title`} className={classes.dialogTitle}>
          <Typography variant="h6">{t("INBOX_FILTER_DIALOG_TITLE")}</Typography>
          <Tooltip title={t("FORM_CANCEL")}>
            <IconButton onClick={() => handleCancelFilters()}>
              <Icon className="fas fa-times" />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.dialogContent}>
          {!isNil(parents) && !isEmpty(parents) && (
            <Accordion
              expanded={expanded === NAME_ACC_PARENTS}
              onChange={handleClickLevel(NAME_ACC_PARENTS)}
              className={classes.dialogAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${NAME_ACC_PARENTS}-content`}
                id={`${NAME_ACC_PARENTS}-header`}
                className={classes.accordionSummary}
              >
                <Typography className={classes.heading}>
                  {countCheckedProperty(temporalCheckedParents) > 0
                    ? `${t("SOLUTIONS_COMBO_LABEL")} (${countCheckedProperty(
                        temporalCheckedParents
                      )})`
                    : t("SOLUTIONS_COMBO_LABEL")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup className={classes.formGroupGrid}>
                  <FormControlLabel
                    key={"ALL"}
                    control={
                      <Checkbox
                        checked={
                          countCheckedProperty(temporalCheckedParents) ===
                            parents.length || false
                        }
                        onChange={() => handleClickAll(NAME_ACC_PARENTS)}
                        name={"ALL"}
                      />
                    }
                    label={t("RECORDS_PANEL_GROUPING_ALL")}
                  />
                  {parents.map((x) => {
                    return (
                      <FormControlLabel
                        key={x}
                        control={
                          <Checkbox
                            checked={temporalCheckedParents[x] || false}
                            onChange={handleClickParent}
                            name={x}
                          />
                        }
                        label={x}
                      />
                    );
                  })}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}

          {!isNil(types) && !isEmpty(types) && (
            <Accordion
              expanded={expanded === NAME_ACC_TYPES}
              onChange={handleClickLevel(NAME_ACC_TYPES)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${NAME_ACC_TYPES}-content`}
                id={`${NAME_ACC_TYPES}-header`}
                className={classes.accordionSummary}
              >
                <Typography className={classes.heading}>
                  {countCheckedProperty(temporalCheckedTypes) > 0
                    ? `Tipos de Item (${countCheckedProperty(
                        temporalCheckedTypes
                      )})`
                    : "Tipos de Item"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    key={"ALL"}
                    control={
                      <Checkbox
                        checked={
                          countCheckedProperty(temporalCheckedTypes) ===
                            types.length || false
                        }
                        onChange={() => handleClickAll(NAME_ACC_TYPES)}
                        name={"ALL"}
                      />
                    }
                    label={t("RECORDS_PANEL_GROUPING_ALL")}
                  />
                  {types.map((x) => {
                    return (
                      <FormControlLabel
                        key={x}
                        control={
                          <Checkbox
                            checked={temporalCheckedTypes[x] || false}
                            onChange={handleClickTypes}
                            name={x}
                          />
                        }
                        label={t(MAP_TYPES_ITEM[x])}
                      />
                    );
                  })}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        <div className={classes.dialogActions}>
          <Button
            onClick={() => handleCleanFilters()}
            className={classes.commonActionsContainer}
          >
            <Icon className="fas fa-trash-alt" style={commonIcon} />
            <Typography>{t("ANALYSIS_CLEAN_FILTERS")}</Typography>
          </Button>

          <div className={classes.commonActionsContainer}>
            <Button
              onClick={() => handleApplyFilters()}
              color="primary"
              className={classes.commonActionsContainer}
            >
              <Icon className="fas fa-play-circle" style={commonIcon} />
              <Typography>{t("SEARCH_FILTER_APPLY")}</Typography>
            </Button>

            <Button
              onClick={() => handleCancelFilters()}
              className={classes.commonActionsContainer}
            >
              <Icon className="fas fa-arrow-circle-left" style={commonIcon} />

              <Typography>{t("FORM_CANCEL")}</Typography>
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
