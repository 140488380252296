import React from "react";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: ({ color }) => (color ? color : "#7FE43C"),
    color: "white",
    boxShadow: `0 0 0 2px ${theme.palette.header.backgroundColor}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 5s infinite ease-in-out",
      border: ({ color }) =>
        color ? `1px solid ${color}` : `1px solid #7FE43C`,
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const StyledBadge = ({ children, color, handleBadgeClick }) => {
  const classes = useStyles({ color });

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
      classes={{ badge: classes.badge }}
      onClick={handleBadgeClick}
    >
      {children}
    </Badge>
  );
};
