import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import { isNil } from "lodash";

import LiveClock from "../../../../../components/clock/live/LiveClock";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  userInitials: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 14,
    color: theme.palette.content.mainColorContrastText,
    marginRight: 10,
  },
  usernameTypo: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
    },
  },
}));

export default function UsernameAndClock() {
  const classes = useStyles();
  const { auth } = useContext(AuthContext);
  const { i18n } = useTranslation();

  if (isNil(auth)) {
    return null;
  }
  return (
    <div className={classes.userInitials}>
      <Typography className={classes.usernameTypo}>{auth?.userName}</Typography>
      <LiveClock lang={i18n?.language} />
    </div>
  );
}
