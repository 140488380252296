import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { AuthContext } from "../core/providers/AuthContext";
import { InteractionStatus } from "@azure/msal-browser";

/*function PrivateRoute({ children, ...rest }) {
  let history = useHistory();
  const { auth } = useContext(AuthContext);
  if (auth.isLogged === false) {
    history.push("/login");
  }
  return <Route {...rest} render={() => children} />;
}*/

function PrivateRoute({ component: Component, ...rest }) {
  const { auth } = useContext(AuthContext);
  const { inProgress } = useMsal();
  const { state } = useLocation();

  if (
    auth.isLoading ||
    inProgress === InteractionStatus.Login ||
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect
  ) {
    return <div />;
  }
  
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isLogged === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state,
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
