import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { HEADER_HEIGHT } from "./header/EmployeeStoreHeader";

const useStyles = makeStyles(() => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: "#ffffff",
    backgroundImage:
      "repeating-radial-gradient(circle at 0 0, transparent 0, #ffffff 150px), repeating-linear-gradient(#0b9cd705, #0b9cd707, #0b9cd709)",
  },
}));

export default function EmployeeStorePageWrapper({ children }) {
  const classes = useStyles();

  return <Box className={classes.pageContainer}>{children}</Box>;
}
