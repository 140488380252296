import { makeStyles } from "@material-ui/core/styles";

const PADDING_MODAL = 0;

export const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const OVERLAY_LIGHT = "rgb(0 0 0 / 40%)";
const OVERLAY_DARK = "#D3D3D344";

const commonCustomModalStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-48%, -51%)",
    padding: PADDING_MODAL,
    border: "none",
    backgroundColor: "transparent",
    borderRadius: 10,
  },
};

export const customModalStylesLight = {
  overlay: {
    backgroundColor: OVERLAY_LIGHT,
    zIndex: 1300,
  },
  ...commonCustomModalStyles,
};

export const customModalStylesDark = {
  overlay: {
    backgroundColor: OVERLAY_DARK,
    zIndex: 1300,
  },
  ...commonCustomModalStyles,
};

export const useStyles = makeStyles(() => ({
  closeModalButton: {
    padding: 4,
    position: "absolute",
    top: 5,
    right: 5,
  },
}));
