import { get, has, isEmpty, isNil, isNumber, isObject, isString } from "lodash";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function makeURLSearchParamsByObject(obj) {
  const queryParams = [];

  if (!isNil(obj) && !isEmpty(obj) && isObject(obj)) {
    for (const key in obj) {
      if (has(obj, key) && !isNil(get(obj, key))) {
        const value = get(obj, key);
        if (isString(value) && !isEmpty(value)) {
          queryParams.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          );
        } else if (isNumber(value)) {
          queryParams.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          );
        }
      }
    }
  }

  if (!isEmpty(queryParams)) {
    const queryString = queryParams
      .filter((x) => !isNil(x) && !isEmpty(x))
      .join("&");
    return `?${queryString}`;
  } else {
    return "";
  }
}

export function useQueryParameters() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
