import { useContext } from "react";

import { AuthContext } from "../providers/AuthContext";
import {
  TOKEN_HEADER,
  REST_HEADERS,
  TOKEN_INIT,
  TIME_OFFSET_HEADER,
} from "../../util/Constants";
import { getTimeOffsetInHours } from "../../util/UtilDates";

export const useRequestHeaders = () => {
  const { auth } = useContext(AuthContext);

  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
    [TIME_OFFSET_HEADER]: getTimeOffsetInHours(),
  };

  return REQUEST_HEADERS;
};
