import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from "@material-ui/core";
import * as _ from "lodash";
import {
  defineInputEnabledByOperation,
  getCompletePath,
} from "../../../util/UtilForm";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
//import { ERROR_BAD_FORM_DEF_COLOR } from "../../../util/Constants";

export default function SimpleCheckboxField(props) {
  //#region [Definitions]
  const { idSchema, schema, formData, formContext } = props;
  //Destr important attrs
  const {
    value: checkValue,
    presentationInfo: pi,
    isSavedEntity,
  } = formData || false;
  const [isEnabled, setIsEnabled] = useState(true);
  const {
    operation,
    section,
    block,
    formInstanceId,
    notifySetValueToServer,
    pre,
    isBlockDisabledByWizard
  } = formContext;
  const checkboxRef = useRef(null);
  const [presentationInfo, setPresentationInfo] = useState(null);
  const { type } = schema;

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });
  //#endregion

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      schema?.canEdit,
      schema?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
  }, [operation, schema]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  const handleChange = (event) => {
    const checked = event.target.checked;

    const checkedSt = checked ? "S" : "N";
    const checkValueToSet =
      type && type.toLowerCase() === "string" ? checkedSt : checked;

    //Line always 0, its head field
    notifySetValueToServer(
      formInstanceId,
      completeIdControl,
      checkValueToSet,
      0,
      checkboxRef
    );
  };

  const inputTextAlign = schema?.textAlign || "left";

  //Input styles
  const controlStyles = {
    padding: 0,
    borderRadius: 0,
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo &&
      presentationInfo.backgroundColor &&
      presentationInfo.backgroundColor,
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
  };

  // const errorStyles = errorMap.some((e) => e && e.key === completeIdControl)
  //   ? {
  //       color: ERROR_BAD_FORM_DEF_COLOR,
  //     }
  //   : {};

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      childAlign={inputTextAlign}
      presentationInfo={presentationInfo}
    >
      <Checkbox
        checked={
          !_.isNil(checkValue) &&
          (checkValue === "true" || checkValue === true || checkValue === "S")
            ? true
            : false
        }
        onChange={handleChange}
        inputProps={{ "aria-label": `${schema?.title}` }}
        disabled={
          !isEnabled ||
          (presentationInfo && presentationInfo?.enabled === false) ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard === true
        }
        size="small"
        inputRef={checkboxRef}
        style={controlStyles}
        //style={{ ...controlStyles, ...errorStyles }}
        id={completeIdControl}
      />
    </SimpleFieldContainer>
  );
}
