import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { IconButton, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useReportsStore } from "../../../core/stores/ReportsStore";

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  }
);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
}));

const TableToolbarOptions = ({
  getToggleAllRowsExpandedProps,
  isAllRowsExpanded,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const setSelColsOpened = useReportsStore((state) => state.setSelColsOpened);

  const handleClick = (event) => {
    setSelColsOpened({ open: true });
  };

  return (
    <div className={classes.container}>
      <Tooltip title={t("REPORTS_EXPAND")}>
        <IconButton
          aria-label="visibility"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={() => getToggleAllRowsExpandedProps().onClick()}
        >
          {isAllRowsExpanded ? <ArrowDownwardIcon /> : <ArrowForwardIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title={t("REPORTS_SHOW")}>
        <IconButton
          id="toggle-sel-menu-cols"
          aria-label="visibility"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default TableToolbarOptions;
