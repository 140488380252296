import React, { useContext } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AuthContext } from "../../../core/providers/AuthContext";
import PrivateRoute from "../../../routes/PrivateRoute";
import NotFound from "../../../components/notFound/NotFound";
import EmployeeStoreLayout from "../layout/EmployeeStoreLayout";
import EmployeeStoreHomePage from "../pages/EmployeeStoreHomePage";
import EmployeeStoreHistoryPage from "../pages/EmployeeStoreHistoryPage";
import EmployeeStoreShoppingCartPage from "../pages/EmployeeStoreShoppingCartPage";
import EmployeeStoreFavsPage from "../pages/EmployeeStoreFavsPage";
import EmployeeStoreCheckoutPage from "../pages/EmployeeStoreCheckoutPage";

export const EMPLOYEE_STORE_PREFIX_ROUTE = "/employee/store/";
export const EMPLOYEE_STORE_HOME_ROUTE = "home";
export const EMPLOYEE_STORE_HISTORY_ROUTE = "history";
export const EMPLOYEE_STORE_SHOPPING_CART_ROUTE = "shopping-cart";
export const EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE = "favorite";
export const EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE = "checkout";

const DEFINED_REDIRECT_URL_FOR_ENV =
  process.env.REACT_APP_EMS_REDIRECT_URL_PREFIX;
const ACTUAL_ORIGIN = window.location.origin;
const HOST_TO_USE =
  DEFINED_REDIRECT_URL_FOR_ENV === "https://doits-suite.vercel.app" &&
  ACTUAL_ORIGIN !== DEFINED_REDIRECT_URL_FOR_ENV
    ? ACTUAL_ORIGIN
    : DEFINED_REDIRECT_URL_FOR_ENV;

export const EMS_REDIRECT_PAYMENT_URL = `${HOST_TO_USE}${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE}`;

const EmployeeStoreRouter = () => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  const isHomePage =
    location.pathname ===
    `${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_HOME_ROUTE}`;

  if (!auth.emsEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>DSuite - Employee Store</title>
      </Helmet>
      <EmployeeStoreLayout isHomePage={isHomePage}>
        <Switch>
          <PrivateRoute
            key={EMPLOYEE_STORE_HOME_ROUTE}
            exact
            path={`${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_HOME_ROUTE}`}
            component={EmployeeStoreHomePage}
          />
          <PrivateRoute
            key={EMPLOYEE_STORE_HISTORY_ROUTE}
            exact
            path={`${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_HISTORY_ROUTE}`}
            component={EmployeeStoreHistoryPage}
          />

          <PrivateRoute
            key={EMPLOYEE_STORE_SHOPPING_CART_ROUTE}
            exact
            path={`${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_CART_ROUTE}`}
            component={EmployeeStoreShoppingCartPage}
          />

          <PrivateRoute
            key={EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE}
            exact
            path={`${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE}`}
            component={EmployeeStoreFavsPage}
          />

          <PrivateRoute
            key={EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE}
            exact
            path={`${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_SHOPPING_CHECKOUT_ROUTE}`}
            component={EmployeeStoreCheckoutPage}
          />

          <Route component={NotFound} />
        </Switch>
      </EmployeeStoreLayout>
    </>
  );
};

export default EmployeeStoreRouter;
