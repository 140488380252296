import { makeStyles } from "@material-ui/core/styles";
import {
  appBarHeight,
  drawerWidth,
} from "../../../shared/layout/LayoutStylesGlobals";
import { BREAKPOINT_SM } from "../../../util/Constants";

export const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const useStyles = makeStyles((theme) => ({
  fetchingSpinnerContainer: {
    display: "flex",
    flex: "1",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  fetchingSpinner: { color: theme.palette.content.mainColor },
  mainContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
    borderRadius: "20px",
    width: "100%",
  },
  contentContainerRow: {
    display: "flex",
    flex: 1,
    //marginLeft: "15px",
    //width: "calc(100% - 30px)",
    width: "100%",
    backgroundColor: theme.palette.form.background,
  },
  contentContainerColumn: {
    backgroundColor: theme.palette.form.background,
    display: "flex",
    //IF tabs to horizontal uncomment the line below
    flexDirection: "column",
    flex: 1,
    //marginLeft: "15px",
    width: "100%",
    //width: "calc(100% - 30px)",
  },
  tabsContainer: {
    borderBottom: `1px solid grey`,
    marginBottom: 5,
    maxWidth: ({ openDrawer }) =>
      openDrawer === true
        ? `calc(100vw - ${drawerWidth}px)`
        : `calc(100vw - ${appBarHeight}px)`,
  },
  tabIndicatorProps: {
    background: theme.palette.content.mainColor,
    width: "5px",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
  },
  tabRoot: {
    display: "-webkit-inline-box",
    textAlign: "left",
    "&:active": {
      fontWeight: "bold",
    },
  },
  tabIconWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 5
  },
  tabLabelContainer: {
    width: "auto",
    padding: 0
  },
  tabLabelIcon: {
    paddingTop: 9,
    minHeight: "inherit"
  },
  formContainer: {
    width: "100%",
    height: "100%",
    margin: "0px 10px 10px 0px",
  },
  gridLayout: {
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  formHeader: {
    //backgroundColor: theme?.palette?.form?.headerBg,
    width: "100%",
    padding: 6,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //boxShadow: "0px -1px 8px -3px #CDCDCD",
    borderBottom: `1px solid ${theme.palette.content.mainColor}55`,
    position: "relative",
    minHeight: 30,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  footer: {
    //backgroundColor: theme?.palette?.header?.backgroundColor,
    width: "100%",
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 45,
    //boxShadow: "0px -1px 8px -3px #CDCDCD",
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },
  },
  footerStartButtonGroup: {
    marginLeft: 10,
    display: "flex",
    columnGap: "10px",
  },
  editButtonCommon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh", maxHeight: 28,
    color: theme.palette.content.mainColor,
    cursor: "pointer",
    fontWeight: "700",
    outline: "none",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.content.mainColor}`,
    marginLeft: 10,
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  editButtonCommonSelected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh", maxHeight: 28,
    color: "white",
    cursor: "pointer",
    fontWeight: "700",
    border: "none",
    outline: "none",
    backgroundColor: theme.palette.content.mainColor,
    marginLeft: 10,
    transform: "scale(1.1)",
  },
  deleteButtonCommon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh", maxHeight: 28,
    backgroundColor: "transparent",
    color: "rgb(109, 109, 109)",
    cursor: "pointer",
    fontWeight: "700",
    border: "1px solid #f87979",
    margin: "0px 10px",
    outline: "none",
    "&:hover": {
      border: "1px solid red",
      transform: "scale(1.1)",
    },
  },
  deleteButtonCommonSelected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh", maxHeight: 28,
    backgroundColor: "#f87979",
    color: "rgb(109, 109, 109)",
    cursor: "pointer",
    fontWeight: "700",
    border: "1px solid #f87979",
    margin: "0px 10px",
    outline: "none",
    transform: "scale(1.1)",
  },
  footerEndButtonGroup: {
    marginRight: 10,
    display: "flex",
    columnGap: "10px",
  },
  decisionButtonForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "6vw",
    height: "3vh", maxHeight: 28,
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: theme.palette.content.mainColorContrastText,
    "&:disabled": {
      color: "grey",
      cursor: "not-allowed",
    },
    "&:hover": {
      transform: "scale(1.1)",
      borderBottom: `2px solid ${theme.palette.content.mainColor}`,
    },
  },
  responsiveGridLayout: {
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
  },
  formNameDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: ({ wd }) =>
      wd && wd.width > BREAKPOINT_SM ? "absolute" : "relative",
    right: 0,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 3,
    },
  },
  iconButtonOpenTrazability: {
    padding: 0,
  },
  pinButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh", maxHeight: 28,
    backgroundColor: "transparent",
    color: theme.palette.content.mainColorContrastText,
    cursor: "pointer",
    fontWeight: "700",
    border: `1px solid ${theme.palette.content.mainColorContrastText}`,
    margin: "0px 10px",
    outline: "none",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  pinButtonAdded: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    height: "2.5em",
    width: "4vh",
    backgroundColor: "transparent",
    color: theme.palette.content.mainColor,
    cursor: "pointer",
    fontWeight: "700",
    border: `1px solid ${theme.palette.content.mainColor}`,
    margin: "0px 10px",
    outline: "none",
    transform: "rotate(90deg)",
  },
  actionTypo: {
    fontSize: "1.05rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.15rem",
    },
  },
}));
