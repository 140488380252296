import React from "react";
import { useTranslation } from "react-i18next";

export default function CustomBodyToast({ title, msg, lastInputErrorValue }) {
  const { t } = useTranslation();
  return (
    <div style={{ overflowWrap: "anywhere" }}>
      {title && title !== "" ? <p>{title}</p> : <p>{t("FORM_ERROR_ACTION")}</p>}
      {msg && msg !== "" && <p>• {msg}</p>}
      {lastInputErrorValue && lastInputErrorValue !== "" && (
        <p>
          • {t("FORM_ERROR_LAST_INPUT_ERROR_VALUE")}: {lastInputErrorValue}
        </p>
      )}
    </div>
  );
}
