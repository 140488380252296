import React, { useContext } from "react";
import {
  IconButton,
  Icon,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import Dock from "react-dock";
import { useTranslation } from "react-i18next";

import { useStyles, commonIconStyles } from "./NotificationsDockStyles";
import {
  MODE_NOTIS_EDIT,
  MODE_NOTIS_LIST,
  NotificationsContext,
} from "../../core/providers/NotificationsContext";
import { refreshPage } from "../../util/util-io";
import useDockSize from "../../core/hooks/useDockSize";
import Notifications from "../../solutions/notifications/pages/Notifications";

const NOTIFICATIONS_CONFIG_ENALED =
  process.env.REACT_APP_NOTIFICATIONS_CONFIG_ENABLED === "y";

function NotificationsDock() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isNotificationsDockOpen,
    handleNotificationsDock,
    newVersion,
    mode,
    toggleMode,
  } = useContext(NotificationsContext);
  const { dockSize, handleDockSize } = useDockSize();

  return (
    <Dock
      position="right"
      isVisible={isNotificationsDockOpen}
      size={dockSize}
      onSizeChange={handleDockSize}
    >
      <Paper className={classes.paperContainer}>
        <div className={classes.titleContainer}>
          <IconButton onClick={() => handleNotificationsDock({ open: false })}>
            <Icon className="fas fa-times" />
          </IconButton>
          <div className={classes.titleTypoAndIcon}>
            <Typography variant="h5">{t("NOTIFICATIONS_TITLE")}</Typography>
            <Icon className="fas fa-bell" style={commonIconStyles} />
          </div>
          {NOTIFICATIONS_CONFIG_ENALED ? (
            <IconButton onClick={() => toggleMode()}>
              <Icon
                className={
                  mode === MODE_NOTIS_EDIT ? "fas fa-list" : "fas fa-cog"
                }
              />
            </IconButton>
          ) : (
            <div />
          )}
        </div>

        {mode === MODE_NOTIS_LIST ? (
          <>
            {newVersion && newVersion !== "" ? (
              <List className={classes.list}>
                <>
                  <ListItem
                    alignItems="center"
                    button
                    onClick={refreshPage}
                    className={classes.listItem}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt="Doits Logo"
                        src="/assets/img/DSUITE_ISOTYPE_NEW.webp"
                        style={{ backgroundColor: "white", padding: 6 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={t("NEW_VERSION_AVAILABLE")}
                      secondary={t("NEW_VERSION_TEXT")}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              </List>
            ) : (
              <div className={classes.emptyNotisContainer}>
                <div className={classes.emptyNotisBody}>
                  <Icon
                    className="fas fa-parachute-box"
                    style={{ ...commonIconStyles, margin: 0, fontSize: 50 }}
                  />
                  <Typography variant="h6">{t("EMPTY_NOTIS")}</Typography>
                </div>
              </div>
            )}
          </>
        ) : (
          <Notifications hideTitleIcon={true} />
        )}
      </Paper>
    </Dock>
  );
}

export default NotificationsDock;
