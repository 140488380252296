import parse from "html-react-parser";
import { isNil } from "lodash";

import { getStringFromBase64String } from "../../../../util/UtilFormat";

export default function HtmlHelpBody({ bytes }) {
  const htmlString = getStringFromBase64String(bytes);
  if (isNil(htmlString)) {
    return null;
  }

  return parse(htmlString);
}
