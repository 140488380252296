import React, { useState } from "react";
import { includes, isEmpty, isNil, size } from "lodash";
import { Box, Tab, Tabs } from "@material-ui/core";
import ResolveHelpBody from "./ResolveHelpBody";
import { getFileNameWithouExtension } from "../../../../util/UtilFile";

const IGNORED_EXTENSIONS = ["doc", "docx"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`help-file-tabpanel-${index}`}
      {...other}
      style={{ overflow: "auto", height: "100%", width: "100%" }}
    >
      {value === index && <Box position="relative">{children}</Box>}
    </div>
  );
}

export default function FilesHelpBody({ files: filesProps }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isNil(filesProps) || isEmpty(filesProps)) {
    return null;
  }

  const files = filesProps.filter(
    (x) => !isNil(x) && !isNil(x.ext) && !includes(IGNORED_EXTENSIONS, x.ext)
  );

  return (
    <Box width="100%" height="100%">
      {size(files) > 1 && (
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {files.map((x) => {
            return (
              <Tab key={x?.name} label={getFileNameWithouExtension(x?.name)} />
            );
          })}
        </Tabs>
      )}

      {files.map((x, index) => {
        return (
          <TabPanel key={x?.name} value={value} index={index}>
            <ResolveHelpBody file={x} />
          </TabPanel>
        );
      })}
    </Box>
  );
}
