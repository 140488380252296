import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Icon } from "@material-ui/core";

import { useStyles, iconAuto } from "./styles";
import { useAccessesStore } from "../../../../core/stores/AccessesStore";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";

export default function RefreshItems(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const {
    fetchMenuDef,
    callServerToGetDashboard,
    callServerToGetProcessesList,
    callServerToGetFavItems,
  } = useAccessesStore((state) => ({
    fetchMenuDef: state.fetchMenuDef,
    callServerToGetDashboard: state.callServerToGetDashboard,
    callServerToGetProcessesList: state.callServerToGetProcessesList,
    callServerToGetFavItems: state.callServerToGetFavItems,
    
  }));

  const refreshItems = useCallback(() => {
    fetchMenuDef({ REQUEST_HEADERS, t, logout });
    callServerToGetDashboard({ REQUEST_HEADERS, t, logout });
    callServerToGetProcessesList({ REQUEST_HEADERS, t, logout });
    callServerToGetFavItems({ REQUEST_HEADERS, t, logout });
    // eslint-disable-next-line
  }, [REQUEST_HEADERS, t]);

  return (
    <Tooltip title={t("REFRESH_ITEMS")} placement="right">
      <IconButton className={classes.normalTab} onClick={refreshItems}>
        <Icon style={iconAuto} className={"fas fa-sync-alt"} />
      </IconButton>
    </Tooltip>
  );
}
