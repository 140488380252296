import { isNil } from "lodash";
import { useState } from "react";
import { KEY_TAB } from "../../util/UtilKeys";

export const useLastWasTab = () => {
  const [lastWasTab, setLastWasTab] = useState(false);

  const handleKeyDown = (e) => {
    if ((!isNil(e) && e?.keyCode === KEY_TAB) || e?.which === KEY_TAB) {
      setLastWasTab(true);
    }
  };

  return { lastWasTab, handleKeyDown };
};
