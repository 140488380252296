/**
 * Resolves label locale {es, en, pt} or directly shows"label"
 * @param {*} obj
 * @returns
 */
export function getLocaleText(obj, i18n) {
  if (i18n && i18n.language) {
    const locale = i18n.language;
    if (obj && typeof obj === "object") {
      if (obj.hasOwnProperty(locale)) {
        return obj[locale];
      } else {
        return obj?.default || obj?.en || obj?.es || "";
      }
    } else if (typeof obj === "string") {
      return obj;
    } else {
      return "";
    }
  } else {
    return "";
  }
}
