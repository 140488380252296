import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import useEmployeeStoreState from "../EmployeeStoreState";

const useHistory = () => {
  const { t } = useTranslation();
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const {
    loadingHistoryItems,
    errorLoadingHistoryItems,
    historyItems,
    fetchHistoryItems,
    loadedHistoryItems
  } = useEmployeeStoreState((state) => ({
    loadingHistoryItems: state.loadingHistoryItems,
    errorLoadingHistoryItems: state.errorLoadingHistoryItems,
    historyItems: state.historyItems,
    fetchHistoryItems: state.fetchHistoryItems,
    loadedHistoryItems: state.loadedHistoryItems
  }));

  const handleFetchHistoryItems = useCallback(() => {
    fetchHistoryItems(headers, logout, t);
  }, [fetchHistoryItems, headers, logout, t]);

  return {
    handleFetchHistoryItems,
    loadingHistoryItems,
    errorLoadingHistoryItems,
    historyItems,
    loadedHistoryItems
  };
};

export default useHistory;
