import React, { useEffect } from "react";
import { Badge, Icon, IconButton, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE } from "../../../routes/EmployeeStoreRouter";
import { size } from "lodash";
import useFavorites from "../../../store/hooks/useFavorites";

export default function EmployeeStoreFavsButton() {
  const { t } = useTranslation();
  let history = useHistory();
  const { favorites, getFavorites } = useFavorites();

  const sizeFavs = size(favorites);

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  return (
    <Tooltip title={t("EMPLOYEE_STORE.HEADER_BTN_FAVS")}>
      <IconButton
        aria-label="favorites-button"
        onClick={() => history.push(EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE)}
      >
        <Badge badgeContent={sizeFavs || 0} color="primary" showZero={false}>
          <Icon className="fas fa-heart" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
