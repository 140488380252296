import React from "react";
import { Icon, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as Constants from "../../util/Constants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function BadForm() {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <Icon
          className={Constants.ERROR_BAD_FORM_DEF_ICON}
          style={{
            fontSize: Constants.ERROR_BAD_FORM_DEF_ICON_SIZE,
            color: theme.palette.content.mainColor,
          }}
        />
        <p
          style={{
            fontSize: Constants.ERROR_BAD_FORM_DEF_TEXT_SIZE,
            fontWeight: Constants.ERROR_BAD_FORM_DEF_TEXT_WEIGHT,
            color: theme.palette.content.mainColor,
          }}
        >
          {Constants.ERROR_BAD_FORM_DEF_TEXT}
        </p>
      </div>
    </div>
  );
}
