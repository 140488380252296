import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLiveClock } from "./useLiveClock";

const useRegionalConfig = () => {
  const { t } = useTranslation();
  const [thousandsSeparator, setThousandsSeparator] = useState("");
  const [decimalSeparator, setDecimalSeparator] = useState("");
  const { timeOffset } = useLiveClock();

  const updateSeparators = () => {
    const numberWithSeparators = new Intl.NumberFormat().format(1000.1);

    const thousandSeparatorSymbol = numberWithSeparators[1];
    const decimalSeparatorSymbol =
      thousandSeparatorSymbol === "0"
        ? numberWithSeparators[4]
        : numberWithSeparators[5];

    setThousandsSeparator(
      thousandSeparatorSymbol === ","
        ? t("COMMA_SEPARATOR_LABEL")
        : t("DOT_SEPARATOR_LABEL")
    );
    setDecimalSeparator(
      decimalSeparatorSymbol === ","
        ? t("COMMA_SEPARATOR_LABEL")
        : t("DOT_SEPARATOR_LABEL")
    );
  };

  useEffect(() => {
    updateSeparators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { thousandsSeparator, decimalSeparator, timeOffset, updateSeparators };
};

export default useRegionalConfig;
