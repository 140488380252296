import React, { createContext, useState } from "react";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  HOME_TAB,
} from "../../util/Constants";

//Creating the context
const LayoutContext = createContext();

function calculateInitialBreakpoint() {
  let result = null;
  if (window && window.innerWidth) {
    let ww = window.innerWidth;
    if (ww >= BREAKPOINT_LG) {
      return "lg";
    } else if (ww >= BREAKPOINT_MD) {
      return "md";
    } else if (ww >= BREAKPOINT_SM) {
      return "sm";
    } else {
      return "xs";
    }
  }
  return result;
}

const LayoutContextProvider = ({ children }) => {
  const [textHeader, setTextHeader] = useState(null);
  const [canGoBack, setCanGoBack] = useState(false);
  const [layoutBreakpoint, setLayoutBreakpoint] = useState(
    calculateInitialBreakpoint()
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(HOME_TAB);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSubItemsInForm, setShowSubItemsInForm] = useState(false);

  const changeTextHeader = (newTextHeader) => {
    setTextHeader(newTextHeader);
  };

  const changeLayoutBreakpoint = (newLayoutBreakpoint) => {
    if (newLayoutBreakpoint) {
      setLayoutBreakpoint(newLayoutBreakpoint);
    }
  };

  const changeCanGoBack = (newCanGoBack) => {
    if (
      newCanGoBack !== null &&
      newCanGoBack !== undefined &&
      typeof newCanGoBack === "boolean"
    ) {
      setCanGoBack(newCanGoBack);
    }
  };

  const changeIsDrawerOpen = (isDrawerOpen) => {
    setIsDrawerOpen(isDrawerOpen);
  };

  const changeActiveTab = (newTab) => {
    setActiveTab(newTab);
  };

  function toggleSideBar() {
    setSidebarOpen((prev) => !prev);
  }

  function changeShowSubItemsInForm(open){
    setShowSubItemsInForm(open);
  }

  const returnToInitialState = () => {
    setTextHeader(null);
    setCanGoBack(false);
    setLayoutBreakpoint(null);
    setIsDrawerOpen(false);
    setActiveTab(HOME_TAB);
    setSidebarOpen(false);
    setShowSubItemsInForm(false);
  };

  return (
    <LayoutContext.Provider
      value={{
        textHeader,
        changeTextHeader,
        layoutBreakpoint,
        changeLayoutBreakpoint,
        canGoBack,
        changeCanGoBack,
        returnToInitialState,
        isDrawerOpen,
        changeIsDrawerOpen,
        activeTab,
        changeActiveTab,
        toggleSideBar,
        sidebarOpen,
        showSubItemsInForm,
        changeShowSubItemsInForm
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

//Better exporting all things at bottom when we have more tan one
export { LayoutContext, LayoutContextProvider };
