import React, { useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import { isArray } from "lodash";
import { toast } from "react-toastify";
import NothingToShow from "../../../components/nothingToShow/NothingToShow";

import { useFetchGen } from "../../../core/hooks/useFetchGen";
import {
  API_FORM_LOG,
  METHOD_GET,
  METHOD_PUT,
  METHOD_DELETE,
  SERVER_HOST,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import AccordionLogs from "../components/AccordionLogs";
import * as logAPI from "../api/api";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";

const API_GET_LOGS = `${SERVER_HOST()}${API_FORM_LOG}/get`;
const API_PUT_CHANGE = `${SERVER_HOST()}${API_FORM_LOG}/upd`;
const API_DELETE_CHANGE = `${SERVER_HOST()}${API_FORM_LOG}/del`;

export default function FrontendLogsPage() {
  const [{ status, data }, fetchData] = useFetchGen(
    API_GET_LOGS,
    null,
    METHOD_GET
  );
  const { ok, errorMsg, dataResponse } = data;

  const REQUEST_HEADERS = useRequestHeaders();

  const refreshData = useCallback(
    () => {
      fetchData();
    },
    //eslint-disable-next-line
    []
  );

  async function handleUpdate(newItem) {
    await logAPI.updateLog(
      API_PUT_CHANGE,
      METHOD_PUT,
      newItem,
      REQUEST_HEADERS
    );
  }

  async function handleDelete(deleteItem) {
    await logAPI.deleteLog(
      API_DELETE_CHANGE,
      METHOD_DELETE,
      deleteItem,
      REQUEST_HEADERS
    );
  }

  if (status === "fetching") {
    return <CircularProgress disableShrink={true} />;
  }

  if (!ok) {
    toast.error(errorMsg, {
      containerId: TOAST_CONTAINER_LAYOUT,
    });
    return null;
  }

  if ((dataResponse && dataResponse.length === 0) || !isArray(dataResponse)) {
    return <NothingToShow />;
  }

  return (
    <AccordionLogs
      logs={dataResponse}
      refreshData={refreshData}
      handleUpdate={handleUpdate}
      handleDelete={handleDelete}
    />
  );
}
