import { makeStyles } from "@material-ui/core";

export const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "move",
    padding: 10,
  },
  btnClose: {
    minHeight: 50,
    minWidth: 50,
  },
  paper: {
    padding: "10px",
    minWidth: "65vw",
    minHeight: "90vh",
  },
  dialogContent: {
    display: "flex",
    padding: 0,
    overflow: "hidden",
  },
}));
