import React from "react";
import MaterialTable from "@material-table/core";

import { useStyles } from "./ServicesListTableStyles";

const TRAZABILITY_COLUMNS = [
  {
    title: "Delay (ms)",
    field: "demora",
  },
  {
    title: "Order",
    field: "exOrder",
  },
  {
    title: "Line",
    field: "line",
  },
  {
    title: "Origin",
    field: "origenId",
  },
  {
    title: "Node",
    field: "nodoId",
  },
  {
    title: "Service",
    field: "serviceId",
  },
  {
    title: "Type",
    field: "tipo",
  },
];

export default function ServicesListTable(props) {
  const { fetching, servicesList, totalDuration } = props;
  const classes = useStyles();

  return (
    <div className={classes.tableDivContainer}>
      {servicesList && servicesList.length > 0 && (
        <div className={classes.table}>
          <MaterialTable
            isLoading={fetching}
            columns={TRAZABILITY_COLUMNS}
            data={servicesList}
            title={"Executed services list"}
            options={{
              grouping: true,
              maxBodyHeight: "50vh",
              headerStyle: {
                position: "sticky",
              },
              pageSize: 50,
              pageSizeOptions: [50, 100, 200, 500],
            }}
          />
        </div>
      )}
      <div className={classes.totalTimeContainer}>
        <div>Total time: {totalDuration}(ms)</div>
      </div>
    </div>
  );
}
