import React, { useState } from "react";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import { alpha, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isEmpty, isNil } from "lodash";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 2,
};

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  endButton: {
    width: "auto",
    height: "100%",
    position: "absolute",
    right: 5,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingInline: 6,
  },
  endButtonClear: {
    width: "auto",
    height: "100%",
    position: "absolute",
    right: 30,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingInline: 6,
  },
}));

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  hasToBlockIndividualFiltersByLimit,
  isMaterializedQuery,
  handleFilterMaterializedQuery,
  lastSearch,
  handleRefreshCurrent,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const count = preGlobalFilteredRows.length;
  const [input, setInput] = useState(lastSearch || "");

  return (
    <Tooltip
      title={
        hasToBlockIndividualFiltersByLimit === false || isMaterializedQuery
          ? ""
          : t("REPORTS_FILTERS_DISABLES_LIMIT")
      }
      placement="right"
      disableHoverListener={
        hasToBlockIndividualFiltersByLimit === false || isMaterializedQuery
      }
    >
      <div
        className={classes.search}
        style={{
          border:
            hasToBlockIndividualFiltersByLimit && !isMaterializedQuery
              ? "1px solid darkorange"
              : "",
        }}
      >
        <div className={classes.searchIcon}>
          {isMaterializedQuery ? (
            <Icon className="fab fa-searchengin" style={commonIcon} />
          ) : (
            <SearchIcon
              color={
                hasToBlockIndividualFiltersByLimit ? "disabled" : undefined
              }
            />
          )}
        </div>
        <OutlinedInput
          value={isMaterializedQuery ? input : globalFilter || ""}
          onChange={(e) => {
            if (isMaterializedQuery) {
              e.preventDefault();
              e.stopPropagation();
              setInput(e.target.value);
            } else {
              setGlobalFilter(e.target.value || undefined);
            }
          }}
          placeholder={
            hasToBlockIndividualFiltersByLimit || isMaterializedQuery
              ? ""
              : `${count} registros...`
          }
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          disabled={hasToBlockIndividualFiltersByLimit && !isMaterializedQuery}
          onKeyDown={(e) => {
            if (
              isMaterializedQuery &&
              !isNil(e) &&
              !isNil(e.key) &&
              e.key === "Enter"
            ) {
              const fts = e.target.value;
              e.preventDefault();
              e.stopPropagation();
              setInput(fts);
              handleFilterMaterializedQuery({ fts });
            }
          }}
        />
        {isMaterializedQuery && (
          <IconButton
            className={classes.endButton}
            onClick={() => {
              const fts = input;
              handleFilterMaterializedQuery({ fts });
            }}
            disabled={isNil(input) || isEmpty(input)}
          >
            <Icon className="fas fa-play" style={commonIcon} fontSize="small" />
          </IconButton>
        )}
        {isMaterializedQuery && !isNil(lastSearch) && !isEmpty(lastSearch) && (
          <IconButton
            className={classes.endButtonClear}
            onClick={() => handleRefreshCurrent()}
          >
            <Icon
              className="fas fa-times"
              style={commonIcon}
              fontSize="small"
            />
          </IconButton>
        )}
      </div>
    </Tooltip>
  );
};

export default GlobalFilter;
