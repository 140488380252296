import React, { useState } from "react";
import { InputAdornment, TextField, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import { useAsyncDebounce } from "react-table";

export const useStyles = makeStyles(() => ({
  searchContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: 150,
  },
  searchInput: {
    fontSize: "1.1rem",
    border: "0",
    marginRight: 10,
    width: "100%",
  },
  searchResultsLabel: {
    color: "grey",
    fontStyle: "italic",
    padding: "0px 0px 0px 10px",
  },
}));

export default function GlobalSearchFilterInput(props) {
  const { globalFilter, setGlobalFilter, rows, count, isDisabled } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(globalFilter);

  const handleChangeGlobalFilterInput = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className={classes.searchContainer}>
      <TextField
        disabled={isDisabled === true}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          handleChangeGlobalFilterInput(e.target.value);
        }}
        placeholder={`${count} ${t("FORM_TABLE_SEARCH_TEXT")}`}
        className={classes.searchInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "grey" }} />
            </InputAdornment>
          ),
        }}
      />

      {rows && rows.length !== count && (
        <label className={classes.searchResultsLabel}>
          ({rows.length}) {t("FORM_TABLE_SEARCH_TEXT_RESULT")}
        </label>
      )}
    </div>
  );
}
