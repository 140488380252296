import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from "@material-ui/core";
import * as _ from "lodash";
import {
  defineInputEnabledByOperation,
  getCompletePathGridItem,
} from "../../../util/UtilForm";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
//import { ERROR_BAD_FORM_DEF_COLOR } from "../../../util/Constants";

export default function EditableCellCheckbox(props) {
  const { value, row, column, updateDataTable, operation, formContext } = props;

  const { id, type } = column;
  const { index } = row;
  const { section, block, listName, isBlockDisabledByWizard } = formContext;

  const [checked, setChecked] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const checkRef = useRef(null);
  const [calculatedInputProps, setCalculatedInputProps] = useState(null);

  let completeIdControl = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column,
    index + 1
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema: column,
    operation,
    isSavedEntity: value?.isSavedEntity,
  });

  useEffect(() => {
    if (!_.isNil(value) && !_.isNil(value.value)) {
      const realValue = value.value;
      const realBooleanValue =
        realValue === "true" || realValue === true || realValue === "S"
          ? true
          : false;
      setChecked(realBooleanValue);
    } else {
      setChecked(false);
    }
    checkPresentationInfo(value);
  }, [value]);

  function checkPresentationInfo(value) {
    if (!_.isNil(value) && !_.isNil(value.presentationInfo)) {
      const { presentationInfo } = value;
      if (presentationInfo && presentationInfo.reset) {
        setCalculatedInputProps(null);
      } else {
        setCalculatedInputProps(presentationInfo);
      }
    } else {
      setCalculatedInputProps(null);
    }
  }

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      column?.canEdit,
      column?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
    if (
      !_.isNil(calculatedInputProps) &&
      !_.isNil(calculatedInputProps.enabled)
    ) {
      setIsEnabled(calculatedInputProps.enabled);
    }
  }, [operation, column, calculatedInputProps]);

  const handleChange = (event) => {
    const checked = event.target.checked;

    const checkedSt = checked ? "S" : "N";
    const checkValueToSet =
      type && type.toLowerCase() === "string" ? checkedSt : checked;

    setChecked(checked);
    updateDataTable(index, id, checkValueToSet, checkRef, column);
  };

  //Input styles
  const controlStyles = {
    padding: 0,
    borderRadius: 0,
    color:
      calculatedInputProps &&
      calculatedInputProps.color &&
      calculatedInputProps.color,
    fontSize:
      calculatedInputProps &&
      calculatedInputProps.fontSize &&
      calculatedInputProps.fontSize,
    backgroundColor:
      calculatedInputProps &&
      calculatedInputProps.backgroundColor &&
      calculatedInputProps.backgroundColor,
    fontWeight:
      calculatedInputProps &&
      calculatedInputProps.fontWeight &&
      calculatedInputProps.fontWeight,
    fontStyle:
      calculatedInputProps &&
      calculatedInputProps.fontStyle &&
      calculatedInputProps.fontStyle,
  };

  // const errorStyles = errorMap.some((e) => e && e.key === completeIdControl)
  //   ? {
  //       color: ERROR_BAD_FORM_DEF_COLOR,
  //     }
  //   : {};

  return (
    <>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "primary checkbox" }}
        disabled={
          !isEnabled ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard === true
        }
        inputRef={checkRef}
        id={completeIdControl}
        style={controlStyles}
        //style={{ ...controlStyles, ...errorStyles }}
      />
    </>
  );
}
