import { replace } from "lodash";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function FormCleanerMiddleware() {
  // route
  let { to } = useParams();

  // state
  const location = useLocation();
  const state = location.state;

  return (
    <Redirect
      to={{
        pathname: `/${replace(to, new RegExp("@", "g"), "/")}`,
        state,
      }}
    />
  );
}
