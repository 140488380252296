import { isNil } from "lodash";
import { KEY_TAB } from "./UtilKeys";
import { EMPLOYEE_STORE_HOME_ROUTE } from "../solutions/employee-store/routes/EmployeeStoreRouter";

export const ELEMENT_TYPE_BTN = "button";
export const ELEMENT_TYPE_TEXT = "text";
export const ELEMENT_TYPE_NUMBER = "number";

export function checkTypeOfElementById(id, type) {
  if (
    id &&
    id !== "" &&
    document &&
    document.getElementById(id) !== null &&
    document.getElementById(id).getAttribute("type") === type
  ) {
    return true;
  } else {
    return false;
  }
}

export function wasTab(e) {
  let result = false;
  const code = e.keyCode ? e.keyCode : e.which;
  if (code === KEY_TAB) {
    result = true;
  }
  return result;
}

export function refreshPage({ isEms }) {
  if (!isNil(isEms) && isEms === true) {
    window.location.replace(EMPLOYEE_STORE_HOME_ROUTE);
  } else {
    window.location.replace("/");
  }
}

export function focusElementByIdInEvent(e) {
  let id = null;
  if (e && !isNil(e.relatedTarget)) {
    id = e.relatedTarget.getAttribute("id");
    if (id && id !== "") {
      if (document && document.getElementById(id) !== null) {
        document.getElementById(id).focus();
      }
    }
  }
}

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
