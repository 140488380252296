import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Icon,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Box,
  IconButton,
} from "@material-ui/core";
import { testSimulationSplitRequestApi } from "../../../api/simulationApi";
import { useRequestHeaders } from "../../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import useCart from "../../../store/hooks/useCart";
import { isArray, isNil, size } from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 2,
};

const useStyles = makeStyles(() => ({
  btnTest: {
    marginInline: 8,
    cursor: "pointer",
    border: "1px solid",
  },
}));

export default function ShoppingCartTestSplitBtn() {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [expandedOrders, setExpandedOrders] = useState({});

  const { logout } = useContext(AuthContext);
  const REQUEST_HEADERS = useRequestHeaders();
  const { cartItems } = useCart();

  const handleTestSplit = async () => {
    setIsLoading(true);
    try {
      const response = await testSimulationSplitRequestApi({
        headers: REQUEST_HEADERS,
        logout,
        body: {
          products: cartItems,
        },
      });
      setData(response?.data);
    } catch (error) {
      setData(null);
    } finally {
      setIsLoading(false);
      setIsDialogOpen(true);
    }
  };

  const sizeSplit = size(data?.orders) || 0;

  const handleExpandToggle = (index) => {
    setExpandedOrders((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <>
      <Button
        onClick={handleTestSplit}
        disabled={isLoading}
        startIcon={
          <Icon className="fas fa-project-diagram" style={commonIconStyles} />
        }
        className={classes.btnTest}
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : "Split"}
      </Button>
      <Dialog open={isDialogOpen}>
        <DialogTitle disableTypography={true}>
          <Typography variant="h5">
            Cantidad ordenes split ({sizeSplit})
          </Typography>
        </DialogTitle>
        <DialogContent>
          {!isNil(data) &&
          !isLoading &&
          !isNil(data.orders) &&
          isArray(data.orders) ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Expandir/Contraer</TableCell>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">Cant. Items</TableCell>
                    <TableCell align="center">País</TableCell>
                    <TableCell align="center">Cliente</TableCell>
                    <TableCell align="center">Org</TableCell>
                    <TableCell align="center">Canal</TableCell>
                    <TableCell align="center">Sector</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.orders.map((order, index) => (
                    <React.Fragment key={index}>
                      <TableRow style={{ backgroundColor: "#daedf5" }}>
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={() => handleExpandToggle(index)}
                          >
                            {expandedOrders[index] ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {size(order.items) || 0}
                        </TableCell>
                        <TableCell align="center">
                          {order.countryCode}
                        </TableCell>
                        <TableCell align="center">
                          {order.customerNumber}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {order.salesOrganizationCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {order.distributionChannelCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {order.divisionCode}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse in={expandedOrders[index] || false}>
                            <Box margin={1}>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                component="div"
                              >
                                Items
                              </Typography>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">
                                      Order Item
                                    </TableCell>
                                    <TableCell align="center">EAN</TableCell>
                                    <TableCell align="center">
                                      Cantidad
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {order.items.map((item, itemIndex) => (
                                    <TableRow key={itemIndex}>
                                      <TableCell align="center">
                                        {item.salesOrderItem}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.internationalArticleNumber}
                                      </TableCell>
                                      <TableCell align="center">
                                        {item.orderQuantityBaseUnit}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <p>No se pudo recuperar info de endpoint</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>CERRAR</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
