import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";

import HistoryItemContainer from "../components/history/HistoryItemContainer";
import { useTranslation } from "react-i18next";
import useHistory from "../store/hooks/useHistory";
import { isArray, isEmpty, isNil, size } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflow: "auto",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 12,
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  contentContainer: {
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
}));

export default function EmployeeStoreHistoryPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    historyItems,
    loadingHistoryItems,
    errorLoadingHistoryItems,
    handleFetchHistoryItems,
    loadedHistoryItems,
  } = useHistory();

  useEffect(() => {
    if (
      (isNil(historyItems) || isEmpty(historyItems)) &&
      !loadingHistoryItems &&
      isNil(errorLoadingHistoryItems) &&
      !loadedHistoryItems
    ) {
      handleFetchHistoryItems();
    }
  }, [
    errorLoadingHistoryItems,
    handleFetchHistoryItems,
    historyItems,
    loadingHistoryItems,
    loadedHistoryItems,
  ]);

  return (
    <EmployeeStorePageWrapper>
      <div className={classes.root}>
        {loadingHistoryItems ? (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box className={classes.contentContainer}>
            <Box className={classes.titleBox}>
              <Typography variant="h4">
                {t("EMPLOYEE_STORE.HEADER_BTN_HISTORY")} ({size(historyItems)})
              </Typography>
              <IconButton onClick={handleFetchHistoryItems}>
                <Icon className="fas fa-sync" />
              </IconButton>
            </Box>

            {!isNil(historyItems) &&
            isArray(historyItems) &&
            !isEmpty(historyItems)
              ? historyItems.map((historyItem, index) => (
                  <HistoryItemContainer key={index} {...historyItem} />
                ))
              : null}
          </Box>
        )}
      </div>
    </EmployeeStorePageWrapper>
  );
}
