import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { EMPLOYEE_STORE_HISTORY_ROUTE } from "../../../routes/EmployeeStoreRouter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

export default function EmployeeStoreHistory() {
  let history = useHistory();
  const { t } = useTranslation();

  return (
    <Tooltip title={t("EMPLOYEE_STORE.HEADER_BTN_HISTORY")}>
      <IconButton
        onClick={() => history.push(EMPLOYEE_STORE_HISTORY_ROUTE)}
        aria-label="history-button"
      >
        <Icon className="fas fa-history" />
      </IconButton>
    </Tooltip>
  );
}
