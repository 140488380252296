import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Icon,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format, isValid, parse } from "date-fns";

import { useStyles, iconStyles } from "../FilterDialogsStlyes";
import {
  DATE_PATTERN,
  PADDING_ICON_BUTTONS_FORMS,
  SEARCH_CRITERIA_EQUAL,
  SEARCH_CRITERIA_GREATER,
  SEARCH_CRITERIA_LESS,
} from "../../../../../util/Constants";

export default function FilterFieldDateDialog({
  open,
  handleFilterDialog,
  inheritedOperation,
  inheritedValue,
  deleteFilterConfig,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [operation, setOperation] = useState(SEARCH_CRITERIA_EQUAL);
  const [filterValue, setFilterValue] = useState(null);

  useEffect(() => {
    if (inheritedOperation && inheritedOperation !== "") {
      setOperation(inheritedOperation);
    }
  }, [inheritedOperation]);

  useEffect(() => {
    if (inheritedValue && inheritedValue !== "") {
      const parsedDate = parse(inheritedValue, DATE_PATTERN, new Date());
      setFilterValue(parsedDate);
    }
  }, [inheritedValue]);

  /**
   * Checks filters to apply and call cell method
   */
  function handleApplyFilter(apply) {
    if (apply) {
      if (isValid(filterValue)) {
        const formattedDate1 = format(filterValue, DATE_PATTERN);
        handleFilterDialog(formattedDate1, false, operation);
      }
    } else {
      handleFilterDialog(null, true);
    }
  }

  function handleKeyPress(e) {
    if (e) {
      const { key } = e;
      if (key === "Enter") {
        handleApplyFilter(true);
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleApplyFilter(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography style={{ fontSize: "20px" }}>
          {t("SEARCH_FILTER_DEF_TITLE")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.filterDialogLine}>
          <Typography>{t("SEARCH_CRITERIA_LABEL")}</Typography>
          <Select
            value={operation}
            onChange={(e) => {
              setOperation(e.target.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "operation select" }}
            style={{ width: "100%", marginLeft: 10 }}
          >
            <MenuItem value={SEARCH_CRITERIA_EQUAL}>
              {t("SEARCH_CRITERIA_EQUAL")}
            </MenuItem>
            <MenuItem value={SEARCH_CRITERIA_GREATER}>
              {t("SEARCH_CRITERIA_GREATER")}
            </MenuItem>
            <MenuItem value={SEARCH_CRITERIA_LESS}>
              {t("SEARCH_CRITERIA_LESS")}
            </MenuItem>
          </Select>
        </div>

        <div className={classes.filterDialogLine} style={{ marginTop: 15 }}>
          <KeyboardDatePicker
            clearable
            label={`${t("SEARCH_CRITERIA_VALUE")}`}
            value={filterValue}
            onChange={(date) => setFilterValue(date)}
            onAccept={(date) => setFilterValue(date)}
            onKeyPress={handleKeyPress}
            format={"dd/MM/yyyy"}
            placeholder="dd/MM/yyyy"
            invalidDateMessage=""
            initialFocusedDate={new Date()}
            disablePast={false}
            disableFuture={false}
            style={{
              width: "100%",
            }}
            KeyboardButtonProps={{
              style: {
                padding: PADDING_ICON_BUTTONS_FORMS,
              },
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleApplyFilter(true)}
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {t("SEARCH_FILTER_APPLY")}
        </Button>
        {inheritedOperation && inheritedValue && (
          <IconButton
            aria-label={`remove filter button`}
            onClick={deleteFilterConfig}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {t("FORM_DELETE").toUpperCase()}
            <Icon
              className="fas fa-trash"
              style={{ ...iconStyles, marginLeft: 3 }}
            />
          </IconButton>
        )}
        <Button onClick={() => handleApplyFilter(false)} color="primary">
          {t("FORM_CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
