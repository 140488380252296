import { isNil } from "lodash";
import {
  API_USER_PRIVATE,
  METHOD_GET,
  METHOD_POST,
  SERVER_HOST,
} from "../../../../util/Constants";

const ENDPOINT_SAVE_NUMER = "/save-number";
const ENDPOINT_GET_INFO = "/get-private";
const ENDPOINT_GET_CODE = "/generate-code";

export async function saveNumber({ phoneNumber, otpCode, REQUEST_HEADERS }) {
  const url = `${SERVER_HOST()}${API_USER_PRIVATE}${ENDPOINT_SAVE_NUMER}?phoneNumber=${phoneNumber}&otpCode=${otpCode}`;
  const response = await fetch(url, {
    method: METHOD_POST,
    headers: REQUEST_HEADERS,
  });
  const status = response.status;
  try {
    const result = await response.json();
    if (!isNil(result)) {
      return {
        status,
        ...result,
      };
    } else {
      return { status };
    }
  } catch (error) {
    return {
      status,
      error: error?.message,
    };
  }
}

export async function getUserPrivateInfo({ REQUEST_HEADERS }) {
  const url = `${SERVER_HOST()}${API_USER_PRIVATE}${ENDPOINT_GET_INFO}`;
  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  });
  const status = response.status;
  try {
    const result = await response.json();
    if (!isNil(result)) {
      return {
        status,
        ...result,
      };
    } else {
      return { status };
    }
  } catch (error) {
    return {
      status,
      error: error?.message,
    };
  }
}

export async function getGenerateCode({ phoneNumber, REQUEST_HEADERS }) {
  const url = `${SERVER_HOST()}${API_USER_PRIVATE}${ENDPOINT_GET_CODE}?phoneNumber=${phoneNumber}`;
  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  });
  const status = response.status;
  try {
    const result = await response.json();
    if (!isNil(result)) {
      return {
        status,
        ...result,
      };
    } else {
      return { status };
    }
  } catch (error) {
    return {
      status,
      error: error?.message,
    };
  }
}
