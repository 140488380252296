import {
  Icon,
  IconButton,
  TextField,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px 5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: 170,
    //borderRadius: 10,
  },
  innerDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    margin: "0px 5px",
  },
  strongTxt: { color: theme.palette.content.mainColor },
}));

export default function Pagination(props) {
  const {
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    //pageSize,
    //setPageSize,
    pageIndex,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  if (pageOptions.length <= 1) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerDiv}>
        <IconButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          style={{ padding: 2 }}
        >
          <Icon
            className="fas fa-angle-double-left"
            style={{
              color: canPreviousPage && theme.palette.content.mainColor,
            }}
          />
        </IconButton>
      </div>

      <div className={classes.innerDiv}>
        <IconButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          style={{ padding: 2 }}
        >
          <Icon
            className="fas fa-angle-left"
            style={{
              color: canPreviousPage && theme.palette.content.mainColor,
            }}
          />
        </IconButton>
        <TextField
          type="number"
          //defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{
            width: "30px",
          }}
          inputProps={{ min: 0, style: { textAlign: "center" } }} // the change is here
          value={pageIndex + 1 || ""}
        />
        <strong className={classes.strongTxt}>
          {t("FORM_TABLE_PAGINATION_OF")} {pageOptions.length}
        </strong>{" "}
        <IconButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
          style={{ padding: 2 }}
        >
          <Icon
            className="fas fa-angle-right"
            style={{ color: canNextPage && theme.palette.content.mainColor }}
          />
        </IconButton>
        {/* <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
      </div>

      <div className={classes.innerDiv}>
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          style={{ padding: 2 }}
        >
          <Icon
            className="fas fa-angle-double-right"
            style={{ color: canNextPage && theme.palette.content.mainColor }}
          />
        </IconButton>
      </div>
    </div>
  );
}
