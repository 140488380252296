import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",
    margin: 10,
    height: "100%",
    padding: "0px 10px",
    //paddingTop: "1rem"
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewsHandlerContainer: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
    height: "48px",
    alignItems: "center",
  },
  queryContainer: {
    maxWidth: ({ isDrawerOpen }) =>
      isDrawerOpen ? "calc(100vw - 270px)" : "calc(100vw - 100px)",
    width: "100%",
    height: "calc(100% - 100px)",
  },
  resultContainer: {
    width: "100%",
    // float: "left",
    // display: "block",
  },
  paperContainerV3: {
    width: "100%",
    background:
      theme.palette.type === "light"
        ? `${theme.palette.background.paper}`
        : `linear-gradient(178.6deg, ${theme.palette.header.backgroundColor}f7, rgb(35 70 104) 83.8%)`,
    overflow: "auto",
  },
  paperContainerV2: {
    width: "100%",
    background:
      theme.palette.type === "light"
        ? `${theme.palette.background.paper}`
        : `linear-gradient(178.6deg, ${theme.palette.header.backgroundColor}f7, rgb(35 70 104) 83.8%)`,
    overflow: "auto",
  },
  paperContainerV1: {
    width: "100%",
    backgroundColor: `${theme.palette.background.paper}`,
    overflow: "auto",
  },
  loadingTable: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#eceaea",
    backgroundSize: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: 0.4,
  },
  loader: {
    position: "absolute",
    top: "50%",
    right: "50%",
  },
  paperContent: {
    position: "relative",
  },
}));
