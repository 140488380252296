import React, { useMemo, useState } from "react";
import { isNil } from "lodash";

import { FORM_OPERATION_VIEW } from "../../util/Constants";
import ExternalFormContainer from "../../components/forms/externalFormContainer/ExternalFormContainer";

const INITIAL_STATE = {
  open: false,
  ukey: null,
  path: null,
  idForm: null,
};

export const useExternalFormWithUkey = () => {
  const [state, setState] = useState(INITIAL_STATE);

  const handleOpenFormWithUkey = ({ ukey, path, idForm }) => {
    setState({ open: true, ukey, path, idForm });
  };

  const handleCloseFormWithUkey = () => {
    setState(INITIAL_STATE);
  };

  const { open, ukey, path, idForm } = state;

  const ExternalForm = useMemo(
    () => () => {
      if (open === true && !isNil(ukey) && !isNil(path) && !isNil(idForm)) {
        return (
          <ExternalFormContainer
            openModal={open}
            ukey={ukey}
            operation={FORM_OPERATION_VIEW}
            path={path}
            idForm={idForm}
            handleOpenModalForm={handleCloseFormWithUkey}
            overrideOps={[FORM_OPERATION_VIEW]}
          />
        );
      } else {
        return <></>;
      }
    },
    [open, ukey, path, idForm]
  );

  return [ExternalForm, handleOpenFormWithUkey];
};
