import React from "react";
import { Button, Icon, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash";
import { useHelpDialog } from "../../../core/hooks/useHelpDialog";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 3,
  fontSize: "1rem",
};

const useStyles = makeStyles((theme) => ({
  btnTriggerHelp: {
    textTransform: "none",
    backgroundColor: `${theme.palette.content.mainColor}`,
    color: "#FFF",
    marginRight: 25,
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}`,
      color: "#FFF",
      transform: "scale(1.02)",
    },
  },
}));

export default function HelpDialog({ hasHelp, path, idForm, nameForm }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { HelpDialogRender, handleOpen } = useHelpDialog();

  if (isNil(hasHelp) || hasHelp === false || isNil(path) || isNil(idForm)) {
    return null;
  }

  return (
    <>
      <Button
        className={classes.btnTriggerHelp}
        onClick={() => {
          handleOpen({ path, idForm, nameForm });
        }}
      >
        <Icon className="fas fa-info-circle" style={commonIcon} />
        <Typography>{t("NEED_HELP")}</Typography>
      </Button>
      <HelpDialogRender />
    </>
  );
}
