import React, { useMemo, useContext } from "react";
import { useStyles } from "../styles/SharedStyles";
import useWindowDimensions from "../../../../core/hooks/useWindowDimensions";
import {
  BREAKPOINT_MD,
  DIRECTION_REVERSE,
  IS_FORM_BUILDING_HELP_ENABLED,
} from "../../../../util/Constants";
import { ThemeContext } from "../../../../core/providers/ThemeContext";
import { Tooltip } from "@material-ui/core";
import { getUTCTooltip } from "../../../../util/UtilDates";
import { get, has, isEmpty, isNil, isObject } from "lodash";
import OverflowTooltip from "../../../reportsContainer/components/OverflowTooltip";

export default function SimpleFieldContainer(props) {
  const {
    children,
    completeIdControl,
    schema,
    pre,
    childAlign,
    addUTCTooltip,
    presentationInfo,
  } = props;

  const wd = useWindowDimensions();
  const classes = useStyles({ wd });
  const { theme } = useContext(ThemeContext);

  const isHidden = schema && schema.hidden;

  const labelWidth = pre?.labelWidth;
  const fieldWidth = pre?.fieldWidth;
  const blockJustify = pre?.align;
  const blockDirection = pre?.dir;
  const itemDirection = schema?.dir;

  const { labelSize, labelStyle, labelWeight, labelColor } = useMemo(() => {
    let lsize = null;
    let lstyle = null;
    let lw = null;
    let lc = null;

    if (!isNil(presentationInfo)) {
      lsize = presentationInfo.labelFontSize;
      lstyle = presentationInfo.labelFontStyle;
      lw = presentationInfo.labelFontWeight;
      lc =
        !isNil(presentationInfo.labelColor) &&
        isObject(presentationInfo.labelColor) &&
        !isEmpty(presentationInfo.labelColor) &&
        has(presentationInfo.labelColor, theme)
          ? get(presentationInfo.labelColor, theme)
          : null;
    } else if (!isNil(schema) && !isNil(schema.labelStyle)) {
      lsize = schema.labelStyle?.fontSize;
      lstyle = schema.labelStyle?.fontStyle;
      lw = schema.labelStyle?.fontWeight;
      lc = has(schema.labelStyle?.color, theme)
        ? get(schema.labelStyle?.color, theme)
        : null;
    }

    return {
      labelSize: lsize,
      labelStyle: lstyle,
      labelWeight: lw,
      labelColor: lc,
    };
  }, [schema, presentationInfo, theme]);

  const onlyInput = schema?.onlyInput ? true : false;
  const isRequired = schema?.required ? true : false;

  const plusSMBreakpoint = useMemo(() => {
    if (wd && wd.width && wd.width > BREAKPOINT_MD) {
      return true;
    } else {
      return false;
    }
  }, [wd]);

  if (isHidden) {
    return null;
  }

  return (
    <div
      className={classes.container}
      style={{
        justifyContent: plusSMBreakpoint && blockJustify && blockJustify,
        flexDirection:
          plusSMBreakpoint &&
          ((blockDirection && blockDirection === DIRECTION_REVERSE) ||
            (itemDirection && itemDirection === DIRECTION_REVERSE)) &&
          "row-reverse",
      }}
      key={completeIdControl}
    >
      {!onlyInput && (
        <Tooltip title={addUTCTooltip ? getUTCTooltip() : ""} placement="top">
          <label
            className={classes.label}
            style={{
              width: labelWidth && plusSMBreakpoint && labelWidth,
              fontSize: labelSize && plusSMBreakpoint && labelSize,
              fontStyle: labelStyle && plusSMBreakpoint && labelStyle,
              fontWeight: labelWeight && plusSMBreakpoint && labelWeight,
              color: labelColor,
            }}
            id="label-field"
          >
            {IS_FORM_BUILDING_HELP_ENABLED ? (
              <OverflowTooltip
                key={completeIdControl}
                value={isRequired ? `${schema?.title} *` : schema?.title}
                ignoreWidth={true}
                overrideValueInCopy={completeIdControl}
                ignoreShowTooltipText={true}
              />
            ) : (
              <>{isRequired ? `${schema?.title} *` : schema?.title}</>
            )}
          </label>
        </Tooltip>
      )}

      <div
        className={classes.children}
        style={{
          width: onlyInput
            ? "100%"
            : fieldWidth && plusSMBreakpoint && fieldWidth,
          justifyContent: childAlign && plusSMBreakpoint && childAlign,
        }}
      >
        {children}
      </div>
    </div>
  );
}
