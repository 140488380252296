import React, { useState, useContext, useMemo } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Icon, IconButton } from "@material-ui/core";
import Modal from "react-modal";
import { Resizable } from "re-resizable";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import { DrawerContext } from "../../../core/providers/DrawerContext";

import {
  useStyles,
  commonIcon,
  customModalStylesDark,
  customModalStylesLight,
} from "./ExternalFormContainerStyles";
import BaseForm from "./BaseForm";

export default function ExternalFormContainerLikeRecordsPanel(props) {
  Modal.setAppElement("#reactapp-root");

  const {
    openModal,
    operation,
    path,
    idForm,
    overrideOps,
    defaultWidth,
    defaultHeight,
    solution,
    tableName,
    openFormLikeRecordsPanel,
    handleBack,
    keepField
  } = props;

  const { drawerOpen } = useContext(DrawerContext);

  const [isResizing, setIsResizing] = useState(false);

  const wd = useWindowDimensions();
  const theme = useTheme();
  const classes = useStyles({ wd, openDrawer: drawerOpen });

  function onResizeStart() {
    setIsResizing(true);
  }

  function onResizeStop() {
    setIsResizing(false);
  }

  const modalStyles = useMemo(() => {
    if (theme?.palette?.type === "dark") {
      return {
        ...customModalStylesDark,
        content: {
          ...customModalStylesDark?.content,
          transform: "translate(-50%, -55%)",
        },
      };
    } else {
      return {
        ...customModalStylesLight,
        content: {
          ...customModalStylesLight?.content,
          transform: "translate(-50%, -55%)",
        },
      };
    }
  }, [theme]);

  return (
    <Modal isOpen={openModal} style={modalStyles}>
      <Resizable
        defaultSize={{
          width: defaultWidth || "70vw",
          height: defaultHeight || "80vh",
        }}
        handleStyles={{
          right: {
            backgroundColor: isResizing && `${theme.palette.content.mainColor}`,
          },
          left: {
            backgroundColor: isResizing && `${theme.palette.content.mainColor}`,
          },
        }}
        //h
        maxHeight="95vh"
        minHeight="50vh"
        //w
        maxWidth="95vw"
        minWidth="50vw"
        style={{ overflow: "hidden" }}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
      >
        <BaseForm
          ukey={undefined}
          operation={operation}
          path={path}
          idForm={idForm}
          handleBack={handleBack}
          overrideOps={overrideOps}
          solution={solution}
          tableName={tableName}
          openFormLikeRecordsPanel={openFormLikeRecordsPanel}
          keepField={keepField}
        />
        <IconButton className={classes.closeModalButton} onClick={handleBack}>
          <Icon className="fas fa-times" style={commonIcon} />
        </IconButton>
      </Resizable>
    </Modal>
  );
}
