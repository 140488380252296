import React, { useState, useEffect } from "react";
import { Icon, IconButton, Typography } from "@material-ui/core";
import { isArray, isEmpty, isNil, isString, toString } from "lodash";
import clsx from "clsx";

import FilterFieldDialog from "../dialogs/FilterFieldDialog";
import { commonIcon, useStyles } from "./FilterFieldStyles";

export default function FilterFieldPasteArea({
  field,
  type,
  title,
  index,
  handleChangeFilterValue,
  owner,
  defaultValue,
  defaultOperation,
  isLimit,
  canRemove,
  isRequiredAndMissing
}) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [operation, setOperation] = useState(null);

  const filterKey = `${field}-${index}`;

  useEffect(() => {
    setValue(defaultValue);
    setOperation(defaultOperation);
  }, [defaultValue, defaultOperation]);

  function handleOpen() {
    setOpen(true);
  }

  function handleFilterDialog(filterValue, cancel, operation) {
    if (!cancel) {
      setValue(filterValue);
      setOperation(operation);
      handleChangeFilterValue({
        value: filterValue,
        field,
        type,
        key: filterKey,
        operation,
        owner,
        isLimit,
      });
    }
    setOpen(false);
  }

  const deleteFilterConfig = (e) => {
    e.stopPropagation();
    handleFilterDialog(null, false, null);
  };

  const opAndVal =
    !isNil(value) && !isEmpty(value)
      ? isArray(value)
        ? `(${value.length})`
        : isString(value)
          ? `(${toString(value).split(",").length})`
          : ""
      : "";

  return (
    <>
      <FilterFieldDialog
        open={open}
        handleFilterDialog={handleFilterDialog}
        type={type}
        title={title}
        field={field}
        inheritedOperation={operation}
        inheritedValue={value}
        deleteFilterConfig={deleteFilterConfig}
        isPasteArea={true}
      />
      <button
        key={filterKey}
        className={clsx(value ? classes.filterButtonWithValue : classes.filterButton,
        isNil(value) && isRequiredAndMissing ? classes.requiredMissing : "")}
        onClick={() => handleOpen()}
      >
        <Icon
          className="fas fa-copy"
          style={{ ...commonIcon, fontSize: "12px" }}
        />

        {!isNil(value) && canRemove !== false && (
          <Icon
            className="fas fa-filter"
            style={{ ...commonIcon, fontSize: "12px" }}
          />
        )}
        {isNil(value) && isRequiredAndMissing && (
          <Icon
            className="fas fa-exclamation-triangle"
            style={{ ...commonIcon, fontSize: "12px", color: "#f44336" }}
          />
        )}
        <Typography>
          {title} {opAndVal}
        </Typography>
        {!isNil(value) && (
          <IconButton
            onClick={deleteFilterConfig}
            className={classes.removeFilterButton}
          >
            <Icon className="fas fa-times" style={commonIcon} />
          </IconButton>
        )}
      </button>
    </>
  );
}
