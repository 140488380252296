import { createStyles, makeStyles } from "@material-ui/core";

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "5px",
      padding: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      color: "darkgrey",
      fontSize: 14,
      position: "absolute",
      bottom: "5px",
      right: "20px",
      [theme.breakpoints.down("xs")]: {
        margin: 0,
        bottom: "0px",
        right: "10px",
        fontSize: 10
      },
    },
    rotateItem: {
      animation: "$spin 10s linear infinite",
      //border: "1px dashed",
      borderRadius: "100%",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
    buttonBadge: {
      padding: 2,
    },
    typo: {
      fontWeight: "bold",
      color: "#FFF",
    },
  })
);
