import { makeStyles } from "@material-ui/core/styles";

const BORDER_RADIUS = 5;
export const FOOTER_MIN_HEIGHT = "50px";

export const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const backButton = {
  padding: 5,
  marginRight: 15,
};

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.form.background,
    minWidth: 300,
    borderRadius: BORDER_RADIUS,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  mainContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
    backgroundColor: theme.palette.form.background,
    borderRadius: BORDER_RADIUS,
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
    backgroundColor: theme?.palette?.header?.backgroundColor,
    height: 40,
    width: "100%",
    position: "relative",
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: 80,
      alignItems: "center",
      justifyContent: "space-around",
    },
  },
  headerStartButtonGroup: {
    marginLeft: 10,
    display: "flex",
    columnGap: "10px",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "absolute",
    right: 0,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  contentContainerColumn: {
    display: "flex",
    //IF tabs to horizontal uncomment the line below
    flexDirection: "column",
    flex: 1,
    marginLeft: "15px",
    width: "calc(100% - 30px)",
  },
  tabsContainer: {
    marginBottom: 5,
  },
  tabIndicatorProps: {
    background: theme.palette.content.mainColor,
    width: "5px",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
  },
  tabRoot: {
    display: "-webkit-inline-box",
    textAlign: "left",
    "&:active": {
      fontWeight: "bold",
    },
  },
  tabIconWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
  tabLabelContainer: {
    width: "auto",
    padding: 0,
  },
  tabLabelIcon: {
    paddingTop: 9,
    minHeight: "inherit",
  },
  formContainer: {
    width: "100%",
    height: "100%",
    margin: "0px 10px 10px 0px",
    overflowY: "auto",
  },
  gridLayout: {
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  footer: {
    backgroundColor: theme?.palette?.header?.backgroundColor,
    width: "100%",
    minHeight: FOOTER_MIN_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //boxShadow: "0px -1px 8px -3px #CDCDCD",
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
  },

  editButtonCommon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh",
    maxHeight: 28,
    color: theme.palette.content.mainColor,
    cursor: "pointer",
    fontWeight: "700",
    outline: "none",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.content.mainColor}`,
    marginLeft: 10,
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  editButtonCommonSelected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh",
    maxHeight: 28,
    color: "white",
    cursor: "pointer",
    fontWeight: "700",
    border: "none",
    outline: "none",
    backgroundColor: theme.palette.content.mainColor,
    marginLeft: 10,
    transform: "scale(1.1)",
  },
  deleteButtonCommon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh",
    maxHeight: 28,
    backgroundColor: "transparent",
    color: "rgb(109, 109, 109)",
    cursor: "pointer",
    fontWeight: "700",
    border: "1px solid #f87979",
    margin: "0px 10px",
    outline: "none",
    "&:hover": {
      border: "1px solid red",
      transform: "scale(1.1)",
    },
  },
  deleteButtonCommonSelected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "2.5em",
    height: "3vh",
    maxHeight: 28,
    backgroundColor: "#f87979",
    color: "rgb(109, 109, 109)",
    cursor: "pointer",
    fontWeight: "700",
    border: "1px solid #f87979",
    margin: "0px 10px",
    outline: "none",
    transform: "scale(1.1)",
  },
  footerEndButtonGroup: {
    marginRight: 10,
    display: "flex",
    columnGap: "10px",
  },
  decisionButtonForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "6vw",
    height: "3vh",
    maxHeight: 28,
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    borderRadius: "5px",
    "&:disabled": {
      color: "grey",
      cursor: "not-allowed",
    },
    "&:hover": {
      transform: "scale(1.1)",
      border: `1px solid`,
    },
  },
}));
