import { isEmpty, isNil } from "lodash";
import {
  API_NOTIFICATIONS,
  METHOD_GET,
  METHOD_PUT,
  SERVER_HOST,
} from "../../../../util/Constants";

export async function invokeToObtain({ REQUEST_HEADERS, endpoint }) {
  const url = `${SERVER_HOST()}${API_NOTIFICATIONS}${endpoint}`;
  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return { ok: false, error: error.message };
    });

  return response;
}

export async function invokeToChange({ REQUEST_HEADERS, endpoint, body }) {
  const url = `${SERVER_HOST()}${API_NOTIFICATIONS}${endpoint}`;
  const response = await fetch(url, {
    method: METHOD_PUT,
    headers: REQUEST_HEADERS,
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return { ok: false, error: error.message };
    });

  return response;
}
