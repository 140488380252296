import React from "react";
import { Button, Icon, makeStyles } from "@material-ui/core";
import { usePDFZoom } from "../../../../core/hooks/usePDFZoom";
import { isNil, isNumber } from "lodash";

const commonControlBtn = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  minWidth: 40,
  maxWidth: 40,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  controlBar: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    gap: "8px",
    borderRadius: 6,
    backgroundColor: `${theme.palette.content.mainColor}22`,
  },
  controlBarBtn: {
    ...commonControlBtn,
    backgroundColor: `${theme.palette.content.mainColor}AA`,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}AA`,
      color: "#FFFFFF",
      transform: "scale(1.02)",
    },
  },
  controlBarBtnDisabled: {
    ...commonControlBtn,
    backgroundColor: theme.palette.grey[300],
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    overflow: "auto",
  },
  imgStyles: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    flexGrow: 1,
    transform: ({ zoom }) =>
      !isNil(zoom) && isNumber(zoom)
        ? zoom > 0
          ? `scale(${zoom})`
          : `scale(${1 / Math.abs(zoom)})`
        : undefined,
    transformOrigin: ({ zoom }) =>
      !isNil(zoom) && isNumber(zoom)
        ? zoom > 0
          ? "top"
          : "center"
        : undefined,
  },
}));

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export default function ImageViewer({ id, keySet, filePathToSet }) {
  const { zoom, increaseZoom, decreaseZoom } = usePDFZoom();
  const classes = useStyles({ zoom: zoom || 1 });

  return (
    <div className={classes.container}>
      <div className={classes.controlBar}>
        <Button className={classes.controlBarBtn} onClick={increaseZoom}>
          <Icon className="fas fa-search-plus" style={commonIcon} />
        </Button>
        <Button className={classes.controlBarBtn} onClick={decreaseZoom}>
          <Icon className="fas fa-search-minus" style={commonIcon} />
        </Button>
      </div>
      <div className={classes.imageContainer}>
        <img
          id={id}
          key={keySet}
          src={filePathToSet}
          className={classes.imgStyles}
          alt={`preview_image_${id}`}
        />
      </div>
    </div>
  );
}
