import {
  METHOD_POST,
  SERVER_HOST,
  SERVICE_CLI_URL,
} from "../../../../util/Constants";

export class CliShowData {
  //Method to implement
  static async execute(props) {
    const { serviceBody, REQUEST_HEADERS } = props;
    const url = SERVER_HOST() + SERVICE_CLI_URL;

    const response = await fetch(url, {
      method: METHOD_POST,
      headers: REQUEST_HEADERS,
      body: JSON.stringify(serviceBody),
    })
      .then((res) => res.json())
      .then((jsonResult) => {
        return jsonResult;
      })
      .catch((error) => {
        return {
          ok: false,
          errorMessage: error,
          ...error,
        };
      });
    if (response) {
      const { ok, errorMessage, dataResult } = response;
      if (ok) {
        console.log(JSON.parse(dataResult));
      } else {
        console.log(errorMessage);
      }
    }
  }
}
