import React, { useEffect, useMemo, useState } from "react";
import {
  Icon,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ListItemIcon,
} from "@material-ui/core";
import {
  EMPLOYEE_STORE_HISTORY_ROUTE,
  EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE,
} from "../../../routes/EmployeeStoreRouter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  drawer: {
    width: "70%",
    minWidth: "70%",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "70%",
    minWidth: "70%",
  },
}));

export default function EmployeeStoreNavigatorMobileMenu() {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  useEffect(() => {
    return history.listen(() => {
      setIsOpen(false);
    });
  }, [history]);

  const handleClickItem = ({ route }) => {
    toggleDrawer(false);
    history.push(route);
  };

  const MENU_ITEMS = useMemo(() => {
    return [
      {
        text: t("EMPLOYEE_STORE.HEADER_BTN_HISTORY"),
        route: EMPLOYEE_STORE_HISTORY_ROUTE,
        icon: "fas fa-history",
      },
      {
        text: t("EMPLOYEE_STORE.HEADER_BTN_FAVS"),
        route: EMPLOYEE_STORE_SHOPPING_FAVORITES_ROUTE,
        icon: "fas fa-heart",
      },
      {
        text: t("EMPLOYEE_STORE.BACK_TO_DSUITE"),
        route: "/",
        icon: "fas fa-sign-out-alt",
      },
    ];
  }, [t]);

  return (
    <>
      <IconButton aria-label="open drawer" onClick={toggleDrawer(true)}>
        <Icon className="fas fa-bars" />
      </IconButton>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <List>
          {MENU_ITEMS.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleClickItem(item)}
            >
              <ListItemIcon>
                <Icon className={item.icon} />
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
