import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mobileCont: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0px 0px 10px",
    borderBottom: `1px solid ${theme.palette.content.mainColor}AA`,
  },
  normalCont: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px 0px 10px",
    borderBottom: `1px solid ${theme.palette.content.mainColor}AA`,
  },
}));

const VersionLabel = () => {
  if (process.env.REACT_APP_VERSION) {
    return (
      <Typography
        style={{
          fontSize: "0.8em",
          marginRight: 5,
        }}
      >
        v{process.env.REACT_APP_VERSION}
        {process.env.NODE_ENV === "development" && " (DEV)"}
      </Typography>
    );
  } else {
    return null;
  }
};

export default function MenuLabelAndVersion({ isMobile }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={isMobile ? classes.mobileCont : classes.normalCont}>
      <Typography component="h5" variant="h5">
        {t("MENU")}
      </Typography>
      <VersionLabel />
    </div>
  );
}
