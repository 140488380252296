import { useMemo } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { includes, isEmpty, isNil, size, toLower } from "lodash";

export const useFeatureOnSolutions = (solutions = []) => {
  const location = useLocation();

  const isFeatureEnabled = useMemo(() => {
    if (
      !isNil(location) &&
      !isNil(location.pathname) &&
      !isNil(solutions) &&
      !isEmpty(solutions)
    ) {
      const locationPathName = location.pathname;
      let result = false;
      for (let i = 0; i < size(solutions); i++) {
        const solution = solutions[i];
        const isSolutionIncluded =
          !isNil(solution) &&
          (includes(toLower(locationPathName), toLower(solution)) ||
            toLower(solution) === "all");
        if (isSolutionIncluded === true) {
          result = true;
          break;
        }
      }
      return result;
    }

    return false;
  }, [solutions, location]);

  return isFeatureEnabled;
};
