import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Grid, Button, Box, Icon } from "@material-ui/core";

import HistoryItemContainerDetails from "./HistoryItemContainerDetails";
import { get, has, isArray, isEmpty, isNil } from "lodash";
import {
  EMS_PAYMENT_STATUS_ICON_MAP,
  formatDateToHumanReadableWithLang,
  formatPriceWithCountry,
} from "../../util/util-ems";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { useLocale } from "../../../../core/hooks/useLocale";
import useToggleableSections, {
  SectionTitleToggler,
} from "../../store/hooks/useToggleableSections";
import { useTranslation } from "react-i18next";
import { safeFormattingDate } from "../../../../util/UtilDates";
import {
  DATE_TIME_PATTERN,
  FRONT_DATE_TIME_PATTERN,
} from "../../../../util/Constants";
import { getHumanReadableTimeOffset } from "../../../../core/hooks/useLiveClock";

const useStyles = makeStyles((theme) => ({
  historyItemPaper: {
    marginBottom: theme.spacing(2),
  },
  historyItemHeader: {
    backgroundColor: `${theme.palette.content.mainColor}`,
    color: theme.palette.primary.contrastText,
    padding: "12px 48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRadius: 8,
  },
  historyItemDetails: {
    padding: "12px 10%",
  },
  historyItemHeaderProperty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  showMoreInfoBtn: {},
  paymentInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingInline: 12,
    gap: 6,
  },
  paymentInfoBoxMember: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 2,
};

const SECTION_PRODUCTS = "section-products";
const SECTION_PAYMENT_INFO = "section-payment";
const SECTION_ORDERS = "section-orders";
const SECTION_SIMULATIONS = "section-simulations";

const INITIAL_SECTIONS_STATE = {
  [SECTION_PRODUCTS]: true,
  [SECTION_PAYMENT_INFO]: true,
  [SECTION_ORDERS]: false,
  [SECTION_SIMULATIONS]: false,
};

const HistoryItemContainer = ({
  emsIdCompra,
  purchaseDate,
  quantityProducts,
  quantityUnits,
  products,
  totalAmount,
  paymentInfo,
  parentSimulationId,
  simulations,
  sapOrders,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { auth } = useContext(AuthContext);
  const [locale] = useLocale();
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const { openSections, toggleSection } = useToggleableSections({
    initialState: INITIAL_SECTIONS_STATE,
  });

  const handleToggleShowMoreInfo = () => {
    setShowMoreInfo((prev) => !prev);
  };

  const hasPaymentInfo =
    !isNil(paymentInfo) &&
    !isNil(paymentInfo?.transactionToken) &&
    paymentInfo?.transactionToken !== "";

  const hasSimulations = !isNil(simulations) && !isEmpty(simulations);

  const hasSapOrders = !isNil(sapOrders) && !isEmpty(sapOrders);

  const timeOffset = getHumanReadableTimeOffset();

  return (
    <Paper className={classes.historyItemPaper}>
      <Grid
        container
        className={classes.historyItemHeader}
        justifyContent="space-between"
      >
        <Grid item className={classes.historyItemHeaderProperty}>
          <Typography variant="h4">
            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_PURCHASE", {
              emsIdCompra,
            })}
          </Typography>
        </Grid>
        <Grid item className={classes.historyItemHeaderProperty}>
          <Typography variant="h5">
            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_DATE")}
          </Typography>
          <Typography variant="h6">
            {formatDateToHumanReadableWithLang({
              dateString: purchaseDate,
              lang: locale,
            })}
          </Typography>
        </Grid>
        <Grid item className={classes.historyItemHeaderProperty}>
          <Typography variant="h5">
            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_PRODUCTS")}
          </Typography>
          <Typography variant="h6">{quantityProducts}</Typography>
        </Grid>
        <Grid item className={classes.historyItemHeaderProperty}>
          <Typography variant="h5">
            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_UNITS")}
          </Typography>
          <Typography variant="h6">{quantityUnits}</Typography>
        </Grid>
        <Grid item className={classes.historyItemHeaderProperty}>
          <Typography variant="h4">
            {formatPriceWithCountry({
              price: totalAmount,
              country: auth?.emsCountry,
            })}
          </Typography>
        </Grid>
      </Grid>
      {!isNil(products) && isArray(products) && !isEmpty(products) ? (
        <Paper className={classes.historyItemDetails}>
          <SectionTitleToggler
            id={SECTION_PRODUCTS}
            open={openSections?.[SECTION_PRODUCTS]}
            title={t("EMPLOYEE_STORE.HISTORY_ITEM_SECTION_PRODUCTS", {
              size: quantityProducts,
            })}
            toggleSection={toggleSection}
          />
          {openSections?.[SECTION_PRODUCTS]
            ? products.map((product) => (
                <HistoryItemContainerDetails key={product?.ean} {...product} />
              ))
            : null}

          {hasPaymentInfo || hasSimulations || hasSapOrders ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              width={"100%"}
              justifyContent={"center"}
            >
              <Button
                onClick={handleToggleShowMoreInfo}
                className={classes.showMoreInfoBtn}
                startIcon={
                  <>
                    {showMoreInfo ? (
                      <Icon
                        className={"fas fa-eye-slash"}
                        style={commonIconStyles}
                      />
                    ) : (
                      <Icon className={"fas fa-eye"} style={commonIconStyles} />
                    )}
                  </>
                }
              >
                {showMoreInfo
                  ? t("EMPLOYEE_STORE.HISTORY_ITEM_HIDE_MORE_INFO")
                  : t("EMPLOYEE_STORE.HISTORY_ITEM_SHOW_MORE_INFO")}
              </Button>
            </Box>
          ) : null}

          {showMoreInfo ? (
            <>
              {hasPaymentInfo && (
                <>
                  <SectionTitleToggler
                    id={SECTION_PAYMENT_INFO}
                    open={openSections?.[SECTION_PAYMENT_INFO]}
                    title={t(
                      "EMPLOYEE_STORE.HISTORY_ITEM_SECTION_PAYMENT_IFNO"
                    )}
                    toggleSection={toggleSection}
                  />
                  {openSections?.[SECTION_PAYMENT_INFO] ? (
                    <Box className={classes.paymentInfoBox}>
                      <Box className={classes.paymentInfoBoxMember}>
                        <Icon
                          className="fas fa-clipboard-list"
                          style={commonIconStyles}
                        />
                        <Typography variant="h6">
                          <strong>Status:</strong> {paymentInfo?.status}
                        </Typography>
                        {has(
                          EMS_PAYMENT_STATUS_ICON_MAP,
                          paymentInfo?.status
                        ) ? (
                          <Icon
                            className={
                              get(
                                EMS_PAYMENT_STATUS_ICON_MAP,
                                paymentInfo?.status
                              )?.icon
                            }
                            style={{
                              ...commonIconStyles,
                              color: get(
                                EMS_PAYMENT_STATUS_ICON_MAP,
                                paymentInfo?.status
                              )?.color,
                            }}
                          />
                        ) : (
                          <Icon
                            className="fas fa-times-circle"
                            style={{
                              ...commonIconStyles,
                              color: "#cb4335",
                            }}
                          />
                        )}
                      </Box>
                      <Box className={classes.paymentInfoBoxMember}>
                        <Icon
                          className="fas fa-clock"
                          style={commonIconStyles}
                        />
                        <Typography variant="h6">
                          <strong>
                            {t("EMPLOYEE_STORE.HISTORY_ITEM_HEADER_DATE")}:
                          </strong>{" "}
                          {safeFormattingDate(
                            DATE_TIME_PATTERN,
                            FRONT_DATE_TIME_PATTERN,
                            paymentInfo?.modificationDate
                          )}
                          ({timeOffset})
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </>
              )}

              {hasSapOrders ? (
                <>
                  <SectionTitleToggler
                    id={SECTION_ORDERS}
                    open={openSections?.[SECTION_ORDERS]}
                    title={t("EMPLOYEE_STORE.HISTORY_ITEM_SECTION_ORDERS")}
                    toggleSection={toggleSection}
                  />
                </>
              ) : null}

              {hasSimulations ? (
                <>
                  <SectionTitleToggler
                    id={SECTION_SIMULATIONS}
                    open={openSections?.[SECTION_SIMULATIONS]}
                    title={t("EMPLOYEE_STORE.HISTORY_ITEM_SECTION_SIMULATION")}
                    toggleSection={toggleSection}
                  />
                  <Typography variant="h6">{parentSimulationId}</Typography>
                </>
              ) : null}
            </>
          ) : null}
        </Paper>
      ) : null}
    </Paper>
  );
};

export default HistoryItemContainer;
