import { METHOD_POST, SERVER_HOST, SERVICE_SEL_URL } from "../../../util/Constants";

export async function callSelectionService(params) {
  const {
    serviceName,
    replacedParamsWithValues,
    dataResultDef,
    company,
    key,
    formInstanceId,
    line,
    REQUEST_HEADERS
  } = params;

  const url = SERVER_HOST() + SERVICE_SEL_URL;

  const serviceBody = {
    serviceName,
    requestData: {
      params: replacedParamsWithValues,
      dataResultDef,
      company,
    },
    formInstanceId,
    key,
    line,
  };

  const response = await fetch(url, {
    method: METHOD_POST,
    headers: REQUEST_HEADERS,
    body: JSON.stringify(serviceBody),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((error) => {
      return {
        ok: false,
        errorMessage: error,
        ...error,
      };
    });

  return response;
}
