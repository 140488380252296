import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, IconButton } from "@material-ui/core";

import FilterFieldButton from "./buttons/FilterFieldButton";
import FilterFieldCombo from "./buttons/FilterFieldCombo";

import {
  INPUT_TYPE_COMBOBOX,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_PASTE_AREA,
} from "../../../util/Constants";
import { clone, isArray, isEmpty, isNil } from "lodash";
import FilterFieldPasteArea from "./buttons/FilterFieldPasteArea";
import FilterFieldDateRange from "./buttons/FilterFieldDateRange";
import FilterFieldComboMultipleChoice from "./buttons/FilterFieldComboMultipleChoice";
import { useExternalResultQuery } from "../hooks/useExternalResultQuery";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: `${theme.palette.content.mainColor}33`,
    height: "auto",
    minHeight: 50,
    padding: 5,
    position: "relative",
    flexWrap: "wrap",
  },
  refreshButton: {
    position: "absolute",
    right: "5px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    cursor: "not-allowed",
  },
}));

export default function ExternalFilterFieldsContainer(props) {
  const { handleRefresh, availableFilters } = props;
  const classes = useStyles();
  const [filtersValues, setFiltersValues] = useState([]);
  const [{ status }] = useExternalResultQuery();
  const isFetching = status === "fetching";

  function handleChangeFilterValue(filterValue) {
    let newFiltersValue = clone(filtersValues);
    if (!isNil(filterValue) && !isEmpty(filterValue) && isArray(filterValue)) {
      for (let i = 0; i < filterValue.length; i++) {
        const fv = filterValue[i];
        const { key } = fv;
        const isIn = newFiltersValue.findIndex((f) => f.key === key);
        if (isIn > -1) {
          newFiltersValue[isIn] = fv;
        } else {
          newFiltersValue.push(fv);
        }
      }
    } else {
      const { key } = filterValue;
      const isIn = newFiltersValue.findIndex((f) => f.key === key);
      if (isIn > -1) {
        newFiltersValue[isIn] = filterValue;
      } else {
        newFiltersValue.push(filterValue);
      }
    }
    setFiltersValues(newFiltersValue);
    handleRefresh(newFiltersValue);
  }

  return (
    <div className={classes.filtersContainer}>
      {isFetching && <div className={classes.overlay} />}
      {availableFilters &&
        availableFilters.length > 0 &&
        availableFilters
          .filter((x) => isNil(x?.hidden) || x?.hidden === false)
          .map((f, index) => {
            const {
              field,
              title,
              type,
              input,
              comboOptions,
              owner,
              defaultValue,
              defaultOperation,
              isLimit,
              canRemove,
              injectsInto,
              canSelectMultiple,
              blockInteraction,
              required,
            } = f;

            const filterValue = filtersValues.find((fv) => fv.key === field);
            const isRequiredAndMissing =
              required && (!filterValue || isEmpty(filterValue.value));

            const filterProps = {
              key: `${field}-${index}`,
              type,
              title,
              field,
              index,
              handleChangeFilterValue,
              comboOptions,
              owner,
              defaultValue,
              defaultOperation,
              isLimit,
              canRemove,
              injectsInto,
              availableFilters,
              blockInteraction,
              isRequiredAndMissing,
            };

            if (!isNil(input) && input === INPUT_TYPE_COMBOBOX) {
              const isComboWithMultipleOutput =
                !isNil(canSelectMultiple) && canSelectMultiple === true;

              if (isComboWithMultipleOutput) {
                return <FilterFieldComboMultipleChoice {...filterProps} />;
              } else {
                return <FilterFieldCombo {...filterProps} />;
              }
            } else if (!isNil(input) && input === INPUT_TYPE_PASTE_AREA) {
              return <FilterFieldPasteArea {...filterProps} />;
            } else if (!isNil(input) && input === INPUT_TYPE_DATE_RANGE) {
              return <FilterFieldDateRange {...filterProps} />;
            } else {
              return <FilterFieldButton {...filterProps} />;
            }
          })}
      <IconButton
        onClick={() => handleRefresh(filtersValues)}
        className={classes.refreshButton}
      >
        <Icon className="fas fa-sync" style={commonIcon} />
      </IconButton>
    </div>
  );
}
