import React from "react";
import { CircularProgress, useTheme } from "@material-ui/core";

export default function ThemedCircularProgress({ size, color }) {
  const theme = useTheme();

  return (
    <CircularProgress
      disableShrink={true}
      style={{ color: color || theme.palette.content.mainColor }}
      size={size && size > 0 && size}
    />
  );
}
