import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Icon, Typography } from "@material-ui/core";

import { WORKSPACE_TAB } from "../../../../util/Constants";
import { useStyles, iconAuto } from "./styles";

export default function WorkspaceButton(props) {
  const { activeTab, handleTabClick, sidebarOpen } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip title={t("TAB_WORKSPACE")} placement="right">
      <div className={classes.envButton}>
        <IconButton
          id="tab_workspace_button"
          className={
            activeTab === WORKSPACE_TAB ? classes.activeTab : classes.normalTab
          }
          onClick={() => {
            handleTabClick(WORKSPACE_TAB, true);
          }}
        >
          <Icon className="fas fa-layer-group" style={iconAuto} />
        </IconButton>
        {sidebarOpen && <Typography>{t("TAB_WORKSPACE")}</Typography>}
      </div>
    </Tooltip>
  );
}
