import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import {
  isArray,
  isEmpty,
  isNil,
  isNumber,
  isString,
  toString,
  trim,
  uniqBy,
} from "lodash";
import { v4 as uuid } from "uuid";

import { useContextualMenu } from "../../../core/hooks/useContextualMenu";
import {
  callServerToSetMultipleValues,
  defineInputEnabledByOperation,
  extractErrorMessageFromResponse,
  getCompletePath,
  getSelectionServices,
  isServerResponseValid,
  transformFormStatusFromServer,
  transformValuesForSetChips,
} from "../../../util/UtilForm";
import ContextMenu from "../handlers/HandlerContextMenu";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { LINE_SEPARATOR, TOAST_TYPE_ERROR } from "../../../util/Constants";
import { usePopover } from "../../../core/hooks/usePopover";
import { useBlockingToast } from "../../../core/hooks/useBlockingToast";
import Draggable from "react-draggable";

const BASE_LINE_HEIGHT = 28;

const PASTE_AREA_BTN = "_paste_area_btn";

const baseRoot = {
  display: "flex",
  position: "relative",
  flexWrap: "wrap",
  listStyle: "none",
  margin: "3px 0px 0px 0px",
  width: "100%",
  minHeight: BASE_LINE_HEIGHT,
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...baseRoot,
    justifyContent: "center",
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}33`,
      cursor: "pointer",
    },
  },
  rootDisabled: {
    ...baseRoot,
    justifyContent: "center",
    padding: theme.spacing(0.5),
    "&:hover": {
      cursor: "default",
    },
  },
  rootFilled: {
    ...baseRoot,
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}33`,
      cursor: "pointer",
    },
  },
  rootFilledDisabled: {
    ...baseRoot,
    padding: theme.spacing(0.5),
    "&:hover": {
      cursor: "default",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipExtras: {
    margin: theme.spacing(0.5),
    border: "3px double grey",
    fontWeight: "bold",
  },
  addIconFixed: {
    position: "absolute",
    top: -1,
    right: -1,
    color: "darkgrey",
  },
  addIconDisabled: {
    color: "grey",
  },
  pasteAreaBtn: {
    padding: 1,
    margin: "0px 3px",
  },
  contentDialog: {
    minHeight: 400,
    minWidth: 400,
    maxHeight: 500,
    maxWidth: 400,
  },
  btnConfirmPaste: {
    color: "#FFF",
    backgroundColor: theme.palette.content.mainColor,
    "&:hover": {
      backgroundColor: theme.palette.content.mainColor,
      transform: "scale(1.05)",
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "move",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "15px 25px",
  },
}));

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-paste-area"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function SimpleChipField(props) {
  const { idSchema, schema, formData, formContext } = props;

  const { value, presentationInfo } = formData || {};

  const {
    operation,
    section,
    block,
    formInstanceId,
    pre,
    globalFormData,
    handleFormDataChange,
    shadowStatus,
    isBlockDisabledByWizard,
  } = formContext;

  const completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  const chipDef = schema?.chipDef;

  const classes = useStyles();
  const REQUEST_HEADERS = useRequestHeaders();

  const { openMenu, menuPosition, toggleContextualMenu, changeMenuPosition } =
    useContextualMenu();

  const { renderBlockingToast, BlockingToastModal } = useBlockingToast();

  const {
    id,
    open,
    anchorEl: anchorElExtras,
    handleClick: handleClickExtrasPopover,
    handleClose: hanldeCloseExtrasPopver,
  } = usePopover();

  const [openPasteArea, setOpenPasteArea] = useState(false);
  const [textValue, setTextValue] = useState("");

  const selectionServices = useMemo(() => {
    return getSelectionServices(schema);
  }, [schema]);

  const inputEnabled = useMemo(() => {
    if (isBlockDisabledByWizard === true) {
      return false;
    } else if (presentationInfo?.enabled === false) {
      return false;
    } else if (schema && schema?.readOnly === true) {
      return false;
    } else {
      const checkInputEnabled = defineInputEnabledByOperation(
        operation,
        schema?.canEdit,
        schema?.canNew
      );
      if (!isNil(checkInputEnabled)) {
        return checkInputEnabled;
      } else {
        return false;
      }
    }
  }, [operation, schema, presentationInfo, isBlockDisabledByWizard]);

  const chipData = useMemo(() => {
    if (!isNil(value) && !isEmpty(value) && isString(value)) {
      const splittedInChips = value.split(",");

      return chipDef?.removeDuplicates === true
        ? [...new Set(splittedInChips)]
        : splittedInChips;
    }
    return [];
  }, [value, chipDef]);

  const handleClick = (e) => {
    if (inputEnabled) {
      handleContextMenu(e, true);
    }
  };

  //Handle context menu opening and position(x and y)
  const handleContextMenu = (e, isRightClick) => {
    //Prevent default and show context menu only if input is enabled
    if (inputEnabled) {
      e.preventDefault();
      if (!isRightClick) {
        const cliX = e.clientX;
        const cliY = e.clientY;
        if (cliX > 0 && cliY > 0)
          changeMenuPosition({
            x: cliX,
            y: cliY,
          });
      }
      toggleContextualMenu();
    }
  };

  const isNeededToAppendValues =
    !isNil(chipDef?.appendValues) && chipDef?.appendValues === true;

  const handleSetDataFromContextMenu = async (valuesFromSelection) => {
    if (isNeededToAppendValues === true) {
      const currentChipData =
        !isNil(chipData) && isArray(chipData) && !isEmpty(chipData)
          ? chipData.map((x, index) => ({
              idControl: completeIdControl,
              line: index,
              value: x,
            }))
          : [];

      const newValuesFromSelectionToSet =
        !isNil(valuesFromSelection) &&
        isArray(valuesFromSelection) &&
        !isEmpty(valuesFromSelection)
          ? [...currentChipData, ...valuesFromSelection].map((x, index) => ({
              ...x,
              line: index,
            }))
          : valuesFromSelection;

      await handleSetData({
        valuesFromSelection: newValuesFromSelectionToSet,
        isDelete: false,
      });
    } else {
      await handleSetData({
        valuesFromSelection,
        isDelete: false,
      });
    }
  };

  const handleDelete = async (chipToDelete) => {
    const parentOf =
      !isNil(chipDef) &&
      !isNil(chipDef.parentOf) &&
      isArray(chipDef.parentOf) &&
      !isEmpty(chipDef.parentOf)
        ? chipDef.parentOf
        : null;

    const posToDelete = chipData.indexOf(chipToDelete);

    const filteredChips = chipData.filter((chip) => chip !== chipToDelete);

    const childrenDeleted = !isNil(parentOf)
      ? parentOf
          .map((x) => {
            if (!isNil(x) && !isEmpty(x) && isString(x) && x.includes(".")) {
              const idControl = x;
              const keyInFormData = x.split(".").pop();
              const valOfChildrenToDelete = globalFormData[keyInFormData];
              if (
                !isNil(valOfChildrenToDelete) &&
                !isNil(valOfChildrenToDelete.value) &&
                isString(valOfChildrenToDelete.value) &&
                !isEmpty(valOfChildrenToDelete.value)
              ) {
                const oldArr = valOfChildrenToDelete.value.split(",");
                if (posToDelete > -1 && !isEmpty(oldArr)) {
                  oldArr.splice(posToDelete, 1);
                }
                return {
                  idControl,
                  line: 0,
                  value: oldArr.toString(),
                };
              } else {
                return null;
              }
            } else {
              return null;
            }
          })
          .filter((x) => !isNil(x))
      : [];

    const valuesFromSelection = [
      {
        idControl: completeIdControl,
        line: 0,
        value: filteredChips.toString(),
      },
      ...childrenDeleted,
    ];
    await handleSetData({
      valuesFromSelection,
      isDelete: true,
    });
  };

  const handleSetData = async ({ valuesFromSelection, isDelete }) => {
    const values = isDelete
      ? valuesFromSelection
      : transformValuesForSetChips({ valuesFromSelection });

    const response = await callServerToSetMultipleValues(
      {
        formInstanceId,
        values,
      },
      REQUEST_HEADERS
    );

    //Analyze response
    if (isServerResponseValid(response)) {
      const { fstatus } = response;
      const transformedFormStatus = transformFormStatusFromServer(fstatus);
      const newGlobalData = { ...globalFormData, ...transformedFormStatus };

      //Set new form data through form context
      await handleFormDataChange(newGlobalData);
    } else if (
      !isNil(response) &&
      !isNil(response.error) &&
      response.error === true &&
      !isNil(response.msg)
    ) {
      //Extract error message if there is one
      const toastText = extractErrorMessageFromResponse(response);

      const toastContent = {
        title: toastText,
      };

      renderBlockingToast({
        type: TOAST_TYPE_ERROR,
        ...toastContent,
      });
    }
  };

  const getPaperClassName = useCallback(() => {
    if (inputEnabled && chipData.length > 0) {
      return classes.rootFilled;
    }

    if (inputEnabled && chipData.length === 0) {
      return classes.root;
    }

    if (!inputEnabled && chipData.length > 0) {
      return classes.rootFilledDisabled;
    }

    if (!inputEnabled && chipData.length === 0) {
      return classes.rootDisabled;
    }
  }, [inputEnabled, chipData, classes]);

  const maxChipCount = useMemo(() => {
    if (
      !isNil(chipDef) &&
      !isNil(chipDef.maxChipCount) &&
      isNumber(chipDef.maxChipCount) &&
      chipDef.maxChipCount > 0
    ) {
      return chipDef.maxChipCount;
    } else {
      return chipData.length;
    }
  }, [chipDef, chipData]);

  const handleClickExtras = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();

      if (chipDef?.enableExtras) {
        handleClickExtrasPopover(e);
      }
    }
  };

  const handleTextAreaValue = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      const textValueInEvent = e.target.value;
      setTextValue(textValueInEvent);
    }
  };

  async function handlePasteAreaAction(confirm) {
    if (confirm) {
      if (!isNil(textValue) && !isEmpty(textValue)) {
        const valuesFromSelection = uniqBy(textValue.split(LINE_SEPARATOR))
          ?.filter((x) => !isNil(x) && !isEmpty(x))
          ?.map((x, index) => {
            return {
              line: index,
              value: trim(toString(x).replace(",", "")),
              idControl: completeIdControl,
            };
          });

        await handleSetData({
          valuesFromSelection,
          isDelete: false,
        });
      }
    }
    setOpenPasteArea(false);
  }

  const validTextValuePasted = useMemo(() => {
    if (!isNil(textValue) && !isEmpty(textValue) && isString(textValue)) {
      return uniqBy(textValue.split(LINE_SEPARATOR)).length > 0;
    }
    return true;
  }, [textValue]);

  const specialStyles = {
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo &&
      presentationInfo.backgroundColor &&
      presentationInfo.backgroundColor,
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
  };

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      presentationInfo={presentationInfo}
    >
      <BlockingToastModal />
      {!isNil(selectionServices) &&
        !isEmpty(selectionServices) &&
        !isNil(menuPosition) &&
        openMenu === true && (
          <ContextMenu
            openMenu={openMenu}
            uniqueID={uuid}
            services={selectionServices}
            menuPosition={menuPosition}
            setValue={handleSetDataFromContextMenu}
            closeMenu={toggleContextualMenu}
            formContextData={globalFormData}
            line={0}
            formInstanceId={formInstanceId}
            auto={selectionServices.length === 1}
            shadowStatus={shadowStatus}
          />
        )}
      <Paper
        id={completeIdControl}
        component="ul"
        className={getPaperClassName()}
        onClick={handleClick}
        style={
          !isNil(chipDef?.initialLines) &&
          isNumber(chipDef?.initialLines) &&
          isEmpty(chipData)
            ? {
                ...specialStyles,
                minHeight: BASE_LINE_HEIGHT * chipDef?.initialLines,
                alignItems: "center",
              }
            : specialStyles
        }
      >
        {chipData.slice(0, maxChipCount).map((data) => {
          const isDeleteDisabled =
            !isNil(chipDef) &&
            !isNil(chipDef.disableUniqueDelete) &&
            chipDef.disableUniqueDelete;

          return (
            <li key={data}>
              {isDeleteDisabled ? (
                <Chip label={data} className={classes.chip} size="small" />
              ) : (
                <Chip
                  label={data}
                  onDelete={() => handleDelete(data)}
                  className={classes.chip}
                  size="small"
                  disabled={!inputEnabled}
                />
              )}
            </li>
          );
        })}
        {chipData.length > maxChipCount && (
          <Chip
            label={`+${chipData.length - maxChipCount}`}
            className={classes.chipExtras}
            size="small"
            onClick={handleClickExtras}
          />
        )}
        {inputEnabled && <AddIcon className={classes.addIconFixed} />}
      </Paper>
      {!isNil(chipDef) && !isNil(chipDef.enablePasteArea) && (
        <IconButton
          id={`${completeIdControl}${PASTE_AREA_BTN}`}
          className={classes.pasteAreaBtn}
          onClick={() => {
            setTextValue("");
            setOpenPasteArea(true);
          }}
        >
          <Icon className="fas fa-paste" style={commonIcon} />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElExtras}
        onClose={hanldeCloseExtrasPopver}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {chipData.slice(maxChipCount, chipData.length).map((data) => {
          const isDeleteDisabled =
            !isNil(chipDef) &&
            !isNil(chipDef.disableUniqueDelete) &&
            chipDef.disableUniqueDelete;

          return (
            <div key={data}>
              {isDeleteDisabled ? (
                <Chip label={data} className={classes.chip} size="small" />
              ) : (
                <Chip
                  label={data}
                  onDelete={() => handleDelete(data)}
                  className={classes.chip}
                  size="small"
                  disabled={!inputEnabled}
                />
              )}
            </div>
          );
        })}
      </Popover>
      <Dialog
        open={openPasteArea}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title-paste-area"
      >
        <DialogTitle id="draggable-dialog-title-paste-area">
          <Typography variant="h4" className={classes.dialogTitle}>
            {chipDef?.titlePasteArea || ""}
            <IconButton onClick={() => handlePasteAreaAction(false)}>
              <Icon className="fas fa-times" />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              multiline
              value={textValue}
              onChange={handleTextAreaValue}
            />
          </FormControl>
          {!validTextValuePasted && (
            <Typography variant="subtitle1">
              Formato del texto pegado es incorrecto
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <IconButton
            disabled={isNil(textValue) || isEmpty(textValue)}
            variant="contained"
            onClick={() => setTextValue("")}
          >
            <Icon className="fas fa-trash" />
          </IconButton>
          <Button
            disabled={
              isNil(textValue) || isEmpty(textValue) || !validTextValuePasted
            }
            variant="contained"
            onClick={() => handlePasteAreaAction(true)}
            className={classes.btnConfirmPaste}
          >
            Confirm
            <Icon
              className="fas fa-file-import"
              style={{ ...commonIcon, marginLeft: 3 }}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </SimpleFieldContainer>
  );
}
