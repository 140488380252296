import React, { useContext } from "react";
import { Badge, Icon, IconButton, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import { NotificationsContext } from "../../../../core/providers/NotificationsContext";

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: theme.palette.content.niceRed,
    color: "white",
  },
}));

export default function NotificationsButtons() {
  const { handleNotificationsDock, newVersion } =
    useContext(NotificationsContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const hasNewVersion = newVersion && newVersion !== "";

  if (!hasNewVersion) return null;

  return (
    <Tooltip title={t("NOTIFICATIONS_TITLE")} placement="left">
      <IconButton
        aria-label="notifications"
        style={{ margin: "0px 5px" }}
        onClick={() => handleNotificationsDock({ open: true })}
      >
        {hasNewVersion ? (
          <Badge badgeContent={1} classes={{ badge: classes.customBadge }}>
            <Icon className="fas fa-bell" />
          </Badge>
        ) : (
          <Icon className="fas fa-bell" />
        )}
      </IconButton>
    </Tooltip>
  );
}
