import React, { useCallback, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  Typography,
  Paper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Draggable from "react-draggable";

import * as API from "./api";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { isArray, isEmpty, isFunction, isNil } from "lodash";
import {
  base64ToArrayBuffer,
  saveByteArrayToFilePDF,
} from "../../../util/UtilFormat";
import CustomBodyToast from "../../toast/CustomBodyToast";
import { toast } from "react-toastify";
import { AuthContext } from "../../../core/providers/AuthContext";
import {
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";

const DEFAULT_FONT_SIZE = 16;

const TOAST_TYPE_ERROR = ["#ffb404b5", "#FFFFFF"];

const BASE_HELP_LEAD = "HELP_GUIDE_COMPRESS_FILES";
const DIALOG_MSG = `${BASE_HELP_LEAD}_MSG`;
const DIALOG_BTN = `${BASE_HELP_LEAD}_BTN`;
const FILE_NAME = `${BASE_HELP_LEAD}_FILE_NAME`;

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 5,
  fontSize: "1.2rem",
};

export const useStyles = makeStyles(() => ({
  dialogTitleContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.1rem",
  },
  innerTitleHtml: {
    fontSize: DEFAULT_FONT_SIZE,
  },
  nestedParagraphs: {
    "& p": {
      fontSize: DEFAULT_FONT_SIZE,
    },
  },
  nestedSpan: {
    "& span": {
      fontSize: DEFAULT_FONT_SIZE,
    },
  },
  nestedDiv: {
    "& div": {
      fontSize: DEFAULT_FONT_SIZE,
    },
  },
  downloadBtn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
    minHeight: "50px",
    backgroundColor: "#8b8484d4",
    color: "#FFFF",
    border: "2px dashed",
    "&:hover": {
      backgroundColor: "#8b8484d4",
      color: "#FFFF",
      textDecoration: "underline",
    },
  },
  downloadBtnTypo: {
    color: "#FFFF",
    fontWeight: "bold",
  },
}));

function DraggablePaperComponent(props) {
  return (
    <Draggable handle="#not-allowed-max-size-alert">
      <Paper {...props} />
    </Draggable>
  );
}

export default function NotAllowedMaxSizeMessage({ open, closeModal }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const handleDownloadGuide = useCallback(async () => {
    let toastContent = null;
    const response = await API.getHelpGuideToCompressFiles({
      REQUEST_HEADERS,
      defaultDocument: `${BASE_HELP_LEAD}-${i18n.language}.pdf`,
    });

    if (response) {
      if (
        response?.status === HTTP_STATUS_UNAUTHORIZED &&
        !isNil(logout) &&
        isFunction(logout)
      ) {
        logout(true);
      } else {
        const { ok, msg, dataResponse } = response;
        if (!ok) {
          toastContent = <CustomBodyToast title={msg} />;
        } else {
          if (
            !isNil(dataResponse) &&
            isArray(dataResponse) &&
            !isEmpty(dataResponse)
          ) {
            for (const f of dataResponse) {
              if (!isNil(f) && !isNil(f.bytes)) {
                const arrBuffer = base64ToArrayBuffer(f.bytes);
                if (!isNil(arrBuffer)) {
                  saveByteArrayToFilePDF(`${t(FILE_NAME)}.pdf`, arrBuffer);
                }
              }
            }
          }
        }
      }
    } else {
      toastContent = (
        <CustomBodyToast
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          msg={null}
        />
      );
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "handleDownloadGuide",
      });
    }
  }, [REQUEST_HEADERS, i18n.language, logout, t]);

  return (
    <Dialog
      open={open}
      aria-labelledby="not-allowed-max-size-alert"
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          backgroundColor: `${TOAST_TYPE_ERROR[0]}`,
          borderRight: `1rem solid ${TOAST_TYPE_ERROR[1]}`,
          color: "white",
          minWidth: 500,
        },
      }}
      PaperComponent={DraggablePaperComponent}
      maxWidth="md"
    >
      <DialogTitle id="not-allowed-max-size-alert" style={{ cursor: "move" }}>
        <div className={classes.dialogTitleContent}>
          <Icon
            className="fas fa-exclamation-circle"
            style={{ ...commonIconStyles }}
          />
          <Typography variant="h5">{t("FILE_UPLOAD_GENERIC_ERROR")}</Typography>
        </div>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6">{t(DIALOG_MSG)}</Typography>
        <Button
          className={classes.downloadBtn}
          startIcon={
            <Icon className="fas fa-download" style={commonIconStyles} />
          }
          endIcon={
            <Icon className="fas fa-paperclip" style={commonIconStyles} />
          }
          onClick={() => handleDownloadGuide()}
        >
          <Typography className={classes.downloadBtnTypo}>
            {t(DIALOG_BTN)}
          </Typography>
        </Button>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "white" }} onClick={closeModal}>
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
