import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  Paper,
  TextField,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import Draggable from "react-draggable";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "@material-ui/lab/Alert";

import { commonIconStyles, useStyles } from "./ForgotPassDialogStyles";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../../util/UtilFormat";
import { RECAPTCHA_SITE_KEY } from "../../../util/Constants";
import { isNil, size } from "lodash";

function DraggablePaperComponent(props) {
  return (
    <Draggable handle="#forgot-pass-title">
      <Paper {...props} />
    </Draggable>
  );
}

export default function ForgotPassDialog({
  open,
  step,
  invokeForgotPassword,
  handleCancel,
  invokeValidateCaptcha,
  sending,
}) {
  const muiTheme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const [mail, setMail] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [validationCode, setValidationCode] = useState(null);

  useEffect(() => {
    if (open) {
      setMail("");
      setCaptchaValue(null);
      setValidationCode(null);
    }
  }, [open]);

  async function handleSendButton() {
    if (step === 0) {
      await invokeValidateCaptcha({ mail, tokenCaptcha: captchaValue });
    } else if (step === 1) {
      invokeForgotPassword({ mail, validationCode });
    }
  }

  function onChangeCaptcha(value) {
    setCaptchaValue(value);
  }

  const isNextDisabled = useMemo(() => {
    if (step === 0) {
      return (
        mail === "" || validateEmail(mail) === false || isNil(captchaValue)
      );
    } else if (step === 1) {
      return (
        validationCode === "" ||
        validationCode === null ||
        size(validationCode) < 6
      );
    } else {
      return true;
    }
  }, [step, mail, captchaValue, validationCode]);

  return (
    <Dialog
      open={open}
      aria-labelledby="forgot-pass-dialog"
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          minWidth: 300,
        },
      }}
      PaperComponent={DraggablePaperComponent}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="forgot-pass-title" style={{ cursor: "move" }}>
        <div className={classes.dialogTitleContent}>
          <Icon className="fas fa-key" style={{ ...commonIconStyles }} />
          {t("FORGOT_PASS_DIALOG_TITLE")}
        </div>
      </DialogTitle>

      {step === 0 ? (
        <DialogContent className={classes.dialogContent}>
          <TextField
            label={t("FORGOT_PASS_DIALOG_EMAIL_LABEL")}
            variant="outlined"
            type="text"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            className={classes.dialogInput}
            error={!validateEmail(mail)}
            helperText={
              validateEmail(mail) === true ? "" : "* Invalid email address"
            }
            autoFocus
            InputLabelProps={{
              style: { fontSize: 16 },
            }}
          />
          {!isNil(RECAPTCHA_SITE_KEY) && (
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={onChangeCaptcha}
              theme={muiTheme?.palette?.type || "light"}
            />
          )}
        </DialogContent>
      ) : (
        <DialogContent className={classes.dialogContent}>
          <Alert severity="success" className={classes.dialogInput}>
            {t("FORGOT_PASS_DIALOG_CODE_SENT")}
          </Alert>
          <TextField
            label={t("MFA_DIALOG_INPUT_LABEL")}
            variant="outlined"
            type="number"
            value={validationCode}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 6) {
                setValidationCode(inputValue);
              }
            }}
            className={classes.dialogInput}
            autoFocus
            InputLabelProps={{
              style: { fontSize: 16 },
            }}
          />
        </DialogContent>
      )}

      <DialogActions>
        <Button disabled={isNextDisabled} onClick={handleSendButton}>
          {!sending ? (
            step === 0 ? (
              t("NEXT")
            ) : step === 1 ? (
              t("FORM_SAVE")
            ) : null
          ) : (
            <CircularProgress size={18} />
          )}
        </Button>
        <Button onClick={handleCancel}>{t("FORM_CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
