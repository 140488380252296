import React, { useState, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Icon, IconButton } from "@material-ui/core";
import Modal from "react-modal";
import { Resizable } from "re-resizable";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import { DrawerContext } from "../../../core/providers/DrawerContext";

import {
  useStyles,
  commonIcon,
  customModalStylesDark,
  customModalStylesLight,
} from "./ExternalFormContainerStyles";
import BaseForm from "./BaseForm";

export default function ExternalFormContainerValues(props) {
  Modal.setAppElement("#reactapp-root");

  const {
    openModal,
    handleOpenModalForm,
    operation,
    path,
    idForm,
    values,
    overrideOps,
    defaultWidth,
    defaultHeight,
  } = props;

  const { drawerOpen } = useContext(DrawerContext);

  const [isResizing, setIsResizing] = useState(false);

  const wd = useWindowDimensions();
  const theme = useTheme();
  const classes = useStyles({ wd, openDrawer: drawerOpen });

  function onResizeStart() {
    setIsResizing(true);
  }

  function onResizeStop() {
    setIsResizing(false);
  }

  return (
    <Modal
      isOpen={openModal}
      style={
        theme.palette.type === "dark"
          ? customModalStylesDark
          : customModalStylesLight
      }
    >
      <Resizable
        defaultSize={{
          width: defaultWidth || "70vw",
          height: defaultHeight || "80vh",
        }}
        handleStyles={{
          right: {
            backgroundColor: isResizing && `${theme.palette.content.mainColor}`,
          },
          left: {
            backgroundColor: isResizing && `${theme.palette.content.mainColor}`,
          },
        }}
        //h
        maxHeight="90vh"
        minHeight="30vh"
        //w
        maxWidth="90vw"
        minWidth="30vw"
        style={{ overflow: "hidden" }}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
      >
        <BaseForm
          ukey={undefined}
          operation={operation}
          path={path}
          idForm={idForm}
          handleBack={() =>
            handleOpenModalForm({
              open: false,
              ukey: null,
            })
          }
          values={values}
          overrideOps={overrideOps}
        />
        <IconButton
          className={classes.closeModalButton}
          onClick={() =>
            handleOpenModalForm({
              open: false,
              ukey: null,
            })
          }
        >
          <Icon className="fas fa-times" style={commonIcon} />
        </IconButton>
      </Resizable>
    </Modal>
  );
}
