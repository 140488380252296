import { useState } from "react";

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  function openDialog() {
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }

  return { open, openDialog, closeDialog };
};
