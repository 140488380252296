import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  avisZoneStoredFilters: null,
  avisZoneStoredCollapsed: false,
  avisZoneStoredShowBpmDialog: false,
  isEditingAvisZoneForm: false,
  avisZoneStoredBreadcumbRoutes: new Map(),
};

export const createAvisZoneSlice = (set, get) => ({
  ...INITIAL_STATE,
  setAvisZoneStoredFilters: (newState) =>
    set(() => ({ avisZoneStoredFilters: newState })),
  setAvisZoneStoredCollapsed: (newState) =>
    set(() => ({ avisZoneStoredCollapsed: newState })),
  setAvisZoneStoredShowBpmDialog: (newState) =>
    set(() => ({ avisZoneStoredShowBpmDialog: newState })),
  addAvisZoneBreadcumbRoute: (newRoute) =>
    set((state) => ({
      avisZoneStoredBreadcumbRoutes: state.avisZoneStoredBreadcumbRoutes.set(
        newRoute,
        {
          avisZoneStoredFilters: state.avisZoneStoredFilters,
          avisZoneStoredCollapsed: state.avisZoneStoredCollapsed,
          avisZoneStoredShowBpmDialog: state.avisZoneStoredShowBpmDialog,
        }
      ),
    })),
  removeAvisZoneBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().avisZoneStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          avisZoneStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          avisZoneStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateAvisZoneBreadcumbRoute: (route, newParams) => {
    const actualBC = get().avisZoneStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      avisZoneStoredBreadcumbRoutes: state.avisZoneStoredBreadcumbRoutes.set(
        route,
        {
          ...spreadBC,
          ...newParams,
        }
      ),
    }));
  },
  toggleIsEditingAvisZoneForm: () => {
    set((state) => ({ isEditingAvisZoneForm: !state.isEditingAvisZoneForm }));
  },
  clear: () => set(INITIAL_STATE),
});
