import { useState, useCallback, useContext } from "react";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { fetchPolicyApi } from "../../api/infoApi";

const usePolicy = () => {
  const { t } = useTranslation();
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [policyInfo, setPolicyInfo] = useState(null); 


  let formatDocument = (data) => { 
    return [
      {
        uri: data?.url,
        fileType: data?.ext,
        key: data?.key,
        id: data?.key

      },
    ];
  };


  const fetchPolicy = useCallback(
    async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchPolicyApi(headers, logout);
        if (!isNil(response)) {
          const { error, data } = response;
          if (!isNil(error)) {
            setError(error);
          } else {
            const formatData = formatDocument(data);
            setPolicyInfo(formatData);   
          }
        } else {
          setError(t("ERROR_RESOURCE_NOT_FOUND_TEXT"));
        }
      } catch (err) {
        setError(t("ERROR_RESOURCE_NOT_FOUND_TEXT"));
      } finally {
        setLoading(false);
      }
    },
    [headers, logout, t]
  );
 

  return {
    loading,
    error,
    policyInfo,
    fetchPolicy
  };
};

export default usePolicy;
