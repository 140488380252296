import { useCallback, useContext, useState, useEffect } from "react";
import useEmployeeStoreState from "../EmployeeStoreState";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";

const DEFAULT_BANNER_INTERVAL_IN_SECONDS = 10; // 10s

const useBanner = () => {
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  const {
    banner,
    bannerVisible,
    setBannerVisible,
    fetchBanner,
    loadingBanner,
  } = useEmployeeStoreState((state) => ({
    banner: state.banner,
    bannerVisible: state.bannerVisible,
    setBannerVisible: state.setBannerVisible,
    fetchBanner: state.fetchBanner,
    loadingBanner: state.loadingBanner,
  }));

  const loadBanner = useCallback(() => {
    fetchBanner(headers, logout);
  }, [fetchBanner, headers, logout]);

  useEffect(() => {
    if (banner && banner.length > 0) {
      const currentBanner = banner[currentBannerIndex];
      const interval =
        (currentBanner.timeInScreen || DEFAULT_BANNER_INTERVAL_IN_SECONDS) *
        1000;

      setTimeLeft(Math.floor(interval / 1000));

      const timer = setTimeout(() => {
        setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banner.length);
      }, interval);

      const countdownTimer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => {
        clearTimeout(timer);
        clearInterval(countdownTimer);
      };
    }
  }, [banner, currentBannerIndex]);

  const currentBanner = banner && banner[currentBannerIndex];

  const goToNextBanner = useCallback(() => {
    setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banner.length);
  }, [banner]);

  const goToPreviousBanner = useCallback(() => {
    setCurrentBannerIndex((prevIndex) => 
      prevIndex === 0 ? banner.length - 1 : prevIndex - 1
    );
  }, [banner]);

  return {
    banner: currentBanner,
    bannerVisible,
    setBannerVisible,
    bannerImagesUrl: currentBanner ? [currentBanner.imageUrl] : [],
    sizeBannerImages: banner ? banner.length : 0,
    loadBanner,
    loadingBanner,
    timeLeft,
    goToNextBanner,
    goToPreviousBanner,
    currentBannerIndex,
    totalBanners: banner ? banner.length : 0,
  };
};

export default useBanner;
