import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cont: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme?.palette?.content?.backgroundColor
  },
  cpRoot: {
    color: theme?.palette?.content?.mainColor || "#3F66A2",
  },
}));

export default function SuspenseFallbackLoader() {
  const classes = useStyles();

  return (
    <div className={classes.cont}>
      <CircularProgress classes={{ root: classes.cpRoot }} disableShrink />
    </div>
  );
}
