import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

export default function UserShouldChangeTelephoneDialog({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="user-should-add-phone-dialog-title"
        aria-describedby="user-should-add-phone-dialog-description"
        style={{ zIndex: 99999999 }}
      >
        <DialogTitle id="user-should-add-phone-dialog-title">
          <Typography variant="h5">
            {t("NOTIFICATIONS_USER_MUST_ADD_PHONE_TITLE")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="user-should-add-phone-dialog-desc-title"
            variant="h6"
          >
            {t("NOTIFICATIONS_USER_MUST_ADD_PHONE_BODY")}
          </DialogContentText>
          {/* <DialogContentText
            id="user-should-add-phone-dialog-desc-2"
            variant="h7"
            style={{ fontStyle: "italic" }}
          >
            {t("NOTIFICATIONS_USER_MUST_ADD_PHONE_BODY_SUB", {
              config: t("GENERAL_CONFIG"),
              title: t("CHANGE_TELEPHONE_TITLE"),
            })}
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color="primary">
            {t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
