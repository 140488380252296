import {
  API_FORM_SERVICE,
  METHOD_GET,
  REST_HEADERS,
  SERVER_HOST,
  TOKEN_HEADER,
  TOKEN_INIT,
} from "../../../util/Constants";

async function getFrontConfirmServices(formInstanceId, token) {
  const url = `${SERVER_HOST()}${API_FORM_SERVICE}/getFrontConfirmServices?formInstanceId=${formInstanceId}`;

  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${token}`,
  };

  const response = await fetch(url, {
    method: METHOD_GET,
    body: null,
    headers: REQUEST_HEADERS,
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e;
    });

  return response;
}

async function getFrontAfterPersistServices(formInstanceId, token) {
  const url = `${SERVER_HOST()}${API_FORM_SERVICE}/getFrontAfterPersistServices?formInstanceId=${formInstanceId}`;

  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${token}`,
  };

  const response = await fetch(url, {
    method: METHOD_GET,
    body: null,
    headers: REQUEST_HEADERS,
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e;
    });

  return response;
}

export { getFrontConfirmServices, getFrontAfterPersistServices };
