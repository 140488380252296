import React, { useRef, useEffect, forwardRef } from "react";
import { Radio } from "@material-ui/core";

export const MainTableSelecionCheck = forwardRef((props, ref) => {
  const { indeterminate, enabled, ...rest } = props;
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div style={{ width: "auto" }}>
      <Radio
        disabled={!enabled}
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        style={{
          width: "auto",
          padding: 0,
        }}
        tabIndex={-1} // disable keyboard focus
        readOnly // radio btn not editable
      />
    </div>
  );
});
