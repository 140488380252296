import { isEmpty, isNil } from "lodash";
import {
  API_FORM_QUERY,
  METHOD_POST,
  SERVER_HOST,
} from "../../../util/Constants";

export async function executeQueryAction({ REQUEST_HEADERS, keyAction, body }) {
  const url = `${SERVER_HOST()}${API_FORM_QUERY}/query-action?key-action=${keyAction}`;

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: METHOD_POST,
    headers: REQUEST_HEADERS,
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return { ok: false, error: error.message };
    });

  return response;
}
