import { METHOD_GET } from "../../../util/Constants";
import { BASE_EMS_URL, handleApiResponse } from "./base";

const HISTORY_GET_ITEMS_ENDPOINT = "/history/get-items";

export const fetchHistoryItems = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}${HISTORY_GET_ITEMS_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};
