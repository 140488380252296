import { BASE_EMS_URL, handleApiResponse } from "./base";

const GET_PRODUCTS_ENDPOINT = "/products";
const GET_PRODUCT_DETAILS_ENDPOINT = "/products/get-one-details";

export const fetchProductsApi = async (headers, params, logout) => {
  const response = await fetch(
    `${BASE_EMS_URL}${GET_PRODUCTS_ENDPOINT}?${params}`,
    {
      method: "POST",
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};

export const fetchProductDetailsApi = async (headers, body, logout) => {
  const response = await fetch(
    `${BASE_EMS_URL}${GET_PRODUCT_DETAILS_ENDPOINT}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    }
  );

  return handleApiResponse(response, logout);
};
