import create from "zustand";
import {
  isNil,
  some,
  includes,
  isEmpty,
  find,
  clone,
  isString,
  isArray,
} from "lodash";
import { mountStoreDevtool } from "simple-zustand-devtools";

const MOUNT_DEVTOOL = false;

function isFormSubItem(params) {
  if (
    !isNil(params?.path) &&
    !isNil(params?.idForm) &&
    !isNil(params?.operation)
  ) {
    return true;
  }

  return false;
}

function isRecordsPanel(params) {
  if (
    !isNil(params?.path) &&
    !isNil(params?.idForm) &&
    isNil(params?.operation)
  ) {
    return true;
  }

  return false;
}

export function findNearestTabItemToMove(params) {
  if (isNil(params) || (!isNil(params) && isEmpty(params))) {
    return null;
  }

  const { tabItemsArray, tabItemToRemove } = params;

  if (
    isNil(tabItemToRemove) ||
    isNil(tabItemsArray) ||
    (!isNil(tabItemsArray) && isEmpty(tabItemsArray))
  ) {
    return null;
  }

  if (
    tabItemsArray.length === 1 &&
    some(tabItemsArray, { route: tabItemToRemove?.route })
  ) {
    return null;
  }

  if (tabItemsArray.length > 1) {
    const copyArray = clone(tabItemsArray);
    const filteredOne = copyArray.filter(
      (x) => x?.route !== tabItemToRemove?.route
    );
    if (!isNil(filteredOne) && !isEmpty(filteredOne)) {
      return filteredOne[0];
    } else {
      return null;
    }
  }
}

const MAPS_DASHBOARDS = [
  "/apollo/tasks/dashboard",
  "/apollo/labelling/dashboard",
  "/avis/local/dashboard",
  "/avis/zone/dashboard",
  "/oneprice/pricing/dashboard",
  "/friday/pedidos/dashboard",
  "/friday/drop/dashboard",
];

function isAlreadySolutionDashboard({ route, actualItems }) {
  let result = false;
  for (const baseSolution of MAPS_DASHBOARDS) {
    if (
      !isNil(route) &&
      !isEmpty(route) &&
      isString(route) &&
      includes(route, baseSolution) &&
      !isNil(actualItems) &&
      !isEmpty(actualItems) &&
      isArray(actualItems)
    ) {
      let innerFound = false;
      for (const tabItem of actualItems) {
        if (!isNil(tabItem?.route) && includes(tabItem.route, baseSolution)) {
          result = true;
          innerFound = true;
          break;
        }
      }
      if (innerFound) {
        break;
      }
    }
  }

  return result;
}

const useInteractiveItemStore = create((set, get) => ({
  tabItems: [],
  subItems: [],
  activeTabItem: null,
  activeSubItem: new Map(),
  activeSubItemObject: [],
  initialFormState: null,
  addTabItem: (params) => {
    if (!isNil(params) && !isEmpty(params)) {
      const { route, id, defaultPinned } = params;

      const actualItems = get().tabItems;

      const isAlreadyIn = some(actualItems, {
        route: route || id,
      });

      if (!isAlreadyIn && !isAlreadySolutionDashboard({ route, actualItems })) {
        //Filter only final tabs
        const oldItemToSet =
          !isNil(actualItems) && !isEmpty(actualItems)
            ? actualItems.filter((x) => x?.final)
            : actualItems;

        set(() => ({
          tabItems: [
            ...oldItemToSet,
            { ...params, final: defaultPinned || false },
          ],
        }));
      }
    }
  },
  makeTabFinal: (params) => {
    if (!isNil(params) && !isEmpty(params)) {
      const { route, id, final } = params;

      const isAlreadyIn = some(get().tabItems, {
        route: route || id,
      });

      if (isAlreadyIn) {
        set((state) => ({
          tabItems: state.tabItems.map((ti) => {
            if (!isNil(ti) && !isNil(ti.route)) {
              if (
                (!isNil(ti.route) && !isNil(route) && ti.route === route) ||
                (!isNil(ti.id) && !isNil(id) && ti.id === id)
              ) {
                return {
                  ...ti,
                  final,
                };
              }
            }

            return ti;
          }),
        }));
      }
    }
  },
  addSubItem: (params) => {
    const isAlreadyIn = some(get().subItems, {
      parentId: params?.parentId,
      route: params?.route || params?.id,
    });

    if (!isAlreadyIn && isFormSubItem(params)) {
      //form
      set((state) => ({
        subItems: [...state.subItems, { ...params }],
      }));
    } else if (
      !isAlreadyIn &&
      !isFormSubItem(params) &&
      isRecordsPanel(params)
    ) {
      set((state) => ({
        subItems: [{ ...params }, ...state.subItems],
      }));
    }
  },
  removeTabItem: (id) => {
    const actualActiveMap = get().activeSubItem;
    actualActiveMap.delete(id);

    set((state) => ({
      tabItems: state.tabItems.filter((tabItem) => tabItem?.route !== id),
      subItems: state.subItems.filter((subItem) => subItem?.parentId !== id),
      activeSubItem: actualActiveMap,
      activeSubItemObject: state.activeSubItemObject.filter(
        (subO) => !isNil(subO) && !isNil(subO?.key) && !includes(subO?.key, id)
      ),
    }));
  },
  removeAllTabItems: () => {
    set(() => ({
      tabItems: [],
      subItems: [],
      activeTabItem: null,
      activeSubItem: new Map(),
      activeSubItemObject: [],
      initialFormState: null,
    }));
  },
  removeSubTabItem: async (sub) => {
    set((state) => ({
      subItems: state.subItems.filter(
        (subItem) =>
          isNil(subItem?.entityName) || subItem?.entityName !== sub?.entityName
      ),
      activeSubItemObject: state.activeSubItemObject.filter(
        (subO) =>
          !isNil(subO) &&
          !isNil(subO?.key) &&
          !includes(subO?.key, `${sub?.parentId}_${sub?.entityName}`)
      ),
    }));
    return sub;
  },
  changeActiveTabItem: (id) =>
    set(() => ({
      activeTabItem: id,
    })),
  changeActiveSubItem: (sub) => {
    if (!isNil(sub) && !isNil(sub.parentId)) {
      const actualActiveMap = get().activeSubItem;
      actualActiveMap.delete(sub.parentId);
      actualActiveMap.set(sub.parentId, sub);

      set(() => ({
        activeSubItem: actualActiveMap,
      }));
    }
  },
  clearActiveTabItem: () =>
    set(() => ({
      activeTabItem: null,
    })),
  clearActiveSubItem: (sub) => {
    const actualActiveMap = get().activeSubItem;
    actualActiveMap.delete(sub?.parentId);

    set(() => ({
      activeSubItem: actualActiveMap,
    }));
  },
  saveActiveSubItem: (tabState) => {
    set((state) => {
      let len = state.activeSubItemObject.length;
      let is_fond = false;
      let i = 0;
      for (i = 0; i < len; i++) {
        if (state.activeSubItemObject[i].key === tabState.key) {
          // console.log("***************" + tabState.key + "***************");
          is_fond = true;
          break;
        }
      }

      let _arr = [...state.activeSubItemObject];

      if (is_fond) {
        if (tabState.isFirst === "updated") {
          _arr[i] = tabState;
        }
      } else {
        _arr.push(tabState);
      }
      return {
        activeSubItemObject: [..._arr],
      };
    });
  },
  setInitialformState: (state_obj) =>
    set(() => ({
      initialFormState: state_obj,
    })),
  removeActiveSubItemObjectByFid: (formInstanceId) => {
    set((state) => ({
      activeSubItemObject:
        !isNil(formInstanceId) &&
        !isNil(state.activeSubItemObject) &&
        !isEmpty(state.activeSubItemObject) &&
        some(state.activeSubItemObject, {
          formInstanceId,
        })
          ? state.activeSubItemObject.filter(
              (x) => x?.formInstanceId !== formInstanceId
            )
          : state.activeSubItemObject,
    }));

    return null;
  },
  updateFormOperationInStore: (formInstanceId, newOperation) => {
    const actualActiveSubItemObject = get().activeSubItemObject;
    const activeObject = !isNil(actualActiveSubItemObject)
      ? find(actualActiveSubItemObject, { formInstanceId })
      : null;
    if (!isNil(activeObject)) {
      set((state) => ({
        subItems: state.subItems.map((x) => {
          if (
            !isNil(x) &&
            !isNil(x.parentId) &&
            !isNil(x.entityName) &&
            `${x.parentId}_${x.entityName}` === activeObject?.key
          ) {
            return {
              ...x,
              operation: newOperation,
            };
          }
          return x;
        }),
        activeSubItemObject: state.activeSubItemObject.map((x) => {
          if (
            !isNil(x) &&
            !isNil(x.formInstanceId) &&
            x.formInstanceId === formInstanceId
          ) {
            return {
              ...x,
              operation: newOperation,
            };
          }
          return x;
        }),
      }));
    }
  },
  updateTabItem: (oldRoute, newRoute, updateActiveTabItem) => {
    const actualTabItems = get().tabItems;
    if (!isNil(actualTabItems) && !isEmpty(actualTabItems)) {
      if (some(actualTabItems, { route: oldRoute })) {
        set((state) => ({
          tabItems: state.tabItems.map((ti) => {
            if (!isNil(ti) && !isEmpty(ti) && ti.route === oldRoute) {
              return {
                ...ti,
                route: newRoute,
              };
            } else {
              return ti;
            }
          }),
          activeTabItem: updateActiveTabItem ? newRoute : state.activeTabItem,
        }));
      }
    }
  },
  clearAllInteractiveItemStore: () => {
    set(() => ({
      tabItems: [],
      subItems: [],
      activeTabItem: null,
      activeSubItem: new Map(),
      activeSubItemObject: [],
      initialFormState: null,
    }));
  },
}));

export { useInteractiveItemStore };

if (process.env.NODE_ENV === "development" && MOUNT_DEVTOOL) {
  mountStoreDevtool("InteractiveItemStore", useInteractiveItemStore);
}
