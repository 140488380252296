import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomBodyToast from "../../toast/CustomBodyToast";
import { useTranslation } from "react-i18next";
import { TOAST_CONTAINER_QUERY } from "../../../util/Constants";
import GenericToastContainer from "../../toast/GenericToastContainer";

export const useToast = () => {
  const { t } = useTranslation();

  const showToast = (msg, notiId) => {
    const toastText = msg && msg !== "" ? `${msg}` : t("FORM_ERROR_ACTION");
    const toastContent = <CustomBodyToast title={toastText} />;
    toast.error(toastContent, {
      toastId: notiId,
      containerId: TOAST_CONTAINER_QUERY,
      position: "top-right",
      //autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const ToastContainerImpl = () => (
    <GenericToastContainer containerId={TOAST_CONTAINER_QUERY} />
  );

  return { showToast, ToastContainerImpl };
};
