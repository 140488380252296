import { useEffect, useState, useMemo } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useDockSize = () => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [dockSize, setDockSize] = useState(0.4);

  const calculatedMinWidth = useMemo(() => {
    if (mobile) {
      return 0.9;
    }

    if (tablet) {
      return 0.75;
    }

    return 0.5;
  }, [tablet, mobile]);

  useEffect(() => {
    setDockSize(calculatedMinWidth);
  }, [calculatedMinWidth]);

  function handleDockSize(newSize) {
    setDockSize(newSize);
  }

  return {
    dockSize,
    handleDockSize,
    isMobileDock: mobile,
  };
};

export default useDockSize;
