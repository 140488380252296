import { isArray, isEmpty, isNil, isString, size, toUpper } from "lodash";
import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { isJsonFormat } from "../../../util/UtilFormat";
import { useDialog } from "../../../core/hooks/useDialog";
import CustomFileViewer from "../../dialogs/fileChooserDialog/CustomFileViewer";
import useFileCellRender, {
  ACTION_DOWNLOAD,
  ACTION_VIEW,
} from "../../../core/hooks/useFileCellRender";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const commonListItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 75,
};
const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "75vw",
    maxHeight: "75vh",
    paddingTop: 50,
    position: "relative",
  },
  controlWithValues: {
    height: "inherit",
    width: "100%",
    borderBottom: `3px solid ${theme.palette.content.mainColor}`,
  },
  controlEmpty: {
    height: "inherit",
    width: "100%",
    borderBottom: "1px solid grey",
  },
  list: {
    minWidth: 500,
    height: "100%",
    maxHeight: "75vh",
    padding: 10,
    overflow: "auto",
  },
  listItem: {
    ...commonListItem,
  },
  selectedListItem: {
    ...commonListItem,
    border: "1px solid",
    borderRadius: 5,
    backgroundColor: `${theme.palette.content.mainColor}55`,
  },
  normalBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 3,
  },
  avatarContainer: {
    backgroundColor: theme.palette.content.mainColor,
  },
  avatarTypo: {
    color: "white",
  },
  closeBtnDialog: {
    position: "absolute",
    top: 5,
    right: 5,
  },
}));

function CustomPaperComponent(props) {
  const classes = useStyles();
  return <Paper {...props} className={classes.dialog} />;
}

function FileCellRenderListDialog({ files, open, closeDialog }) {
  const classes = useStyles();
  const {
    fetchUrl,
    isFetchinView,
    isFetchinDownload,
    openViewer,
    preview,
    closeOpenViewer,
  } = useFileCellRender();
  const [selected, setSelected] = useState(null);
  const [selectedForDownload, setSelectedForDownload] = useState(null);

  if (isNil(files) || !isArray(files) || isEmpty(files)) {
    return null;
  }

  const handleClose = () => {
    closeDialog();
  };

  const handleClickAction = (e, action, file) => {
    e.stopPropagation();
    e.preventDefault();
    if (action === ACTION_VIEW) {
      setSelectedForDownload(null);
      setSelected(file);
      fetchUrl({ key: file?.key, ext: file?.ext, action });
    } else if (action === ACTION_DOWNLOAD) {
      closeOpenViewer();
      setSelected(null);
      setSelectedForDownload(file);
      fetchUrl({
        key: file?.key,
        ext: file?.ext,
        action,
        fileName: file?.name,
      });
    } else {
      closeDialog();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="FileCellRenderListDialog-title"
      open={open}
      PaperComponent={CustomPaperComponent}
    >
      <IconButton
        onClick={() => closeDialog()}
        className={classes.closeBtnDialog}
      >
        <Icon className="fas fa-times" />
      </IconButton>

      <List className={classes.list}>
        {files.map((file) => {
          const fileExtension = file?.ext;
          const isSelected =
            !isNil(selected) &&
            !isEmpty(selected) &&
            file?.key === selected?.key;
          const isSelectedForDownload =
            !isNil(selectedForDownload) &&
            !isEmpty(selectedForDownload) &&
            file?.key === selectedForDownload?.key;

          return (
            <ListItem
              key={file?.key}
              className={
                isSelected ? classes.selectedListItem : classes.listItem
              }
            >
              <Box className={classes.normalBox}>
                {!isNil(fileExtension) && isString(fileExtension) && (
                  <ListItemAvatar>
                    <Avatar className={classes.avatarContainer}>
                      <Typography className={classes.avatarTypo}>
                        {toUpper(fileExtension)}
                      </Typography>
                    </Avatar>
                  </ListItemAvatar>
                )}

                <ListItemText primary={file?.name} />
              </Box>
              <Box className={classes.normalBox}>
                <IconButton
                  onClick={(e) => handleClickAction(e, ACTION_VIEW, file)}
                  disabled={isFetchinView || isFetchinDownload}
                >
                  {isFetchinView && isSelected ? (
                    <CircularProgress size={14} />
                  ) : (
                    <Icon
                      className="fas fa-eye"
                      fontSize="small"
                      style={commonIcon}
                    />
                  )}
                </IconButton>
                <IconButton
                  onClick={(e) => handleClickAction(e, ACTION_DOWNLOAD, file)}
                  disabled={isFetchinView || isFetchinDownload}
                >
                  {isFetchinDownload && isSelectedForDownload ? (
                    <CircularProgress size={14} />
                  ) : (
                    <Icon
                      className="fas fa-download"
                      fontSize="small"
                      style={commonIcon}
                    />
                  )}
                </IconButton>
              </Box>
            </ListItem>
          );
        })}
      </List>
      {openViewer &&
      !isNil(preview) &&
      !isNil(preview.url) &&
      !isNil(preview.ext) ? (
        <Box
          width="inherit"
          height="inherit"
          position="relative"
          minWidth={400}
        >
          <CustomFileViewer
            id={`preview_${uuid()}_dialog`}
            keyViewer={`preview_${uuid()}_dialog`}
            fileType={preview.ext}
            filePath={preview.url}
            isExternalDialog={true}
          />
        </Box>
      ) : null}
    </Dialog>
  );
}

export default function FileCellRender(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const value = props?.value;
  const { open, openDialog, closeDialog } = useDialog();

  const fileValue = isJsonFormat(value) ? JSON.parse(value) : [];
  const sizeItems = size(fileValue);

  return (
    <>
      <Button
        onClick={() => openDialog()}
        disabled={sizeItems === 0}
        endIcon={<AttachFileIcon style={{ fontSize: 16 }} />}
        className={
          sizeItems > 0 ? classes.controlWithValues : classes.controlEmpty
        }
      >
        {`(${sizeItems}) ` + t("FILE")}
      </Button>
      <FileCellRenderListDialog
        open={open}
        closeDialog={closeDialog}
        files={fileValue}
      />
    </>
  );
}
