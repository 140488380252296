import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  onePriceStoredFilters: null,
  onePriceStoredCollapsed: false,
  onePriceStoredShowBpmDialog: false,
  isEditingOnePriceForm: false,
  onePriceStoredBreadcumbRoutes: new Map(),
};

export const createOnePriceSlice = (set, get) => ({
  ...INITIAL_STATE,
  setOnePriceStoredFilters: (newState) =>
    set(() => ({ onePriceStoredFilters: newState })),
  setOnePriceStoredCollapsed: (newState) =>
    set(() => ({ onePriceStoredCollapsed: newState })),
  setOnePriceStoredShowBpmDialog: (newState) =>
    set(() => ({ onePriceStoredShowBpmDialog: newState })),
  addOnePriceBreadcumbRoute: (newRoute) =>
    set((state) => ({
      onePriceStoredBreadcumbRoutes: state.onePriceStoredBreadcumbRoutes.set(newRoute, {
        onePriceStoredFilters: state.onePriceStoredFilters,
        onePriceStoredCollapsed: state.onePriceStoredCollapsed,
        onePriceStoredShowBpmDialog: state.onePriceStoredShowBpmDialog,
      }),
    })),
  removeOnePriceBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().onePriceStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          onePriceStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          onePriceStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateOnePriceBreadcumbRoute: (route, newParams) => {
    const actualBC = get().onePriceStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      onePriceStoredBreadcumbRoutes: state.onePriceStoredBreadcumbRoutes.set(route, {
        ...spreadBC,
        ...newParams,
      }),
    }));
  },
  toggleIsEditingOnePriceForm: () => {
    set((state) => ({ isEditingOnePriceForm: !state.isEditingOnePriceForm }));
  },
  clear: () => set(INITIAL_STATE),
});
