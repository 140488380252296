import { Icon, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  PADDING_ICON_BUTTONS_FORMS,
  SEL_BTN_SUFFIX,
} from "../../../../util/Constants";
import { head, isNil, size } from "lodash";

const iconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  transform: "scale(0.9)",
};

const helperIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export default function SelectionAdornment(props) {
  const {
    selectionServices,
    completeIdControl,
    isSettingValueInServer,
    presentationInfo,
    disableDueChangeSavedValue,
    handleContextMenu,
    inputEnabled,
    hasHelper,
    disabledSelectionServicesDynamic,
  } = props;

  const disabledItem = useMemo(() => {
    const selServicesSize = size(selectionServices);
    const disabledServicesSize = size(disabledSelectionServicesDynamic);

    if (selServicesSize === 1 && disabledServicesSize === 1) {
      return head(disabledSelectionServicesDynamic);
    } else {
      return null;
    }
  }, [disabledSelectionServicesDynamic, selectionServices]);

  return (
    <InputAdornment position="end">
      {selectionServices && selectionServices.length > 0 ? (
        <Tooltip
          title={disabledItem?.disableInteractionText || ""}
          disableHoverListener={isNil(disabledItem)}
        >
          <span>
            <IconButton
              id={`${completeIdControl}${SEL_BTN_SUFFIX}`}
              disabled={
                inputEnabled === false ||
                isSettingValueInServer === true ||
                (presentationInfo && presentationInfo?.enabled === false) ||
                disableDueChangeSavedValue ||
                !isNil(disabledItem)
              }
              style={{ padding: PADDING_ICON_BUTTONS_FORMS }}
              onClick={(e) => handleContextMenu(e, false)}
              tabIndex="-1"
            >
              <Icon
                className={hasHelper ? "fab fa-searchengin" : "fas fa-search"}
                style={hasHelper ? helperIconStyles : iconStyles}
              />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <div>{""}</div>
      )}
    </InputAdornment>
  );
}
