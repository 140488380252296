import React from "react";
import { Typography } from "@material-ui/core";
import { isEmpty, isNil, toLower } from "lodash";
import HtmlHelpBody from "./HtmlHelpBody";
import PdfHelpBody from "./PdfHelpBody";

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

export default function ResolveHelpBody({ file }) {
  if (isNil(file) || isEmpty(file) || isNil(file.ext) || isNil(file.bytes)) {
    return null;
  }

  if (toLower(file?.ext) === "html") {
    return <HtmlHelpBody bytes={file.bytes} />;
  } else if (toLower(file?.ext) === "pdf") {
    return (
      <PdfHelpBody filePath={base64ToArrayBuffer(file.bytes)} isBytes={true} />
    );
  } else {
    return <Typography variant="h5">FILE FORMAT NOT IMPLEMENTED</Typography>;
  }
}
