import {
  API_PDF,
  API_FORM_AWS,
  METHOD_POST,
  SERVER_HOST,
  REST_HEADERS,
  METHOD_GET,
  API_HELP,
} from "../../../util/Constants";
import { saveAs } from "file-saver";
import { isEmpty, isNil } from "lodash";

const AWS_FILE_UPLOAD_METHOD = "/file/upload";
const AWS_FILE_DELETE_METHOD = "/file/delete";
const AWS_GET_PRESIGNED_URL = "/file/get-pre-signed-url";
const HELP_GUIDE_COMPRESS = "/items";

const PDF_GET_FIRST_PAGE = "/get/firstPage/url";

async function uploadFiles(params) {
  const { files, formInstanceId, completeIdControl, REQUEST_HEADERS, line } =
    params;

  const formData = new FormData();

  for (const file of files) {
    formData.append("files", file, file.name);
  }

  //Make URL
  let url = `${SERVER_HOST()}${API_FORM_AWS}${AWS_FILE_UPLOAD_METHOD}?fid=${encodeURIComponent(
    formInstanceId
  )}&cf=${encodeURIComponent(completeIdControl)}&line=${line}`;

  //Fetch
  const response = await fetch(url, {
    method: METHOD_POST,
    body: formData,
    headers: REQUEST_HEADERS,
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return { ok: false, msg: error.message };
    });

  return response;
}

async function deleteFiles(params) {
  const { files, formInstanceId, REQUEST_HEADERS } = params;

  //Make URL
  let url =
    SERVER_HOST() + API_FORM_AWS + AWS_FILE_DELETE_METHOD + "/" + formInstanceId;

  //Fetch
  const response = await fetch(url, {
    method: METHOD_POST,
    body: JSON.stringify(files),
    headers: { ...REQUEST_HEADERS, ...REST_HEADERS },
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return {
        ok: !err,
      };
    });

  return response;
}

async function downloadFile(url, name) {
  saveAs(url, name);
  // const response = await fetch(url);
  // const blob = await response.blob();
  // const link = document.createElement("a");
  // link.href = URL.createObjectURL(blob);
  // link.download = name;
  // link.target = null;
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
}

// Function to download the first page of the PDF from a URL
async function downloadFirstPagePDFWithUrl(
  presignedUrl,
  name,
  REQUEST_HEADERS
) {
  try {
    let url = `${SERVER_HOST()}${API_PDF}${PDF_GET_FIRST_PAGE}?presignedUrl=${encodeURIComponent(
      presignedUrl
    )}`;
    const pdfContent = await fetch(url, {
      headers: REQUEST_HEADERS,
      method: METHOD_GET,
    }).then((res) => res.json());
    if (
      !isNil(pdfContent) &&
      !isNil(pdfContent.ok) &&
      pdfContent.ok === true &&
      !isNil(pdfContent.dataResponse)
    ) {
      const blob = createBlobFromStringBase64(pdfContent.dataResponse);
      if (blob != null) {
        saveAs(blob, name);
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function createBlobFromStringBase64(base64String) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  // Create the Blob object
  const blob = new Blob([byteArray], { type: "application/pdf" });

  return blob;
}

async function getPresignedURLOnDemand({ REQUEST_HEADERS, fileKey }) {
  const url = `${SERVER_HOST()}${API_FORM_AWS}${AWS_GET_PRESIGNED_URL}?key=${fileKey}`;

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return {
        ok: false,
        errorMsg: error?.message,
        dataResponse: null,
        status: 500,
      };
    });

  return response;
}

async function getHelpGuideToCompressFiles({
  REQUEST_HEADERS,
  defaultDocument,
}) {
  const url = `${SERVER_HOST()}${API_HELP}${HELP_GUIDE_COMPRESS}?path=Gen&idForm=FRONTEND&defaultDocument=${defaultDocument}`;

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return {
        ok: false,
        errorMsg: error?.message,
        dataResponse: null,
        status: 500,
      };
    });

  return response;
}

export {
  uploadFiles,
  deleteFiles,
  downloadFile,
  downloadFirstPagePDFWithUrl,
  getPresignedURLOnDemand,
  getHelpGuideToCompressFiles,
};
