import React, { createContext, useState } from "react";
import {
  browserName,
  browserVersion,
  deviceType,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "react-device-detect";

//Initial state context
const initialContextState = {
  browserName,
  browserVersion,
  deviceType,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
  ip: "-",
};

//Creating the context
const DeviceContext = createContext();

const DeviceContextProvider = ({ children }) => {
  const [deviceInfo, setDeviceInfo] = useState(initialContextState);

  return (
    <DeviceContext.Provider value={{ deviceInfo, setDeviceInfo }}>
      {children}
    </DeviceContext.Provider>
  );
};

//Better exporting all things at bottom when we have more tan one
export { DeviceContext, DeviceContextProvider };
