import React, { useEffect, useContext } from "react";
import { IconButton, Icon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../core/providers/AuthContext";

import {
  METHOD_POST,
  DATE_TIME_PATTERN,
  SERVER_HOST,
  TOKEN_HEADER,
  TOKEN_INIT,
  REST_HEADERS,
  API_FORM_LOG,
} from "../util/Constants";
import { refreshPage } from "../util/util-io";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  errorFallBackContainer: {
    width: "100vw",
    height: "100vh",
    backgroundSize: "cover",
    backgroundImage: 'url("/assets/img/loginBack.webp")',
  },
  overlayD: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#F5F5F522",
    width: "100%",
    height: "100%",
  },
  textError: {
    color: "white",
  },
}));

const iconStyles = {
  color: "white",
  fontSize: "3rem",
  padding: 1,
  width: "auto",
  height: "auto",
};

export default function ErrorFallback({ error: { stack } }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const url = `${SERVER_HOST()}${API_FORM_LOG}/new`;

      const body = {
        trace: stack,
        clientTime: format(new Date(), DATE_TIME_PATTERN),
        route: pathname,
        status: null,
      };

      fetch(url, {
        body: JSON.stringify(body),
        method: METHOD_POST,
        headers: {
          ...REST_HEADERS,
          [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
        },
      });
    }

    return () => null;
    //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.errorFallBackContainer}>
      <div className={classes.overlayD}>
        <>
          <Typography component="h4" variant="h4" className={classes.textError}>
            {t("FATAL_ERROR")}
          </Typography>
          <IconButton onClick={refreshPage}>
            <Icon className="fas fa-sync" style={{ ...iconStyles }} />
          </IconButton>
        </>
      </div>
    </div>
  );
}
