import { useState } from "react";
import { v4 as uuid } from "uuid";

export const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${uuid()}` : undefined;

  return { id, open, anchorEl, handleClick, handleClose };
};
