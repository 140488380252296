import React, { useState, useEffect, useRef, useMemo } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import * as _ from "lodash";
import { add, format, isAfter, isBefore, isValid, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  applyPreservedEvent,
  defineInputEnabledByOperation,
  getCompletePathGridItem,
  preserveFormEvent,
} from "../../../util/UtilForm";
import {
  CALENDAR_BTN_SUFFIX,
  DATE_PATTERN,
  FORM_OPERATION_NEW,
  MAX_DATE_FORM,
  MIN_DATE_FORM,
  PADDING_ICON_BUTTONS_FORMS,
  TOAST_CONTAINER_FORM_CONTAINER,
} from "../../../util/Constants";
import { focusElementByIdInEvent } from "../../../util/util-io";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { useLastWasTab } from "../../../core/hooks/useLastWasTab";

export default function EditableCellDatePicker(props) {
  const {
    value,
    row: { index },
    updateDataTable,
    operation,
    column,
    formContext,
  } = props;
  const { t } = useTranslation();
  const { id } = column;
  const { section, block, listName, isBlockDisabledByWizard, formInstanceId } =
    formContext;

  const [selectedDate, setSelectedDate] = useState(null);
  const [isEnabled, setIsEnabled] = useState(true);
  const datePickerRef = useRef(null);
  const [calculatedInputProps, setCalculatedInputProps] = useState(null);

  const formatToApply =
    column && column.dateFormat ? column.dateFormat : "dd/MM/yyyy";

  let completeIdControl = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column,
    index + 1
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema: column,
    operation,
    isSavedEntity: value?.isSavedEntity,
  });

  const { lastWasTab, handleKeyDown } = useLastWasTab();

  //Clearing function
  useEffect(() => {
    if (!_.isNil(value) && !_.isNil(value.value) && value.value !== "") {
      const realValue = value.value;
      //const realDate = new Date(realValue);
      const realDate = parse(realValue, DATE_PATTERN, new Date());
      if (!_.isNil(realDate) && isValid(realDate)) {
        setSelectedDate(realDate);
      } else {
        const parsedDate = parse(realValue, DATE_PATTERN, new Date());
        if (!_.isNil(parsedDate) && isValid(parsedDate)) {
          setSelectedDate(parsedDate);
        } else {
          setSelectedDate(null);
        }
      }
    } else {
      setSelectedDate(null);
    }
    checkPresentationInfo(value);
  }, [value]);

  function checkPresentationInfo(value) {
    if (!_.isNil(value) && !_.isNil(value.presentationInfo)) {
      const { presentationInfo } = value;
      if (presentationInfo && presentationInfo.reset) {
        setCalculatedInputProps(null);
      } else {
        setCalculatedInputProps(presentationInfo);
      }
    } else {
      setCalculatedInputProps(null);
    }
  }

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      column?.canEdit,
      column?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
    if (
      !_.isNil(calculatedInputProps) &&
      !_.isNil(calculatedInputProps.enabled)
    ) {
      setIsEnabled(calculatedInputProps.enabled);
    }
  }, [operation, column, calculatedInputProps]);

  const maxDateDefinedInForm = useMemo(() => {
    if (
      !_.isNil(column) &&
      !_.isNil(column.maxDate) &&
      isValid(parse(column.maxDate, DATE_PATTERN, new Date()))
    ) {
      try {
        const maxDateDefined = parse(column.maxDate, DATE_PATTERN, new Date());
        const maxDateDefinedPlusOneDay = add(maxDateDefined, { days: 1 });
        return maxDateDefinedPlusOneDay;
      } catch (_) {
        return null;
      }
    } else {
      return null;
    }
  }, [column]);

  const minDateDefinedInForm = useMemo(() => {
    if (
      !_.isNil(column) &&
      !_.isNil(column.minDate) &&
      isValid(parse(column.minDate, DATE_PATTERN, new Date()))
    ) {
      try {
        return parse(column.minDate, DATE_PATTERN, new Date());
      } catch (_) {
        return null;
      }
    } else {
      return null;
    }
  }, [column]);

  const handleChange = async (newValue, toSend, e) => {
    const preservedEvent = preserveFormEvent({
      event: e,
      lastWasTab,
      formInstanceId,
    });

    const isAfterMaxDate =
      isValid(newValue) &&
      isAfter(newValue, maxDateDefinedInForm || MAX_DATE_FORM);
    const isBeforeMinDate =
      isValid(newValue) &&
      isBefore(newValue, minDateDefinedInForm || MIN_DATE_FORM);

    const isOutRange =
      newValue != null &&
      isValid(newValue) &&
      (isAfterMaxDate || isBeforeMinDate);

    if (isOutRange && toSend) {
      toast.error(t("DATE_PICKERS_OUT_OF_RANGE"), {
        containerId: TOAST_CONTAINER_FORM_CONTAINER,
      });
    }

    const value = isOutRange && toSend ? null : newValue;

    focusElementByIdInEvent(e);

    //For component level, give the exactly format it want
    setSelectedDate(value);

    if (toSend) {
      //For form context, format date to schema definition
      if (isValid(value)) {
        const formattedDate = format(value, DATE_PATTERN);
        await updateDataTable(index, id, formattedDate, datePickerRef, column);
      } else {
        await updateDataTable(index, id, value, datePickerRef, column);
      }
      applyPreservedEvent({ preservedEvent });
    }
  };

  //Input styles
  const controlStyles = {
    base: {
      backgroundColor:
        calculatedInputProps &&
        calculatedInputProps.backgroundColor &&
        calculatedInputProps.backgroundColor,
    },
    inner: {
      color:
        calculatedInputProps &&
        calculatedInputProps.color &&
        calculatedInputProps.color,
      fontSize:
        calculatedInputProps &&
        calculatedInputProps.fontSize &&
        calculatedInputProps.fontSize,

      fontWeight:
        calculatedInputProps &&
        calculatedInputProps.fontWeight &&
        calculatedInputProps.fontWeight,
      fontStyle:
        calculatedInputProps &&
        calculatedInputProps.fontStyle &&
        calculatedInputProps.fontStyle,
    },
  };

  const isDisabled =
    !isEnabled ||
    disableDueChangeSavedValue ||
    isBlockDisabledByWizard === true;

  return (
    <KeyboardDatePicker
      id={completeIdControl}
      disabled={isDisabled}
      //clearable
      value={selectedDate}
      //onChange={(date) => handleChange(date)}
      onChange={(date) => handleChange(date, false)}
      onBlur={(e) => handleChange(selectedDate, true, e)}
      onAccept={(date) => handleChange(date, true)}
      onKeyDown={handleKeyDown}
      format={formatToApply}
      //invalidDateMessage={t("INVALID_DATE")}
      invalidDateMessage=""
      initialFocusedDate={new Date()}
      disablePast={
        column && column.disablePast && FORM_OPERATION_NEW === operation
          ? column.disablePast
          : false
      }
      disableFuture={
        column && column.disableFuture && FORM_OPERATION_NEW === operation
          ? column.disableFuture
          : false
      }
      inputRef={datePickerRef}
      error={false}
      //error={errorMap.some((e) => e && e.key === completeIdControl)}
      KeyboardButtonProps={{
        id: `${completeIdControl}${CALENDAR_BTN_SUFFIX}`,
        style: {
          padding: PADDING_ICON_BUTTONS_FORMS,
        },
        tabIndex: "-1",
      }}
      style={controlStyles.base}
      InputProps={{
        style: controlStyles.inner,
        autoComplete: "off",
        form: {
          autoComplete: "off",
        },
      }}
      cancelLabel={t("DATE_PICKERS_CANCEL")}
      okLabel={t("DATE_PICKERS_OK")}
      maxDateMessage={null}
      minDateMessage={null}
      maxDate={maxDateDefinedInForm || MAX_DATE_FORM}
      minDate={minDateDefinedInForm || MIN_DATE_FORM}
      //Turn off suggestions
      autoComplete={"off"}
      //Turn off auto correct
      autoCorrect={"off"}
    />
  );
}
