import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { includes, isNil, replace, toLower } from "lodash";
import { useTranslation } from "react-i18next";
import CustomFileViewer from "../../dialogs/fileChooserDialog/CustomFileViewer";
import { generatePreSignedUrlOnDemand } from "../../../core/hooks/useFileCellRender/api";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { HTTP_STATUS_UNAUTHORIZED } from "../../../util/Constants";
import { AuthContext } from "../../../core/providers/AuthContext";
import ThemedCircularProgress from "../../loaders/ThemedCircularProgress";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "250px",
    padding: 0,
  },
  alert: {
    width: "90%",
    minHeight: 150,
  },
}));

function removeConstantsFromKey(key, lang) {
  if (includes(key, "$LOCALE")) {
    return replace(key, "$LOCALE", lang);
  } else {
    return key;
  }
}

export default function HandlerContextMenuPasteAreaHelpDialog({
  open,
  pasteAreaHelp,
  handleClose,
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const [fileUrl, setFileUrl] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchDone, setFetchDone] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    msg: null,
    fileKey: null,
  });

  const hasFetched = useRef(false);

  useEffect(() => {
    async function fetchPresignedUrl() {
      if (
        !isNil(pasteAreaHelp) &&
        open &&
        REQUEST_HEADERS &&
        logout &&
        !isFetching &&
        i18n &&
        !alert.open &&
        !hasFetched.current
      ) {
        setIsFetching(true);
        hasFetched.current = true;

        const fileKey = removeConstantsFromKey(
          pasteAreaHelp?.fileKey,
          i18n.language
        );
        const presignedUrlResponse = await generatePreSignedUrlOnDemand({
          key: fileKey,
          REQUEST_HEADERS,
        });

        if (!isNil(presignedUrlResponse)) {
          const { status, ok, dataResponse, errorMsg } = presignedUrlResponse;

          if (status === HTTP_STATUS_UNAUTHORIZED) {
            logout(true);
          } else if (ok) {
            setFileUrl(dataResponse);
            setIsFetching(false);
            setFetchDone(true);
          } else {
            setIsFetching(false);
            setFetchDone(true);
            setAlert({
              open: true,
              msg: errorMsg || t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
              fileKey,
            });
          }
        } else {
          setAlert({
            open: true,
            msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
            fileKey,
          });
          setIsFetching(false);
          setFetchDone(true);
        }
      }
    }

    fetchPresignedUrl();
  }, [
    REQUEST_HEADERS,
    logout,
    open,
    pasteAreaHelp,
    t,
    i18n,
    isFetching,
    alert.open,
  ]);

  if (isNil(pasteAreaHelp)) {
    return null;
  }

  const fileType = pasteAreaHelp?.fileType;

  return (
    <Dialog
      open={open}
      aria-labelledby="handler-context-menu-paste-area-help"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle id="handler-context-menu-paste-area-help-title">
        <div className={classes.dialogTitle}>
          <Typography variant="h5">{t("HELP")}</Typography>
          <IconButton onClick={() => handleClose()}>
            <Icon className="fas fa-times" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {alert?.open ? (
          <Alert severity="error" className={classes.alert}>
            <AlertTitle>{alert?.msg}</AlertTitle>
            <strong>{alert?.fileKey}</strong>
          </Alert>
        ) : null}

        {isFetching || !fetchDone ? (
          <ThemedCircularProgress size={32} />
        ) : isNil(fileUrl) && fetchDone ? null : (
          <CustomFileViewer
            key={`preview-handler-context-menu-paste-area-help`}
            id={`preview-handler-context-menu-paste-area-help`}
            fileType={toLower(fileType)}
            bg={"transparent"}
            fileValue={{
              url: fileUrl,
            }}
            isFieldPreview={true}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
