import React, { useContext } from "react";
import Button from "@material-ui/core/Button";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import { useNotificationStore } from "../../../core/stores/solutions/notification/NotificationStore";
import { useTranslation } from "react-i18next";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../core/providers/AuthContext";

export default function GeneralDialog() {
  const { t } = useTranslation();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);
  const {
    openGeneralDialog,
    setOpenGeneralDialog,
    isGeneralDisabled,
    saveUserNotificationConfigChanges,
  } = useNotificationStore();

  const handleCancel = () => {
    setOpenGeneralDialog(false);
  };

  const handleOk = () => {
    saveUserNotificationConfigChanges({
      t,
      REQUEST_HEADERS,
      logout,
      isGeneralChange: true,
    });
    setOpenGeneralDialog(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="general-confirmation-dialog-title"
      open={openGeneralDialog}
      style={{ zIndex: 1000000000 }}
    >
      <DialogTitle id="general-confirmation-dialog-title">
        {isGeneralDisabled ? "Activar todas" : "Apagar todas"}
      </DialogTitle>
      <DialogContent dividers>
        {isGeneralDisabled ? (
          <Typography>
            Confirme si desea activar todas las notificaciones, podra elegir que
            soluciones y/o notificaciones desea recibir.
          </Typography>
        ) : (
          <Typography>
            Confirme si desea apagar todas las notificaciones, no le llegara
            ninguna notificacion de DSuite.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          {t("OK")}
        </Button>
        <Button onClick={handleCancel}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
