import { makeStyles } from "@material-ui/core/styles";
import { BREAKPOINT_MD } from "../../../../../util/Constants";

const dropDownItemHeight = 40;
const dropDownPadding = 6;

export const itemIconStart = {
  marginRight: 10,
  width: "auto",
  height: "auto",
  padding: 1,
};

export const itemIconEnd = {
  marginRight: 20,
};

export const flagIConStart = {
  marginRight: 10,
  width: "25px",
  height: "25px",
};

export const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const useStyles = makeStyles((theme) => ({
  dropDown: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      // bottom: 22,
      // transform: "translateX(165px)",
      // border: "1px solid",
      // borderRadius: "10px 10px 10px 0px",
      top: "55px",
      //border: "1px solid",
      transform: "translateX(-45%)",
      borderRadius: "0px 0px 0px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      top: 50,
      transform: "translateX(-45%)",
      //border: "1px solid",
      borderRadius: "0px 0px 0px 10px",
      //height: 225,
    },
    [theme.breakpoints.between(BREAKPOINT_MD, "sm")]: {
      top: 55,
      transform: "translateX(-45%)",
      //border: "1px solid",
      borderRadius: "0px 0px 0px 10px",
      //height: 225,
    },
    [theme.breakpoints.between("sm", BREAKPOINT_MD - 1)]: {
      top: 75,
      transform: "translateX(-45%)",
      //border: "1px solid",
      borderRadius: "0px 0px 0px 10px",
      //height: 225,
    },
    width: "300px",
    backgroundColor: theme.palette.content.backgroundColor,
    padding: "1rem",
    overflow: "hidden",
    transition: "height 500ms ease",
    marginLeft: "15px",
    zIndex: 10,
  },
  navBar: {
    maxWidth: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  navItem: {
    width: "calc(60px * 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropdownOpened: {
    backgroundColor: `${theme.palette.content.titleTextColor}44`,
  },
  itemDiv: {
    display: "flex",
    color: "white",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    height: dropDownItemHeight,
    padding: dropDownPadding,
    textTransform: "none",
  },
  itemDivLang: {
    display: "flex",
    color: "white",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    height: dropDownItemHeight,
    padding: dropDownPadding,
    textTransform: "none",
    paddingLeft: 16,
  },
  itemButtonLang: {
    display: "flex",
    color: "white",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    height: dropDownItemHeight,
    padding: dropDownPadding,
    textTransform: "none",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  iconAndText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
  },
  itemButton: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    height: dropDownItemHeight,
    padding: dropDownPadding,
    textTransform: "none",
    color: theme.palette.text.primary,
  },
  itemLabel: {
    color: theme.palette.text.primary,
  },
  menu: {
    width: "100%",
  },
  ulItem: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  rootAccordion: {
    width: "100%",
    backgroundColor: "transparent",
  },
  accodtion: {
    backgroundColor: "transparent",
    border: "none",
  },
  headingAccordion: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionDetails: {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 0,
    marginTop: -20,
    marginBottom: 6,
  },
}));
