import { fetchInitialInfoApi } from "../../api/infoApi";

const createInfoSlice = (set) => ({
  loadingInfo: false,
  errorLoadingInfo: null,
  initialInfo: null,

  fetchInitialInfo: async (headers, logout) => {
    set({ loadingInfo: true, errorLoadingInfo: null });
    try {
      const { data, error } = await fetchInitialInfoApi(headers, logout);
      if (data) {
        set({ initialInfo: data, loadingInfo: false });
      } else {
        set({ errorLoadingInfo: error, loadingInfo: false });
      }
    } catch (error) {
      set({ errorLoadingInfo: error.message, loadingInfo: false });
    }
  },
});

export default createInfoSlice;
