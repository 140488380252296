import React, { useContext, useMemo, useReducer } from "react";
import {
  REST_HEADERS,
  TOKEN_HEADER,
  TOKEN_INIT,
  API_QFORM,
  SERVER_HOST,
  API_QFORM_PATH,
  METHOD_GET,
} from "../../../util/Constants";

const FormShemaContext = React.createContext(undefined);

const API_URL = `${SERVER_HOST()}${API_QFORM}/${API_QFORM_PATH}/`;

const ExternalFormShemaProvider = ({ children }) => {
  const initialState = {
    status: "idle",
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  const data = useMemo(() => [state, dispatch], [state, dispatch]);

  return (
    <FormShemaContext.Provider value={data}>
      {children}
    </FormShemaContext.Provider>
  );
};

const useExternalFormShema = () => {
  const context = useContext(FormShemaContext);
  if (context === undefined) {
    throw new Error(
      "useExternalFormShema can only be used inside ExternalFormShemaProvider"
    );
  }
  return context;
};

const UpdateExternalForm = async (dispatch, formValue, auth) => {
  dispatch({ type: "FETCHING" });
  try {
    const response = await fetch(API_URL + formValue, {
      method: METHOD_GET,
      headers: {
        ...REST_HEADERS,
        [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
      },
    });
    const data = await response.json();
    dispatch({ type: "FETCHED", payload: data.form });
    return data;
  } catch (error) {
    dispatch({ type: "FETCH_ERROR", payload: error.message });
    return Promise.reject(error);
  }
};

export { ExternalFormShemaProvider, useExternalFormShema, UpdateExternalForm };
