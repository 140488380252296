import * as base64 from "base-64";
import { has, isArray, isEmpty, isNil, isString, split } from "lodash";

import { METHOD_POST, REST_HEADERS } from "../../../../util/Constants";

export class CliPrintZPL {
  //Method to implement
  static async execute(props) {
    if (
      !isNil(props) &&
      !isNil(props.serviceBody) &&
      !isNil(props.serviceBody.requestData) &&
      !isEmpty(props.serviceBody.requestData) &&
      has(props.serviceBody.requestData, "params")
    ) {
      const { params } = props.serviceBody.requestData;
      const { printer_ip, printer_port, zpl, pservice_url, separator } = params;
      try {
        // iterative
        if (!isNil(separator) && isString(separator) && !isEmpty(separator)) {
          const parts = split(zpl, separator);
          if (!isNil(parts) && isArray(parts) && !isEmpty(parts)) {
            let resultIteration = null;
            for (const zplPart of parts) {
              const bodyParams = {
                ipPrinter: printer_ip,
                portPrinter: Number.parseInt(printer_port),
                dataToPrint: base64.encode(zplPart),
                serviceId: props?.serviceBody?.serviceId,
                formInstId: props?.serviceBody?.formInstanceId,
              };
              const response = await fetch(pservice_url, {
                body: JSON.stringify(bodyParams),
                method: METHOD_POST,
                headers: REST_HEADERS,
              });

              if (response) {
                const jsonResponse = await response.json();
                if (jsonResponse) {
                  const { ok, msgError } = jsonResponse;
                  if (ok) {
                    resultIteration = [true, "", {}];
                  } else {
                    resultIteration = [
                      false,
                      `${props?.serviceBody?.errorMessage}: ${msgError}`,
                      {},
                    ];
                    break;
                  }
                }
              } else {
                resultIteration = [
                  false,
                  "CLI_SERVICE_PRINT_ZPL_CANT_CONN",
                  { 0: pservice_url || "-" },
                ];
                break;
              }
            }
            return resultIteration;
          } else {
            return [false, "CLI_SERVICE_DEFAULT_WRONG_DEF", {}];
          }
        } else {
          const bodyParams = {
            ipPrinter: printer_ip,
            portPrinter: Number.parseInt(printer_port),
            dataToPrint: base64.encode(zpl),
            serviceId: props?.serviceBody?.serviceId,
            formInstId: props?.serviceBody?.formInstanceId,
          };
          const response = await fetch(pservice_url, {
            body: JSON.stringify(bodyParams),
            method: METHOD_POST,
            headers: REST_HEADERS,
          });

          if (response) {
            const jsonResponse = await response.json();
            if (jsonResponse) {
              const { ok, msgError } = jsonResponse;
              if (ok) {
                return [true, "", {}];
              } else {
                return [
                  false,
                  `${props?.serviceBody?.errorMessage}: ${msgError}`,
                  {},
                ];
              }
            }
          } else {
            return [
              false,
              "CLI_SERVICE_PRINT_ZPL_CANT_CONN",
              { 0: pservice_url || "-" },
            ];
          }
        }
      } catch (e) {
        return [
          false,
          "CLI_SERVICE_PRINT_ZPL_CANT_CONN",
          { 0: pservice_url || "-" },
        ];
      }
    } else {
      return [false, "CLI_SERVICE_DEFAULT_WRONG_DEF", {}];
    }
  }

  static hasToSubstituteValuesInParams() {
    return true;
  }

  static hasToThrowToast() {
    return true;
  }
}
