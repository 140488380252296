import React from "react";
import { Icon, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { includes, isNil } from "lodash";

const baseContainer = {
  top: "0",
  right: "0",
  position: "absolute",
  borderRadius: "50px",
  width: "18px",
  height: "18px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "all !important",
  cursor: "pointer !important",
};

const useStyles = makeStyles((theme) => ({
  containerCurrent: {
    ...baseContainer,
    backgroundColor: theme.palette.content.mainColor,
  },
  containerActiveNotCurret: {
    ...baseContainer,
    backgroundColor: "grey",
  },
  containerCheck: {
    ...baseContainer,
    backgroundColor: `${theme.palette.semaphore.green}EE`,
  },
  containerHourglass: {
    ...baseContainer,
    backgroundColor: "grey",
  },
  containerOnlyRead: {
    ...baseContainer,
    backgroundColor: "grey",
  },
  typo: {
    fontWeight: "bold",
    color: "#FFF",
  },
}));

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  fontSize: 10,
  color: "#FFF",
};

const WIZARD_TYPE = 1;

const ICON_ACTIVE = "fas fa-pen";
const ICON_COMPLETED = "fas fa-check-circle";
const ICON_PENDING = "fas fa-hourglass-half";
const ICON_ONLY_READ = "fas fa-eye";

export default function StatusWizardIndicator({
  blockWizardStep,
  isWizardEnabled,
  blockShouldBeDisabled,
  blockWasCompletedBefore,
  blockShouldBeFocused,
  blockId,
  listBlocksInWizard,
  simpleBlockShouldBeBorderFocused,
  isBlockDisabledByProperty,
  blockHasAtLeastSomethingEnabledAndVisible,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  if (isWizardEnabled === false) {
    return null;
  }

  if (blockShouldBeFocused || simpleBlockShouldBeBorderFocused) {
    return (
      <Tooltip
        placement="left"
        title={t("WIZARD_STATUS_ACTIVE")}
        className={classes.tooltip}
      >
        <div className={classes.containerCurrent}>
          {WIZARD_TYPE === 1 ? (
            <Icon className={ICON_ACTIVE} style={commonIcon} />
          ) : (
            <Typography className={classes.typo}>{blockWizardStep}</Typography>
          )}
        </div>
      </Tooltip>
    );
  }

  if (blockShouldBeDisabled === true && blockWasCompletedBefore === true) {
    return (
      <Tooltip
        placement="left"
        title={t("WIZARD_STATUS_COMPLETED")}
        className={classes.tooltip}
      >
        <div className={classes.containerCheck}>
          {WIZARD_TYPE === 1 ? (
            <Icon className={ICON_COMPLETED} style={commonIcon} />
          ) : (
            <Typography className={classes.typo}>{blockWizardStep}</Typography>
          )}
        </div>
      </Tooltip>
    );
  }

  if (
    (blockShouldBeDisabled === true || includes(listBlocksInWizard, blockId)) &&
    blockWasCompletedBefore === false
  ) {
    return (
      <Tooltip
        placement="left"
        title={t("WIZARD_STATUS_PENDING")}
        className={classes.tooltip}
      >
        <div className={classes.containerHourglass}>
          {WIZARD_TYPE === 1 ? (
            <Icon className={ICON_PENDING} style={commonIcon} />
          ) : (
            <Typography className={classes.typo}>{blockWizardStep}</Typography>
          )}
        </div>
      </Tooltip>
    );
  }

  if (
    blockShouldBeDisabled === false &&
    blockShouldBeFocused === false &&
    blockWasCompletedBefore === false &&
    !isNil(blockWizardStep) &&
    isBlockDisabledByProperty === true
  ) {
    return (
      <Tooltip
        placement="left"
        title={t("WIZARD_STATUS_ONLY_READ")}
        className={classes.tooltip}
      >
        <div className={classes.containerOnlyRead}>
          {WIZARD_TYPE === 1 ? (
            <Icon className={ICON_ONLY_READ} style={commonIcon} />
          ) : (
            <Typography className={classes.typo}>{blockWizardStep}</Typography>
          )}
        </div>
      </Tooltip>
    );
  }

  if (isNil(blockWizardStep)) {
    return (
      <Tooltip
        placement="left"
        title={
          blockHasAtLeastSomethingEnabledAndVisible
            ? t("WIZARD_STATUS_EDITABLE")
            : t("WIZARD_STATUS_ONLY_READ")
        }
        className={classes.tooltip}
      >
        <div
          className={
            blockHasAtLeastSomethingEnabledAndVisible
              ? classes.containerActiveNotCurret
              : classes.containerOnlyRead
          }
        >
          {WIZARD_TYPE === 1 ? (
            <Icon
              className={
                blockHasAtLeastSomethingEnabledAndVisible
                  ? ICON_ACTIVE
                  : ICON_ONLY_READ
              }
              style={commonIcon}
            />
          ) : (
            <Typography className={classes.typo}>{blockWizardStep}</Typography>
          )}
        </div>
      </Tooltip>
    );
  }

  return null;
}
