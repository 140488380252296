import { useState, useEffect } from "react";

function useDevicePixelRatio() {
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  useEffect(() => {
    const handlePixelRatioChange = () => {
      setDevicePixelRatio(window.devicePixelRatio);
    };

    // Agregar un event listener para detectar cambios en devicePixelRatio
    window.addEventListener("resize", handlePixelRatioChange);

    // Manejar posibles cambios en devicePixelRatio en tiempo real
    const mediaQuery = window.matchMedia(
      `(resolution: ${window.devicePixelRatio}dppx)`
    );
    mediaQuery.addEventListener("change", handlePixelRatioChange);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handlePixelRatioChange);
      mediaQuery.removeEventListener("change", handlePixelRatioChange);
    };
  }, []);

  return devicePixelRatio;
}

export default useDevicePixelRatio;
