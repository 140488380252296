import create from "zustand";

const useAnalysisStore = create((set) => ({
  analysisInformationByKey: {},

  updateKeyInformation: (propertyToChange, newInformation, key) => {
    set((current) => {
      let currentKeyInfo = {...current.analysisInformationByKey};
      
      if (currentKeyInfo[key]) {
        currentKeyInfo[key] = {
          ...currentKeyInfo[key],
          [propertyToChange]: newInformation,
        }
      } else {
        currentKeyInfo[key] = {
          dimensions: [],
          measures: [],
          filtersValues: [],
          filteredInformation: undefined,
          tableInformation: undefined,
          [propertyToChange]: newInformation,
        }
      }
      
      return {
        ...current,
        analysisInformationByKey: currentKeyInfo
      }
    })
  },

  removeAnalysisData: (key) => set((current) => {
    let currentKeys = {...current.analysisInformationByKey}
    currentKeys[key] = undefined;

    return {
      ...current,
      analysisInformationByKey: currentKeys,
    };
  }),
}));

export { useAnalysisStore };
