import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useGlobalFilter,
  useRowSelect,
  useColumnOrder,
  //useAbsoluteLayout
} from "react-table";
import _, {
  filter,
  find,
  has,
  includes,
  isArray,
  isEmpty,
  isNil,
  isString,
  keyBy,
  mapValues,
  size,
  split,
  trim,
} from "lodash";
import { useTranslation } from "react-i18next";
import { matchSorter } from "match-sorter";
import MUITable from "@material-ui/core/Table";
import MUITableBody from "@material-ui/core/TableBody";
import MUITableCell from "@material-ui/core/TableCell";
import MUITableHead from "@material-ui/core/TableHead";
import MUITableRow from "@material-ui/core/TableRow";
import MUITablePagination from "@material-ui/core/TablePagination";
import MUITableContainer from "@material-ui/core/TableContainer";

import {
  Checkbox,
  Divider,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import * as API from "../api/api";
import { useStyles } from "./styles";
import TablePaginationActions from "../components/TablePaginationActions";
import TableToolbar from "../components/TableToolbar";
import { handlerColumnFilter } from "../components/FilterMenu";
import {
  getKeyForMaps,
  useReportsStore,
} from "../../../core/stores/ReportsStore";
import ColsVisibilityDialog from "../components/ColsVisibilityDialog";
import FileCellRender from "../components/FileCellRender";
import HeaderMenu from "../components/HeaderMenu";
import {
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import CustomBodyToast from "../../toast/CustomBodyToast";
import { toast } from "react-toastify";
import { AuthContext } from "../../../core/providers/AuthContext";
import ConfirmQueryActionDialog from "../components/ConfirmQueryActionDialog";
import QueryActionLoaderModal from "../components/QueryActionLoaderModal";
import {
  applyAggreationFunction,
  getCorrectStyleVersionToUse,
  QUERIES_STYLE_VERSION_2,
  QUERIES_STYLE_VERSION_3,
  QUERIES_STYLE_VERSION_OLD,
  resolveCoordtinationFiltersForExternalQuery,
  resolveIfQueryActionBtnIsDisabled,
} from "../util-reports";
import CircularCheckbox from "../components/CircularCheckbox";
import { copyTextToClipboard } from "../../../util/util-io";
import { getFormattedValueWithObjectDef } from "../../../util/UtilFormat";
import { usePinnedColumns } from "../hooks/usePinnedColumns";

const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100, 250, 500];
const PAGE_SIZE_INITIAL = 25;

function mapValuesOpenFormDefinition(definition, values) {
  return Object.keys(definition).reduce((result, key) => {
    const mappedKey = definition[key];
    const value = values[key];
    result[mappedKey] = value;
    return result;
  }, {});
}

function validateAllRowsHasQA({ selectedFlatRows, btnDisabled }) {
  let result = true;
  if (
    !isNil(selectedFlatRows) &&
    isArray(selectedFlatRows) &&
    !isEmpty(selectedFlatRows)
  ) {
    for (const sfr of selectedFlatRows) {
      if (!isNil(sfr)) {
        const { original, id } = sfr;
        const isDisabledThisAction = resolveIfQueryActionBtnIsDisabled({
          rowValuesToUse: original,
          selectedFlatRows,
          btnDisabled,
          multipleExecutionEnabled: true,
          rowId: id,
        });

        if (isDisabledThisAction === true) {
          result = false;
          break;
        }
      }
    }
  }

  return result;
}

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, stylesVersion, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const stylesVersionToApply = getCorrectStyleVersionToUse({ stylesVersion });

    return (
      <>
        {stylesVersionToApply === QUERIES_STYLE_VERSION_OLD ? (
          <Checkbox
            ref={resolvedRef}
            {...rest}
            className="checkbox"
            size="small"
          />
        ) : (
          <CircularCheckbox
            ref={resolvedRef}
            {...rest}
            className="checkbox"
            size="small"
          />
        )}
      </>
    );
  }
);

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;
function dateBetweenFilterFn(rows, id, filterValues) {
  let sd = new Date(filterValues[0]);
  let ed = new Date(filterValues[1]);
  return rows.filter((r) => {
    var time = new Date(r.values[id]);
    if (filterValues.length === 0) return rows;
    return time >= sd && time <= ed;
  });
}

dateBetweenFilterFn.autoRemove = (val) => !val;

function multipleSelectFilterFn(rows, id, filterValue) {
  if (
    !_.isNil(filterValue) &&
    !_.isEmpty(filterValue) &&
    _.isArray(filterValue)
  ) {
    let result = [];
    for (var i = 0, n = filterValue.length; i < n; ++i) {
      const matched = matchSorter(rows, filterValue[i], {
        keys: [(row) => row.values[id]],
      });
      if (!_.isNil(matched) && !_.isEmpty(matched) && _.isArray(matched)) {
        result.push(...matched);
      }
    }
    return result || [];
  } else {
    return rows;
  }
}

multipleSelectFilterFn.autoRemove = (val) => !val;

export const INITIAL_DIALOG_CONFIRM_QUERY_ACTION = {
  open: false,
  title: null,
  params: null,
};

function resolvePathAndIdFormFromRow({ row, def }) {
  if (isNil(def)) {
    return null;
  }

  const trimmed = trim(def);
  if (includes(trimmed, "@")) {
    const splitted = trimmed.split("@");
    if (splitted.length > 1) {
      return row?.[splitted[1]];
    } else {
      return null;
    }
  } else {
    return trimmed;
  }
}

export default function Table({
  columns,
  data,
  title,
  hiddenColumns: propsHiddenColumns,
  handleOpenModalForm,
  path,
  idForm,
  keyTable,
  keyReport,
  queryFilters,
  selectedTab,
  hasToBlockIndividualFiltersByLimit,
  isMaterializedQuery,
  handleFilterMaterializedQuery,
  countRows,
  overrideMaxExcelRows,
  lastSearch,
  handleRefreshCurrent,
  lastModDateMaterialized,
  historyBtnFrontConfig,
  handleOpenProcessHistoryDialog,
  actions,
  handleOpenExternalQuery,
  disableSelectAll,
  disableSelectRow,
  stylesVersion,
  disablePagination,
  disableSorting,
  totalsRow,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);
  const [confirmQueryActionDialog, setConfirmQueryActionDialog] = useState(
    INITIAL_DIALOG_CONFIRM_QUERY_ACTION
  );
  const [openQueryActionLoaderModal, setOpenQueryActionLoaderModal] =
    useState(false);

  const hiddenColumns = useMemo(() => {
    return propsHiddenColumns;
  }, [propsHiddenColumns]);

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      includesMultiple: multipleSelectFilterFn,
    }),
    []
  );

  const handleOpenModal = ({
    ukey,
    idFormRow,
    pathRow,
    defaultOperationRow,
    overrideOpsRow,
  }) => {
    handleOpenModalForm({
      open: true,
      ukey: ukey,
      idFormRow,
      pathRow,
      defaultOperationRow,
      overrideOpsRow,
    });
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: handlerColumnFilter,
      FileCell: (params) => {
        return <FileCellRender {...params} />;
      },
      Aggregated: (params) => {
        if (!_.isNil(params) && !_.isNil(params.value)) {
          if (_.isNumber(params.value)) {
            return _.round(params.value, 2);
          } else {
            return params?.value;
          }
        } else {
          return params?.value;
        }
      },
    }),
    []
  );

  //const modifyGroupBy = useReportsStore((state) => state.modifyGroupBy);
  const sGroupBy = useReportsStore((state) => state.sGroupBy);
  //const sHiddenColumns = useReportsStore((state) => state.sHiddenColumns);
  const selColsOpened = useReportsStore((state) => state.selColsOpened);
  const setSelColsOpened = useReportsStore((state) => state.setSelColsOpened);

  const sPageSize = useReportsStore((state) => state.sPageSize);
  const modifyPageSize = useReportsStore((state) => state.modifyPageSize);

  const selectedViewReport = useReportsStore(
    (state) => state.selectedViewReport
  );
  const selectedView = selectedViewReport.get(keyReport) || null;
  const toggleHiddenColumns = useReportsStore(
    (state) => state.toggleHiddenColumns
  );

  const modifyColumnsOrder = useReportsStore(
    (state) => state.modifyColumnsOrder
  );

  const keyToAsk = getKeyForMaps({ keyTable, selectedView });

  async function executeQueryAction({
    keyAction,
    line,
    row,
    needRefreshAfterExecute,
    cols,
    openFormDef,
    hideQueryActionLoaderModal,
    openQueryDef,
    confirmFields,
  }) {
    if (hideQueryActionLoaderModal !== true) {
      setOpenQueryActionLoaderModal(true);
    }

    let toastContent = null;
    let wasOk = false;
    let response = null;
    if (!isNil(openFormDef) && !isEmpty(openFormDef)) {
      const { path, idForm, useUkey, params, formOperations } = openFormDef;
      if (useUkey === false) {
        //open form with set values
        const valuesToSet = mapValuesOpenFormDefinition(params, row);
        const idFormToUse = resolvePathAndIdFormFromRow({ row, def: idForm });
        const pathToUse = resolvePathAndIdFormFromRow({ row, def: path });
        handleOpenModalForm({
          open: true,
          idFormRow: idFormToUse,
          pathRow: pathToUse,
          overrideOpsRow: formOperations || null,
          useUkey: false,
          values: valuesToSet,
        });
      } else {
        //open form with ukey
        if (isNil(row?.ukey) || !has(row, "ukey")) {
          toastContent = toastContent = (
            <CustomBodyToast title={t("QUERY_OPEN_FORM_NOT_UKEY")} />
          );
        } else {
          const idFormToUse = resolvePathAndIdFormFromRow({
            row,
            def: idForm || row.original?.idForm,
          });
          const pathToUse = resolvePathAndIdFormFromRow({
            row,
            def: path || row.original?.path,
          });

          handleOpenModal({
            ...row,
            pathRow: pathToUse,
            idFormRow: idFormToUse,
            overrideOpsRow: formOperations || null,
          });
        }
      }
    } else if (!isNil(openQueryDef) && !isEmpty(openQueryDef)) {
      const {
        queryKey,
        params,
        coordinationFilters,
        defaultWidth,
        defaultHeight,
      } = openQueryDef;
      handleOpenExternalQuery({
        queryKey,
        params,
        coordinationFilters: resolveCoordtinationFiltersForExternalQuery({
          row,
          coordinationFiltersDef: coordinationFilters,
        }),
        defaultWidth,
        defaultHeight,
      });
    } else {
      const body = {
        query: keyReport,
        selectedSection: selectedTab,
        line,
        row,
        cols,
        confirmFields,
      };
      response = await API.executeQueryAction({
        REQUEST_HEADERS,
        body,
        keyAction,
      });

      if (!_.isNil(response)) {
        const { status } = response;
        if (
          status === HTTP_STATUS_UNAUTHORIZED &&
          !_.isNil(logout) &&
          _.isFunction(logout)
        ) {
          logout(true);
        } else {
          const { ok, msg } = response;
          if (ok === true) {
            wasOk = true;
            if (hideQueryActionLoaderModal !== true) {
              toast.success(t("FORM_SUCCESS_ACTION"), {
                containerId: TOAST_CONTAINER_LAYOUT,
                toastId: "executeQueryAction",
              });
            }
          } else {
            const rightMsg =
              !_.isNil(msg) && !_.isEmpty(msg)
                ? msg
                : t("ERROR_RESOURCE_NOT_FOUND_TEXT");
            toastContent = <CustomBodyToast title={rightMsg} />;
          }
        }
      } else {
        toastContent = (
          <CustomBodyToast title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")} />
        );
      }
    }

    if (toastContent !== null && hideQueryActionLoaderModal !== true) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "executeQueryAction",
      });
    }

    if (hideQueryActionLoaderModal !== true) {
      setTimeout(() => {
        setOpenQueryActionLoaderModal(false);
      }, 300);

      if (needRefreshAfterExecute && wasOk) {
        handleRefreshCurrent();
      }
    }

    return response;
  }

  const calculatedInitialPageSize =
    disablePagination === true ? size(data) : PAGE_SIZE_INITIAL;

  const stylesVersionToApply = getCorrectStyleVersionToUse({ stylesVersion });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
    selectedFlatRows,
    getToggleAllRowsExpandedProps,
    isAllRowsExpanded,
    setGroupBy,
    setHiddenColumns,
    setColumnOrder,
    state: {
      pageIndex,
      pageSize,
      selectedRowIds,
      globalFilter,
      groupBy,
      hiddenColumns: stateHiddenColumns,
    },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableMultiSort: true,
      disableSortBy: disableSorting,
      initialState: {
        hiddenColumns,
        pageSize: calculatedInitialPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const hasToShowHistoryColumn =
          !_.isNil(historyBtnFrontConfig) &&
          _.isArray(historyBtnFrontConfig) &&
          !_.isEmpty(historyBtnFrontConfig);

        const hasToShowActionsColumns =
          !_.isNil(actions) && _.isArray(actions) && !_.isEmpty(actions);

        const historyColumn = hasToShowHistoryColumn
          ? [
              {
                id: "history-form",
                groupByBoundary: true,
                width: 35,
                minWidth: 35,
                maxWidth: 35,
                title: t("GEN_DASH_HISTORY"),
                Cell: ({ row }) => {
                  return (
                    <Tooltip title={t("GEN_DASH_HISTORY")}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropateation();
                          e.preventDefault();
                          handleOpenProcessHistoryDialog({
                            config: historyBtnFrontConfig,
                            rowData: row?.values || rows?.original || null,
                          });
                        }}
                      >
                        <Icon
                          className={[
                            classes.iconExternal,
                            "fas fa-history",
                          ].join(" ")}
                        />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
            ]
          : [];

        const actionColumns = hasToShowActionsColumns
          ? actions.map((actionObject) => {
              const {
                btnColor,
                btnTooltip,
                btnIcon,
                btnIconSize,
                colName,
                colWidth,
                keyAction,
                needRefreshAfterExecute,
                btnDisabled,
                needConfirmDialog,
                confirmDialogTitle,
                openFormDef,
                multipleExecutionEnabled,
                multipleConfirmDialogTitle,
                openQueryDef,
                endpointGetConfirmationFields,
              } = actionObject;

              return {
                id: keyAction,
                pinned: "right",
                isActionColumn: true,
                groupByBoundary: true,
                title: colName,
                align: "center",
                sortable: false,
                filterable: false,
                width: !_.isNil(colWidth) && colWidth,
                Header: (props) => (
                  <HeaderMenu
                    title={colName}
                    tableProps={props}
                    disableShowMenu={true}
                    stylesVersion={stylesVersion}
                  />
                ),
                Cell: (cellProps) => {
                  const { allColumns, row, selectedFlatRows } = cellProps;
                  const rowValuesToUse = row?.original || row?.values;
                  const isBtnDisabled = resolveIfQueryActionBtnIsDisabled({
                    rowValuesToUse,
                    btnDisabled,
                    selectedFlatRows,
                    rowId: row?.id,
                    multipleExecutionEnabled,
                  });

                  const multipleExecution =
                    !_.isNil(selectedFlatRows) &&
                    _.isArray(selectedFlatRows) &&
                    _.size(selectedFlatRows) > 1
                      ? true
                      : false;

                  const params = {
                    keyAction,
                    line: _.toSafeInteger(row?.index),
                    row: rowValuesToUse,
                    cols: _.filter(allColumns, function (c) {
                      return !_.isNil(c?.keyTable);
                    }).map((c) => {
                      return {
                        title: c?.title,
                        field: c?.field,
                        mask: c?.mask,
                        type: c?.type,
                        isFile: c?.isFile,
                        hidden: c?.hidden,
                        description: c?.description,
                        align: c?.align,
                        width: c?.width,
                      };
                    }),
                    needRefreshAfterExecute,
                    openFormDef,
                    multipleExecution,
                    selectedFlatRows,
                    openQueryDef,
                    endpointGetConfirmationFields,
                  };

                  return (
                    <Tooltip
                      title={btnTooltip}
                      disableHoverListener={
                        isBtnDisabled ||
                        isNil(btnTooltip) ||
                        isEmpty(btnTooltip)
                      }
                      style={{
                        cursor: isBtnDisabled ? "not-allowed" : "pointer",
                      }}
                    >
                      <span>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if (
                              (needConfirmDialog === true &&
                                !_.isNil(confirmDialogTitle) &&
                                _.isString(confirmDialogTitle) &&
                                !_.isEmpty(confirmDialogTitle)) ||
                              params?.multipleExecution === true
                            ) {
                              const validationRowsQA =
                                params?.multipleExecution === true &&
                                size(selectedFlatRows) > 0
                                  ? validateAllRowsHasQA({
                                      keyAction,
                                      selectedFlatRows,
                                      btnDisabled,
                                    })
                                  : true;
                              if (validationRowsQA) {
                                setConfirmQueryActionDialog({
                                  open: true,
                                  title:
                                    params?.multipleExecution === true
                                      ? multipleConfirmDialogTitle ||
                                        confirmDialogTitle
                                      : confirmDialogTitle,
                                  params,
                                });
                              } else {
                                toast.warn(
                                  t(
                                    "REPORTS_QUERY_ACTION_MULTIPLE_PRE_VALIDATION",
                                    { colName }
                                  ),
                                  {
                                    containerId: TOAST_CONTAINER_LAYOUT,
                                    autoClose: 8000,
                                  }
                                );
                              }
                            } else {
                              executeQueryAction(params);
                            }
                          }}
                          disabled={isBtnDisabled}
                        >
                          {btnIcon.includes("/") ? (
                            <img
                              src={btnIcon}
                              alt="icon"
                              style={{
                                color:
                                  !_.isNil(btnColor) && !_.isEmpty(btnColor)
                                    ? _.isObject(btnColor)
                                      ? btnColor?.[theme.palette.type]
                                      : btnColor
                                    : null,
                                width: !_.isNil(btnIconSize) && btnIconSize,
                                opacity: isBtnDisabled ? 0.5 : 1,
                              }}
                            />
                          ) : (
                            <Icon
                              className={[classes.iconExternal, btnIcon].join(
                                " "
                              )}
                              style={{
                                color: isBtnDisabled
                                  ? "grey"
                                  : !_.isNil(btnColor) && !_.isEmpty(btnColor)
                                  ? _.isObject(btnColor)
                                    ? btnColor?.[theme.palette.type]
                                    : btnColor
                                  : null,
                                fontSize: !_.isNil(btnIconSize) && btnIconSize,
                              }}
                            />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  );
                },
              };
            })
          : [];

        const selectionColumn =
          disableSelectAll === true && disableSelectRow === true
            ? []
            : [
                {
                  id: "selection",
                  pinned: "start",
                  isSelectionColumn: true,
                  groupByBoundary: true,
                  width: 35,
                  minWidth: 35,
                  maxWidth: 35,
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                      {disableSelectAll === true ? null : (
                        <IndeterminateCheckbox
                          {...getToggleAllRowsSelectedProps()}
                          stylesVersion={stylesVersion}
                        />
                      )}
                    </div>
                  ),
                  Cell: ({ row }) => (
                    <div>
                      {disableSelectRow === true ? null : (
                        <IndeterminateCheckbox
                          {...row.getToggleRowSelectedProps()}
                          stylesVersion={stylesVersion}
                        />
                      )}
                    </div>
                  ),
                },
              ];

        const selectionFormColum =
          !_.isNil(data) &&
          !_.isEmpty(data) &&
          _.isArray(data) &&
          _.some(data, function (row) {
            return (
              ((!_.isNil(row?.path) && !_.isNil(row?.idForm)) ||
                (!_.isNil(path) && !_.isNil(idForm))) &&
              !_.isNil(row?.ukey)
            );
          })
            ? [
                {
                  id: "selection-form",
                  pinned: "start",
                  isSelectionColumn: true,
                  groupByBoundary: true,
                  width: 35,
                  minWidth: 35,
                  maxWidth: 35,
                  title: t("FORM_OPEN"),
                  Cell: ({ row }) => {
                    return (
                      <Tooltip title={t("FORM_OPEN")}>
                        <IconButton
                          onClick={() =>
                            handleOpenModal({
                              ...row.values,
                              pathRow: row.original?.path || path,
                              idFormRow: row.original?.idForm || idForm,
                              defaultOperationRow:
                                row.original?.defaultOperation || null,
                              overrideOpsRow: !isNil(row.original?.overrideOps)
                                ? isString(row.original?.overrideOps)
                                  ? split(row.original?.overrideOps, ",")
                                  : row.original?.overrideOps
                                : null,
                            })
                          }
                        >
                          {stylesVersionToApply ===
                          QUERIES_STYLE_VERSION_OLD ? (
                            <Icon
                              className={[
                                classes.iconExternal,
                                "fas fa-external-link-alt",
                              ].join(" ")}
                            />
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/icons/queries/open-form.png`}
                              className={classes.openFormImage}
                              alt="open-form"
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    );
                  },
                },
              ]
            : [];

        return [
          ...selectionColumn,
          ...selectionFormColum,
          ...historyColumn,
          ...columns,
          ...actionColumns,
        ];
      });
    },
    // Se agrega al final porque usa los atributos isSelectColumn y isActionColumn
    usePinnedColumns
  );

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [setHiddenColumns, hiddenColumns]);

  useEffect(() => {
    const keyToAsk = getKeyForMaps({ keyTable, selectedView });
    if (
      !_.isNil(sPageSize) &&
      !_.isEmpty(sPageSize) &&
      !_.isNil(sPageSize.get(keyToAsk))
    ) {
      const pageSizeToSet = sPageSize.get(keyToAsk);
      setPageSize(pageSizeToSet);
    } else {
      setPageSize(calculatedInitialPageSize);
    }
    // eslint-disable-next-line
  }, [keyTable, sPageSize, calculatedInitialPageSize]);

  /**
   * Recover group by from store
   */
  useEffect(() => {
    const keyToAsk = getKeyForMaps({ keyTable, selectedView });
    if (
      !_.isNil(sGroupBy) &&
      !_.isEmpty(sGroupBy) &&
      !_.isNil(sGroupBy.get(keyToAsk))
    ) {
      const groupByToSet = sGroupBy.get(keyToAsk);
      setGroupBy(groupByToSet);
    } else {
      setGroupBy([]);
    }
    // eslint-disable-next-line
  }, [keyTable, sGroupBy]);

  /**
   * Syncro group by to store
   */
  useEffect(() => {
    const keyToAsk = getKeyForMaps({ keyTable, selectedView });
    if (
      !_.isNil(sGroupBy) &&
      !_.isEmpty(groupBy) &&
      groupBy !== sGroupBy.get(keyToAsk)
    ) {
      //modifyGroupBy({ key: keyToAsk, newGroupBy: groupBy });
    } else {
      setGroupBy(sGroupBy.get(keyToAsk) || groupBy);
    }

    return () => null;
    // eslint-disable-next-line
  }, [groupBy]);

  const handleCloseVisDialog = useCallback(() => {
    const keyToAsk = getKeyForMaps({ keyTable, selectedView });
    toggleHiddenColumns({
      key: keyToAsk,
      hiddenColumns: stateHiddenColumns,
    });
    setSelColsOpened({ open: false });
    modifyColumnsOrder({
      key: keyToAsk,
      newColumnsOrder: filter(allColumns, function (o) {
        return !isNil(o?.field);
      }).map((d) => d.field),
    });
  }, [
    keyTable,
    selectedView,
    toggleHiddenColumns,
    stateHiddenColumns,
    setSelColsOpened,
    modifyColumnsOrder,
    allColumns,
  ]);

  function handleFilterMaterializedQueryInTable({ fts }) {
    handleFilterMaterializedQuery({
      fts,
      allColumns,
    });
  }

  function handleCloseAndRefresh() {
    setConfirmQueryActionDialog(INITIAL_DIALOG_CONFIRM_QUERY_ACTION);
    handleRefreshCurrent();
  }

  const hasToShowTotalsRow = useMemo(() => {
    if (
      !isNil(columns) &&
      !isNil(rows) &&
      !isEmpty(rows) &&
      !isNil(totalsRow)
    ) {
      return true;
    }

    return false;
  }, [columns, rows, totalsRow]);

  const appliedAggregations =
    !isNil(columns) && isArray(columns) && !isEmpty(columns)
      ? columns
          .filter((col) => col?.aggregateSqlFunction)
          .map((col) => {
            return {
              field: col.field,
              aggregate: col?.aggregateSqlFunction,
            };
          })
      : [];

  return (
    <div className="tableWrap">
      <QueryActionLoaderModal open={openQueryActionLoaderModal} />
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        title={title}
        selectedFlatRows={selectedFlatRows}
        getToggleAllRowsExpandedProps={getToggleAllRowsExpandedProps}
        isAllRowsExpanded={isAllRowsExpanded}
        isTableGrouped={groupBy && groupBy.length > 0}
        allColumns={allColumns}
        keyReport={keyReport}
        queryFilters={queryFilters?.queryFilters}
        selectedTab={selectedTab}
        hasToBlockIndividualFiltersByLimit={hasToBlockIndividualFiltersByLimit}
        isMaterializedQuery={isMaterializedQuery}
        handleFilterMaterializedQuery={handleFilterMaterializedQueryInTable}
        countRows={countRows}
        overrideMaxExcelRows={overrideMaxExcelRows}
        lastSearch={lastSearch}
        handleRefreshCurrent={handleRefreshCurrent}
        lastModDateMaterialized={lastModDateMaterialized}
        keyToAsk={keyToAsk}
        stylesVersion={stylesVersion}
        groupedColumns={groupBy || []}
        aggregationsFunctions={
          !isNil(appliedAggregations) &&
          isArray(appliedAggregations) &&
          !isEmpty(appliedAggregations)
            ? mapValues(keyBy(appliedAggregations, "field"), "aggregate")
            : {}
        }
      />
      <ColsVisibilityDialog
        key="columns-dialog-vis-selection"
        open={selColsOpened.open}
        allColumns={allColumns}
        handleClose={() => handleCloseVisDialog()}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        setColumnOrder={setColumnOrder}
      />
      {confirmQueryActionDialog.open === true ? (
        <ConfirmQueryActionDialog
          confirmQueryActionDialog={confirmQueryActionDialog}
          executeQueryAction={executeQueryAction}
          setConfirmQueryActionDialog={setConfirmQueryActionDialog}
          closeAndRefresh={() => handleCloseAndRefresh()}
        />
      ) : null}

      <MUITableContainer
        className="tableContainer"
        key={`${keyTable}-container`}
      >
        <MUITable
          {...getTableProps()}
          className="table"
          key={`${keyTable}-mui-table`}
        >
          <MUITableHead
            className={
              stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                ? undefined
                : "tableHead"
            }
          >
            {headerGroups.map((headerGroup, index) => (
              <React.Fragment key={index}>
                <MUITableRow
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.id}
                  className={
                    stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                      ? undefined
                      : "table-header-row"
                  }
                >
                  {headerGroup.headers.map((column) => {
                    const tooltipText = column?.description || "";
                    return (
                      <Tooltip key={column.id} title={tooltipText}>
                        <MUITableCell
                          className={
                            stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                              ? classes.headCell
                              : "headCell"
                          }
                          {...column.getHeaderProps({
                            style: {
                              ...column.getHeaderProps().style,
                              width: column.width,
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                            },
                          })}
                        >
                          {column.render("Header", column)}
                        </MUITableCell>
                      </Tooltip>
                    );
                  })}
                </MUITableRow>

                {_.isNil(isMaterializedQuery) ||
                  (isMaterializedQuery === false && (
                    <MUITableRow
                      {...headerGroup.getHeaderGroupProps()}
                      key={`${headerGroup.id}-filter`}
                      className={
                        stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                          ? undefined
                          : "table-header-filter-row"
                      }
                    >
                      {headerGroup.headers.map((column) => (
                        <MUITableCell
                          {...column.getHeaderProps()}
                          key={column.id}
                          className={
                            stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                              ? classes.headFilterCell
                              : "headFilterCell"
                          }
                        >
                          <div>
                            {column.filterable
                              ? column.render("Filter", {
                                  hasToBlockIndividualFiltersByLimit,
                                  stylesVersion,
                                })
                              : null}
                          </div>
                        </MUITableCell>
                      ))}
                    </MUITableRow>
                  ))}

                {hasToShowTotalsRow ? (
                  <MUITableRow
                    {...headerGroup.getHeaderGroupProps()}
                    key={`${headerGroup.id}-totals-row`}
                    className={
                      stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                        ? undefined
                        : "table-header-filter-row"
                    }
                  >
                    {headerGroup.headers.map((column) => {
                      const idColumn = column.id;
                      const filteredRowsHere = column?.preFilteredRows || [];
                      const totalRowColumn = find(totalsRow?.columns, {
                        field: idColumn,
                      });

                      if (!isNil(totalRowColumn)) {
                        const aggFunctionApplied = applyAggreationFunction({
                          fun: totalRowColumn?.function,
                          column: totalRowColumn?.field,
                          rows: filteredRowsHere,
                        });

                        const formattedValue = getFormattedValueWithObjectDef({
                          obj: column,
                          value: aggFunctionApplied.val,
                        });

                        return (
                          <MUITableCell
                            {...column.getHeaderProps()}
                            key={idColumn}
                            className={
                              stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                                ? classes.headTotalCell
                                : "headTotalCell"
                            }
                          >
                            {!isNil(aggFunctionApplied) ? (
                              <div
                                className={classes.totalsRowsAggFunContainer}
                                style={{
                                  justifyContent: [
                                    "number",
                                    "numeric",
                                  ].includes(column.type)
                                    ? "end"
                                    : undefined,
                                }}
                              >
                                <Tooltip title={t(aggFunctionApplied.label)}>
                                  <Icon
                                    className={aggFunctionApplied.icon}
                                    fontSize="small"
                                    style={{
                                      marginRight: 3,
                                      width: "auto",
                                      height: "auto",
                                      padding: 1,
                                    }}
                                  />
                                </Tooltip>
                                {formattedValue}
                              </div>
                            ) : null}
                          </MUITableCell>
                        );
                      } else {
                        return (
                          <MUITableCell
                            {...column.getHeaderProps()}
                            key={idColumn}
                            className={
                              stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                                ? classes.headTotalCell
                                : "headTotalCell"
                            }
                          />
                        );
                      }
                    })}
                  </MUITableRow>
                ) : null}
              </React.Fragment>
            ))}
          </MUITableHead>
          <MUITableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <MUITableRow
                  {...row.getRowProps()}
                  className={
                    stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                      ? undefined
                      : "tableBodyRow"
                  }
                >
                  {row.cells.map((cell) => {
                    const isFile = cell?.column?.isFile === true;
                    const isSelectedRow = cell?.row?.isSelected === true;
                    return (
                      <MUITableCell
                        className={
                          stylesVersionToApply === QUERIES_STYLE_VERSION_OLD
                            ? undefined
                            : "tableBodyCell"
                        }
                        {...cell.getCellProps({
                          style: {
                            ...cell.getCellProps().style,
                            textAlign: !_.isNil(cell?.column?.align)
                              ? cell.column.align
                              : cell?.column?.type === "numeric" ||
                                cell?.column?.type === "number"
                              ? "right"
                              : "left",
                            width: cell.column?.width,
                            // si no es necesario, se puede sacar el min o max. pero al ser una layout flexible, a cuando las columnas estan fijas hace un pequeño movimiento si no estan estas propiedades
                            minWidth: cell.column?.minWidth,
                            maxWidth: cell.column?.maxWidth,
                            backgroundColor:
                              isSelectedRow === true
                                ? stylesVersionToApply ===
                                    QUERIES_STYLE_VERSION_3 ||
                                  stylesVersionToApply ===
                                    QUERIES_STYLE_VERSION_2
                                  ? `${theme.palette.content.mainColor}BB`
                                  : `${theme.palette.content.mainColor}33`
                                : `${theme.palette.background.paper}`,
                          },
                        })}
                      >
                        {cell.isGrouped ? (
                          <button
                            className={classes.groupedCellContainer}
                            onClick={() => {
                              if (!_.isNil(cell?.value)) {
                                copyTextToClipboard(cell?.value);
                              }
                            }}
                          >
                            <IconButton {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? (
                                <Icon
                                  className="fas fa-angle-down"
                                  style={{
                                    fontSize: "16px",
                                  }}
                                />
                              ) : (
                                <Icon
                                  className="fas fa-angle-right"
                                  style={{
                                    fontSize: "16px",
                                  }}
                                />
                              )}
                            </IconButton>
                            {cell.render("Cell", {
                              editable: false,
                              isGrouped: true,
                            })}

                            <Typography
                              variant="body1"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (!_.isNil(cell?.value)) {
                                  copyTextToClipboard(cell?.value);
                                }
                              }}
                            >{`(${row.subRows.length})`}</Typography>
                          </button>
                        ) : cell.isAggregated ? (
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : isFile ? (
                          cell.render("FileCell", {
                            editable: true,
                            isGrouped: false,
                          })
                        ) : (
                          cell.render("Cell", {
                            editable: true,
                            isGrouped: false,
                          })
                        )}
                      </MUITableCell>
                    );
                  })}
                </MUITableRow>
              );
            })}
          </MUITableBody>
        </MUITable>
      </MUITableContainer>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!_.isNil(countRows) && _.isNumber(countRows) && countRows > 0 ? (
          <Typography>
            {t("QUERY_TOTAL_ROWS_TEXT", {
              current: rows?.length || 0,
              total: countRows,
            })}
          </Typography>
        ) : (
          <Divider />
        )}

        {disablePagination === true ? null : (
          <MUITablePagination
            rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            labelRowsPerPage={t("PAGINATION_ROWS_PER_PAGE")}
            count={rows.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            component="div"
            onPageChange={(ev, newPage) => gotoPage(newPage)}
            onRowsPerPageChange={(ev) =>
              modifyPageSize({
                key: keyToAsk,
                newPageSize: Number(ev.target.value),
              })
            }
            ActionsComponent={TablePaginationActions}
          />
        )}
      </div>
    </div>
  );
}
