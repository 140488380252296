import React, { useState } from "react";
import { Box, Switch, Typography, makeStyles } from "@material-ui/core";
import useFilters from "../../store/hooks/useFilters";
import FilterAdminBadge from "./FilterAdminBadge";
import useInfo from "../../store/hooks/useInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  itemsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 6,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  btn: {
    border: "1px solid",
  },
}));

const FilterSwitch = ({ filter }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(
    filter?.defaultValue === true ? true : false
  );
  const { handleSwitchFilterChange } = useFilters();
  const { initialInfo } = useInfo();

  const showOnlyAdmin = filter?.showOnlyAdmin === true;
  const isEmsAdmin = initialInfo?.isEmsAdmin === true;

  const handleChange = (event) => {
    const newState = event.target.checked;
    setChecked(newState);
    handleSwitchFilterChange(filter.id, newState);
  };

  if (!isEmsAdmin && showOnlyAdmin) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.itemsContainer}>
        <Typography variant="h6" className={classes.title}>
          {filter?.name}
        </Typography>
        <Switch
          checked={checked}
          onChange={handleChange}
          name={filter?.name}
          inputProps={{ "aria-label": `${filter?.name} switch` }}
        />
      </Box>
      <FilterAdminBadge {...filter} />
    </Box>
  );
};

export default FilterSwitch;
