import { useMemo } from "react";
import { FORM_OPERATON_EDIT } from "../../util/Constants";

export const useCanChangeSaved = ({ schema, operation, isSavedEntity }) => {
  const disableDueChangeSavedValue = useMemo(() => {
    if (operation !== FORM_OPERATON_EDIT) {
      return false;
    }

    return schema?.canChangeSavedValue === false && isSavedEntity === true;
  }, [operation, schema, isSavedEntity]);

  return { disableDueChangeSavedValue };
};
