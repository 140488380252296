import { isNil, trim } from "lodash";
import { API_FORM_AWS, METHOD_GET, SERVER_HOST } from "../../../util/Constants";

const ENDPOINT_GET_PRESIGNED_URL = "/file/get-pre-signed-url";

async function generatePreSignedUrlOnDemand({ key, REQUEST_HEADERS }) {
  const url = `${SERVER_HOST()}${API_FORM_AWS}${ENDPOINT_GET_PRESIGNED_URL}?key=${trim(
    key
  )}`;
  const response = await fetch(url, {
    method: METHOD_GET,
    headers: REQUEST_HEADERS,
  });
  const status = response.status;
  try {
    const result = await response.json();
    if (!isNil(result)) {
      return {
        status,
        ...result,
      };
    } else {
      return { status };
    }
  } catch (error) {
    return {
      status,
      error: error?.message,
    };
  }
}

export { generatePreSignedUrlOnDemand };
