import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import * as _ from "lodash";

import {
  defineInputEnabledByOperation,
  getCompletePath,
  resolveValuesFieldAgainsShadowStatus,
} from "../../../util/UtilForm";
import {
  FORM_SERVICE_CALCULATION,
  REST_HEADERS,
  TOAST_CONTAINER_FORM_CONTAINER,
  TOKEN_HEADER,
  TOKEN_INIT,
} from "../../../util/Constants";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import { clientServices } from "../clientServices/services";
import { AuthContext } from "../../../core/providers/AuthContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function SimpleButtonField(props) {
  const { t } = useTranslation();
  const { idSchema, schema, formData, formContext } = props;
  const { presentationInfo: pi } = formData || false;
  const [isEnabled, setIsEnabled] = useState(true);
  const { operation, section, block, formInstanceId, pre, shadowStatus, isBlockDisabledByWizard } =
    formContext;
  const buttonRef = useRef(null);
  const [presentationInfo, setPresentationInfo] = useState(null);
  const theme = useTheme();
  const { auth } = useContext(AuthContext);
  const [isExecutingClick, setIsExecutingClick] = useState(false);

  const REQUEST_HEADERS = {
    ...REST_HEADERS,
    [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
  };

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      schema?.canEdit,
      schema?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
  }, [operation, schema]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  const currentShadowStatus = useMemo(() => {
    return shadowStatus;
  }, [shadowStatus]);

  const inputTextAlign = schema?.textAlign || "left";

  //Input styles
  const controlStyles = {
    padding: 3,
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo && presentationInfo.backgroundColor
        ? presentationInfo.backgroundColor
        : `${theme.palette.content.mainColor}AA`,
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
    width: "100%",
    borderRadius: 5,
    marginBlock: 5,
    "&:hover": {
      backgroundColor: "green",
      color: "#FFF",
    },
  };

  async function handleClick() {
    setIsExecutingClick(true);
    const servicesIn = schema?.services;
    if (servicesIn && servicesIn.length > 0) {
      const servicesToClientExecution = servicesIn.filter(
        (x) => x && x.serviceType === FORM_SERVICE_CALCULATION && x.enabled
      );
      if (servicesToClientExecution) {
        for (let i = 0; i < servicesToClientExecution.length; i++) {
          const { key, dataResultDef, serviceName, params } =
            servicesToClientExecution[i];
          if (
            serviceName &&
            serviceName !== "" &&
            clientServices &&
            clientServices.hasOwnProperty(serviceName)
          ) {
            const serviceInstance = clientServices[serviceName];
            const hastToSubstituteValuesInParams =
              _.has(serviceInstance, "hasToSubstituteValuesInParams") &&
              _.isFunction(serviceInstance.hasToSubstituteValuesInParams) &&
              serviceInstance.hasToSubstituteValuesInParams() === true;

            const hasToThrowToast =
              _.has(serviceInstance, "hasToThrowToast") &&
              _.isFunction(serviceInstance.hasToThrowToast) &&
              serviceInstance.hasToThrowToast() === true;

            const paramsWithValues = hastToSubstituteValuesInParams
              ? resolveValuesFieldAgainsShadowStatus({
                  values: params,
                  shadowStatus: currentShadowStatus,
                  completeIdControl,
                })
              : params;
            const serviceBody = {
              serviceName,
              requestData: {
                params: paramsWithValues,
                dataResultDef,
                company: auth?.company?.codCompany,
              },
              formInstanceId,
              key,
              line: 0,
            };
            const res = await serviceInstance.execute({
              serviceBody,
              REQUEST_HEADERS,
            });

            if (
              !_.isNil(res) &&
              _.isArray(res) &&
              !_.isEmpty(res) &&
              hasToThrowToast
            ) {
              const success = res[0];
              if (success === true) {
                toast.success(t("FORM_SUCCESS_ACTION"), {
                  toastId: "btn-success",
                  containerId: TOAST_CONTAINER_FORM_CONTAINER,
                });
              } else {
                if (res.length > 1) {
                  const idTranslation = res[1] || "FORM_ERROR_ACTION";
                  const paramsTranslation = res[2] || {};
                  toast.error(t(idTranslation, paramsTranslation), {
                    toastId: "btn-error",
                    containerId: TOAST_CONTAINER_FORM_CONTAINER,
                  });
                }
              }
            }
          }
        }
      }
    }

    setTimeout(() => {
      setIsExecutingClick(false);
    }, 300);
  }

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      childAlign={inputTextAlign}
      presentationInfo={presentationInfo}
    >
      <Button
        onClick={handleClick}
        disabled={
          !isEnabled ||
          (presentationInfo && presentationInfo?.enabled === false) ||
          isExecutingClick ||
          isBlockDisabledByWizard === true
        }
        ref={buttonRef}
        style={controlStyles}
        id={completeIdControl}
      >
        {isExecutingClick ? (
          <CircularProgress size={18} style={{ color: "#FFF" }} />
        ) : (
          `${schema?.title}`
        )}
      </Button>
    </SimpleFieldContainer>
  );
}
