import React from 'react';

// TODO : Implement case
export default function SimpleMultiPartInput() {
  return (
    <>
    
    </>
  );
}
