import { find } from "lodash";
import create from "zustand";
import { persist } from "zustand/middleware";
import { loadConfig } from "../../config/configLoader"; // Ajusta la ruta según la estructura de tu proyecto
import { setConfig } from "../../config/dynamicConfig"; // Ajusta la ruta según la estructura de tu proyecto

const DEFAULT_ID = "development";
export const DEFAULT_LABEL = "DESARROLLO (LOCAL)";

const ENVS = [
  {
    id: DEFAULT_ID,
    label: DEFAULT_LABEL,
    enabled: true,
    icon: "fab fa-dev",
    bg: "#D5F5E3",
    bgBars: "#229954",
    defaultProtocol: "https",
    defaultHost: "localhost",
    defaultPort: 8762,
  },
  {
    id: "qa",
    label: "TESTING (QA)",
    enabled: true,
    icon: "fas fa-vial",
    bg: "#F9E79F",
    bgBars: "#928808",
    defaultProtocol: "https",
    defaultHost: "api.labs-dsuite.cloud",
    defaultPort: 0,
  },
  {
    id: "prd",
    label: "PRODUCCION",
    enabled: true,
    icon: "fas fa-exclamation-triangle",
    bg: "#FADBD8",
    bgBars: "#CB4335",
    defaultProtocol: "https",
    defaultHost: "api.lor.dsuite.cloud",
    defaultPort: 0,
  },
];

const useEnvironmentStore = create(
  persist(
    (set, get) => ({
      envs: ENVS,
      selectedEnv: DEFAULT_ID,
      selectedEnvInfo: find(ENVS, { id: DEFAULT_ID }),
      isInitialized: false,
      initializeConfig: async () => {
        const env = get().selectedEnv;
        const configData = await loadConfig(env);
        setConfig(configData);
        set({ isInitialized: true });
      },
      changeEnv: async (newEnv) => {
        const configData = await loadConfig(newEnv);
        setConfig(configData);
        set({
          selectedEnv: newEnv,
          selectedEnvInfo: find(ENVS, { id: newEnv }),
        });
      },
    }),
    {
      name: "environment-store", // nombre en el almacenamiento local
      getStorage: () => localStorage, // Puedes cambiar esto a sessionStorage si prefieres
    }
  )
);

export default useEnvironmentStore;
