import React, { useCallback, useMemo, useState } from "react";
import BlockingToast from "../../components/toast/blockingToast/BlockingToast";

export const useBlockingToast = () => {
  const [type, setType] = useState(null);
  const [title, setTitle] = useState(null);
  const [msg, setMsg] = useState(null);
  const [showBlockingToast, setShowBlockingToast] = useState(false);

  const hideBlockingToast = useCallback(() => {
    setShowBlockingToast((prev) => !prev);
  }, []);

  function renderBlockingToast({ type, title, msg }) {
    setShowBlockingToast(true);
    setType(type);
    setTitle(title);
    setMsg(msg);
  }

  const BlockingToastModal = useMemo(
    () => (props) =>
      (
        <BlockingToast
          type={type}
          title={title}
          msg={msg}
          open={showBlockingToast}
          closeModal={hideBlockingToast}
          {...props}
        />
      ),
    [type, title, msg, showBlockingToast, hideBlockingToast]
  );

  return {
    renderBlockingToast,
    BlockingToastModal,
  };
};
