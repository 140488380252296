import React from "react";
import Particles from "react-particles-js";

export default function LoginParticles() {
  return (
    <Particles
      height="100vh"
      width="100vw"
      // params={{
      //   background: {
      //     color: {
      //       value: "#0a0335",
      //     },
      //     position: "50% 50%",
      //     repeat: "no-repeat",
      //     size: "cover",
      //   },
      //   fullScreen: {
      //     enable: true,
      //     zIndex: 1,
      //   },
      //   interactivity: {
      //     events: {
      //       onClick: {
      //         enable: true,
      //         mode: "push",
      //       },
      //       onHover: {
      //         enable: true,
      //         mode: "repulse",
      //       },
      //     },
      //     modes: {
      //       bubble: {
      //         distance: 400,
      //         duration: 2,
      //         opacity: 0.8,
      //         size: 40,
      //       },
      //       grab: {
      //         distance: 400,
      //       },
      //       light: {
      //         area: {
      //           gradient: {
      //             stop: {
      //               value: "#0a0335",
      //             },
      //           },
      //         },
      //         shadow: {
      //           color: {
      //             value: "#0a0335",
      //           },
      //         },
      //       },
      //     },
      //   },
      //   particles: {
      //     color: {
      //       value: "#d9ff00",
      //       animation: {
      //         h: {
      //           enable: true,
      //           speed: 20,
      //         },
      //       },
      //     },
      //     links: {
      //       color: {
      //         value: "#ffffff",
      //       },
      //       enable: true,
      //       opacity: 0.4,
      //       shadow: {
      //         color: {
      //           value: "#f0b565",
      //         },
      //       },
      //     },
      //     move: {
      //       attract: {
      //         rotate: {
      //           x: 600,
      //           y: 1200,
      //         },
      //       },
      //       enable: true,
      //       path: {},
      //       outModes: {
      //         bottom: "out",
      //         left: "out",
      //         right: "out",
      //         top: "out",
      //       },
      //       speed: 6,
      //       trail: {
      //         fillColor: {
      //           value: "#1bc2fd",
      //         },
      //       },
      //     },
      //     number: {
      //       density: {
      //         enable: true,
      //       },
      //       value: 80,
      //     },
      //     opacity: {
      //       value: 0.5,
      //       animation: {
      //         speed: 3,
      //         minimumValue: 0.1,
      //       },
      //     },
      //     shadow: {
      //       color: {
      //         value: "##0a0335",
      //       },
      //     },
      //     shape: {
      //       options: {
      //         polygon: {
      //           nb_sides: 5,
      //         },
      //         star: {
      //           nb_sides: 5,
      //         },
      //         image: {
      //           src: "https://particles.js.org/images/github.svg",
      //           width: 100,
      //           height: 100,
      //         },
      //         images: {
      //           src: "https://particles.js.org/images/github.svg",
      //           width: 100,
      //           height: 100,
      //         },
      //       },
      //     },
      //     size: {
      //       random: {
      //         enable: true,
      //       },
      //       animation: {
      //         speed: 20,
      //         minimumValue: 0.1,
      //       },
      //     },
      //   },
      // }}

      params={{
        particles: {
          color: {
            value: "#00b8a9",
          },
          number: {
            value: 400,
            density: {
              enable: true,
              value_area: 5000,
            },
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.75,
            width: 1,
          },
          line_linked: {
            enable: true,
            opacity: 0.25,
          },
          move: {
            direction: "none",
            speed: 0.4,
          },
          size: {
            value: 7,
            opacity: 0.8,
          },
          opacity: {
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.05,
            },
          },
        },
        interactivity: {
          events: {
            onclick: {
              enable: true,
              mode: "push",
            },
          },
          modes: {
            push: {
              particles_nb: 1,
            },
          },
        },
        retina_detect: true,
      }}
    />
  );
}
