import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FORM_OPERATION_DELETE,
  FORM_OPERATION_NEW,
  FORM_OPERATION_VIEW,
  FORM_OPERATON_EDIT,
} from "../../util/Constants";

export default function useNormalForm(props) {
  const { actualOperation } = props;
  const { t } = useTranslation();

  const [gridLayout, setGridLayout] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [shadowStatus, setShadowStatus] = useState(null);

  /**
   * Handle tab change, from general to audit, etc
   * @param {*} event
   * @param {*} newTab
   */
  function handleTabChange(event, newTab) {
    handleChangeGridLayout(gridLayout);
    setSelectedTab(newTab);
  }

  function handleChangeGridLayout(newGridLAyout) {
    setGridLayout(newGridLAyout);
  }

  function moveToAnotherSectionByIndex(newSectionIndex) {
    setSelectedTab(newSectionIndex);
  }

  /**
   * Get operation text internacionalized
   */
  const getOperationText = useCallback(() => {
    if (actualOperation === FORM_OPERATION_NEW) {
      return t("TABLE_PANEL_NEW");
    } else if (actualOperation === FORM_OPERATON_EDIT) {
      return t("TABLE_PANEL_DECISION_EDIT");
    } else if (actualOperation === FORM_OPERATION_DELETE) {
      return t("TABLE_PANEL_DECISION_DELETE");
    } else if (actualOperation === FORM_OPERATION_VIEW) {
      return t("FORM_VIEW");
    } else {
      return "";
    }
  }, [t, actualOperation]);

  /**
   * Get icon of operation in base of form operation
   */
  const getFasIcon = useCallback(() => {
    if (actualOperation === FORM_OPERATION_NEW) {
      return "fas fa-save";
    } else if (actualOperation === FORM_OPERATON_EDIT) {
      return "fas fa-edit";
    } else if (actualOperation === FORM_OPERATION_DELETE) {
      return "fas fa-trash";
    } else if (actualOperation === FORM_OPERATION_VIEW) {
      return "fas fa-eye";
    } else {
      return "";
    }
  }, [actualOperation]);

  /**
   * Get description text of form operation
   */
  const getDescText = useCallback(() => {
    if (actualOperation === FORM_OPERATION_NEW) {
      return t("FORM_SAVE");
    } else if (actualOperation === FORM_OPERATON_EDIT) {
      return t("FORM_EDIT");
    } else if (actualOperation === FORM_OPERATION_DELETE) {
      return t("FORM_DELETE");
    } else if (actualOperation === FORM_OPERATION_VIEW) {
      return t("FORM_VIEW");
    } else {
      return "";
    }
  }, [t, actualOperation]);

  /**
   * In charge of update shadow status to check if value has changed
   * @param {*} newShadowStatus
   */
  function updateShadowStatus(newShadowStatus) {
    setShadowStatus(newShadowStatus);
  }

  return {
    gridLayout,
    handleChangeGridLayout,
    selectedTab,
    handleTabChange,
    getOperationText,
    getFasIcon,
    getDescText,
    shadowStatus,
    updateShadowStatus,
    moveToAnotherSectionByIndex,
  };
}
