import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: "75vw",
    height: "85vh",
    padding: "20px",
    borderRadius: "20px",
  },
}));

export default function CustomDialog({ children, isOpen }) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        classes={{ paper: classes.paper }}
        open={isOpen}
        TransitionComponent={Transition}
      >
        {children}
      </Dialog>
    </div>
  );
}
