import React from "react";
import { useTranslation } from "react-i18next";

export default function NotAllowedExtensionMessage({
  filesNotAllowed,
  useOwnErrorCode,
}) {
  const { t } = useTranslation();

  return (
    <div style={{ overflowWrap: "anywhere", width: "auto" }}>
      {filesNotAllowed.map((fna, index) => {
        if (useOwnErrorCode) {
          return <p key={index}>• {fna?.err}</p>;
        } else {
          return (
            <p key={index}>
              • {fna?.name}: {t("FILE_EXTENSION_NOT_ALLOWED")}
            </p>
          );
        }
      })}
    </div>
  );
}
