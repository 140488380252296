import { isMap, isNil } from "lodash";

const INITIAL_STATE = {
  storedFilters: null,
  storedCollapsed: false,
  storedShowBpmDialog: false,
  storedBreadcumbRoutes: new Map(),
};

export const createMercurySlice = (set, get) => ({
  ...INITIAL_STATE,
  setStoredFilters: (newState) => set(() => ({ storedFilters: newState })),
  setStoredCollapsed: (newState) => set(() => ({ storedCollapsed: newState })),
  setStoredShowBpmDialog: (newState) =>
    set(() => ({ storedShowBpmDialog: newState })),
  addBreadcumbRoute: (newRoute) =>
    set((state) => ({
      storedBreadcumbRoutes: state.storedBreadcumbRoutes.set(newRoute, {
        storedFilters: state.storedFilters,
        storedCollapsed: state.storedCollapsed,
        storedShowBpmDialog: state.storedShowBpmDialog,
      }),
    })),
  removeBreadcumbRoute: (routeToDelete) => {
    const actualMap = get().storedBreadcumbRoutes;
    if (
      !isNil(routeToDelete) &&
      isMap(actualMap) &&
      actualMap.has(routeToDelete)
    ) {
      actualMap.delete(routeToDelete);
      set(() => ({
        storedBreadcumbRoutes: actualMap,
      }));
    }
  },
  updateBreadcumbRoute: (route, newParams) => {
    const actualBC = get().storedBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      storedBreadcumbRoutes: state.storedBreadcumbRoutes.set(route, {
        ...spreadBC,
        ...newParams,
      }),
    }));
  },
  clear: () => set(INITIAL_STATE),
});
