import React from "react";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.content.mainColor,
    color: "white",
    boxShadow: `0 0 0 2px ${theme.palette.header.backgroundColor}`,
    marginTop: "10px",
    marginRight: "-10px",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1s infinite ease-in-out",
      border: `1px solid ${theme.palette.content.mainColor}`,
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const BadgeWizardStepSection = ({ children, shouldRenderBadge }) => {
  const classes = useStyles();

  return (
    <Badge
      variant="dot"
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      classes={{ badge: classes.badge }}
      invisible={shouldRenderBadge === false}
    >
      {children}
    </Badge>
  );
};
