import { useCallback, useContext } from "react";
import useEmployeeStoreState from "../EmployeeStoreState";
import { toast } from "react-toastify";
import { TOAST_CONTAINER_LAYOUT } from "../../../../util/Constants";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";

const THROW_TOAST = false;

const useFavorites = () => {
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const { favorites, addFavorite, removeFavorite, getFavorites } =
    useEmployeeStoreState((state) => ({
      favorites: state.favorites,
      addFavorite: state.addFavorite,
      removeFavorite: state.removeFavorite,
      getFavorites: state.getFavorites,
    }));

  const handleAddFavorite = useCallback(
    (product) => {
      addFavorite({ headers: REQUEST_HEADERS, logout, product });
      if (THROW_TOAST) {
        toast.success(`${product.title} añadido a Favoritos`, {
          containerId: TOAST_CONTAINER_LAYOUT,
          autoClose: 1000,
          hideProgressBar: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addFavorite]
  );

  const handleRemoveFavorite = useCallback(
    (product) => {
      removeFavorite({ headers: REQUEST_HEADERS, logout, product });
      if (THROW_TOAST) {
        toast.warn(`Producto eliminado de Favoritos`, {
          containerId: TOAST_CONTAINER_LAYOUT,
          autoClose: 1000,
          hideProgressBar: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeFavorite]
  );

  const handleGetFavorites = useCallback(() => {
    getFavorites({ headers: REQUEST_HEADERS, logout });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFavorites]);


  return {
    favorites,
    addFavorite: handleAddFavorite,
    removeFavorite: handleRemoveFavorite,
    getFavorites: handleGetFavorites,
  };
};

export default useFavorites;
