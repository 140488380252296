import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";

import { EMPLOYEE_STORE_HOME_ROUTE } from "../../../routes/EmployeeStoreRouter";

const FLAG_SIZE = 16;

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
  },
  logoUrlImg: {
    width: 125,
    cursor: "pointer",
  },
  flagContainer: {
    right: "-14px",
    width: "auto",
    border: "1px solid #bfb5b5",
    bottom: "-6px",
    height: "auto",
    display: "flex",
    padding: "6px",
    position: "absolute",
    alignItems: "center",
    borderRadius: "100%",
    justifyContent: "center",
    backgroundColor: "#f0efef",
  },
}));

export const flagIConStart = {
  width: FLAG_SIZE,
  height: FLAG_SIZE,
};

export default function EmployeeStoreLogo() {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.container}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/img/employee-store/employee-store-logo.png`}
        alt="store-logo"
        className={classes.logoUrlImg}
        onClick={() => history.push(EMPLOYEE_STORE_HOME_ROUTE)}
      />
      <div className={classes.flagContainer}>
        <ReactCountryFlag svg countryCode={"CL"} style={flagIConStart} />
      </div>
    </div>
  );
}
