import { useEffect, useRef } from "react";

function useSafeEffect(effect, deps) {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      return effect();
    }
    return () => {};
  }, [deps, effect]);
}

export default useSafeEffect;
