import styled from "styled-components";

export const TableContainer = styled.div`
  height: inherit;
  overflow-y: hidden; // TO TBODY SCROLL
  overflow-x: auto;
  padding-inline-end: ${({ isNewGridDistributionEnabled }) =>
    isNewGridDistributionEnabled === true ? null : "10px"};
  width: inherit;

  .table {
    border-spacing: 0;
    border: 1px solid grey;
    border-radius: 10px;
    width: ${({ isNewGridDistributionEnabled }) =>
      isNewGridDistributionEnabled === true ? "calc(100% - 20px)" : "auto"};

    //height: 100%;
    //height: -webkit-fill-available;
    height: inherit;

    //BODY
    .tbody {
      display: block; // TO TBODY SCROLL
      overflow-y: auto; // TO TBODY SCROLL
      overflow-x: hidden; // TO TBODY SCROLL
      //background-color: cyan;
      height: 100%;
    }

    // HEADER
    .th {
      display: block; // TO TBODY SCROLL
      margin: 0;
      padding: 0.3rem;
      background-color: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background-color: transparent;
      /* background-color: ${(props) =>
        props.theme ? `${props.theme.palette.content.mainColor}25` : "cyan"}; */

      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :first-child {
        //border-radius: 0px 0px 0px 10px;
      }

      :last-child {
        /* border-right: 1px solid grey; */
        width: 100%;
        //border-radius: 0px 0px 10px 0px;
      }

      .resizer {
        display: inline-block;
        background: ${(props) => props.theme.palette.content.mainColor};
        width: 4px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: ${(props) =>
            `${props.theme.palette.content.mainColor}33`};
          width: 5px;
        }
      }
    }

    //ROW
    .tr {
      width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
      border-bottom: ${(props) =>
        props.theme
          ? `1px solid ${props.theme.palette.content.mainColor}`
          : "inherit"};
      :first-child {
        //border-radius: 0px 0px 10px 10px;
      }
      :last-child {
        //border-radius: 0px 0px 10px 10px;
        .td {
          border-bottom: 0;
        }
      }
      /* :nth- child(0) {
        background-color: ${(props) =>
        props.theme ? `${props.theme.palette.content.mainColor}25` : "cyan"};
      } */
    }

    .trHeadCells {
      width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
      border-bottom: ${(props) =>
        props.theme
          ? `1px solid ${props.theme.palette.content.mainColor}`
          : "inherit"};
      background-color: ${(props) =>
        props.theme ? `${props.theme.palette.content.mainColor}25` : "cyan"};
      :first-child {
        //border-radius: 0px 0px 10px 10px;
      }
      :last-child {
        //border-radius: 0px 0px 10px 10px;
        .td {
          border-bottom: 0;
        }
      }
    }

    //CELL
    .td {
      margin: 0;
      //padding: 0.5rem;
      border-right: 1px solid lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;

      :first-child {
        border-left: none;
      }

      :last-child {
        border-right: none;
      }
    }
  }
`;
