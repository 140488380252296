import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { CircularProgress } from "@material-ui/core";

import HeaderMenu from "../components/HeaderMenu";
import { useToast } from "../components/Toast";
import { Styles, useStyles } from "./styles";
import { safeFormattingDate } from "../../../util/UtilDates";
import {
  DATE_TIME_PATTERN,
  FRONT_DATE_TIME_PATTERN,
} from "../../../util/Constants";
import ExternalTable from "./ExternalTable";
import OverflowTooltip from "../components/OverflowTooltip";
import {
  getFormattedValueWithObjectDef,
  sortArrayByArrayValues,
} from "../../../util/UtilFormat";
import useDevicePixelRatio from "../../../core/hooks/useDevicePixelRatio";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import {
  getKeyForMaps,
  useExternalReportsStore,
} from "../../../core/stores/ExternalReportsStore";
import { useExternalResultQuery } from "../hooks/useExternalResultQuery";

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function ExternalResultTable({
  columns,
  selectedTab,
  title,
  handleOpenModalForm,
  idForm,
  path,
  keyTable,
  keyReport,
  jsonHiddenColums,
  queryFilters,
  availableFilters,
  isMaterializedQuery,
  handleFilterMaterializedQuery,
  overrideMaxExcelRows,
  handleRefreshCurrent,
  historyBtnFrontConfig,
  handleOpenProcessHistoryDialog,
  actions,
  handleOpenExternalQuery,
  globalActions,
  coordinationFilters,
  disableSelectAll,
  disableSelectRow,
  stylesVersion,
  disablePagination,
  disableSorting,
}) {
  const wd = useWindowDimensions();
  const { showToast, ToastContainerImpl } = useToast();
  const classes = useStyles();
  const [{ status, error, data, listCounts, lastSearchs, lastUpdate }] =
    useExternalResultQuery();
  const [cols, setCols] = React.useState(columns);

  const [hiddenColumns, setHiddenColumns] = useState(jsonHiddenColums);

  const selectedViewReport = useExternalReportsStore(
    (state) => state.selectedViewReport
  );
  const sColumns = useExternalReportsStore((state) => state.sColumns);
  const selectedView = selectedViewReport.get(keyReport) || null;
  const modifyColumns = useExternalReportsStore((state) => state.modifyColumns);
  const sHiddenColumns = useExternalReportsStore(
    (state) => state.sHiddenColumns
  );
  const modifyGroupBy = useExternalReportsStore((state) => state.modifyGroupBy);
  const sGroupBy = useExternalReportsStore((state) => state.sGroupBy);
  const keyToAsk = getKeyForMaps({ keyTable, selectedView });
  const sColumnsOrder = useExternalReportsStore((state) => state.sColumnsOrder);

  useEffect(() => {
    const mustOrderAgain =
      !_.isNil(sColumnsOrder) &&
      !_.isNil(sColumnsOrder?.get(keyToAsk)) &&
      _.isArray(sColumnsOrder?.get(keyToAsk)) &&
      !_.isEmpty(sColumnsOrder?.get(keyToAsk));

    if (
      !_.isNil(selectedView) &&
      !_.isNil(selectedView.config) &&
      !_.isNil(selectedView.config.sColumns) &&
      ((_.isArray(selectedView.config.sColumns) &&
        _.some(selectedView.config.sColumns, { key: keyToAsk })) ||
        (_.isMap(selectedView.config.sColumns) &&
          selectedView.config.sColumns.has(keyToAsk)))
    ) {
      let colsToSet = [];
      if (
        !_.isNil(sColumns) &&
        !_.isEmpty(sColumns) &&
        !_.isNil(sColumns.get(keyToAsk))
      ) {
        const tentative = sColumns.get(keyToAsk);
        colsToSet = _.isArray(tentative) ? tentative : tentative?.value;
      } else {
        colsToSet = _.isArray(selectedView.config.sColumns)
          ? _.find(selectedView.config.sColumns, {
              key: keyToAsk,
            }).value
          : selectedView.config.sColumns.get(keyToAsk);
      }

      if (!_.isNil(colsToSet) && !_.isEmpty(colsToSet)) {
        const colsCalculated = columns.map((x) => {
          const sc = _.find(colsToSet, {
            field: x?.field,
            keyTable,
          });

          if (sc) {
            return {
              ...x,
              ...sc,
            };
          } else {
            return x;
          }
        });
        if (mustOrderAgain) {
          const orderCols = sColumnsOrder.get(keyToAsk);
          const orderedColumnsToSet = sortArrayByArrayValues(
            colsCalculated,
            orderCols,
            "field"
          );
          setCols(orderedColumnsToSet);
        } else {
          setCols(colsCalculated);
        }
      } else {
        if (mustOrderAgain) {
          const orderCols = sColumnsOrder.get(keyToAsk);
          const orderedColumnsToSet = sortArrayByArrayValues(
            columns,
            orderCols,
            "field"
          );
          setCols(orderedColumnsToSet);
        } else {
          setCols(columns);
        }
      }
    } else if (
      (_.isNil(selectedView) ||
        (!_.isNil(selectedView) &&
          !_.isNil(selectedView.config) &&
          !_.isNil(selectedView.config.sColumns) &&
          _.isEmpty(selectedView.config.sColumns)) ||
        (!_.isNil(selectedView) &&
          !_.isNil(selectedView.config) &&
          !_.isNil(selectedView.config.sColumns) &&
          !_.isEmpty(selectedView.config.sColumns) &&
          !_.some(selectedView.config.sColumns, { key: keyToAsk }))) &&
      !_.isNil(sColumns) &&
      !_.isEmpty(sColumns) &&
      !_.isNil(sColumns.get(keyToAsk))
    ) {
      const tentative = sColumns.get(keyToAsk);
      const colsToSet = _.isArray(tentative) ? tentative : tentative?.value;
      if (!_.isNil(colsToSet) && !_.isEmpty(colsToSet)) {
        const colsCalculated = columns.map((x) => {
          const sc = _.find(colsToSet, {
            field: x?.field,
            keyTable,
          });

          if (sc) {
            return {
              ...x,
              ...sc,
            };
          } else {
            return x;
          }
        });

        if (mustOrderAgain) {
          const orderCols = sColumnsOrder.get(keyToAsk);
          const orderedColumnsToSet = sortArrayByArrayValues(
            colsCalculated,
            orderCols,
            "field"
          );
          setCols(orderedColumnsToSet);
        } else {
          setCols(colsCalculated);
        }
      } else {
        if (mustOrderAgain) {
          const orderCols = sColumnsOrder.get(keyToAsk);
          const orderedColumnsToSet = sortArrayByArrayValues(
            columns,
            orderCols,
            "field"
          );
          setCols(orderedColumnsToSet);
        } else {
          setCols(columns);
        }
      }
    } else {
      if (mustOrderAgain) {
        const orderCols = sColumnsOrder.get(keyToAsk);
        const orderedColumnsToSet = sortArrayByArrayValues(
          columns,
          orderCols,
          "field"
        );
        setCols(orderedColumnsToSet);
      } else {
        setCols(columns);
      }
    }
    // eslint-disable-next-line
  }, [columns, selectedView, sColumns, sColumnsOrder]);

  if (status === "error") {
    showToast(error, "executeQuery");
  }

  function handleUpdateColumns(newColumns) {
    setCols(newColumns);
    modifyColumns({
      key: keyToAsk,
      newColumns: newColumns.map((x) => ({
        field: x?.field,
        aggregate: x?.aggregate,
        keyTable: x?.keyTable || keyTable,
      })),
    });
  }

  useEffect(() => {
    if (_.isNil(sHiddenColumns) || _.isEmpty(sHiddenColumns)) {
      return;
    }

    if (
      !_.isNil(sHiddenColumns) &&
      !_.isEmpty(sHiddenColumns) &&
      sHiddenColumns.has(keyToAsk)
    ) {
      const storedHiddenCols = sHiddenColumns.get(keyToAsk);
      if (!_.isNil(storedHiddenCols) && !_.isEmpty(storedHiddenCols)) {
        setHiddenColumns(storedHiddenCols);
      } else {
        setHiddenColumns([]);
      }
    } else {
      setHiddenColumns([]);
    }
    //eslint-disable-next-line
  }, [sHiddenColumns, keyTable, selectedView, cols]);

  const makaDefaultColumns = React.useMemo(
    () =>
      cols.map((column) => {
        const firstContentColumn = _.find(cols, function (o) {
          return !_.isNil(o) && o?.hidden !== true;
        });
        const isFirstContentColumn =
          firstContentColumn?.field === column?.field;

        return {
          keyTable,
          Header: (props) => (
            <HeaderMenu
              title={column.title}
              tableProps={props}
              updateColumn={handleUpdateColumns}
              handleToggleGroupBy={handleToggleGroupBy}
            />
          ),
          accessor: column.field,
          filterable: true,
          title: column.title,
          // tiene que venir en el servicio
          Cell: (props) => {
            const value = props?.value;
            const isGrouped = props?.isGrouped;
            const formattedValue = getFormattedValueWithObjectDef({
              obj: column,
              value,
            });
            return (
              <OverflowTooltip
                value={formattedValue}
                column={column}
                isGrouped={isGrouped}
                isFirstContentColumn={isFirstContentColumn}
                doNotApplyMaxWidth={true}
              />
            );
          },
          ...column,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    // eslint-disable-next-line
    [cols, keyTable, selectedView]
  );

  function handleToggleGroupBy(column) {
    if (!_.isNil(column) && !_.isNil(column.field)) {
      const fieldToToggle = column.field;
      if (!_.isNil(sGroupBy)) {
        const actualGb = sGroupBy.get(keyToAsk);
        if (!_.isNil(actualGb) && !_.isEmpty(actualGb)) {
          if (!_.includes(actualGb, fieldToToggle)) {
            modifyGroupBy({
              key: keyToAsk,
              newGroupBy: [...actualGb, fieldToToggle],
            });
          } else {
            modifyGroupBy({
              key: keyToAsk,
              newGroupBy: actualGb.filter((x) => x !== fieldToToggle),
            });
          }
        } else {
          modifyGroupBy({ key: keyToAsk, newGroupBy: [fieldToToggle] });
        }
      }
    }
  }

  const hasToBlockIndividualFiltersByLimit = useMemo(() => {
    let result = false;

    if (
      !_.isNil(data) &&
      !_.isEmpty(data) &&
      _.isArray(data) &&
      !_.isNil(data[selectedTab]) &&
      !_.isEmpty(data[selectedTab]) &&
      _.isArray(data[selectedTab])
    ) {
      const hasLimit =
        !_.isNil(availableFilters) &&
        !_.isEmpty(availableFilters) &&
        _.some(availableFilters, { isLimit: true });
      if (hasLimit) {
        const limitValue = _.find(availableFilters, {
          isLimit: true,
        })?.defaultValue;
        const limitValueInteger = _.toSafeInteger(limitValue);
        const lengthSelectedTabData = data[selectedTab].length;
        if (
          limitValueInteger > 0 &&
          lengthSelectedTabData >= limitValueInteger
        ) {
          result = true;
        }
      }
    }

    return result;
  }, [availableFilters, data, selectedTab]);

  const devicePixelRatio = useDevicePixelRatio();

  return (
    <Styles devicePixelRatio={devicePixelRatio} wd={wd}>
      <ToastContainerImpl />
      <div className={classes.paperContent}>
        {status === "fetching" || status === "idle" ? (
          <div className={classes.loadingTable}>
            <CircularProgress
              disableShrink={true}
              className={
                !_.isNil(data) && !_.isNil(data[selectedTab])
                  ? classes.loaderData
                  : classes.loader
              }
              size={!_.isNil(data) && !_.isNil(data[selectedTab]) ? 60 : 30}
            />
          </div>
        ) : null}
        <ExternalTable
          hiddenColumns={hiddenColumns}
          columns={makaDefaultColumns}
          data={data[selectedTab] || []}
          title={title}
          handleOpenModalForm={handleOpenModalForm}
          path={path}
          idForm={idForm}
          keyTable={keyTable}
          keyReport={keyReport}
          queryFilters={queryFilters}
          selectedTab={selectedTab}
          hasToBlockIndividualFiltersByLimit={
            hasToBlockIndividualFiltersByLimit
          }
          isMaterializedQuery={isMaterializedQuery}
          handleFilterMaterializedQuery={handleFilterMaterializedQuery}
          countRows={
            !_.isNil(listCounts) &&
            !_.isEmpty(listCounts) &&
            _.isArray(listCounts) &&
            !_.isNil(listCounts[selectedTab])
              ? listCounts[selectedTab]
              : null
          }
          overrideMaxExcelRows={overrideMaxExcelRows}
          lastSearch={
            !_.isNil(lastSearchs) &&
            !_.isEmpty(lastSearchs) &&
            _.isArray(lastSearchs) &&
            !_.isNil(lastSearchs[selectedTab])
              ? lastSearchs[selectedTab]
              : null
          }
          handleRefreshCurrent={handleRefreshCurrent}
          lastModDateMaterialized={
            !_.isNil(lastUpdate) &&
            !_.isEmpty(lastUpdate) &&
            _.isArray(lastUpdate) &&
            !_.isNil(lastUpdate[selectedTab])
              ? safeFormattingDate(
                  DATE_TIME_PATTERN,
                  FRONT_DATE_TIME_PATTERN,
                  lastUpdate[selectedTab]
                )
              : null
          }
          historyBtnFrontConfig={historyBtnFrontConfig}
          handleOpenProcessHistoryDialog={handleOpenProcessHistoryDialog}
          actions={actions}
          handleOpenExternalQuery={handleOpenExternalQuery}
          globalActions={globalActions}
          coordinationFilters={coordinationFilters}
          disableSelectAll={disableSelectAll}
          disableSelectRow={disableSelectRow}
          availableFilters={availableFilters}
          stylesVersion={stylesVersion}
          disablePagination={disablePagination}
          disableSorting={disableSorting}
        />
      </div>
    </Styles>
  );
}

export default ExternalResultTable;
