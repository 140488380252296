import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { Paper, Radio, Typography, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import useIsMounted from "react-is-mounted-hook";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { useDropzone } from "react-dropzone";
import { filesize } from "filesize";

import { useStyles } from "./FileChooserDialogStyles";
import CustomFileViewer from "./CustomFileViewer";
import {
  AVAILABLE_FORMATS,
  AWS_S3_URL_FILE_ON_DEMAND,
  MIME_TYPES_ALLOWED,
} from "./formats";
import * as API from "./api";
import {
  DATE_TIME_PATTERN,
  FRONT_DATE_TIME_PATTERN,
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_CONTAINER_LAYOUT,
  TOKEN_HEADER,
  TOKEN_INIT,
} from "../../../util/Constants";
import CustomBodyToast from "../../toast/CustomBodyToast";
//import { makeAcceptedFormatFiles } from "../../../util/UtilFormat";
import NotAllowedExtensionMessage from "./NotAllowedExtensionMessage";
import {
  clone,
  filter,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  toLower,
  toNumber,
  toUpper,
} from "lodash";
import { AuthContext } from "../../../core/providers/AuthContext";
import {
  getNewFileNameAvoidDuplicates,
  renameFilesToAvoidDuplicates,
} from "../../../util/UtilFile";
import NotAllowedMaxSizeMessage from "./NotAllowedMaxSizeMessage";
import { safeFormattingDate } from "../../../util/UtilDates";
import { useFetchSize } from "../../../core/hooks/useFetchSize";

const CLIENT_CHECK_FILE_EXTENSION =
  process.env.REACT_APP_CLIENT_CHECK_FILE_EXT === "y";

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  fontSize: 14,
};

/* COMPONENT TO MAKE DIALOG DRAGGABLE */
function DraggableComponent(props) {
  return (
    <Draggable
      handle="#file-chooser-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          height: "90%",
          width: "auto",
          minWidth: "90%",
        }}
      />
    </Draggable>
  );
}

const activeStyle = {
  borderColor: "#2196f3",
  backgroundColor: "blue",
};

const acceptStyle = {
  borderColor: "#00e676",
  borderWidth: 4,
  backgroundColor: "#7DCEA033",
};

const rejectStyle = {
  borderColor: "#ff1744",
  borderWidth: 4,
  backgroundColor: "#D9888033",
};

const FEATURE_FLAG_DELETION_FILES = false;

function FileSizeLoader({ url }) {
  const classes = useStyles();
  const { size, loading } = useFetchSize(url);
  if (isNil(url) || isNil(size)) {
    return null;
  }

  if (loading) {
    return <CircularProgress size={"0.65rem"} />;
  }

  return (
    <div className={classes.fileContextInfoContainer}>
      <Icon
        className="far fa-file-archive"
        style={{
          ...commonIconStyles,
          fontSize: "0.65rem",
        }}
      />
      <Typography>{toUpper(filesize(size))}</Typography>
    </div>
  );
}

export default function FileChooserDialog(props) {
  const {
    handleClose,
    openFileChooser,
    setFileValue,
    fileValue,
    setLoading,
    isLoading,
    enabled,
    formInstanceId,
    completeIdControl,
    line,
    handleOnlyFirstPDFPage,
    canAddFileForRemoval,
    maxFiles,
    blockAddFilesByMaxFiles,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const inputRef = useRef(null);
  const classes = useStyles();
  const [preview, setPreview] = useState(null);
  //const [isFirstTime, setIsFirstTime] = useState(true);
  const isMounted = useIsMounted();
  const [selectedItems, setSelectedItems] = useState([]);
  const { auth, logout } = useContext(AuthContext);
  const [indexLoading, setIndexLoading] = useState(null);
  const [throwNotAllowedMaxSizeAlert, setThrowNotAllowedMaxSizeAlert] =
    useState(false);

  const REQUEST_HEADERS = useMemo(() => {
    return {
      [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
    };
  }, [auth]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log("FILES ACCEPTED VIA DND", acceptedFiles);
      setLoading((prev) => !prev);
      let toastContent = null;
      let filesNotAllowedMessage = null;
      if (acceptedFiles && acceptedFiles.length > 0) {
        const filesList = new DataTransfer();

        for (let i = 0; i < acceptedFiles.length; i++) {
          const acceptedFile = acceptedFiles[i];
          if (
            !isNil(fileValue) &&
            !isEmpty(fileValue) &&
            !isNil(acceptedFile) &&
            !isNil(acceptedFile.name) &&
            !isEmpty(acceptedFile.name) &&
            includes(acceptedFile.name, ".") &&
            filter(fileValue, { name: acceptedFile.name }).length > 0
          ) {
            const newFileName = getNewFileNameAvoidDuplicates(
              acceptedFile.name,
              fileValue
            );
            const newFileToAdd = new File([acceptedFile], newFileName, {
              type: acceptedFile.type,
            });
            filesList.items.add(newFileToAdd);
          } else {
            filesList.items.add(acceptedFile);
          }
        }
        const filesChecked = filesList.files;
        const response = await API.uploadFiles({
          files: filesChecked,
          formInstanceId,
          completeIdControl,
          REQUEST_HEADERS,
          line,
        });

        if (response) {
          const { ok, status, msg, files, errorFiles } = response;
          if (status === HTTP_STATUS_UNAUTHORIZED) {
            logout(true);
          } else {
            if (ok) {
              if (isMounted()) {
                const newFilesToSet = [...fileValue, ...files];
                setFileValue(newFilesToSet);
              }

              if (errorFiles && errorFiles.length > 0) {
                filesNotAllowedMessage = (
                  <NotAllowedExtensionMessage
                    filesNotAllowed={errorFiles}
                    useOwnErrorCode={true}
                  />
                );
              }
            } else {
              if (!isNil(msg)) {
                toastContent = <CustomBodyToast title={msg} msg={null} />;
              } else {
                setThrowNotAllowedMaxSizeAlert(true);
              }
            }
          }
        } else {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        }
      }

      if (toastContent !== null) {
        toast.error(toastContent, {
          containerId: TOAST_CONTAINER_LAYOUT,
          position: "top-right",
        });
      }

      if (filesNotAllowedMessage != null) {
        toast.info(filesNotAllowedMessage, {
          containerId: TOAST_CONTAINER_LAYOUT,
          position: "top-right",
        });
      }

      setLoading((prev) => !prev);
    },
    [
      formInstanceId,
      completeIdControl,
      REQUEST_HEADERS,
      line,
      fileValue,
      isMounted,
      setFileValue,
      t,
      setLoading,
      logout,
    ]
  );

  const onDropRejected = useCallback(
    (fileRejections) => {
      if (fileRejections && fileRejections.length > 0) {
        console.log("FILES REJECTED VIA DND", fileRejections);
        if (
          !isNil(maxFiles) &&
          isNumber(maxFiles) &&
          fileRejections.length >= toNumber(maxFiles)
        ) {
          toast.error(t("FORM_TABLE_FILE_CH_MAX_FILES_TOAST", { maxFiles }), {
            containerId: TOAST_CONTAINER_LAYOUT,
            position: "top-right",
          });
        } else {
          const filesNotAllowed = fileRejections.map((x) => ({
            name: x?.file?.name,
          }));
          const filesNotAllowedMessage = (
            <NotAllowedExtensionMessage filesNotAllowed={filesNotAllowed} />
          );

          if (filesNotAllowedMessage != null) {
            toast.info(filesNotAllowedMessage, {
              containerId: TOAST_CONTAINER_LAYOUT,
              position: "top-right",
            });
          }
        }
      }
    },
    [maxFiles, t]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropRejected,
    onDrop,
    accept: MIME_TYPES_ALLOWED,
    disabled: !enabled || blockAddFilesByMaxFiles,
    noClick: true,
    maxFiles: maxFiles,
  });

  useEffect(() => {
    if (preview && preview.url) {
      return () => {
        window.URL.revokeObjectURL(preview?.url);
      };
    }
  }, [preview]);

  const handleDialog = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    setPreview(null);
    handleClose(false);
  };

  const handleDeleteSelectedFiles = async () => {
    setLoading(true);
    let toastContent = null;

    const keysToDelete = [];
    const filesToDelete = selectedItems.map(({ key, url }) => {
      keysToDelete.push(key);
      return {
        key: key,
        url: url,
      };
    });
    const filesToSetAfter = fileValue.filter(
      ({ key }) => !keysToDelete.includes(key)
    );

    const response =
      canAddFileForRemoval && FEATURE_FLAG_DELETION_FILES === true
        ? await API.deleteFiles({
            formInstanceId: formInstanceId,
            files: filesToDelete,
            REQUEST_HEADERS,
          })
        : { ok: true };

    if (response) {
      const { ok, msg } = response;
      if (ok) {
        if (isMounted()) {
          setSelectedItems([]);
          setFileValue(filesToSetAfter);
        }
      } else {
        toastContent = <CustomBodyToast title={msg} />;
      }
    } else {
      toastContent = (
        <CustomBodyToast
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          msg={null}
        />
      );
    }
    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false);
  };

  const handlePreview = async (index) => {
    let toastContent = null;
    if (preview) {
      setPreview(null);
    } else {
      if (fileValue && fileValue.length > 0) {
        const fileToPreview = fileValue[index];
        if (!isNil(fileToPreview) && !isNil(fileToPreview?.url)) {
          const { url, key } = fileToPreview;
          if (isEqual(url, AWS_S3_URL_FILE_ON_DEMAND)) {
            setIndexLoading(index);
            const { ok, errorMsg, dataResponse, status } =
              await API.getPresignedURLOnDemand({
                fileKey: encodeURIComponent(key),
                REQUEST_HEADERS,
              });
            if (status === HTTP_STATUS_UNAUTHORIZED) {
              logout(true);
            } else {
              if (ok) {
                setPreview({
                  ...fileToPreview,
                  url: dataResponse,
                });
              } else {
                toastContent = <CustomBodyToast title={errorMsg} />;
              }
            }
          } else {
            setPreview(fileToPreview);
          }
        }
      }
    }

    if (toastContent != null) {
      if (toastContent !== null) {
        toast.error(toastContent, {
          toastId: "handlePreview",
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      }
    }

    setIndexLoading(null);
  };

  const handleDownloadOne = async (index) => {
    let toastContent = null;
    if (fileValue && fileValue.length > 0) {
      const { url, name, key } = fileValue[index];
      if (isEqual(url, AWS_S3_URL_FILE_ON_DEMAND)) {
        setIndexLoading(index);
        const { ok, errorMsg, dataResponse, status } =
          await API.getPresignedURLOnDemand({
            fileKey: encodeURIComponent(key),
            REQUEST_HEADERS,
          });
        if (status === HTTP_STATUS_UNAUTHORIZED) {
          logout(true);
        } else {
          if (ok) {
            if (handleOnlyFirstPDFPage) {
              await API.downloadFirstPagePDFWithUrl(
                dataResponse,
                name,
                REQUEST_HEADERS
              );
            } else {
              await API.downloadFile(dataResponse, name);
            }
          } else {
            toastContent = <CustomBodyToast title={errorMsg} />;
          }
        }
      } else {
        if (handleOnlyFirstPDFPage) {
          await API.downloadFirstPagePDFWithUrl(url, name, REQUEST_HEADERS);
        } else {
          await API.downloadFile(url, name);
        }
      }
    }

    if (toastContent != null) {
      if (toastContent !== null) {
        toast.error(toastContent, {
          toastId: "handleDownloadOne",
          containerId: TOAST_CONTAINER_LAYOUT,
        });
      }
    }

    setIndexLoading(null);
  };

  const handleSetFromChooser = async (e) => {
    setLoading(true);
    let toastContent = null;
    let filesNotAllowedMessage = null;

    const actualFiles = fileValue;
    const filesToUpload = e.target.files;

    console.log("FILES VIA SELECTION", filesToUpload);

    //Client extension types
    const filesNotAllowed = [];
    let filesChecked = [];

    if (
      !isNil(maxFiles) &&
      isNumber(maxFiles) &&
      !isNil(filesToUpload) &&
      !isEmpty(filesToUpload) &&
      filesToUpload.length > 0 &&
      filesToUpload.length > maxFiles
    ) {
      toast.error(t("FORM_TABLE_FILE_CH_MAX_FILES_TOAST", { maxFiles }), {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
      });
      setLoading(false);
      return;
    }

    if (CLIENT_CHECK_FILE_EXTENSION) {
      const it =
        filesToUpload && filesToUpload.length > 0
          ? Array.from(filesToUpload)
          : [];

      for (let i = 0; i < it.length; i++) {
        const f = it[i];
        const pos = f?.name.lastIndexOf(".");
        const extension = f?.name.substring(pos + 1).toLowerCase();
        if (AVAILABLE_FORMATS.hasOwnProperty(extension)) {
          filesChecked.push(f);
        } else {
          console.log("ADD FILES TO NOW ALLOED SELECTION", f);
          filesNotAllowed.push(f);
        }
      }
    } else {
      filesChecked = filesToUpload;
      console.log("FILES CHECKED VIA SELECTION", filesChecked);
    }

    if (filesChecked && filesChecked.length > 0) {
      const filesCheckedAvoidDuplicates = renameFilesToAvoidDuplicates(
        filesChecked,
        fileValue
      );

      const response = await API.uploadFiles({
        files: filesCheckedAvoidDuplicates,
        formInstanceId,
        completeIdControl,
        REQUEST_HEADERS,
        line,
      });

      if (response) {
        const { ok, status, msg, files, errorFiles } = response;
        if (status === HTTP_STATUS_UNAUTHORIZED) {
          logout(true);
        } else {
          if (ok) {
            if (isMounted()) {
              const newFilesToSet = [...actualFiles, ...files];
              setFileValue(newFilesToSet);
            }

            if (errorFiles && errorFiles.length > 0) {
              filesNotAllowedMessage = (
                <NotAllowedExtensionMessage
                  filesNotAllowed={errorFiles}
                  useOwnErrorCode={true}
                />
              );
            }
          } else {
            if (!isNil(msg)) {
              toastContent = <CustomBodyToast title={msg} msg={null} />;
            } else {
              setThrowNotAllowedMaxSizeAlert(true);
            }
          }
        }
      } else {
        toastContent = (
          <CustomBodyToast
            title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
            msg={null}
          />
        );
      }
    } else {
      console.log("FILES NOT ALLOWED VIA SELECTION", filesNotAllowed);
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (filesNotAllowed.length > 0) {
      filesNotAllowedMessage = (
        <NotAllowedExtensionMessage filesNotAllowed={filesNotAllowed} />
      );
    }

    if (filesNotAllowedMessage != null) {
      toast.info(filesNotAllowedMessage, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false);
  };

  const handleAddItem = ({ event, pos }) => {
    const val = event.target.value;
    if (val) {
      const indexOf = selectedItems.findIndex(
        (s) => s && s.key === val && s.pos === pos
      );
      if (indexOf > -1) {
        const arrayCopy = clone(selectedItems);
        arrayCopy.splice(indexOf, 1);
        setSelectedItems(arrayCopy);
      } else {
        const valueToSet = { ...fileValue[pos], pos };
        setSelectedItems((prev) => [...prev, valueToSet]);
      }
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length > 0) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        fileValue.map((x, i) => {
          return {
            ...x,
            pos: i,
          };
        })
      );
    }
  };

  // const acceptedFilesTypes = useMemo(
  //   () => makeAcceptedFormatFiles(AVAILABLE_FORMATS),
  //   []
  // );

  function openFileChooserDialog() {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }

  const dropzoneStyle = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Dialog
      onClose={handleDialog}
      aria-labelledby="file-chooser-dialog"
      open={openFileChooser}
      PaperProps={{
        style: {
          maxWidth: "100vw",
          zIndex: 5000,
        },
      }}
    >
      <NotAllowedMaxSizeMessage
        key="NotAllowedMaxSizeMessage"
        open={throwNotAllowedMaxSizeAlert}
        closeModal={() => {
          setThrowNotAllowedMaxSizeAlert(false);
        }}
      />
      <input
        id="file_chooser_input_trigger"
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        multiple={maxFiles !== 1}
        onChange={handleSetFromChooser}
        value={""}
        //accept={acceptedFilesTypes}
      />
      <div className={classes.container}>
        <div className={classes.containerChild}>
          <div className={classes.listContainer}>
            <div className={classes.contentContainer}>
              <div className={classes.titleContainer}>
                {fileValue && fileValue.length > 0 ? (
                  <p className={classes.titleText}>
                    {t("FORM_TABLE_FILE_CH_DIALOG_INTRO_WITH_ITEMS")} (
                    {fileValue.length})
                  </p>
                ) : (
                  <p className={classes.titleText}>
                    {t("FORM_TABLE_FILE_CH_DIALOG_INTRO")}
                  </p>
                )}

                {enabled && (
                  <div className={classes.titleActionContainer}>
                    {fileValue && fileValue.length > 0 && (
                      <Tooltip
                        title={t("FORM_TABLE_FILE_CH_DIALOG_BUTTON_SELECT")}
                        placement="left"
                      >
                        <IconButton onClick={handleSelectAll}>
                          <Icon
                            className="fas fa-tasks"
                            style={{
                              fontSize: 16,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {selectedItems && selectedItems.length > 0 && (
                      <Tooltip
                        title={t("FORM_TABLE_FILE_CH_DIALOG_BUTTON_DELETE")}
                        placement="left"
                      >
                        <IconButton onClick={handleDeleteSelectedFiles}>
                          <Icon
                            className="fas fa-trash-alt"
                            style={{
                              fontSize: 16,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/* <div
                      className={
                        blockAddFilesByMaxFiles
                          ? classes.addItemContainerDisabled
                          : classes.addItemContainer
                      }
                    >
                      <Tooltip
                        title={
                          blockAddFilesByMaxFiles
                            ? t("FORM_TABLE_FILE_CH_MAX_FILES")
                            : t("FORM_TABLE_FILE_CH_DIALOG_BUTTON_ADD")
                        }
                        placement="left"
                      >
                        <IconButton
                          onClick={() => {
                            if (blockAddFilesByMaxFiles) {
                              return;
                            }
                            openFileChooserDialog();
                          }}
                        >
                          <Icon
                            className="fas fa-folder-plus"
                            style={{
                              fontSize: 20,
                              color: blockAddFilesByMaxFiles
                                ? "grey"
                                : theme.palette.content.mainColor,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div> */}
                  </div>
                )}
              </div>

              <div
                className={classes.fileList}
                {...getRootProps({ style: dropzoneStyle })}
              >
                <input {...getInputProps()} />
                {fileValue && fileValue.length > 0 ? (
                  fileValue.map((file, index) => {
                    const extension = file?.ext;
                    const url = file?.url;

                    const isEyeBtnDisabled =
                      !AVAILABLE_FORMATS.hasOwnProperty(extension) ||
                      (AVAILABLE_FORMATS.hasOwnProperty(extension) &&
                        !AVAILABLE_FORMATS[extension].canPreview) ||
                      isLoading ||
                      isNil(url) ||
                      (AVAILABLE_FORMATS?.pdf?.desc === toLower(extension) &&
                        handleOnlyFirstPDFPage);

                    return (
                      <div key={index} className={classes.fileListItem}>
                        <div className={classes.radioAndName}>
                          {enabled ? (
                            <Radio
                              checked={
                                selectedItems.findIndex(
                                  (x) =>
                                    x && x.key === file.key && x.pos === index
                                ) > -1
                              }
                              onClick={(event) =>
                                handleAddItem({ event, pos: index })
                              }
                              value={file.key}
                              name={`radio-button-${file.name}`}
                              inputProps={{ "aria-label": file.name }}
                            />
                          ) : (
                            <div> </div>
                          )}

                          <div className={classes.contFi}>
                            <Tooltip title={file.name}>
                              <Typography
                                variant="h6"
                                className={classes.fileListItemText}
                              >
                                {`${index + 1} - ${file.name}`}
                              </Typography>
                            </Tooltip>
                            {!isNil(file.lastDate) || !isNil(file.lastUser) ? (
                              <div className={classes.fileListRow}>
                                {!isNil(file.lastUser) && (
                                  <div
                                    className={classes.fileContextInfoContainer}
                                  >
                                    <Icon
                                      className="fas fa-user"
                                      style={{
                                        ...commonIconStyles,
                                        fontSize: "0.65rem",
                                      }}
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      style={{ fontSize: "0.65rem" }}
                                    >
                                      {`${file.lastUser}`}
                                    </Typography>
                                  </div>
                                )}
                                {!isNil(file.lastDate) && (
                                  <div
                                    className={classes.fileContextInfoContainer}
                                  >
                                    <Icon
                                      className="fas fa-clock"
                                      style={{
                                        ...commonIconStyles,
                                        fontSize: "0.65rem",
                                      }}
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      style={{ fontSize: "0.65rem" }}
                                    >
                                      {`${
                                        safeFormattingDate(
                                          DATE_TIME_PATTERN,
                                          FRONT_DATE_TIME_PATTERN,
                                          file.lastDate
                                        ) || file.lastDate
                                      }`}
                                    </Typography>
                                  </div>
                                )}

                                <FileSizeLoader url={file?.url} />
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {!isNil(indexLoading) && indexLoading === index ? (
                          <CircularProgress
                            size={20}
                            style={{ marginRight: 16 }}
                          />
                        ) : (
                          <div className={classes.actionsPrevContainer}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handlePreview(index);
                              }}
                              disabled={isEyeBtnDisabled}
                              classes={{
                                root: classes.buttonActionPrev,
                              }}
                            >
                              <Icon
                                className={
                                  preview ? "fas fa-eye-slash" : "fas fa-eye"
                                }
                                style={commonIconStyles}
                              />
                            </IconButton>
                            <IconButton
                              disabled={isLoading || isNil(url)}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleDownloadOne(index);
                              }}
                              classes={{
                                root: classes.buttonActionPrev,
                              }}
                            >
                              <Icon
                                className="fas fa-download"
                                style={commonIconStyles}
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.emptyPlaceholder}>
                    <Icon
                      className="fas fa-upload"
                      style={{ ...commonIconStyles, fontSize: "3rem" }}
                    />
                    <Typography className={classes.emptyPlaceholderTypo}>
                      {t("FORM_TABLE_FILE_CH_DIALOG_DND")}
                    </Typography>
                    {enabled && (
                      <Button
                        variant="outlined"
                        className={
                          blockAddFilesByMaxFiles
                            ? classes.uploadBtnDisabled
                            : classes.uploadBtn
                        }
                        startIcon={
                          isLoading === true ? null : <CheckCircleIcon />
                        }
                        onClick={() => {
                          if (blockAddFilesByMaxFiles) {
                            return;
                          }
                          openFileChooserDialog();
                        }}
                        disabled={blockAddFilesByMaxFiles}
                      >
                        {isLoading === true ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "#FFF" }}
                          />
                        ) : (
                          t("FORM_TABLE_FILE_CH_DIALOG_BUTTON_ADD")
                        )}
                      </Button>
                    )}
                  </div>
                )}
              </div>
              {blockAddFilesByMaxFiles && (
                <Typography className={classes.disableAddMaxFilesTypo}>
                  {t("FORM_TABLE_FILE_CH_MAX_FILES")}
                </Typography>
              )}
            </div>
          </div>
        </div>

        <div className={classes.actionContainer}>
          {fileValue && fileValue.length > 0 && enabled ? (
            <Button
              variant="outlined"
              className={
                blockAddFilesByMaxFiles
                  ? classes.uploadBtnDisabled
                  : classes.uploadBtn
              }
              startIcon={isLoading === true ? null : <CheckCircleIcon />}
              onClick={() => {
                if (blockAddFilesByMaxFiles) {
                  return;
                }
                openFileChooserDialog();
              }}
              disabled={blockAddFilesByMaxFiles}
            >
              {isLoading === true ? (
                <CircularProgress size={20} style={{ color: "#FFF" }} />
              ) : (
                t("FORM_TABLE_FILE_CH_DIALOG_BUTTON_ADD")
              )}
            </Button>
          ) : (
            <div />
          )}

          <Button
            variant="outlined"
            className={classes.readyBtn}
            startIcon={
              <CheckCircleIcon
                style={{
                  color:
                    isLoading === true
                      ? "grey"
                      : theme.palette.content.mainColor,
                }}
              />
            }
            onClick={handleDialog}
            disabled={isLoading === true}
          >
            {t("FORM_TABLE_FILE_CH_DIALOG_BUTTON_READY")}
          </Button>
        </div>
      </div>
      {preview && preview.url && preview.ext && (
        <Dialog
          fullScreen={
            AVAILABLE_FORMATS[preview.ext] &&
            AVAILABLE_FORMATS[preview.ext].full
          }
          aria-labelledby="preview-file-dialog"
          open={preview && preview.url && preview.ext ? true : false}
          PaperComponent={DraggableComponent}
        >
          <div className={classes.filePreviewToolbar}>
            <Typography
              id="file-chooser-dialog-title"
              variant="h4"
              className={classes.previewNameTypo}
            >
              {preview.name}
            </Typography>
            <IconButton id="close_preview_btn" onClick={handlePreview}>
              <Icon
                className="fas fa-times-circle"
                style={{
                  ...commonIconStyles,
                  fontSize: 25,
                  color: theme.palette.content.mainColor,
                }}
              />
            </IconButton>
          </div>
          <div
            id={`preview_${uuid()}_dialog`}
            key={`preview_${uuid()}_dialog`}
            className={classes.filePreview}
          >
            <CustomFileViewer
              id={`preview_${uuid()}_dialog`}
              keyViewer={`preview_${uuid()}_dialog`}
              fileType={preview.ext}
              filePath={preview.url}
            />
          </div>
        </Dialog>
      )}
    </Dialog>
  );
}
