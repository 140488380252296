import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, IconButton, InputAdornment, TextField } from "@material-ui/core";
import FormHistorySelect from "./FormHistorySelect";

const searchIconStyle = {
  width: "auto",
  height: "auto",
  fontSize: 16,
  padding: 1,
  color: "grey",
};

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
  },
  form: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
  },
}));

function SearchMenuBar({ filterMenuItems, handleEnterOnSeach }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchBarValue, setSearchBarValue] = useState("");

  const handleSearchBarText = (e) => {
    setSearchBarValue(e.target.value);
  };

  function clearSearcBarText(e) {
    setSearchBarValue("");
  }

  useEffect(() => {
    filterMenuItems(searchBarValue);
    return () => null;
    // eslint-disable-next-line
  }, [searchBarValue]);

  function onSubmitForm(e) {
    e.preventDefault();
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={onSubmitForm}
      className={classes.form}
    >
      <TextField
        value={searchBarValue}
        onChange={handleSearchBarText}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleEnterOnSeach();
            e.preventDefault();
          }
        }}
        margin="dense"
        id="search_bar_menu"
        label=""
        placeholder={t("HEADER_SEARCH_PLACEHOLDER")}
        variant="outlined"
        className={classes.searchBar}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon className="fas fa-search" style={searchIconStyle} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={clearSearcBarText}
                style={{ padding: 2 }}
                disabled={searchBarValue === ""}
              >
                <Icon
                  className="fas fa-times"
                  style={
                    searchBarValue === ""
                      ? searchIconStyle
                      : { ...searchIconStyle, color: "inherit" }
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHistorySelect />
    </form>
  );
}

//SearchMenuBar.whyDidYouRender = true;

export default SearchMenuBar;
