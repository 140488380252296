import { useState, useEffect } from "react";
import { isNil } from "lodash";

export const useFetchSize = (url) => {
  const [size, setSize] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSize = async () => {
      try {
        const response = await fetch(url);
        const contentLength = response.headers.get("Content-Length");
        setSize(contentLength);
        setLoading(false);
      } catch (error) {
        setSize(null);
        setLoading(false);
      }
    };

    if (!isNil(url)) {
      fetchSize();
    }

    return () => {};
  }, [url]);

  return { size, loading };
};
