import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import useEmployeeStoreState from "../EmployeeStoreState";

const useInfo = () => {
  const { t } = useTranslation();
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const { loadingInfo, errorLoadingInfo, initialInfo, fetchInitialInfo } =
    useEmployeeStoreState((state) => ({
      loadingInfo: state.loadingInfo,
      errorLoadingInfo: state.errorLoadingInfo,
      initialInfo: state.initialInfo,
      fetchInitialInfo: state.fetchInitialInfo,
    }));

  const handleFetchInitialInfo = useCallback(() => {
    fetchInitialInfo(headers, logout, t);
  }, [fetchInitialInfo, headers, logout, t]);

  return {
    handleFetchInitialInfo,
    loadingInfo,
    errorLoadingInfo,
    initialInfo,
  };
};

export default useInfo;
