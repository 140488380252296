import React, { useState, useEffect, useRef, useMemo } from "react";
import * as _ from "lodash";
import {
  defineInputEnabledByOperation,
  getCompletePathGridItem,
  callServerToSetMultipleValues,
  makeValuesToSetValuesMultiple,
  makeEmptyMultiComboValue,
  formatValuesMultiComboBox,
  resolveFieldAgainstShadowStatus,
} from "../../../util/UtilForm";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { InputLabel } from "@material-ui/core";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";

export default function EditableCellMultiComboBox(props) {
  const {
    value,
    row: { index },
    operation,
    column,
    formContext,
    processServerResponse,
  } = props;

  const { id } = column;
  const {
    section,
    block,
    listName,
    formInstanceId,
    shadowStatus,
    isBlockDisabledByWizard,
  } = formContext;

  // We need to keep and update the state of the cell normally
  const [selectValue, setSelectValue] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  const comboRef = useRef(null);
  const [calculatedInputProps, setCalculatedInputProps] = useState(null);

  const REQUEST_HEADERS = useRequestHeaders();

  let completeIdControl = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column,
    index + 1
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema: column,
    operation,
    isSavedEntity: value?.isSavedEntity,
  });

  const multiComboProps = column?.multiComboProps;

  const availableOptions = useMemo(() => {
    if (_.isNil(multiComboProps) || _.isNil(multiComboProps?.display)) {
      return [];
    }

    if (
      !_.isNil(value) &&
      !_.isNil(value.optionsValues) &&
      !_.isEmpty(value.optionsValues) &&
      _.isArray(value.optionsValues)
    ) {
      const display = multiComboProps?.display;
      return value.optionsValues.map((o) => o[display]);
    }

    if (
      !_.isNil(value) &&
      (_.isNil(value.optionsValues) || _.isEmpty(value.optionsValues)) &&
      !_.isNil(selectValue) &&
      selectValue !== ""
    ) {
      return [selectValue];
    }

    return [];
  }, [value, selectValue, multiComboProps]);

  useEffect(() => {
    if (!_.isNil(value) && !_.isNil(value.value)) {
      const realValue = value.value;
      setSelectValue(realValue);
    } else {
      setSelectValue("");
    }
    checkPresentationInfo(value);
  }, [value]);

  function checkPresentationInfo(value) {
    if (!_.isNil(value) && !_.isNil(value.presentationInfo)) {
      const { presentationInfo } = value;
      if (presentationInfo && presentationInfo.reset) {
        setCalculatedInputProps(null);
      } else {
        setCalculatedInputProps(presentationInfo);
      }
    } else {
      setCalculatedInputProps(null);
    }
  }

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      column?.canEdit,
      column?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
    if (_.isNil(availableOptions) || _.isEmpty(availableOptions)) {
      setIsEnabled(false);
    }
    if (
      !_.isNil(calculatedInputProps) &&
      !_.isNil(calculatedInputProps.enabled)
    ) {
      setIsEnabled(calculatedInputProps.enabled);
    }
  }, [operation, column, availableOptions, calculatedInputProps]);

  const handleChange = async (selVal) => {
    setSelectValue(selVal);

    const fieldsToReturn = multiComboProps?.valsToSet;
    const display = multiComboProps?.display;
    const objectSelected = !_.isEmpty(selVal)
      ? _.find(value?.optionsValues, { [display]: selVal })
      : makeEmptyMultiComboValue({ optionsValues: value?.optionsValues });

    if (!_.isNil(fieldsToReturn) && !_.isNil(objectSelected)) {
      let valuesMade = makeValuesToSetValuesMultiple(
        fieldsToReturn,
        [objectSelected],
        index + 1
      );

      const valuesToSet = formatValuesMultiComboBox(
        valuesMade,
        completeIdControl
      );

      const response = await callServerToSetMultipleValues(
        {
          formInstanceId,
          values: valuesToSet,
        },
        REQUEST_HEADERS
      );

      const completeId = getCompletePathGridItem(
        section,
        block,
        `_${id}`,
        listName,
        column
      );

      processServerResponse(
        response,
        id,
        true,
        comboRef,
        index + 1,
        completeId
      );
    }
  };

  //Input styles
  const controlStyles = {
    color:
      calculatedInputProps &&
      calculatedInputProps.color &&
      calculatedInputProps.color,
    fontSize:
      calculatedInputProps &&
      calculatedInputProps.fontSize &&
      calculatedInputProps.fontSize,
    backgroundColor:
      calculatedInputProps &&
      calculatedInputProps.backgroundColor &&
      calculatedInputProps.backgroundColor,
    fontWeight:
      calculatedInputProps &&
      calculatedInputProps.fontWeight &&
      calculatedInputProps.fontWeight,
    fontStyle:
      calculatedInputProps &&
      calculatedInputProps.fontStyle &&
      calculatedInputProps.fontStyle,
  };

  const isClearabale = useMemo(() => {
    if (
      _.isNil(column) ||
      _.isNil(value) ||
      _.isNil(value?.value) ||
      _.isNil(column?.isClearable) ||
      _.trim(_.toString(value)) === ""
    ) {
      return true;
    }

    const definedIsClearable = column?.isClearable;
    if (_.isBoolean(definedIsClearable)) {
      return definedIsClearable;
    } else if (_.isString(definedIsClearable)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: definedIsClearable,
        shadowStatus,
        completeIdControl: definedIsClearable,
        line: index,
      });

      return (
        _.isEqual("S", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("TRUE", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("Y", _.toUpper(_.toString(valueInShadowStatus)))
      );
    } else {
      return true;
    }
  }, [column, value, shadowStatus, index]);

  return (
    <>
      <InputLabel htmlFor={completeIdControl} />
      <NativeSelect
        value={selectValue}
        onChange={(e) => handleChange(e.target.value)}
        disabled={
          !isEnabled ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard === true
        }
        inputRef={comboRef}
        id={completeIdControl}
        style={controlStyles}
        error={false}
        inputProps={{
          id: completeIdControl,
        }}
      >
        {isClearabale && <option aria-label="None" value="" />}
        {!_.isNil(availableOptions) &&
          !_.isEmpty(availableOptions) &&
          availableOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </NativeSelect>
    </>
  );
}
