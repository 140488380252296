import {
  METHOD_POST,
  SERVER_HOST,
  SERVICE_CLI_URL,
  TOAST_CONTAINER_LAYOUT,
} from "../../../../util/Constants";
import { toast } from "react-toastify";
export class PlvGenFile {
  //Method to implement
  static async execute(props) {
    const { serviceBody, REQUEST_HEADERS } = props;
    const url = SERVER_HOST() + SERVICE_CLI_URL;
    const response = await fetch(url, {
      method: METHOD_POST,
      headers: REQUEST_HEADERS,
      body: JSON.stringify(serviceBody),
    })
      .then((res) => res.json())
      .then((jsonResult) => {
        return jsonResult;
      })
      .catch((error) => {
        return {
          ok: false,
          errorMessage: error,
          ...error,
        };
      });
    if (response) {
      const { ok, errorMessage, dataResult } = response;
      if (ok) {
        const dataParsed = JSON.parse(dataResult);
        if (dataParsed && dataParsed.length > 0) {
          const { file, fileName } = dataParsed[0];
          if (file && fileName) {
            this.download(fileName, file);
          }
        }
      } else {
        //Throw toast
        toast.error(errorMessage, {
          containerId: TOAST_CONTAINER_LAYOUT,
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  static download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
