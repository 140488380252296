import { useState } from "react";

export function useMenuAnchor() {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenuAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuAnchor = () => {
    setAnchorEl(null);
  };

  return [anchorEl, openMenuAnchor, closeMenuAnchor];
}
