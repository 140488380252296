import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  adminBadge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.semaphore.orange,
    color: "#FFFFFF",
    fontWeight: "bold",
    borderRadius: "6px",
    padding: "2px",
    fontSize: "10px",
  },
  fromProfile: {
    position: "absolute",
    bottom: "4px",
    left: "25%",
  },
}));

export default function FilterAdminBadge(props) {
  const classes = useStyles();

  const isProfileBadge = props?.isProfileBadge === true;
  const show = props?.showOnlyAdmin === true;

  if (show) {
    return (
      <div
        className={`${classes.adminBadge} ${
          isProfileBadge ? classes.fromProfile : ""
        }`}
      >
        ADMIN
      </div>
    );
  } else {
    return null;
  }
}
