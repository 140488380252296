import React, { useContext, useState } from "react";
import { IconButton, makeStyles, Popper } from "@material-ui/core";
import ProfilePopoverContent from "../../../../../shared/layout/components/appBar/info/ProfilePopoverContent";
import LogoutConfirmationDialog from "../../../../../components/dialogs/logoutConfirmationDialog/LogoutConfirmationDialog";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import UserImage from "../../../../../shared/layout/components/appBar/info/UserImage";
import FilterAdminBadge from "../../../components/filters/FilterAdminBadge";
import useInfo from "../../../store/hooks/useInfo";

const useStyles = makeStyles(() => ({
  avatar: {
    width: 40,
    height: 40,
    border: "1px solid",
  },
  mainPopper: {
    zIndex: 10,
    marginTop: 5,
    borderRadius: "5px",
    maxWidth: "calc(100% - 30px)",
    maxHeight: "calc(100% - 30px)",
    outline: "0px",
  },
}));

export default function EmployeeStoreProfileAvatar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfilePop, setOpenProfilePop] = useState(false);
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const { logout } = useContext(AuthContext);
  const { initialInfo } = useInfo();

  const handleBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenProfilePop((prev) => !prev);
  };

  const handleLogout = (confirmLogout) => {
    if (confirmLogout === true) {
      logout();
    }
    setOpenLogoutConfirmation(false);
  };

  return (
    <>
      <IconButton onClick={handleBtnClick}>
        <UserImage />
        <FilterAdminBadge
          isProfileBadge={true}
          showOnlyAdmin={initialInfo?.isEmsAdmin === true}
        />
      </IconButton>
      <Popper
        open={openProfilePop}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        transition
        className={classes.mainPopper}
      >
        <ProfilePopoverContent
          setOpenLogoutConfirmation={setOpenLogoutConfirmation}
          setOpenProfilePop={setOpenProfilePop}
          isEms={true}
        />
      </Popper>
      <LogoutConfirmationDialog
        open={openLogoutConfirmation}
        handleLogout={handleLogout}
      />
    </>
  );
}
