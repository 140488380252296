import { METHOD_POST } from "../../../util/Constants";
import { BASE_EMS_URL, handleApiResponse } from "./base";

export const fetchBannersApi = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}/banners`, {
    method: METHOD_POST,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};
