import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  labellingStoredFilters: null,
  labellingStoredCollapsed: false,
  labellingStoredShowBpmDialog: false,
  isEditingLabellingForm: false,
  labellingStoredBreadcumbRoutes: new Map(),
};

export const createLabellingSlice = (set, get) => ({
  ...INITIAL_STATE,
  setLabellingStoredFilters: (newState) =>
    set(() => ({ labellingStoredFilters: newState })),
  setLabellingStoredCollapsed: (newState) =>
    set(() => ({ labellingStoredCollapsed: newState })),
  setLabellingStoredShowBpmDialog: (newState) =>
    set(() => ({ labellingStoredShowBpmDialog: newState })),
  addLabellingBreadcumbRoute: (newRoute) =>
    set((state) => ({
      labellingStoredBreadcumbRoutes: state.labellingStoredBreadcumbRoutes.set(
        newRoute,
        {
          labellingStoredFilters: state.labellingStoredFilters,
          labellingStoredCollapsed: state.labellingStoredCollapsed,
          labellingStoredShowBpmDialog: state.labellingStoredShowBpmDialog,
        }
      ),
    })),
  removeLabellingBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().labellingStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          labellingStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          labellingStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateLabellingBreadcumbRoute: (route, newParams) => {
    const actualBC = get().labellingStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      labellingStoredBreadcumbRoutes: state.labellingStoredBreadcumbRoutes.set(
        route,
        {
          ...spreadBC,
          ...newParams,
        }
      ),
    }));
  },
  toggleIsEditingLabellingForm: () => {
    set((state) => ({ isEditingLabellingForm: !state.isEditingLabellingForm }));
  },
  clear: () => set(INITIAL_STATE),
});
