import { addFavoriteRequestApi, getFavoritesRequestApi, removeFavoriteRequestApi } from "../../api/favoriteApi";

const createFavoriteSlice = (set) => ({
  favorites: [],
  getFavorites: async ({ headers, logout }) => {
    const response = await getFavoritesRequestApi({ headers, logout });
    if (!response.error) {
      set({ favorites: response.data.favorites });
    }
  },
  addFavorite: async ({ headers, logout, product }) => {
    const body = {
      ean: product.ean,
      codMaterial: product.sku
    }

    const response = await addFavoriteRequestApi({ headers, logout, body });
    if (!response.error) {
      set((state) => {
        const isAlreadyFavorite = state.favorites.some(
          (item) => item.sku === product.sku
        );
        if (isAlreadyFavorite) {
          return state;
        }
        return {
          favorites: [...state.favorites, product],
        };
      });
    }
  },
  removeFavorite: async ({ headers, logout, product }) => {
    const body = {
      ean: product.ean,
      codMaterial: product.sku
    }

    const response = await removeFavoriteRequestApi({ headers, logout, body });
    if (!response.error) {
      set((state) => ({
        favorites: state.favorites.filter((item) => item.sku !== product.sku),
      }));
    }
  },
});

export default createFavoriteSlice;
