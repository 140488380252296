import React, { useMemo, useState } from "react";
import {
  isArray,
  isEmpty,
  isNil,
  isString,
  split,
  toLower,
  toNumber,
  trim,
} from "lodash";

import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardHeader,
  DialogContent,
  Icon,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Draggable from "react-draggable";
import { format } from "date-fns";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { createStringDateAsUTC } from "../../util/UtilDates";
import {
  API_DASHBOARD,
  FORM_OPERATION_NEW,
  FORM_OPERATION_VIEW,
  INITIAL_OPEN_EXTERNAL_FORM_STATE,
  METHOD_GET,
  SERVER_HOST,
} from "../../util/Constants";
import { useFetch } from "./useFetch";
import BaseForm from "../../components/forms/externalFormContainer/BaseForm";

const BASE_WIDTH = 75;
const BASE_HEIGHT = 85;

const OUTER_WIDTH = `${BASE_WIDTH}vw`;
const OUTER_HEIGHT = `${BASE_HEIGHT}vh`;

const INNER_WIDTH = `${BASE_WIDTH - 1}vw`;
const INNER_HEIGHT = `${BASE_HEIGHT - 3}vh`;

const INNER_WIDTH_FORM = `${BASE_WIDTH - 2}vw`;
const INNER_HEIGHT_FORM = `${BASE_HEIGHT - 5}vh`;

const CONTROL_ID_WF_TRASK_ID_TO_SET = "#A.INFOWF.wfTaskId";
const CONTROL_ID_COD_PAIS_TO_SET = "$G.G1.paisesBandeja";

const OPERATIONS_COLUMN = "operations";

const useStyles = makeStyles((theme) => ({
  dotFinished: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  dotPending: {
    color: `rgb(89,190,98) !important`,
    backgroundColor: `rgb(89,190,98) !important`,
  },
  historyCard: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    borderRadius: "10px",
    border: "1px solid #f0f0f0",
    boxShadow: "none",
    transition: "all 0.2s ease 0s",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "&:hover": {
      boxShadow: "rgb(0 0 0 / 12%) 0px 5px 10px",
      cursor: "pointer",
    },
  },
  dialogTitle: {
    cursor: "move",
    padding: "6px 24px",
  },
  dialogTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContent: {
    overflowY: "hidden",
  },
  tabsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const lockIcon = { position: "absolute", top: 5, left: 5, fontSize: 10 };

function transformStringByCommaIntoArray(st) {
  if (
    isNil(st) ||
    !isString(st) ||
    isEmpty(st) ||
    isEmpty(trim(toString(st)))
  ) {
    return [];
  }

  const splitted = split(st, ",");
  if (!isNil(splitted) && !isEmpty(splitted)) {
    return splitted.map((x) => trim(x));
  } else {
    return [];
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width="100%" height="100%">
          {children}
        </Box>
      )}
    </div>
  );
}

function DraggablePaperComponent(props) {
  return (
    <Draggable handle="#history-process-dialog-title">
      <Paper {...props} />
    </Draggable>
  );
}

function getFormValuesToSet(item, config) {
  if (
    !isNil(item) &&
    !isNil(config) &&
    !isNil(config.formValues) &&
    isArray(config.formValues) &&
    !isEmpty(config.formValues)
  ) {
    const formValues = config.formValues;
    const resValues = {};
    for (const f of formValues) {
      if (!isNil(f) && !isNil(f.toSetInForm) && !isNil(f.inHistoryItem)) {
        if (toLower(f.type) === "number" || toLower(f.type) === "numeric") {
          Object.assign(resValues, {
            [f.toSetInForm]: toNumber(item[f.inHistoryItem]),
          });
        } else {
          Object.assign(resValues, { [f.toSetInForm]: item[f.inHistoryItem] });
        }
      }
    }
    return resValues;
  } else {
    return {
      [CONTROL_ID_WF_TRASK_ID_TO_SET]: toNumber(item?.taskId),
      [CONTROL_ID_COD_PAIS_TO_SET]: item?.codPais,
    };
  }
}

function HistoryTimeline({ apiUrl, config, overrideOps }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openExternalForm, setOpenExternalForm] = useState(
    INITIAL_OPEN_EXTERNAL_FORM_STATE
  );

  const { status, data, error } = useFetch(
    apiUrl,
    undefined,
    METHOD_GET,
    "dataResponse"
  );

  function handleBackClick() {
    setOpenExternalForm(INITIAL_OPEN_EXTERNAL_FORM_STATE);
  }

  function handleOpenFormWithValues(item) {
    if (item?.taskState === "F") {
      setOpenExternalForm({
        ...INITIAL_OPEN_EXTERNAL_FORM_STATE,
        ...item,
        operation: FORM_OPERATION_VIEW,
        open: true,
        useUkey: true,
      });
    } else {
      setOpenExternalForm({
        ...INITIAL_OPEN_EXTERNAL_FORM_STATE,
        ...item,
        operation: FORM_OPERATION_NEW,
        values: getFormValuesToSet(item, config),
        open: true,
        useUkey: false,
      });
    }
  }

  if (status === "error") return <div>Error: {error}</div>;
  if ((isNil(data) || isEmpty(data)) && status === "fetched") return null;
  return (
    <Box width="100%" height="100%">
      {openExternalForm.open && (
        <Box width={INNER_WIDTH_FORM} height={INNER_HEIGHT_FORM}>
          {openExternalForm.useUkey === true ? (
            <BaseForm
              ukey={openExternalForm.ukey}
              operation={openExternalForm.operation || FORM_OPERATION_VIEW}
              path={openExternalForm.path}
              idForm={openExternalForm.idForm}
              handleBack={() => handleBackClick()}
              values={undefined}
              overrideOps={
                !isNil(overrideOps) && !isEmpty(overrideOps)
                  ? overrideOps
                  : null
              }
            />
          ) : (
            <BaseForm
              ukey={undefined}
              operation={openExternalForm.operation || FORM_OPERATION_NEW}
              path={openExternalForm.path}
              idForm={openExternalForm.idForm}
              handleBack={() => handleBackClick()}
              values={openExternalForm.values}
            />
          )}
        </Box>
      )}
      {!openExternalForm.open && (
        <Timeline align="left">
          {data.map((item, i) => {
            const itemSubHeader =
              !isNil(item?.codPais) && !isEmpty(item?.codPais)
                ? `[${item?.codPais}] - ${item.description}`
                : item.description;

            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent
                  style={{ flex: "0 1 15%", minWidth: 80, padding: 6 }}
                >
                  <Typography variant="body1" color="primary">
                    {item.taskState === "F"
                      ? format(
                          createStringDateAsUTC(item.creationDate),
                          "dd/MM/yyyy HH:mm"
                        )
                      : t("PENDING")}
                  </Typography>
                  {item.taskState === "F" && (
                    <Typography variant="body2">{item.userId}</Typography>
                  )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className={
                      item.taskState === "F"
                        ? classes.dotFinished
                        : classes.dotPending
                    }
                    variant="default"
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card
                    className={classes.historyCard}
                    onClick={() => handleOpenFormWithValues(item)}
                  >
                    {item?.enable === "N" && (
                      <Icon className="fas fa-lock" style={lockIcon} />
                    )}
                    <CardHeader
                      subheader={itemSubHeader}
                      title={item.nameForm}
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    {item.taskState === "F" && (
                      <Box
                        alignItems="flex-end"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        p={2}
                      >
                        <Box>
                          <Typography color="primary" variant="subtitle1">
                            {format(
                              createStringDateAsUTC(item.initDate),
                              "dd/MM/yyyy HH:mm"
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2">
                            {item?.executionDelay || 0}{" "}
                            {t("GEN_DASH_HISTORY_ITEM_DAYS")}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Card>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      )}
    </Box>
  );
}

export function useProcessHistoryDialog() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState([]);
  const [rowData, setRowData] = useState();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleOpen = ({ config, rowData }) => {
    setOpen(true);
    setConfig(config || []);
    setRowData(rowData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const ProcessHistoryDialog = useMemo(
    () => () => {
      if (!isNil(config) && isArray(config) && !isEmpty(config)) {
        return (
          <Dialog
            aria-labelledby="history-process-dialog-title"
            open={open}
            PaperProps={{
              style: {
                minWidth: OUTER_WIDTH,
                minHeight: OUTER_HEIGHT,
              },
            }}
            PaperComponent={DraggablePaperComponent}
          >
            <DialogContent
              className={classes.dialogContent}
              style={{ padding: 10 }}
            >
              <div className={classes.tabsContainer}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="full width tabs example"
                >
                  {config.map((x) => {
                    const taskIdColumnValue = !isNil(rowData)
                      ? rowData[x?.columnTask]
                      : "-";
                    return (
                      <Tab
                        key={x?.endpoint}
                        label={`${x?.label} (${taskIdColumnValue})`}
                      />
                    );
                  })}
                </Tabs>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  <Icon className="fas fa-times" />
                </IconButton>
              </div>

              <div
                style={{
                  maxHeight: INNER_HEIGHT,
                  maxWidth: INNER_WIDTH,
                  overflow: "auto",
                }}
              >
                {config.map((x, index) => {
                  const taskIdColumnValue = rowData?.[x?.columnTask];
                  const apiUrl = `${SERVER_HOST()}${API_DASHBOARD}${
                    x?.endpoint
                  }?taskId=${taskIdColumnValue}`;
                  const overrideOps = transformStringByCommaIntoArray(
                    rowData?.[OPERATIONS_COLUMN]
                  );
                  return (
                    <TabPanel
                      key={`${x?.endpoint}_${index}`}
                      value={selectedTab}
                      index={index}
                    >
                      <HistoryTimeline
                        apiUrl={apiUrl}
                        config={x}
                        overrideOps={overrideOps}
                      />
                    </TabPanel>
                  );
                })}
              </div>
            </DialogContent>
          </Dialog>
        );
      } else {
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, open, config, rowData, selectedTab]
  );

  return [ProcessHistoryDialog, handleOpen];
}
