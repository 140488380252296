import React from "react";
import {
  has,
  isEmpty,
  isFunction,
  isNil,
  some,
  get as getLodash,
  cloneDeep,
  find,
  filter,
  isArray,
  set as setLodash,
  isEqual,
  toString,
  toUpper,
} from "lodash";
import create from "zustand";

import * as API from "./api";
import {
  HTTP_STATUS_NOT_FOUND,
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_NOTIFICATIONS_CONFIG_CONTAINER,
} from "../../../../util/Constants";
import CustomBodyToast from "../../../../components/toast/CustomBodyToast";
import { toast } from "react-toastify";

const ENDPOINT_GET_CONFIG = "/get-notif-user-config";
const ENDPOINT_CHANGE_CONFIG = "/change-notif-user-config";

const INITIAL_CONFIG = {
  isGeneralDisabled: false,
  solutionsDisabled: [],
  notificationsConfig: null,
  mustConfigTelephoneNumber: false,
};

const useNotificationStore = create((set, get) => ({
  isFetchingInitialConfig: false,
  isSavingChanges: false,
  setFetchingInitialConfig: (newState) =>
    set({ isFetchingInitialConfig: newState }),
  setSavingChanges: (newState) => set({ isSavingChanges: newState }),
  isEditing: false,
  initialConfig: INITIAL_CONFIG,
  currentConfig: null,
  solutionsDisabled: [],
  isGeneralDisabled: false,
  openGeneralDialog: false,
  mustConfigTelephoneNumber: false,
  fetchInitialConfig: async function ({ t, REQUEST_HEADERS, logout }) {
    set({ isFetchingInitialConfig: true });
    let toastContent = null;
    const response = await API.invokeToObtain({
      REQUEST_HEADERS,
      endpoint: ENDPOINT_GET_CONFIG,
    });

    if (response) {
      const { status } = response;

      if (
        status === HTTP_STATUS_UNAUTHORIZED &&
        !isNil(logout) &&
        isFunction(logout)
      ) {
        logout(true);
      } else {
        if (status === HTTP_STATUS_NOT_FOUND) {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        } else {
          const { ok, errorMsg: msg, dataResponse } = response;
          if (!ok) {
            const rightMsg =
              !isNil(msg) && !isEmpty(msg)
                ? msg
                : t("ERROR_RESOURCE_NOT_FOUND_TEXT");
            toastContent = <CustomBodyToast title={rightMsg} />;
          } else {
            set({
              initialConfig: dataResponse,
              currentConfig:
                dataResponse?.notificationsConfig ||
                INITIAL_CONFIG.notificationsConfig,
              solutionsDisabled:
                dataResponse?.solutionsDisabled ||
                INITIAL_CONFIG.solutionsDisabled,
              isGeneralDisabled:
                dataResponse?.isGeneralDisabled ||
                INITIAL_CONFIG.isGeneralDisabled,
              mustConfigTelephoneNumber:
                dataResponse?.mustConfigTelephoneNumber ||
                INITIAL_CONFIG.mustConfigTelephoneNumber,
            });
          }
        }
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_NOTIFICATIONS_CONFIG_CONTAINER,
        toastId: "fetchInitialConfig",
      });
    }

    set({ isFetchingInitialConfig: false });
  },
  toggleIsGeneralDisabled: () => {
    set((state) => ({
      isGeneralDisabled: !state.isGeneralDisabled,
    }));
  },
  setOpenGeneralDialog: (newState) => set({ openGeneralDialog: newState }),
  undoChanges: () => {
    const initialConfig = get().initialConfig;
    set({
      currentConfig: initialConfig?.notificationsConfig,
      solutionsDisabled: initialConfig?.solutionsDisabled,
      isGeneralDisabled: initialConfig?.isGeneralDisabled,
      mustConfigTelephoneNumber: initialConfig?.mustConfigTelephoneNumber,
      isEditing: false,
    });
  },
  changeThirdLevel: ({ solution, key, type, newState, inBackground }) => {
    if (!inBackground) {
      set({ isEditing: true });
    }
    const currentConfig = get().currentConfig;
    if (
      !isNil(currentConfig) &&
      has(currentConfig, solution) &&
      some(getLodash(currentConfig, solution), { key })
    ) {
      const updatedConfig = cloneDeep(currentConfig);
      const item = find(updatedConfig[solution], { key });
      if (!isNil(item)) {
        const items = updatedConfig[solution];
        const item = find(items, { key });
        if (!isNil(item) && !isNil(item.possibleTypes)) {
          const possibleTypesUpdated = item.possibleTypes?.map((x) => {
            if (!isNil(x) && !isNil(type) && x?.type === type) {
              return {
                type,
                enabled: newState,
              };
            } else {
              return x;
            }
          });
          item.possibleTypes = possibleTypesUpdated;
        }
        const newUpdatedToSet = { ...currentConfig, [solution]: items };
        set({ currentConfig: newUpdatedToSet });
      }
    }
  },
  toggleSolution: ({ solution, newStatus }) => {
    if (!isNil(solution) && !isNil(newStatus)) {
      set({ isEditing: true });
      if (newStatus === false) {
        set((state) => ({
          solutionsDisabled: [...state.solutionsDisabled, solution],
        }));
      } else {
        const currentConfig = get().currentConfig;
        if (!isNil(currentConfig) && has(currentConfig, solution)) {
          const currentChildren = getLodash(currentConfig, solution);
          if (
            !isNil(currentChildren) &&
            isArray(currentChildren) &&
            !isEmpty(currentChildren)
          ) {
            const newCurrentChildrenToSet = currentChildren.map((x) => {
              return {
                ...x,
                enabled: true,
              };
            });
            const updatedConfig = cloneDeep(currentConfig);
            setLodash(updatedConfig, solution, newCurrentChildrenToSet);
            set((state) => ({
              solutionsDisabled: filter(state.solutionsDisabled, function (o) {
                return !isEqual(
                  toUpper(toString(solution)),
                  toUpper(toString(o))
                );
              }),
              currentConfig: updatedConfig,
            }));
          }
        } else {
          set((state) => ({
            solutionsDisabled: filter(state.solutionsDisabled, solution),
          }));
        }
      }
    }
  },
  saveUserNotificationConfigChanges: async function ({
    t,
    REQUEST_HEADERS,
    logout,
    isGeneralChange,
  }) {
    set({ isSavingChanges: true });
    let toastContent = null;
    const notificationsConfigSt = get().currentConfig;
    const solutionsDisabledSt = get().solutionsDisabled;
    const isGeneralDisabledSt = get().isGeneralDisabled;

    const bodyToSet =
      isGeneralChange === true
        ? {
            notificationsConfig: null,
            solutionsDisabled: [],
            isGeneralDisabled: !isGeneralDisabledSt,
          }
        : {
            notificationsConfig: notificationsConfigSt || null,
            solutionsDisabled: solutionsDisabledSt || [],
            isGeneralDisabled: isGeneralDisabledSt || false,
          };
    const response = await API.invokeToChange({
      REQUEST_HEADERS,
      endpoint: ENDPOINT_CHANGE_CONFIG,
      body: bodyToSet,
    });

    if (response) {
      const { status } = response;

      if (
        status === HTTP_STATUS_UNAUTHORIZED &&
        !isNil(logout) &&
        isFunction(logout)
      ) {
        logout(true);
      } else {
        if (status === HTTP_STATUS_NOT_FOUND) {
          toastContent = (
            <CustomBodyToast
              title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
              msg={null}
            />
          );
        } else {
          const { ok, errorMsg: msg } = response;
          if (!ok) {
            const rightMsg =
              !isNil(msg) && !isEmpty(msg)
                ? msg
                : t("ERROR_RESOURCE_NOT_FOUND_TEXT");
            toastContent = <CustomBodyToast title={rightMsg} />;
          }
        }
      }
    }

    if (toastContent !== null) {
      toast.error(toastContent, {
        containerId: TOAST_NOTIFICATIONS_CONFIG_CONTAINER,
        toastId: "saveChanges",
      });
    }

    set({ isSavingChanges: false, isEditing: false });
    const refetch = get().fetchInitialConfig;
    if (!isNil(refetch) && isFunction(refetch)) {
      refetch({ t, REQUEST_HEADERS, logout });
    }
  },
}));

export { useNotificationStore };
