import React from "react";

import FilterFieldDateDialog from "./types/FilterFieldDateDialog";
import FilterFieldNumberDialog from "./types/FilterFieldNumberDialog";
import FilterFieldTextDialog from "./types/FilterFieldTextDialog";
import FilterFieldPaseAreaTextDialog from "./types/FilterFieldPaseAreaTextDialog";

export default function FilterFieldDialog({
  open,
  handleFilterDialog,
  inheritedOperation,
  inheritedValue,
  deleteFilterConfig,
  type,
  isPasteArea,
  canRemove,
}) {
  if (type === "number") {
    return (
      <FilterFieldNumberDialog
        open={open}
        handleFilterDialog={handleFilterDialog}
        inheritedOperation={inheritedOperation}
        inheritedValue={inheritedValue}
        deleteFilterConfig={deleteFilterConfig}
        canRemove={canRemove}
      />
    );
  } else if (type === "string" || type === "text") {
    if (isPasteArea) {
      return (
        <FilterFieldPaseAreaTextDialog
          open={open}
          handleFilterDialog={handleFilterDialog}
          inheritedOperation={inheritedOperation}
          inheritedValue={inheritedValue}
          deleteFilterConfig={deleteFilterConfig}
          canRemove={canRemove}
        />
      );
    } else {
      return (
        <FilterFieldTextDialog
          open={open}
          handleFilterDialog={handleFilterDialog}
          inheritedOperation={inheritedOperation}
          inheritedValue={inheritedValue}
          deleteFilterConfig={deleteFilterConfig}
          canRemove={canRemove}
        />
      );
    }
  } else if (type === "date") {
    return (
      <FilterFieldDateDialog
        open={open}
        handleFilterDialog={handleFilterDialog}
        inheritedOperation={inheritedOperation}
        inheritedValue={inheritedValue}
        deleteFilterConfig={deleteFilterConfig}
        canRemove={canRemove}
      />
    );
  } else {
    return null;
  }
}
