import React from "react";
import { ToastContainer } from "react-toastify";

export default function GenericToastContainer({ containerId }) {
  return (
    <ToastContainer
      enableMultiContainer
      newestOnTop
      containerId={containerId}
      theme="colored"
      style={{ paddingTop: 60 }}
    />
  );
}
