import React from "react";
import { useStyles, commonIconStyles } from "./BlockingToastStyles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  Paper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_WARNING,
  TOAST_TYPE_INFO,
  TOAST_TYPE_SUCCESS,
} from "../../../util/Constants";
import Draggable from "react-draggable";

function getTypeColor(type) {
  if (type === TOAST_TYPE_ERROR) {
    return ["#e74c3c", "#FFFFFF"];
  } else if (type === TOAST_TYPE_WARNING) {
    return ["#f1c40f", "#FFFFFF"];
  } else if (type === TOAST_TYPE_INFO) {
    return ["#3498db", "#FFFFFF"];
  } else if (type === TOAST_TYPE_SUCCESS) {
    return ["#07bc0c", "#FFFFFF"];
  } else {
    return ["inherit", "#FFFFFF"];
  }
}

function DraggablePaperComponent(props) {
  return (
    <Draggable handle="#blocking-toast-title">
      <Paper {...props} />
    </Draggable>
  );
}

export default function BlockingToast({ open, closeModal, type, title, msg }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      aria-labelledby="blocking-toast"
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          backgroundColor: `${getTypeColor(type)[0]}`,
          borderRight: `1rem solid ${getTypeColor(type)[1]}`,
          color: "white",
          minWidth: 300,
        },
      }}
      PaperComponent={DraggablePaperComponent}
    >
      <DialogTitle id="blocking-toast-title" style={{ cursor: "move" }}>
        <div className={classes.dialogTitleContent}>
          <Icon
            className="fas fa-exclamation-circle"
            style={{ ...commonIconStyles }}
          />
          {title && title !== "" ? (
            <div
              className={`${classes.innerTitleHtml} ${classes.nestedParagraphs} ${classes.nestedSpan} ${classes.nestedDiv}`}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
            />
          ) : (
            t("FORM_ERROR_ACTION")
          )}
        </div>
      </DialogTitle>

      {msg && (
        <DialogContent>
          <div
            className={classes.innerTitleHtml}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg) }}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button style={{ color: "white" }} onClick={closeModal}>
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
