import React from "react";
import { useHistory } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";

// MSAL imports
import { msalInstance } from "../sso/instance/msalInstance";

import { CustomNavigationClient } from "../sso/api/NavigationClient";

export function AADContextProvider({ children }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  msalInstance.setNavigationClient(navigationClient);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}
