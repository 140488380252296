import { useState, useEffect } from "react";

const DEFAULT_PADDING_SIZE = 1;

export const useCompareSize = ({ inputRef }) => {
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare =
      inputRef?.current?.scrollWidth >
      inputRef?.current?.clientWidth + DEFAULT_PADDING_SIZE;

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => window.removeEventListener("resize", compareSize);
    // eslint-disable-next-line
  }, []);

  return { hoverStatus };
};
