import React, { createContext, useState } from "react";

//Initial state context
const initialContextState = {
  selectedIndex: 0,
  selectedChildIndex: undefined,
  selectedGrandIndex: undefined,
  selectedLastIndex: undefined,
  openItems: {},
};

//Creating the context
const DrawerContext = createContext();

const DrawerContextProvider = ({ children }) => {
  const [drawerInfo, setDrawerInfo] = useState(initialContextState);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [headerImg, setHeaderImg] = useState(null);

  const returnToInitialState = () => {
    setDrawerInfo(initialContextState);
  };

  const openOrCloseDrawer = (toSet) => {
    setDrawerOpen(toSet);
  };

  function cleanDrawerState() {
    setDrawerInfo(initialContextState);
  }

  function changeHeaderImg(newImg) {
    setHeaderImg(newImg);
  }

  return (
    <DrawerContext.Provider
      value={{
        drawerInfo,
        setDrawerInfo,
        returnToInitialState,
        openOrCloseDrawer,
        drawerOpen,
        cleanDrawerState,
        headerImg,
        changeHeaderImg,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

//Better exporting all things at bottom when we have more tan one
export { DrawerContext, DrawerContextProvider };
