import useEnvironmentStore from "../core/stores/EnvironmentStore";

const getConfigEnvStore = () => {
  const state = useEnvironmentStore.getState();
  return {
    selectedEnv: state.selectedEnv,
    selectedEnvInfo: state.selectedEnvInfo,
  };
};

export default getConfigEnvStore;
