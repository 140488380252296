//Letters
export const KEY_T = 84;
export const KEY_V = 86;
export const KEY_G = 71;

//Num Keys
export const KEY_0 = 48;
export const KEY_1 = 49;
export const KEY_2 = 50;
export const KEY_3 = 51;
export const KEY_4 = 52;
export const KEY_5 = 53;
export const KEY_6 = 54;
export const KEY_7 = 55;
export const KEY_8 = 56;
export const KEY_9 = 57;

//Num Pad
export const KEY_NUMPAD_0 = 96;
export const KEY_NUMPAD_1 = 97;
export const KEY_NUMPAD_2 = 98;
export const KEY_NUMPAD_3 = 99;
export const KEY_NUMPAD_4 = 100;
export const KEY_NUMPAD_5 = 101;
export const KEY_NUMPAD_6 = 102;
export const KEY_NUMPAD_7 = 103;
export const KEY_NUMPAD_8 = 104;
export const KEY_NUMPAD_9 = 105;

//Specials
export const KEY_ALT = 18;
export const KEY_F1 = 112;
export const KEY_F2 = 113;
export const KEY_F3 = 114;
export const KEY_F4 = 115;
export const KEY_F5 = 116;
export const KEY_F6 = 117;
export const KEY_F7 = 118;
export const KEY_F8 = 119;
export const KEY_F9 = 120;
export const KEY_TAB = 9;
export const KEY_ESC = 27;
export const KEY_ENTER = 13;

//Arrows
export const KEY_ARROW_LEFT = 37;
export const KEY_ARROW_UP = 38;
export const KEY_ARROW_RIGHT = 39;
export const KEY_ARROW_DOWN = 40;
