import React, { useState, useCallback } from "react";
import {
  Box,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxSectionLine: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  titleWithLine: {
    flex: "1 1 100%",
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    cursor: "pointer",
    "&::before": {
      content: '""',
      width: 10,
      marginRight: theme.spacing(1),
      borderBottom: `1px solid`,
    },
    "&::after": {
      content: '""',
      flex: 1,
      marginLeft: theme.spacing(1),
      borderBottom: `1px solid`,
    },
  },
  btnSectionOpen: {
    padding: 6,
    color: "inherit",
  },
}));

export const SectionTitleToggler = ({ id, title, open, toggleSection }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxSectionLine}>
      <Typography
        className={classes.titleWithLine}
        variant="h5"
        onClick={() => toggleSection(id)}
      >
        {title}
      </Typography>
      <IconButton
        className={classes.btnSectionOpen}
        onClick={() => toggleSection(id)}
      >
        <Icon className={open ? "fas fa-chevron-up" : "fas fa-plus"} />
      </IconButton>
    </Box>
  );
};

const useToggleableSections = ({ initialState }) => {
  const [openSections, setOpenSections] = useState(initialState);

  const toggleSection = useCallback((sectionId) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  }, []);

  return {
    openSections,
    toggleSection,
  };
};

export default useToggleableSections;
