import { makeStyles } from "@material-ui/core/styles";

export const iconStyles = {
  fontSize: "1em",
  padding: 1,
  width: "auto",
  height: "auto",
};

export const useStyles = makeStyles((theme) => ({
  filterDialogLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));
