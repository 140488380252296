import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  dropStoredFilters: null,
  dropStoredCollapsed: false,
  dropStoredShowBpmDialog: false,
  isEditingDropForm: false,
  dropStoredBreadcumbRoutes: new Map(),
};

export const createDropSlice = (set, get) => ({
  ...INITIAL_STATE,
  setDropStoredFilters: (newState) =>
    set(() => ({ dropStoredFilters: newState })),
  setDropStoredCollapsed: (newState) =>
    set(() => ({ dropStoredCollapsed: newState })),
  setDropStoredShowBpmDialog: (newState) =>
    set(() => ({ dropStoredShowBpmDialog: newState })),
  addDropBreadcumbRoute: (newRoute) =>
    set((state) => ({
      dropStoredBreadcumbRoutes: state.dropStoredBreadcumbRoutes.set(newRoute, {
        dropStoredFilters: state.dropStoredFilters,
        dropStoredCollapsed: state.dropStoredCollapsed,
        dropStoredShowBpmDialog: state.dropStoredShowBpmDialog,
      }),
    })),
  removeDropBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().dropStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          dropStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          dropStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateDropBreadcumbRoute: (route, newParams) => {
    const actualBC = get().dropStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      dropStoredBreadcumbRoutes: state.dropStoredBreadcumbRoutes.set(route, {
        ...spreadBC,
        ...newParams,
      }),
    }));
  },
  toggleIsEditingDropForm: () => {
    set((state) => ({ isEditingDropForm: !state.isEditingDropForm }));
  },
  clear: () => set(INITIAL_STATE),
});
