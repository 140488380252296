import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Tooltip,
  Icon,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useStyles, iconAuto } from "./styles";
import { ThemeContext } from "../../../../core/providers/ThemeContext";
import { LIGHT_MODE } from "../../../../util/Constants";
import {
  EMPLOYEE_STORE_HOME_ROUTE,
  EMPLOYEE_STORE_PREFIX_ROUTE,
} from "../../../../solutions/employee-store/routes/EmployeeStoreRouter";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { useInteractiveItemStore } from "../../../../core/stores/InteractiveItemStore";
import { LayoutContext } from "../../../../core/providers/LayoutContext";
import { useExternalReportsStore } from "../../../../core/stores/ExternalReportsStore";
import { useReportsStore } from "../../../../core/stores/ReportsStore";

export default function EmployeeStoreButton({ sidebarOpen }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { setTheme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const { returnToInitialState } = useContext(LayoutContext);
  const muiTheme = useTheme();
  const isMdUp = useMediaQuery(muiTheme.breakpoints.up("md"));

  const clearAllInteractiveItemStore = useInteractiveItemStore(
    (state) => state.clearAllInteractiveItemStore
  );
  const clearAllExternalReportsStore = useExternalReportsStore(
    (state) => state.clearAllExternalReportsStore
  );

  const clearAllReportsStore = useReportsStore(
    (state) => state.clearAllReportsStore
  );

  const handleClick = () => {
    setTheme(LIGHT_MODE);
    clearAllReportsStore();
    clearAllExternalReportsStore();
    clearAllInteractiveItemStore();
    returnToInitialState();
    history.push(`${EMPLOYEE_STORE_PREFIX_ROUTE}${EMPLOYEE_STORE_HOME_ROUTE}`);
  };

  const emsEnabled = useMemo(() => {
    return auth?.emsEnabled === true;
  }, [auth]);

  if (!emsEnabled) {
    return null;
  }

  return (
    <>
      <Tooltip title={t("TAB_EMPLOYEE_STORE")} placement="right">
        <div className={classes.envButton}>
          <IconButton
            id="tab_employee_store_button"
            className={classes.normalTab}
            onClick={() => handleClick()}
          >
            <Icon className="fas fa-store" style={iconAuto} />
          </IconButton>
          {sidebarOpen && <Typography>{t("TAB_EMPLOYEE_STORE")}</Typography>}
        </div>
      </Tooltip>
      {isMdUp && <Divider orientation="horizontal" style={{ width: "75%" }} />}
    </>
  );
}
