import { findOptionById, getAllSubOptionIds } from "../../util/store-fn";
import { fetchFiltersApi } from "../../api/filtersApi";
import { EMS_DEFAULT_SORT_BY, EMS_DEFAULT_SORT_DIR } from "../../util/util-ems";
import { isNil } from "lodash";

const createFilterSlice = (set) => ({
  // Estado de Filtros Disponibles
  availableFilters: [],
  loadingFilters: false,
  errorFilters: null,

  // Estado de Filtros Seleccionados
  selectedFilters: {},

  // Estado de Input de Búsqueda
  searchInput: "",

  // Estado de Ordenamiento
  sortBy: EMS_DEFAULT_SORT_BY,
  sortDir: EMS_DEFAULT_SORT_DIR,

  // Acciones de Ordenamiento
  setSorting: (sortBy, sortDir) => {
    set({ sortBy, sortDir });
  },

  // Acciones de Filtros
  fetchFilters: async (headers, logout) => {
    set({ loadingFilters: true });
    try {
      const { data, error } = await fetchFiltersApi(headers, logout);
      if (data) {
        set({ availableFilters: data.filters, loadingFilters: false });
      } else {
        set({ errorFilters: error, loadingFilters: false });
      }
    } catch (error) {
      set({ errorFilters: error.message, loadingFilters: false });
    }
  },

  // Acciones para Seleccionar Filtros
  setFilterSelection: (filterId, optionId, isSelected) => {
    set((state) => {
      const currentSelections = state.selectedFilters[filterId] || [];
      let updatedSelections = [...currentSelections];

      // Buscar la opción en availableFilters
      const filter = state.availableFilters.find((f) => f.id === filterId);
      if (!filter) return {};

      const option = findOptionById(filter.options, optionId);
      if (!option) return {};

      if (isSelected) {
        // Añadir opción
        if (!updatedSelections.includes(optionId)) {
          updatedSelections.push(optionId);
        }

        // Si la opción tiene subOpciones, añadir todas las subOpciones
        if (option.subOptions && option.subOptions.length > 0) {
          const subOptionIds = getAllSubOptionIds(option.subOptions);
          subOptionIds.forEach((subId) => {
            if (!updatedSelections.includes(subId)) {
              updatedSelections.push(subId);
            }
          });
        }

        // Verificar si todas las subOpciones del padre están seleccionadas para seleccionar al padre
        if (option.parentId) {
          const parentId = option.parentId;
          const parentOption = findOptionById(filter.options, parentId);
          if (parentOption) {
            const siblingIds = getAllSubOptionIds(parentOption.subOptions);
            const allSiblingsSelected = siblingIds.every((id) =>
              updatedSelections.includes(id)
            );
            if (allSiblingsSelected && !updatedSelections.includes(parentId)) {
              updatedSelections.push(parentId);
            }
          }
        }
      } else {
        // Eliminar opción
        updatedSelections = updatedSelections.filter((id) => id !== optionId);

        // Si la opción tiene subOpciones, eliminar todas las subOpciones
        if (option.subOptions && option.subOptions.length > 0) {
          const subOptionIds = getAllSubOptionIds(option.subOptions);
          updatedSelections = updatedSelections.filter(
            (id) => !subOptionIds.includes(id)
          );
        }

        // Si se deselecciona una subOpción, deseleccionar al padre
        if (option.parentId) {
          const parentId = option.parentId;
          updatedSelections = updatedSelections.filter((id) => id !== parentId);
        }
      }

      return {
        selectedFilters: {
          ...state.selectedFilters,
          [filterId]: updatedSelections,
        },
      };
    });
  },

  // Acciones para Seleccionar Filtros de Tipo Checkbox
  setCheckboxFilterSelection: (filterId, optionId, isSelected) => {
    set((state) => {
      const currentSelections =
        state.selectedFilters.checkbox?.[filterId] || [];
      let updatedSelections = [...currentSelections];

      // Buscar la opción en availableFilters
      const filter = state.availableFilters.find((f) => f.id === filterId);
      if (!filter) return {};

      const option = findOptionById(filter.options, optionId);
      if (!option) return {};

      if (isSelected) {
        // Añadir opción
        if (!updatedSelections.includes(optionId)) {
          updatedSelections.push(optionId);
        }

        // Si la opción tiene subOpciones, añadir todas las subOpciones
        if (option.subOptions && option.subOptions.length > 0) {
          const subOptionIds = getAllSubOptionIds(option.subOptions);
          subOptionIds.forEach((subId) => {
            if (!updatedSelections.includes(subId)) {
              updatedSelections.push(subId);
            }
          });
        }

        // Verificar si todas las subOpciones del padre están seleccionadas para seleccionar al padre
        if (option.parentId) {
          const parentId = option.parentId;
          const parentOption = findOptionById(filter.options, parentId);
          if (parentOption) {
            const siblingIds = getAllSubOptionIds(parentOption.subOptions);
            const allSiblingsSelected = siblingIds.every((id) =>
              updatedSelections.includes(id)
            );
            if (allSiblingsSelected && !updatedSelections.includes(parentId)) {
              updatedSelections.push(parentId);
            }
          }
        }
      } else {
        // Eliminar opción
        updatedSelections = updatedSelections.filter((id) => id !== optionId);

        // Si la opción tiene subOpciones, eliminar todas las subOpciones
        if (option.subOptions && option.subOptions.length > 0) {
          const subOptionIds = getAllSubOptionIds(option.subOptions);
          updatedSelections = updatedSelections.filter(
            (id) => !subOptionIds.includes(id)
          );
        }

        // Si se deselecciona una subOpción, deseleccionar al padre
        if (option.parentId) {
          const parentId = option.parentId;
          updatedSelections = updatedSelections.filter((id) => id !== parentId);
        }
      }

      return {
        selectedFilters: {
          ...state.selectedFilters,
          checkbox: {
            ...state.selectedFilters.checkbox,
            [filterId]: updatedSelections,
          },
        },
      };
    });
  },

  // Acciones para Seleccionar Filtros de Tipo Range
  setRangeFilterSelection: (filterId, range) => {
    const validatedRange = {
      min:
        range.min !== undefined && range.min <= (range.max || Infinity)
          ? range.min
          : undefined,
      max:
        range.max !== undefined && range.max >= (range.min || -Infinity)
          ? range.max
          : undefined,
    };

    const newState = {};

    if (!isNil(validatedRange?.min)) {
      Object.assign(newState, { [`${filterId}From`]: validatedRange.min });
    }

    if (!isNil(validatedRange?.max)) {
      Object.assign(newState, { [`${filterId}To`]: validatedRange.max });
    }

    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        range: {
          ...state.selectedFilters.range,
          ...newState,
        },
      },
    }));
  },

  // Acciones para Seleccionar Filtros de Tipo Switch
  setSwitchFilterSelection: (filterId, checked) => {
    const newState = { [filterId]: checked };

    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        switch: {
          ...state.selectedFilters.switch,
          ...newState,
        },
      },
    }));
  },

  // Acciones para Resetear Filtros
  resetFilters: () => {
    set({ selectedFilters: {} });
  },

  // Acciones para Input de Búsqueda
  setSearchInput: (input) => {
    set({ searchInput: input });
  },
});

export default createFilterSlice;
