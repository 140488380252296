import { makeStyles } from "@material-ui/core/styles";
import { BREAKPOINT_MD } from "../../../../util/Constants";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? "center"
        : "flex-start",
    width: "100%",
    minHeight: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? 30
        : "auto",
    flexDirection: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? "row"
        : "column",
    lineHeight: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? "default"
        : "1.3",
    marginBottom: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? "default"
        : 7,
  },
  label: {
    marginRight: "5px",
    //color: `${theme.palette.form.labelTextColor}BB`,
    //color: `inherit`,
    fontSize: "1.1em",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    width: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? "30%"
        : "100%",
    height: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? 30
        : "auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "unset",
      textDecoration: "underline",
    },
  },
  children: {
    width: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? "70%"
        : "100%",
    display: "flex",
    justifyContent: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD
        ? "flex-end"
        : "flex-start",
    alignItems: "center",
    height: (props) =>
      props.wd && props.wd.width && props.wd.width > BREAKPOINT_MD && "auto",
  },
}));
