import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  CloseOutlined,
  OpenInNew,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import { useEffect } from "react";
import useBanner from "../store/hooks/useBanner";
import EmployeeStoreContactUs from "../layout/footer/components/EmployeeStoreContactUs";
import EmployeeStorePolicy from "../layout/footer/components/EmployeeStorePolicy";
import useFilters from "../store/hooks/useFilters";
import {
  EMS_FILTER_TYPES
} from "../util/util-ems";

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  footerBanner: {
    width: "100%",
    height: "auto",
    maxHeight: 180,
    objectFit: "cover",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    boxShadow: "-3px -6px 10px 1px #0000004D",
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    left: 10,
    backgroundColor: "#FFFFFF",
    border: "3px solid",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  openBtn: {
    position: "absolute",
    bottom: 5,
    left: 30,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      left: 10,
    },
  },
  navigationButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
  },
  prevButton: {
    left: 10,
  },
  nextButton: {
    right: 10,
  },
}));

const HomeFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const {
    banner,
    bannerVisible,
    setBannerVisible,
    loadBanner,
    loadingBanner,
    timeLeft,
    goToNextBanner,
    goToPreviousBanner,
    totalBanners,
  } = useBanner();

  const { 
    handleCheckboxFilterChange,  
    handleSearchInputChange,
    handleApplyFilters, 
  } = useFilters();

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    loadBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(src);
        img.onerror = () => reject();
        img.src = src;
      });
    };

    const tryLoadImage = async () => {
      try {
        if (banner?.imageUrl) {
          await loadImage(banner?.imageUrl);
          setImageSrc(banner?.imageUrl);
          return;
        }
      } catch (_) {
        setImageSrc(null);
      }
    };

    tryLoadImage();
  }, [banner]);

  
  const handleBannerFilter = (banner) => {
    switch(banner.type) {
      case EMS_FILTER_TYPES.ean: 
      handleSearchInputChange(banner.value);
      handleApplyFilters();
      break;
      case EMS_FILTER_TYPES.catalogo:
      handleCheckboxFilterChange('catalogs', banner.value, true)
      break;
      case EMS_FILTER_TYPES.categoria:
      handleCheckboxFilterChange('categories', banner.value, true)
      break;
      default:
      break;
    }
  };

  return (
    <Box className={classes.bannerContainer}>
      <Grid container spacing={1}>
        {bannerVisible && !loadingBanner && imageSrc && (
          <Grid xs={12} item>
            <Box position="relative">
              <IconButton
                size="small"
                onClick={() => setBannerVisible(false)}
                className={classes.closeBtn}
              >
                <CloseOutlined />
              </IconButton>

              <>
                <img
                  src={imageSrc}
                  alt="Footer Banner"
                  className={classes.footerBanner}
                  onClick={() => handleBannerFilter(banner)}
                />
                {totalBanners > 1 && (
                  <>
                    <IconButton
                      className={`${classes.navigationButton} ${classes.prevButton}`}
                      onClick={goToPreviousBanner}
                    >
                      <ChevronLeft />
                    </IconButton>
                    <IconButton
                      className={`${classes.navigationButton} ${classes.nextButton}`}
                      onClick={goToNextBanner}
                    >
                      <ChevronRight />
                    </IconButton>
                  </>
                )}
              </>

              {imageSrc && timeLeft > 0 && (
                <Box
                  position="absolute"
                  top={10}
                  right={10}
                  bgcolor="#FFF"
                  borderRadius="50%"
                  width={35}
                  height={35}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    bgcolor="#FFF"
                    width={"100%"}
                    height={"100%"}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                    border={"3px dashed"}
                  >
                    <Typography variant="h6">{timeLeft}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            paddingBottom="10px"
            position="relative"
          >
            {!bannerVisible && (
              <Button
                size="small"
                onClick={() => setBannerVisible(true)}
                className={classes.openBtn}
                startIcon={<OpenInNew />}
                variant="text"
                disabled={loadingBanner}
              >
                {isMdUp ? "Abrir banner" : ""}
              </Button>
            )}

            <Grid container spacing={8} justifyContent="center">
              <Grid item>
                <EmployeeStorePolicy footer={true} />
              </Grid>
              <Grid item>
                <EmployeeStoreContactUs />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeFooter;
