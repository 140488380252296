import React from "react";

export default function TabContainer(props) {
  const { children, value, index, bg, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ backgroundColor: bg || "inherit", height: "90%", width: "100%" }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
