import React, { useState, useEffect } from "react";
import { Icon, IconButton, Typography } from "@material-ui/core";
import { filter, includes, isEmpty, isNil } from "lodash";
import clsx from "clsx";

import FilterFieldDialog from "../dialogs/FilterFieldDialog";
import { commonIcon, useStyles } from "./FilterFieldStyles";

export default function FilterFieldButton({
  field,
  type,
  title,
  index,
  handleChangeFilterValue,
  owner,
  defaultValue,
  defaultOperation,
  isLimit,
  canRemove,
  injectsInto,
  availableFilters,
  blockInteraction,
  isRequiredAndMissing,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [operation, setOperation] = useState(null);

  const filterKey = `${field}-${index}`;

  useEffect(() => {
    setValue(defaultValue);
    setOperation(defaultOperation);
  }, [defaultValue, defaultOperation]);

  function handleOpen() {
    setOpen(true);
  }

  function handleFilterDialog(filterValue, cancel, operation) {
    if (!cancel) {
      setValue(filterValue);
      setOperation(operation);
      if (
        !isNil(injectsInto) &&
        !isEmpty(injectsInto) &&
        !isNil(availableFilters) &&
        !isEmpty(availableFilters)
      ) {
        const injectedFilters = filter(availableFilters, (x) =>
          includes(injectsInto, x?.owner)
        );
        if (!isNil(injectedFilters) && !isEmpty(injectedFilters)) {
          const injectedFiltersValues = injectedFilters.map((x, i) => {
            return {
              value: filterValue,
              field: x?.field,
              type: x?.type,
              key: `${x?.field}-${i}`,
              operation,
              owner: x?.owner,
              isLimit: x?.isLimit,
            };
          });
          const newFiltersArrayToSet = [
            {
              value: filterValue,
              field,
              type,
              key: filterKey,
              operation,
              owner,
              isLimit,
            },
            ...injectedFiltersValues,
          ];
          handleChangeFilterValue(newFiltersArrayToSet);
        } else {
          handleChangeFilterValue({
            value: filterValue,
            field,
            type,
            key: filterKey,
            operation,
            owner,
            isLimit,
          });
        }
      } else {
        handleChangeFilterValue({
          value: filterValue,
          field,
          type,
          key: filterKey,
          operation,
          owner,
          isLimit,
        });
      }
    }
    setOpen(false);
  }

  // useEffect(() => {
  //   handleChangeFilterValue({
  //     value,
  //     field,
  //     type,
  //     key: filterKey,
  //     operation,
  //     owner,
  //     isLimit,
  //   });
  //   //eslint-disable-next-line
  // }, [value, operation]);

  const deleteFilterConfig = (e) => {
    e.stopPropagation();
    handleFilterDialog(null, false, null);
  };

  const opAndVal = operation && value ? `${operation} ${value}` : "";
  
  return (
    <>
      <FilterFieldDialog
        open={open}
        handleFilterDialog={handleFilterDialog}
        type={type}
        title={title}
        field={field}
        inheritedOperation={operation}
        inheritedValue={value}
        deleteFilterConfig={deleteFilterConfig}
        canRemove={canRemove}
      />
      <button
        key={filterKey}
        className={clsx(
          value
            ? blockInteraction === true
              ? classes.filterButtonWithValueBlocked
              : classes.filterButtonWithValue
            : classes.filterButton,
          isNil(value) && isRequiredAndMissing ? classes.requiredMissing : ""
        )}
        onClick={() => handleOpen()}
        disabled={blockInteraction === true}
      >
        {!isNil(value) && canRemove !== false && (
          <Icon
            className="fas fa-filter"
            style={{ ...commonIcon, fontSize: "12px" }}
          />
        )}
        {isNil(value) && isRequiredAndMissing && (
          <Icon
            className="fas fa-exclamation-triangle"
            style={{ ...commonIcon, fontSize: "12px", color: "#f44336" }}
          />
        )}
        <Typography
          style={blockInteraction === true ? { fontWeight: "bold" } : null}
        >
          {title} {opAndVal}
        </Typography>
        {!isNil(value) &&
          blockInteraction !== true &&
          (isNil(canRemove) || canRemove === true) && (
            <IconButton
              onClick={deleteFilterConfig}
              className={classes.removeFilterButton}
            >
              <Icon className="fas fa-times" style={commonIcon} />
            </IconButton>
          )}
      </button>
    </>
  );
}
