//Generic
import { CliShowData } from "./generic/CliShowData";

//PLV
import { PlvPrintLabelService } from "./plv/PlvPrintLabelService";
import { PlvGenFile } from "./plv/PlvGenFile";

//Fenix
import { FenGeneradorJson } from "./fenix/FenGeneradorJson";
import { CliPrintZPL } from "./generic/CliPrintZPL";
import { CliGenFilesGenerator } from "./generic/CliGenFilesGenerator";

//Generic
const CLI_SHOW_DATA = "cli_showdata";
const CLI_PRINT_ZPL = "cli_print_zpl";
const CLI_EXE_GEN_FILES = "exe_gen_files_generator";

//PLV - New services names
const CLI_PLV_IMP_ETIQ = "cli_plvimpetiq";
const CLI_PLV_GEN_FILE = "cli_plvgenfile";

//Fenix
const CLI_FENIX_GEN_JSON = "cli_fengeneradorjson";

//Mapping of names and functions
export const clientServices = {
  [CLI_SHOW_DATA]: CliShowData,
  [CLI_PLV_IMP_ETIQ]: PlvPrintLabelService,
  [CLI_PLV_GEN_FILE]: PlvGenFile,
  [CLI_FENIX_GEN_JSON]: FenGeneradorJson,
  [CLI_PRINT_ZPL]: CliPrintZPL,
  [CLI_EXE_GEN_FILES]: CliGenFilesGenerator,
};

//SelCLI
export const SEL_CLI_PASTE_AREA = "seleccion_cli_area_generica";

export const SEL_GENERICA = "seleccion_generica";
