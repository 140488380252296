import { useCallback, useContext } from "react";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import useEmployeeStoreState from "../EmployeeStoreState";
import { AuthContext } from "../../../../core/providers/AuthContext";

const useProducts = () => {
  const {
    products,
    fetchProducts,
    loadingProducts,
    errorProducts,
    resetPagination,
  } = useEmployeeStoreState((state) => ({
    products: state.products,
    fetchProducts: state.fetchProducts,
    loadingProducts: state.loadingProducts,
    errorProducts: state.errorProducts,
    resetPagination: state.resetPagination,
  }));

  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const loadProducts = useCallback(() => {
    fetchProducts(headers, logout);
  }, [fetchProducts, headers, logout]);

  return {
    products,
    loadProducts,
    loadingProducts,
    errorProducts,
    resetPagination,
  };
};

export default useProducts;
