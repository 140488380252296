import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
    backgroundColor: "transparent",
    [theme.breakpoints.up("md")]: {
      width: "45vw",
      maxWidth: "700px",
    },
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
    },
  },
  containerChild: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "95%",
    height: "calc(50vh - 50px)",
  },
  listContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    flex: 3,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      width: "42vw",
      maxWidth: "650px",
    },
    [theme.breakpoints.down("md")]: {
      width: "47vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "67vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "77vw",
    },
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleText: {
    fontWeight: "700",
    fontSize: 16,
  },
  addItemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    border: `1px dashed ${theme.palette.content.mainColor}`,
    borderRadius: 50,
    color: theme.palette.content.mainColor,
  },
  addItemContainerDisabled: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    borderRadius: 50,
  },
  fileList: {
    overflowY: "auto",
    height: "80%",
    marginBottom: 10,
    border: "1px dashed grey",
    borderRadius: "10px",
    width: "100%",
    padding: 5,
  },
  fileListItem: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    whiteSpace: "pre",
    borderBottom: `1px solid ${theme.palette.text.primary}55`,
    marginBottom: 10,
    padding: 5,
    gap: 15,
  },
  actionContainer: {
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: 6,
    gap: 10,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: 10,
    },
  },
  filePreviewToolbar: {
    width: "100%",
    height: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
    cursor: "pointer",
  },
  previewNameTypo: {
    width: "100%",
    height: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  filePreview: {
    width: "100%",
    height: "calc(100% - 50px)",
  },
  fileListItemText: {
    marginLeft: 5,
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "nowrap",
      maxWidth: "40vw",
      marginLeft: 0,
    },
  },
  radioAndName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    minWidth: "50%",
  },
  titleActionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  actionsPrevContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonActionPrev: {
    padding: 5,
  },
  emptyPlaceholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  emptyPlaceholderTypo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    marginTop: "10px",
    marginBottom: 16,
  },
  disableAddMaxFilesTypo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "1rem",
    marginBottom: 6,
    color: `${theme.palette.semaphore.yellow}`,
    fontStyle: "italic",
    paddingInline: 12
  },
  fileListRow: {
    display: "flex",
    gap: 12,
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 3,
    [theme.breakpoints.down(500)]: {
      display: "none",
    },
  },
  fileContextInfoContainer: {
    display: "flex",
    gap: 3,
    alignItems: "center",
  },
  contFi: {
    maxWidth: "inherit",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  readyBtn: {
    color: theme.palette.content.mainColor,
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  uploadBtn: {
    backgroundColor: `${theme.palette.content.mainColor}DD`,
    color: "#FFF",
    width: 200,
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: `${theme.palette.content.mainColor}DD`,
    },
    [theme.breakpoints.up("md")]: {
      width: 400,
    },
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  uploadBtnDisabled: {
    backgroundColor: `${theme.palette.grey}`,
    color: "#FFF",
    width: 200,
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: `${theme.palette.grey}`,
    },
    [theme.breakpoints.up("md")]: {
      width: 400,
    },
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
