import { useEffect, useState } from "react";
import { LOCALE_ENGLISH, LOCALE_PROP } from "../../util/Constants";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash";

export const useLocale = () => {
  const [locale, setLocale] = useState(LOCALE_ENGLISH);
  const { i18n } = useTranslation();

  const changeLocale = (locale) => {
    if (!isNil(locale)) {
      window.localStorage.setItem(LOCALE_PROP, locale);
      setLocale(locale);
      i18n.changeLanguage(locale);
    }
  };

  useEffect(() => {
    const cachedLocale = window.localStorage.getItem(LOCALE_PROP);
    cachedLocale && setLocale(cachedLocale);
  }, []);

  return [locale, changeLocale];
};
