import React from "react";
import { Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import {
  ERROR_NOTHING_TO_SHOW_DEF_ICON,
  ERROR_NOTHING_TO_SHOW_DEF_ICON_SIZE,
  ERROR_NOTHING_TO_SHOW_DEF_TEXT_SIZE,
  ERROR_NOTHING_TO_SHOW_DEF_TEXT_WEIGHT,
} from "../../util/Constants";

export const useStyles = makeStyles(() => ({
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function NothingToShow({ msg }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.content}>
        <Icon
          className={ERROR_NOTHING_TO_SHOW_DEF_ICON}
          style={{
            fontSize: ERROR_NOTHING_TO_SHOW_DEF_ICON_SIZE,
            color: theme.palette.drawer.item,
          }}
        />
        <p
          style={{
            color: theme.palette.drawer.item,
            fontSize: ERROR_NOTHING_TO_SHOW_DEF_TEXT_SIZE,
            fontWeight: ERROR_NOTHING_TO_SHOW_DEF_TEXT_WEIGHT,
          }}
        >
          {msg || t("TABLE_PANEL_NOTHING_TO_SHOW")}
        </p>
      </div>
    </div>
  );
}
