import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { makeStyles } from "@material-ui/core";
import { IconButton, Icon, Typography } from "@material-ui/core";
import {
  KEY_ARROW_DOWN,
  KEY_ARROW_LEFT,
  KEY_ARROW_RIGHT,
  KEY_ARROW_UP,
} from "../../../../util/UtilKeys";
import {
  DEFAULT_ZOOM_VALUE,
  usePDFZoom,
} from "../../../../core/hooks/usePDFZoom";
import { useTranslation } from "react-i18next";

const commonPdfPagination = {
  width: "100%",
  padding: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const commonIconStyle = {
  width: "auto",
  height: "auto",
  padding: 1,
  color: "#FFF",
};

const commonIconDisabled = {
  ...commonIconStyle,
  color: "lightgrey",
};

const useStyles = makeStyles((theme) => ({
  pdfContainer: {
    display: "flex",
    padding: "200px 0px",
    maxWidth: "80vw",
  },
  pdfPagination: {
    ...commonPdfPagination,
    backgroundColor: theme.palette.content.mainColor,
    color: theme.palette.content.mainColorContrastText,
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    gap: 10,
    marginTop: 50,
    zIndex: 1000,
  },
  fieldPdfPagination: {
    ...commonPdfPagination,
    backgroundColor: theme.palette.content.mainColor,
    color: theme.palette.content.mainColorContrastText,
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
  },
}));

function Pagination(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    pageNumber,
    numPages,
    handlePrevPage,
    handleNextPage,
    isFieldPreview,
    increaseZoom,
    decreaseZoom,
    isMinusEnabled,
    isExternalDialog,
  } = props;

  useEffect(() => {
    const keyPressEvent = (e) => {
      const { keyCode } = e;
      if (
        (keyCode === KEY_ARROW_LEFT || keyCode === KEY_ARROW_UP) &&
        pageNumber > 1
      ) {
        handlePrevPage();
      }

      if (
        (keyCode === KEY_ARROW_RIGHT || keyCode === KEY_ARROW_DOWN) &&
        pageNumber < numPages
      ) {
        handleNextPage();
      }
    };

    window.addEventListener("keydown", keyPressEvent);

    return () => {
      window.removeEventListener("keydown", keyPressEvent);
    };
  }, [pageNumber, numPages, handlePrevPage, handleNextPage]);

  return (
    <div
      className={
        isFieldPreview ? classes.fieldPdfPagination : classes.pdfPagination
      }
      style={{ marginTop: isExternalDialog && 0 }}
    >
      {numPages > 1 && (
        <div className={classes.rowContainer}>
          <Typography variant="h6">{t("PDF_VIEWER_PAGES")}</Typography>
          <IconButton onClick={handlePrevPage} disabled={pageNumber <= 1}>
            <Icon className="fas fa-chevron-left" style={commonIconStyle} />
          </IconButton>
          <Typography>
            {pageNumber} / {numPages}
          </Typography>
          <IconButton
            disabled={pageNumber >= numPages}
            onClick={handleNextPage}
          >
            <Icon className="fas fa-chevron-right" style={commonIconStyle} />
          </IconButton>
        </div>
      )}

      <div className={classes.rowContainer}>
        <Typography variant="h6">{t("PDF_VIEWER_ZOOM")}</Typography>
        <IconButton disabled={!isMinusEnabled} onClick={decreaseZoom}>
          <Icon
            className="fas fa-search-minus"
            style={!isMinusEnabled ? commonIconDisabled : commonIconStyle}
          />
        </IconButton>
        <IconButton onClick={increaseZoom}>
          <Icon className="fas fa-search-plus" style={commonIconStyle} />
        </IconButton>
      </div>
    </div>
  );
}

export default function PDFViewer(props) {
  const { filePath, isFieldPreview, isExternalDialog } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { zoom, isMinusEnabled, increaseZoom, decreaseZoom } = usePDFZoom();

  const classes = useStyles();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlePrevPage = useCallback(() => {
    setPageNumber((prev) => prev - 1);
  }, []);

  const handleNextPage = useCallback(() => {
    setPageNumber((prev) => prev + 1);
  }, []);

  const translateYState = useMemo(() => {
    if (zoom > DEFAULT_ZOOM_VALUE) {
      const moveCalculated = zoom > 2 ? zoom * 300 : zoom * 200;
      return {
        transform: `translateY(${moveCalculated}px)`,
        paddingBottom: 50,
      };
    } else {
      return {};
    }
  }, [zoom]);

  return (
    <div
      className={classes.pdfContainer}
      style={{ paddingTop: isExternalDialog && 50 }}
    >
      <Pagination
        pageNumber={pageNumber}
        numPages={numPages}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        isFieldPreview={isFieldPreview}
        isMinusEnabled={isMinusEnabled}
        increaseZoom={increaseZoom}
        decreaseZoom={decreaseZoom}
        isExternalDialog={isExternalDialog}
      />
      <div style={translateYState}>
        <Document
          file={filePath}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{ scrollX: true, renderer: "svg" }}
        >
          <Page pageNumber={pageNumber} scale={zoom} />
        </Document>
      </div>
    </div>
  );
}
