import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import { commonIconStyles } from "./ShoppingCartTestSplitBtn";
import usePayments from "../../../store/hooks/usePayments";
import { PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS } from "../../../util/util-ems";
import useSimulation from "../../../store/hooks/useSimulation";
import { EMS_REDIRECT_PAYMENT_URL } from "../../../routes/EmployeeStoreRouter";
import { isNil } from "lodash";
import useCart from "../../../store/hooks/useCart";

const useStyles = makeStyles(() => ({
  btnTest: {
    marginInline: 8,
    cursor: "pointer",
    border: "1px solid",
  },
  boxTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
  },
  tableCell: {
    padding: "8px",
    border: "1px solid #ddd",
    fontSize: 14,
  },
  tableCellBold: {
    padding: "8px",
    border: "1px solid #ddd",
    fontWeight: "bold",
    fontSize: 14,
  },
}));

export default function ShoppingCartTestCreatePayment() {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const { emsCartId } = useCart();
  const { createPayment } = usePayments();
  const { parentSimulationId, simulationsIds, simulationTotalizers } =
    useSimulation();

  const handleTestCreatePayment = async () => {
    setIsLoading(true);
    try {
      const response = await createPayment({
        paymentSystem: PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS,
        body: {
          simulationIds: simulationsIds,
          returnUrl: EMS_REDIRECT_PAYMENT_URL,
          totalAmount: simulationTotalizers.totalNF,
          emsIdCompra: emsCartId,
          parentSimulationId: parentSimulationId,
        },
      });
      setData(response?.data);
      setIsDialogOpen(true);
    } catch (_) {
      setData(null);
      setIsDialogOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  const transactionOk = data?.ok === true;
  const tokenWs = data?.token_ws;
  const url = data?.url;
  const errorMsg = data?.msg;

  return (
    <>
      <Button
        onClick={handleTestCreatePayment}
        disabled={isLoading}
        startIcon={
          <Icon className="fas fa-money-check-alt" style={commonIconStyles} />
        }
        className={classes.btnTest}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Crear transacción de pago"
        )}
      </Button>
      <Dialog open={isDialogOpen && !isNil(data)} maxWidth="md">
        <DialogTitle disableTypography={true}>
          {transactionOk ? (
            <Box className={classes.boxTitle}>
              <Icon
                className="fas fa-check-circle"
                style={{ ...commonIconStyles, color: "green", fontSize: 21 }}
              />
              <Typography variant="h4">
                Transacción creada correctamente en{" "}
                {PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS}
              </Typography>
            </Box>
          ) : (
            <Box className={classes.boxTitle}>
              <Icon
                className="fas fa-times-circle"
                style={{ ...commonIconStyles, color: "red", fontSize: 21 }}
              />
              <Typography variant="h4">
                No fue posible crear transacción en{" "}
                {PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS}
              </Typography>
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          {transactionOk ? (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCellBold}>
                    token_ws
                  </TableCell>
                  <TableCell className={classes.tableCell}>{tokenWs}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellBold}>url</TableCell>
                  <TableCell className={classes.tableCell}>{url}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Typography variant="subtitle1">Error: {errorMsg}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
