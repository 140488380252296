import React, { createContext, useState } from "react";

//Creating the context
const WorkspaceContext = createContext();

const WorkspaceContextProvider = ({ children }) => {
  const [formHistory, setFormHistory] = useState([]);

  function addToFormHistory(newForm) {
    if (newForm && newForm.route) {
      const indexIn = formHistory.findIndex(
        (x) => x && x.route === newForm.route
      );
      if (indexIn > -1) {
        const newArray = [...formHistory];
        newArray.splice(indexIn, 1);
        setFormHistory([newForm, ...newArray]);
      } else {
        setFormHistory((prev) => [newForm, ...prev]);
      }
    }
  }

  return (
    <WorkspaceContext.Provider
      value={{
        formHistory,
        addToFormHistory,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

//Better exporting all things at bottom when we have more tan one
export { WorkspaceContext, WorkspaceContextProvider };
