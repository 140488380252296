import create from "zustand";
import { persist } from "zustand/middleware";

import createBagSlice from "./slices/createBagSlice";
import createCheckoutSlice from "./slices/createCheckoutSlice";
import createBannerSlice from "./slices/createBannerSlice";
import createQuickViewSlice from "./slices/createQuickViewSlice";
import createProductSlice from "./slices/createProductSlice";
import createFavoriteSlice from "./slices/createFavoriteSlice";
import createFilterSlice from "./slices/createFilterSlice";
import createInfoSlice from "./slices/createInfoSlice";
import createHistorySlice from "./slices/createHistorySlice";

const EMS_STORE_STORAGE_NAME = "ems-store";

const useEmployeeStoreState = create(
  persist(
    (set, get) => ({
      ...createBagSlice(set, get),
      ...createCheckoutSlice(set, get),
      ...createBannerSlice(set, get),
      ...createQuickViewSlice(set, get),
      ...createProductSlice(set, get),
      ...createFavoriteSlice(set, get),
      ...createFilterSlice(set, get),
      ...createInfoSlice(set, get),
      ...createHistorySlice(set, get),
    }),
    {
      name: EMS_STORE_STORAGE_NAME,
      partialize: (state) => ({
        favorites: state.favorites,
        bannerVisible: state.bannerVisible,
        emsCartId: state.emsCartId,
      }),
    }
  )
);

// Sincroniza el estado entre pestañas
window.addEventListener("storage", (event) => {
  if (event?.key === EMS_STORE_STORAGE_NAME) {
    // Vuelve a hidratar el estado en la tienda Zustand
    useEmployeeStoreState.persist.rehydrate();
  }
});

export default useEmployeeStoreState;
