import { makeStyles } from "@material-ui/core/styles";
import { BREAKPOINT_MD } from "../../util/Constants";
import {
  appBarHeight,
  borderRadiusContainer,
  drawerWidth,
} from "./LayoutStylesGlobals";

export const iconAuto = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    width: "100vw",
    height: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.container.backgroundColor,
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      height: "100vh",
    },
  },
  fetchingRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 20px)",
    width: "calc(100vw - 20px)",
    borderRadius: "20px",
    backgroundColor: theme?.palette?.content?.backgroundColor,
    position: "relative",
    border: `1px solid ${theme?.palette?.container?.backgroundColor}`,
  },
  fetchingContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  contentRoot: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: borderRadiusContainer,
    position: "relative",
  },
  drawerContainer: {
    width: ({ openDrawer }) => (openDrawer ? drawerWidth : 0),
    flexShrink: 0,
    borderRadius: borderRadiusContainer,
  },
  typo: {
    color: theme.palette.content.titleTextColor,
    fontWeight: "bold",
    textShadow: `3px 3px 6px ${theme.palette.content.titleTextShadow}`,
  },
  paperAnchorDockedLeft: {
    borderRight: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "1em",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  select: {
    fontSize: "1.3em",
  },
  icon: {
    //fill: theme.palette.header.iconColor,
  },
  companyDiv: {
    height: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 2,
    color: theme.palette.drawer.item,
    marginBottom: "15px",
    "&::before": {
      content: '""',
      position: "absolute",
      right: 0,
      bottom: 0,
      height: "1px",
      width: "95%",
      borderBottom: "1px solid white",
    },
  },
  solutionContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,
    height: "auto",
    backgroundColor: `${theme.palette.content.backgroundColor}`,
    width: ({ openDrawer }) =>
      openDrawer && openDrawer === true
        ? `calc(100% - ${drawerWidth + appBarHeight}px)`
        : "100%",
    marginTop: appBarHeight * 2,
    minHeight: `calc(100vh - ${appBarHeight * 2}px)`,
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      marginTop: appBarHeight,
      minHeight: "auto",
      flexDirection: "column",
      maxHeight: `calc(100% - ${appBarHeight}px)`,
    },
  },
  notificationBadge: {
    colorSecondary: {
      backgroundColor: "red",
    },
  },
  dsuiteLogo: {
    maxWidth: 40,
    maxHeight: 40,
    cursor: "pointer",
    marginRight: 10,
  }
}));
