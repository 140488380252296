import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { useTheme } from "@material-ui/core/styles";
import * as _ from "lodash";
import { v4 as uuid } from "uuid";

import { useStyles, commonIconStyles } from "./MenuDrawerStyles";
import { DrawerContext } from "../../core/providers/DrawerContext";
import { lightenDarkenColor } from "../../util/UtilStyles";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RefreshMenuButton from "./components/RefreshMenuButton";
import SearchMenuBar from "./components/SearchMenuBar";
import { getLocaleText } from "../../util/UtilTraduction";
import { useRequestHeaders } from "../../core/hooks/useRequestHeaders";

const MODE_PROD = "prod";
const MODE_VIEW = "view";

const EXCLUDE_WITH_EXECUTABLE_CHILD = true;
function hasAtLeastOneExecutableChild(item) {
  let result = false;

  // early return
  if (EXCLUDE_WITH_EXECUTABLE_CHILD === false) {
    return true;
  }

  if (
    !_.isNil(item) &&
    !_.isNil(item?.children) &&
    !_.isEmpty(item?.children)
  ) {
    for (const child of item.children) {
      if (!_.isNil(child)) {
        const { inProduction, isKpi, children, formPath, formName, route } =
          child;

        const isFormItem = !_.isNil(formPath) && !_.isNil(formName);
        const isRouteable = !_.isNil(route);
        const notKpi = _.isNil(isKpi) || isKpi === false;

        if (
          inProduction === true &&
          !_.isNil(children) &&
          !_.isEmpty(children)
        ) {
          result = true;
          break;
        }

        if (
          inProduction === true &&
          (_.isNil(children) || _.isEmpty(children))
        ) {
          result = isFormItem || (isRouteable && notKpi);

          if (result === true) {
            break;
          }
        }
      }
    }
  } else {
    result = true;
  }

  return result;
}

export default function MenuDrawer(props) {
  const {
    //prod
    drawerItems,
    handleClick,
    handleChildClick,
    handleGrandClick,
    handleLastClick,
    mode,
    refreshMenu,
    //view only
    selectedIndexV,
    selectedChildIndexV,
    selectedGrandIndexV,
    selectedLastIndexV,
    openItemsV,
    pushPage,
    isMobile,
  } = props;

  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [openItems, setOpenItems] = useState({});
  const { drawerInfo } = useContext(DrawerContext);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedChildIndex, setselectedChildIndex] = useState("");
  const [selectedGrandIndex, setselectedGrandIndex] = useState("");
  const [selectedLastIndex, setSelectedLastIndex] = useState("");

  const REQUEST_HEADERS = useRequestHeaders();

  //Show menu items
  const [menuItems, setMenuItems] = useState(drawerItems || []);

  useEffect(() => {
    if (drawerItems && mode === MODE_VIEW) {
      setMenuItems(drawerItems);
    }
  }, [drawerItems, mode]);

  useEffect(() => {
    if (drawerInfo && mode === MODE_PROD) {
      setSelectedIndex(drawerInfo.selectedIndex);
      setselectedChildIndex(drawerInfo.selectedChildIndex);
      setselectedGrandIndex(drawerInfo.selectedGrandIndex);
      setSelectedLastIndex(drawerInfo.selectedLastIndex);
      setOpenItems(drawerInfo.openItems);
    } else if (drawerInfo && mode === MODE_VIEW) {
      setSelectedIndex(selectedIndexV);
      setselectedChildIndex(selectedChildIndexV);
      setselectedGrandIndex(selectedGrandIndexV);
      setSelectedLastIndex(selectedLastIndexV);
      setOpenItems(openItemsV);
    }
  }, [
    drawerInfo,
    selectedIndexV,
    selectedChildIndexV,
    selectedGrandIndexV,
    selectedLastIndexV,
    openItemsV,
    mode,
  ]);

  const childColorList = useMemo(() => {
    return lightenDarkenColor(theme.palette?.drawer.backgroundColor, 20);
  }, [theme]);

  const grandColorList = useMemo(() => {
    return lightenDarkenColor(theme.palette?.drawer.backgroundColor, 40);
  }, [theme]);

  const lastColorList = useMemo(() => {
    return lightenDarkenColor(theme.palette?.drawer.backgroundColor, 60);
  }, [theme]);
  /**
   * Calls refresh menu to get new one on server
   */
  const handleRefreshMenu = () => {
    refreshMenu({ t, REQUEST_HEADERS });
  };

  const recursiveFilterMenuItems = useCallback(
    (menuItems, textToSearch) => {
      let filteredMenuItems = [];
      for (let i = 0; i < menuItems.length; i++) {
        const mi = menuItems[i];
        if (_.isNil(mi) || _.isEmpty(mi)) {
          return drawerItems;
        } else {
          const { children } = mi;
          if (
            _.isNil(children) ||
            _.isEmpty(children) ||
            children.length === 0
          ) {
            if (mustAddMenuItem(mi, textToSearch)) {
              filteredMenuItems.push(mi);
            }
          } else {
            /*const mustAddParent = mustAddMenuItem(mi, textToSearch);
          if (mustAddParent) {
            filteredMenuItems.push(mi);
          }*/
            const childrenResult = recursiveFilterMenuItems(
              children,
              textToSearch
            );
            if (childrenResult && childrenResult.length > 0) {
              filteredMenuItems.push(...childrenResult);
            }
          }
        }
      }
      return filteredMenuItems;
    },
    [drawerItems]
  );

  const filterMenuItems = useCallback(
    (text) => {
      if (_.isNil(text) || text === "") {
        setMenuItems(drawerItems);
        setIsFiltered(false);
      } else {
        const newMenuItems = recursiveFilterMenuItems(drawerItems, text);
        setIsFiltered(true);
        setMenuItems(newMenuItems);
      }
    },
    [drawerItems, recursiveFilterMenuItems]
  );

  function handleEnterOnSeach() {
    if (isFiltered && menuItems && menuItems.length === 1) {
      const { route, propText, formPath, formName, defaultPinned } =
        menuItems[0];
      if (route) {
        pushPage({
          route,
          propText,
          path: formPath,
          idForm: formName,
          defaultPinned,
        });
      }
    }
  }

  function mustAddMenuItem(mi, textToSearch) {
    let result = false;
    const { propText } = mi;
    if (
      propText &&
      typeof propText === "string" &&
      propText.toUpperCase().includes(textToSearch.toUpperCase())
    ) {
      result = true;
    } else if (propText && typeof propText === "object") {
      const propTextValues = Object.values(mi.propText);
      const propTextValuesUp = propTextValues.map((pp) => {
        return pp.toUpperCase();
      });
      const textUp = textToSearch.toUpperCase();
      if (propTextValuesUp.find((pt) => pt.includes(textUp))) {
        result = true;
      }
    }
    return result;
  }

  //Default return if nothing was received from server
  if ((_.isNil(menuItems) || _.isEmpty(menuItems)) && !isFiltered) {
    return (
      <div className={classes.emptyListContainer}>
        <IconButton onClick={handleRefreshMenu}>
          <Icon
            className="fas fa-sync-alt"
            style={{
              color: theme.palette.content.mainColor,
              fontSize: "5em",
              marginBottom: 5,
            }}
          />
        </IconButton>
        <p className={classes.paragraphServerOff}>{t("ERROR_SERVER_OFF")}</p>
      </div>
    );
  }

  return (
    <>
      {isMobile && (
        <SearchMenuBar
          filterMenuItems={filterMenuItems}
          handleEnterOnSeach={handleEnterOnSeach}
        />
      )}
      {isFiltered && menuItems && menuItems.length > 0 && (
        <p className={classes.resultParagraph}>
          {`(${menuItems.length}) ${t("MENU_SEARCH_RESULTS")}`}
        </p>
      )}
      {(_.isNil(menuItems) || _.isEmpty(menuItems)) && isFiltered ? (
        <div className={classes.emptyListContainer}>
          <Icon
            className="fab fa-creative-commons-zero"
            style={{
              color: theme.palette.content.mainColor,
              fontSize: "5em",
              marginBottom: 5,
            }}
          />
          <p className={classes.paragraphServerOff}>
            {t("MENU_SEARCH_NOT_FOUND")}
          </p>
        </div>
      ) : (
        <div className={classes.container}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            style={{
              overflowY: "auto",
            }}
          >
            {menuItems.map((item) => {
              const index = item.id;
              const itemChildrenCount = _.size(item?.children) || 0;
              if (
                item.inProduction === true &&
                !item.hidden &&
                !item?.isKpi &&
                hasAtLeastOneExecutableChild(item)
              ) {
                return (
                  <List key={index + uuid()} style={{ padding: "0px" }}>
                    <ListItem
                      className={
                        index === selectedIndex && item?.children?.length === 0
                          ? classes.finalItemSelected
                          : classes.item
                      }
                      key={item.id}
                      button
                      onClick={() => {
                        handleClick({
                          index,
                          children: item?.children?.length > 0,
                          route: item.route,
                          propText: item.propText,
                          openNewTab: item?.openNewTab,
                          formPath: item?.formPath,
                          formName: item?.formName,
                          id: index,
                          headerImg: item?.headerImg,
                          name: item?.name,
                          isExecution: item?.isExecution,
                          defaultPinned: item?.defaultPinned,
                        });
                      }}
                    >
                      <ListItemIcon className={classes.listItemIconContainer}>
                        <Icon
                          className={item.name}
                          style={{
                            ...commonIconStyles,
                            color: theme?.palette?.drawer?.item,
                            fontSize: theme.palette.drawer.iconSize,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={
                          index === selectedIndex &&
                          (openItems[index] === true ||
                            item?.children?.length === 0)
                            ? classes.parentTextSelected
                            : classes.listItemText
                        }
                        primary={
                          itemChildrenCount > 0
                            ? `${getLocaleText(
                                item.propText,
                                i18n
                              )} (${itemChildrenCount})`
                            : `${getLocaleText(item.propText, i18n)}`
                        }
                      />

                      {openItems &&
                      openItems[index] === true &&
                      !_.isNil(item) &&
                      !_.isNil(item.children) &&
                      !_.isEmpty(item.children) ? (
                        <Icon
                          className="fas fa-chevron-up"
                          style={{
                            fontSize: theme.palette.drawer.iconSize - 3,
                            width: "auto",
                            //color: theme.palette.drawer.item,
                          }}
                        />
                      ) : (
                        openItems &&
                        (openItems[index] === false ||
                          _.isNil(openItems[index])) &&
                        !_.isNil(item) &&
                        !_.isNil(item.children) &&
                        !_.isEmpty(item.children) && (
                          <Icon
                            className="fas fa-chevron-down"
                            style={{
                              ...commonIconStyles,
                              fontSize: theme.palette.drawer.iconSize - 3,
                            }}
                          />
                        )
                      )}
                      {index === selectedIndex &&
                        item?.children?.length === 0 && (
                          <Icon
                            className="fas fa-circle"
                            style={{
                              ...commonIconStyles,
                              color: theme?.palette?.drawer?.item,
                              fontSize: "10px",
                              padding: "3px",
                            }}
                          />
                        )}
                    </ListItem>

                    {/* SECOND LEVEL */}
                    <Collapse
                      in={openItems && openItems[index] === true}
                      timeout={0}
                    >
                      <List
                        style={{
                          padding: "0px",
                          backgroundColor: childColorList,
                        }}
                        component="div"
                      >
                        {!_.isNil(item) &&
                          !_.isNil(item.children) &&
                          !_.isEmpty(item.children) &&
                          item.children.map((child) => {
                            const childIndex = child.id;
                            const childItemsCount =
                              _.size(child?.children) || 0;

                            if (
                              child.inProduction === true &&
                              !child.hidden &&
                              !child?.isKpi &&
                              hasAtLeastOneExecutableChild(child)
                            ) {
                              return (
                                <div key={childIndex + uuid()}>
                                  <ListItem
                                    key={child.id}
                                    className={
                                      childIndex === selectedChildIndex &&
                                      child.children.length === 0
                                        ? classes.finalItemSelected
                                        : classes.item
                                    }
                                    onClick={() => {
                                      handleChildClick({
                                        childIndex,
                                        children: child.children.length > 0,
                                        route: child.route,
                                        propText: child.propText,
                                        formPath: child?.formPath,
                                        formName: child?.formName,
                                        id: childIndex,
                                        headerImg: item?.headerImg,
                                        name: child?.name,
                                        isExecution: child?.isExecution,
                                        defaultPinned: child?.defaultPinned,
                                      });
                                    }}
                                    button
                                  >
                                    <div
                                      className={classes.childIdentedIconText}
                                    >
                                      <ListItemIcon
                                        className={
                                          classes.listItemIconContainer
                                        }
                                      >
                                        {childIndex === selectedChildIndex ? (
                                          <Icon
                                            className={
                                              child.name
                                                ? child.name
                                                : "fas fa-folder-open"
                                            }
                                            style={{
                                              ...commonIconStyles,
                                              fontSize:
                                                theme.palette.drawer.iconSize,
                                              color:
                                                theme?.palette?.drawer?.item,
                                            }}
                                          />
                                        ) : (
                                          <Icon
                                            className={
                                              child.name
                                                ? child.name
                                                : "fas fa-folder"
                                            }
                                            style={{
                                              ...commonIconStyles,
                                              fontSize:
                                                theme.palette.drawer.iconSize,
                                              color:
                                                theme?.palette?.drawer?.item,
                                            }}
                                          />
                                        )}
                                      </ListItemIcon>
                                      <ListItemText
                                        className={
                                          childIndex === selectedChildIndex
                                            ? classes.childTextSelected
                                            : classes.listItemText
                                        }
                                        primary={
                                          childItemsCount > 0
                                            ? `${getLocaleText(
                                                child?.propText,
                                                i18n
                                              )} (${childItemsCount})`
                                            : `${getLocaleText(
                                                child?.propText,
                                                i18n
                                              )}`
                                        }
                                      />
                                    </div>
                                    {openItems &&
                                    openItems[childIndex] === true &&
                                    child.children.length > 0 ? (
                                      <Icon
                                        className="fas fa-chevron-up"
                                        style={{
                                          ...commonIconStyles,
                                          fontSize:
                                            theme.palette.drawer.iconSize - 3,
                                        }}
                                      />
                                    ) : (
                                      openItems &&
                                      (openItems[childIndex] === false ||
                                        _.isNil(openItems[childIndex])) &&
                                      child.children.length > 0 && (
                                        <Icon
                                          className="fas fa-chevron-down"
                                          style={{
                                            ...commonIconStyles,
                                            fontSize:
                                              theme.palette.drawer.iconSize - 3,
                                          }}
                                        />
                                      )
                                    )}
                                    {childIndex === selectedChildIndex &&
                                      child.children.length === 0 && (
                                        <Icon
                                          className="fas fa-circle"
                                          style={{
                                            ...commonIconStyles,
                                            color: theme?.palette?.drawer?.item,
                                            fontSize: "10px",
                                            padding: "3px",
                                          }}
                                        />
                                      )}
                                  </ListItem>
                                  {/* THIRD LEVEL */}
                                  <Collapse
                                    in={
                                      openItems &&
                                      openItems[childIndex] === true
                                    }
                                    timeout={0}
                                  >
                                    <List
                                      style={{
                                        padding: "0px",
                                        backgroundColor: grandColorList,
                                      }}
                                      component="div"
                                    >
                                      {child.children.map((grand) => {
                                        const grandIndex = grand.id;
                                        const grandItemsCount =
                                          _.size(grand?.children) || 0;
                                        if (
                                          grand.inProduction === true &&
                                          !grand.hidden &&
                                          !grand?.isKpi &&
                                          hasAtLeastOneExecutableChild(grand)
                                        ) {
                                          return (
                                            <div key={grandIndex + uuid()}>
                                              <ListItem
                                                className={
                                                  grandIndex ===
                                                    selectedGrandIndex &&
                                                  grand?.children?.length === 0
                                                    ? classes.finalItemSelected
                                                    : classes.item
                                                }
                                                key={grand.id}
                                                button
                                                onClick={() => {
                                                  handleGrandClick({
                                                    grandIndex,
                                                    children:
                                                      grand?.children?.length >
                                                      0,
                                                    route: grand.route,
                                                    propText: grand.propText,
                                                    formPath: grand?.formPath,
                                                    formName: grand?.formName,
                                                    id: grandIndex,
                                                    headerImg: item?.headerImg,
                                                    name: grand?.name,
                                                    isExecution:
                                                      grand?.isExecution,
                                                    defaultPinned:
                                                      grand?.defaultPinned,
                                                  });
                                                }}
                                              >
                                                <div
                                                  className={
                                                    classes.grandIdentedIconText
                                                  }
                                                >
                                                  <ListItemIcon
                                                    className={
                                                      classes.listItemIconContainer
                                                    }
                                                  >
                                                    <Icon
                                                      className={grand.name}
                                                      style={{
                                                        ...commonIconStyles,
                                                        fontSize:
                                                          theme.palette.drawer
                                                            .iconSize,
                                                        color:
                                                          theme?.palette?.drawer
                                                            ?.item,
                                                      }}
                                                    />
                                                  </ListItemIcon>

                                                  <ListItemText
                                                    primary={
                                                      grandItemsCount > 0
                                                        ? `${getLocaleText(
                                                            grand?.propText,
                                                            i18n
                                                          )} (${grandItemsCount})`
                                                        : `${getLocaleText(
                                                            grand?.propText,
                                                            i18n
                                                          )}`
                                                    }
                                                    className={
                                                      grandIndex ===
                                                      selectedGrandIndex
                                                        ? classes.childTextSelected
                                                        : classes.listItemText
                                                    }
                                                  />
                                                </div>
                                                {openItems &&
                                                openItems[grandIndex] ===
                                                  true &&
                                                grand?.children?.length > 0 ? (
                                                  <Icon
                                                    className="fas fa-chevron-up"
                                                    style={{
                                                      ...commonIconStyles,
                                                      fontSize:
                                                        theme.palette.drawer
                                                          .iconSize - 3,
                                                    }}
                                                  />
                                                ) : (
                                                  openItems &&
                                                  (openItems[grandIndex] ===
                                                    false ||
                                                    _.isNil(
                                                      openItems[grandIndex]
                                                    )) &&
                                                  grand?.children?.length >
                                                    0 && (
                                                    <Icon
                                                      className="fas fa-chevron-down"
                                                      style={{
                                                        ...commonIconStyles,
                                                        fontSize:
                                                          theme.palette.drawer
                                                            .iconSize - 3,
                                                      }}
                                                    />
                                                  )
                                                )}
                                                {grandIndex ===
                                                  selectedGrandIndex &&
                                                  grand?.children?.length ===
                                                    0 && (
                                                    <Icon
                                                      className="fas fa-circle"
                                                      style={{
                                                        ...commonIconStyles,
                                                        color:
                                                          theme?.palette?.drawer
                                                            ?.item,
                                                        fontSize: "10px",
                                                        padding: "3px",
                                                      }}
                                                    />
                                                  )}
                                              </ListItem>

                                              {/* FOURTH LEVEL */}
                                              <Collapse
                                                in={
                                                  openItems &&
                                                  openItems[grandIndex] === true
                                                }
                                                timeout={0}
                                              >
                                                <List
                                                  style={{
                                                    padding: "0px",
                                                    backgroundColor:
                                                      lastColorList,
                                                  }}
                                                  component="div"
                                                >
                                                  {grand &&
                                                    grand?.children &&
                                                    grand.children.length > 0 &&
                                                    grand.children.map(
                                                      (last) => {
                                                        const lastIndex =
                                                          last.id;
                                                        if (
                                                          last.inProduction ===
                                                            true &&
                                                          !last.hidden &&
                                                          !last?.isKpi
                                                        ) {
                                                          return (
                                                            <div
                                                              key={
                                                                lastIndex +
                                                                uuid()
                                                              }
                                                            >
                                                              <ListItem
                                                                className={
                                                                  lastIndex ===
                                                                  selectedLastIndex
                                                                    ? classes.finalItemSelected
                                                                    : classes.item
                                                                }
                                                                key={lastIndex}
                                                                button
                                                                onClick={() => {
                                                                  handleLastClick(
                                                                    {
                                                                      lastIndex,
                                                                      children:
                                                                        last
                                                                          ?.children
                                                                          ?.length >
                                                                        0,
                                                                      route:
                                                                        last.route,
                                                                      propText:
                                                                        last.propText,
                                                                      formPath:
                                                                        last?.formPath,
                                                                      formName:
                                                                        last?.formName,
                                                                      id: lastIndex,
                                                                      headerImg:
                                                                        item?.headerImg,
                                                                      name: last?.name,
                                                                      isExecution:
                                                                        last?.isExecution,
                                                                      defaultPinned:
                                                                        last?.defaultPinned,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className={
                                                                    classes.lastIdentedIconText
                                                                  }
                                                                >
                                                                  <ListItemIcon
                                                                    className={
                                                                      classes.listItemIconContainer
                                                                    }
                                                                  >
                                                                    <Icon
                                                                      className={
                                                                        last.name
                                                                      }
                                                                      style={{
                                                                        ...commonIconStyles,
                                                                        fontSize:
                                                                          theme
                                                                            .palette
                                                                            .drawer
                                                                            .iconSize,
                                                                        color:
                                                                          theme
                                                                            ?.palette
                                                                            ?.drawer
                                                                            ?.item,
                                                                      }}
                                                                    />
                                                                  </ListItemIcon>

                                                                  <ListItemText
                                                                    primary={getLocaleText(
                                                                      last.propText,
                                                                      i18n
                                                                    )}
                                                                    className={
                                                                      lastIndex ===
                                                                      selectedLastIndex
                                                                        ? classes.childTextSelected
                                                                        : classes.listItemText
                                                                    }
                                                                  />
                                                                </div>
                                                                {lastIndex ===
                                                                  selectedLastIndex && (
                                                                  <Icon
                                                                    className="fas fa-circle"
                                                                    style={{
                                                                      ...commonIconStyles,
                                                                      color:
                                                                        theme
                                                                          ?.palette
                                                                          ?.drawer
                                                                          ?.item,
                                                                      fontSize:
                                                                        "10px",
                                                                      padding:
                                                                        "3px",
                                                                    }}
                                                                  />
                                                                )}
                                                              </ListItem>
                                                            </div>
                                                          );
                                                        } else {
                                                          return null;
                                                        }
                                                      }
                                                    )}
                                                </List>
                                              </Collapse>
                                            </div>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </List>
                                  </Collapse>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </List>
                    </Collapse>
                  </List>
                );
              } else {
                return null;
              }
            })}
          </List>
          {mode === MODE_PROD && isMobile && (
            <RefreshMenuButton handleRefreshMenu={handleRefreshMenu} />
          )}
        </div>
      )}
    </>
  );
}
