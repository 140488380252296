import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";

import { AuthContext } from "./AuthContext";
import * as dashboardAPI from "../../solutions/home/api";
import {
  API_FORM_STATUS,
  HTTP_STATUS_UNAUTHORIZED,
  MUST_ASK_CLOUD_VERSION,
  PROXY_HOST,
  SERVER_HOST,
} from "../../util/Constants";
import { isNil } from "lodash";
import useIsMounted from "react-is-mounted-hook";

const DEFAULT_TIME_TO_ASK = 1000 * 60 * 1; //1 minute
const METHOD_GET_FRONT_VERSION = "/version";
const FILE_METHOD_GET_VERSION = "/front_version.txt";

export const MODE_NOTIS_LIST = 1;
export const MODE_NOTIS_EDIT = 2;

//Creating the context
const NotificationsContext = createContext();

const NotificationsContextProvider = ({ children }) => {
  const { auth, logout } = useContext(AuthContext);
  const [isNotificationsDockOpen, setIsNotificationsDockOpen] = useState(false);
  const [continueAsking, setContinueAsking] = useState(true);
  const [newVersion, setNewVersion] = useState("");
  const [fetching, setFetching] = useState(false);
  const [mode, setMode] = useState(MODE_NOTIS_LIST);

  const isMounted = useIsMounted();

  const handleNotificationsDock = useCallback(({ open, isFromConfig }) => {
    if (isFromConfig) {
      setMode(MODE_NOTIS_EDIT);
    } else {
      setMode(MODE_NOTIS_LIST);
    }
    setIsNotificationsDockOpen(open);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const interval = setInterval(() => {
        if (auth && auth.token) {
          if (MUST_ASK_CLOUD_VERSION) {
            getNewFrontVersionCloud();
          } else {
            getNewFrontVersion(auth?.token);
          }
        }
      }, DEFAULT_TIME_TO_ASK);
      return () => clearInterval(interval);
    } else {
      return () => null;
    }
    //eslint-disable-next-line
  }, [auth]);

  async function getNewFrontVersion(token) {
    if (!fetching && continueAsking) {
      if (isMounted()) setFetching(true);
      const url = SERVER_HOST() + API_FORM_STATUS + METHOD_GET_FRONT_VERSION;
      const response = await dashboardAPI.getNewFrontVersion(token, url);
      await analyzeResponse(response);
      if (isMounted()) setFetching(false);
    }
  }

  async function getNewFrontVersionCloud() {
    if (!fetching && continueAsking && !isNil(PROXY_HOST)) {
      if (isMounted()) setFetching(true);
      const url = PROXY_HOST + FILE_METHOD_GET_VERSION;
      const response = await dashboardAPI.getNewFrontVersionCloud(url);
      await analyzeResponse(response);
      if (isMounted()) setFetching(false);
    }
  }

  async function analyzeResponse(response) {
    if (!isNil(response)) {
      const { ok, dataResponse, status } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else {
        if (ok) {
          if (dataResponse && dataResponse !== "") {
            const versionAreEqual =
              process.env.REACT_APP_VERSION === dataResponse;
            if (!versionAreEqual) {
              if (isMounted()) {
                setNewVersion(dataResponse);
                setContinueAsking(false);
              }
            }
          }
        }
      }
    }

    return response;
  }

  function toggleMode() {
    if (mode === MODE_NOTIS_LIST) {
      setMode(MODE_NOTIS_EDIT);
    } else {
      setMode(MODE_NOTIS_LIST);
    }
  }

  return (
    <NotificationsContext.Provider
      value={{
        isNotificationsDockOpen,
        handleNotificationsDock,
        newVersion,
        mode,
        toggleMode,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

//Better exporting all things at bottom when we have more tan one
export { NotificationsContext, NotificationsContextProvider };
