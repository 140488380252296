import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  makeStyles,
  IconButton,
  Icon,
  Box,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  CircularProgress,
  DialogTitle,
} from "@material-ui/core";
import ProductActions from "./ProductActions";
import ProductImage from "./ProductImage";
import ProductPriceAndStock from "./ProductPriceAndStock";
import Carousel from "react-material-ui-carousel";
import useProductDetails from "../store/hooks/useProductDetails";
import { isArray, isEmpty, isNil, size } from "lodash";
import { useTranslation } from "react-i18next";
import { SectionTitleToggler } from "../store/hooks/useToggleableSections";

const VISIBLES_IMAGES = 4;

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: "16px",
    borderRadius: "15px",
    border: `3px solid ${theme.palette.content.mainColor}`,
    [theme.breakpoints.up("xs")]: {
      maxHeight: "90vh",
      minHeight: "90vh",
      maxWidth: "95vw",
      minWidth: "95vw",
      padding: "5px",
      margin: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "90vh",
      minHeight: "90vh",
      maxWidth: "80vw",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "80vh",
      minHeight: "85vh",
      maxWidth: "60vw",
    },
  },
  dialogTitle: {
    padding: "6px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dialogContent: {
    padding: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: "auto",
    maxHeight: 400,
    objectFit: "contain",
  },
  price: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(1),
  },
  full_description: {
    marginTop: theme.spacing(2),
    //overflowY: "auto",
    //maxHeight: 280,
  },
  name: {
    fontWeight: 700,
  },
  quantityLabelContainer: {
    padding: "6px 12px",
    borderRadius: 6,
    color: "#FFF",
    backgroundColor: theme.palette.content.mainColor,
  },
  quantityLabelTypo: {
    fontWeight: "bold",
  },
  amountBtn: {
    color: theme.palette.header.textColor,
  },
  actionsBtn: {
    marginTop: theme.spacing(2),
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  mainGridItem: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
  },
  carouselBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    gap: "6px",
    minWidth: 100,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carouselItem: {
    cursor: "pointer",
    width: 96,
    height: 96,
    objectFit: "contain",
  },
  carouselItemSelected: {
    border: "3px solid",
    borderColor: theme.palette.primary.main,
  },
  carouselLastItem: {
    position: "relative",
    opacity: 0.6,
  },
  carouselLastItemOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0, 0, 0, 0.25)",
    color: "#fff",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  productDetails: {
    flexGrow: 1,
    overflowY: "auto",
  },
  bottomSection: {
    marginTop: "auto",
    marginBottom: "12px",
  },
  carouselContainer: {
    width: "100%",
    height: 532,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.up("xl")]: {
      height: 630,
      minHeight: 630,
    },
  },
  carouselImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  table: {
    marginTop: theme.spacing(2),
  },
  tableCell: {
    padding: theme.spacing(1),
  },
  tableCellBold: {
    fontWeight: "bold",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "0px",
    right: "0px",
    left: "0px",
    bottom: "0px",
  },
  errorMessage: {
    color: theme.palette.error.main,
    textAlign: "center",
  },
}));

const SECTION_DESCRIPTION = "section-description";
const SECTION_ESPECIFICATIONS = "section-espec";
const INITIA_SECTIONS_STATE = {
  [SECTION_DESCRIPTION]: true,
  [SECTION_ESPECIFICATIONS]: true,
};

const ProductQuickViewModal = ({ product, open, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    loading,
    error,
    extraImages,
    legalItems,
    regulatoryItems,
    productDetails,
    fetchProductDetails,
  } = useProductDetails();

  const [openSections, setOpenSections] = useState(INITIA_SECTIONS_STATE);

  const toggleSection = useCallback((sectionId) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  }, []);

  useEffect(() => {
    if (open && product) {
      fetchProductDetails(product);
      setSelectedIndex(0);
      setOpenSections(INITIA_SECTIONS_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, product]);

  const hasLegalItems = useMemo(() => {
    return !isNil(legalItems) && isArray(legalItems) && !isEmpty(legalItems);
  }, [legalItems]);

  const hasRegItems = useMemo(() => {
    return (
      !isNil(regulatoryItems) &&
      isArray(regulatoryItems) &&
      !isEmpty(regulatoryItems)
    );
  }, [regulatoryItems]);

  if (!product) return null;

  const displayProduct = productDetails || product;
  const renderContent = () => {
    if (loading) {
      return (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box className={classes.loadingContainer}>
          <Typography variant="body1" className={classes.errorMessage}>
            {error}
          </Typography>
        </Box>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} className={classes.mainGridItem} spacing={0}>
          <Box className={classes.carouselContainer}>
            <Carousel
              autoPlay={false}
              animation="fade"
              timeout={0}
              indicators={isMobile}
              swipe={isMobile}
              navButtonsAlwaysInvisible={isMobile || size(extraImages) < 2}
              navButtonsAlwaysVisible={!isMobile && size(extraImages) > 1}
              cycleNavigation={true}
              index={selectedIndex}
              onChange={(index) => setSelectedIndex(index)}
            >
              {size(extraImages) > 0 ? (
                extraImages.map((image, index) => (
                  <ProductImage
                    key={index}
                    imageUrl={image}
                    title={displayProduct.firmDescription}
                    firm={displayProduct.firm}
                    isDetailImage={true}
                    isCarouselBigImage={true}
                  />
                ))
              ) : (
                <ProductImage
                  imageUrl={product?.imageUrl}
                  title={product?.firmDescription}
                  firm={product?.firm}
                  isDetailImage={true}
                  isCarouselBigImage={true}
                />
              )}
            </Carousel>
          </Box>
          {!isMobile && size(extraImages) > 1 && (
            <Box className={classes.carouselBox}>
              {extraImages.slice(0, VISIBLES_IMAGES).map((image, index) => (
                <ProductImage
                  key={index}
                  imageUrl={image}
                  title={displayProduct.firmDescription}
                  firm={displayProduct.firm}
                  isDetailImage={false}
                  className={`${classes.carouselItem} ${
                    index === selectedIndex ? classes.carouselItemSelected : ""
                  }`}
                  onClick={() => setSelectedIndex(index)}
                  isCaruselLeftBarImage={true}
                />
              ))}
              {extraImages.length > VISIBLES_IMAGES && (
                <Box
                  className={`${classes.carouselItem} ${classes.carouselLastItem}`}
                  onClick={() => setSelectedIndex(VISIBLES_IMAGES)}
                >
                  <ProductImage
                    imageUrl={extraImages[VISIBLES_IMAGES]}
                    title={displayProduct.firmDescription}
                    firm={displayProduct.firm}
                    isDetailImage={false}
                  />
                  <Box
                    className={`${classes.carouselLastItemOverlay} ${
                      selectedIndex > VISIBLES_IMAGES - 1
                        ? classes.carouselItemSelected
                        : ""
                    }`}
                  >
                    +{extraImages.length - VISIBLES_IMAGES}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className={classes.productInfoContainer}>
            <Box className={classes.productDetails}>
              <Typography variant="h4" className={classes.name}>
                {displayProduct.title}
              </Typography>
              <Box mt={1} mb={2}>
                <ProductPriceAndStock {...displayProduct} />
                <Box mt={2}>
                  <ProductActions
                    item={displayProduct}
                    isShowedInDetails={true}
                  />
                </Box>
              </Box>
              <SectionTitleToggler
                id={SECTION_DESCRIPTION}
                title={t(
                  "EMPLOYEE_STORE.PRODUCT_DETAILS_SECTION_TITLE_DESCRIPTIONS"
                )}
                open={openSections?.[SECTION_DESCRIPTION]}
                toggleSection={toggleSection}
              />
              <Typography
                variant="h6"
                className={classes.full_description}
                component="div"
                hidden={openSections?.[SECTION_DESCRIPTION] === false}
              >
                {displayProduct.description}
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.description}
                hidden={openSections?.[SECTION_DESCRIPTION] === false}
              >
                {displayProduct.firmDescription}
              </Typography>
              <Typography
                variant="subtitle1"
                hidden={openSections?.[SECTION_DESCRIPTION] === false}
              >
                EAN: {displayProduct.ean}
              </Typography>

              {hasLegalItems || hasRegItems ? (
                <SectionTitleToggler
                  id={SECTION_ESPECIFICATIONS}
                  title={t(
                    "EMPLOYEE_STORE.PRODUCT_DETAILS_SECTION_TITLE_ESPEC"
                  )}
                  open={openSections?.[SECTION_ESPECIFICATIONS]}
                  toggleSection={toggleSection}
                />
              ) : null}

              {hasLegalItems && openSections?.[SECTION_ESPECIFICATIONS] && (
                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small">
                    <TableBody>
                      {legalItems.map((item, index) => (
                        <TableRow key={index} selected={index % 2 === 0}>
                          <TableCell
                            className={`${classes.tableCell} ${classes.tableCellBold}`}
                            style={{ width: "120px" }}
                          >
                            {item.key}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {hasRegItems && openSections?.[SECTION_ESPECIFICATIONS] && (
                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small">
                    <TableBody>
                      {regulatoryItems.map((item, index) => (
                        <TableRow key={index} selected={index % 2 === 0}>
                          <TableCell
                            className={`${classes.tableCell} ${classes.tableCellBold}`}
                            style={{ width: "120px" }}
                          >
                            {item.key}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.dialogContainer }}
    >
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        <IconButton onClick={onClose} size="small">
          <Icon className="fas fa-times" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ProductQuickViewModal);
