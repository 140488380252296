import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  endOfYear,
  subYears,
} from "date-fns";
import { useTranslation } from "react-i18next";

const useDefaultStaticRanges = (selectedRange) => {
  const { t } = useTranslation();

  const selectableDateRangesLimits = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOfLastYear: startOfYear(subYears(new Date(), 1)),
    endOfLastYear: endOfYear(subYears(new Date(), 1)),
  };

  const selectableRangesWithoutIsSelected = [
    {
      label: t("DATE_RANGE_TODAY"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfToday,
        endDate: selectableDateRangesLimits.endOfToday,
      }),
    },
    {
      label: t("DATE_RANGE_YESTERDAY"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfYesterday,
        endDate: selectableDateRangesLimits.endOfYesterday,
      }),
    },

    {
      label: t("DATE_RANGE_CURRENT_WEEK"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfWeek,
        endDate: selectableDateRangesLimits.endOfWeek,
      }),
    },
    {
      label: t("DATE_RANGE_LAST_WEEK"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfLastWeek,
        endDate: selectableDateRangesLimits.endOfLastWeek,
      }),
    },
    {
      label: t("DATE_RANGE_CURRENT_MONTH"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfMonth,
        endDate: selectableDateRangesLimits.endOfMonth,
      }),
    },
    {
      label: t("DATE_RANGE_LAST_MONTH"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfLastMonth,
        endDate: selectableDateRangesLimits.endOfLastMonth,
      }),
    },
    {
      label: t("DATE_RANGE_CURRENT_YEAR"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfYear,
        endDate: selectableDateRangesLimits.endOfYear,
      }),
    },
    {
      label: t("DATE_RANGE_LAST_YEAR"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfLastYear,
        endDate: selectableDateRangesLimits.endOfLastYear,
      }),
    },
    {
      label: t("DATE_RANGE_ALL"),
      range: () => ({
        startDate: selectableDateRangesLimits.startOfLastYear,
        endDate: selectableDateRangesLimits.endOfMonth,
      }),
    },
  ];

  const isSelected = (staticRange) =>
    selectedRange.startDate &&
    staticRange?.range()?.startDate &&
    selectedRange.endDate &&
    staticRange?.range()?.endDate &&
    isSameDay(selectedRange.startDate, staticRange.range().startDate) &&
    isSameDay(selectedRange.endDate, staticRange.range().endDate);

  const addIsSelectedToSelectableRanges = (ranges) => {
    return ranges.map((range) => ({
      isSelected: () => isSelected(range),
      ...range,
    }));
  };

  return addIsSelectedToSelectableRanges(selectableRangesWithoutIsSelected);
};

export default useDefaultStaticRanges;
