import React, { useContext, useState } from "react";
import { MenuItem, Icon, Menu } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { isMap, isNil } from "lodash";
import { v4 as uuid } from "uuid";

import { WorkspaceContext } from "../../../core/providers/WorkspaceContext";
import { useTranslation } from "react-i18next";
import { getLocaleText } from "../../../util/UtilTraduction";
import { DrawerContext } from "../../../core/providers/DrawerContext";
import { useInteractiveItemStore } from "../../../core/stores/InteractiveItemStore";
import { FORM_OPERATION_EXECUTION } from "../../../util/Constants";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 250,
  },
  buttonOpen: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.content.mainColor}99`,
    height: "35px",
    cursor: "pointer",
    margin: "5px 0px 0px 5px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.content.mainColor,
    "&:hover": {
      border: `2px solid ${theme.palette.content.mainColor}`,
    },
  },
}));

const styles = {
  labelIcon: {
    width: "auto",
    height: "auto",
    fontSize: 16,
    padding: 1,
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default function FormHistorySelect(props) {
  const { changeHeaderImg } = useContext(DrawerContext);
  const { formHistory, addToFormHistory } = useContext(WorkspaceContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const {
    activeSubItem,
    addTabItem,
    addSubItem,
    changeActiveTabItem,
    changeActiveSubItem,
  } = useInteractiveItemStore();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose(value) {
    if (value && value.route) {
      changeHeaderImg(value?.headerImg);
      addToFormHistory(value);
      addTabItem(value);

      const isExecutionForm = value?.isExecution === true;
      const uniqueIdForExe = uuid();
      const sub = isExecutionForm
        ? {
            ...value,
            parentId: value?.route,
            propText: value?.idForm,
            count_name: value?.idForm,
            count_number: uniqueIdForExe,
            entityName: `${value?.idForm}_${uniqueIdForExe}`,
            operation: FORM_OPERATION_EXECUTION,
            id: value?.route,
          }
        : {
            ...value,
            parentId: value.route,
            propText: t("MULTI_ITEM_SAVED_RECORDS"),
          };

      addSubItem(sub);
      changeActiveTabItem(value.route);
      if (isExecutionForm) {
        changeActiveSubItem(sub);
      }
      if (
        !isNil(activeSubItem) &&
        isMap(activeSubItem) &&
        activeSubItem.has(value.route)
      ) {
        const activeSubItemInMap = activeSubItem.get(value.route);
        const routeToMove = activeSubItemInMap?.id || activeSubItemInMap?.route;
        history.push(routeToMove, activeSubItemInMap);
      } else {
        history.push(value.route, {
          path: value.path,
          idForm: value.idForm,
        });
      }
    }
    setAnchorEl(null);
  }

  if (isNil(formHistory) || formHistory.length === 0) {
    return null;
  }

  return (
    <>
      <button onClick={handleOpen} className={classes.buttonOpen}>
        <Icon className="fas fa-history" style={styles.labelIcon} />
      </button>
      <Menu
        id="history-route-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={styles.anchorOrigin}
        transformOrigin={styles.transformOrigin}
        classes={{
          list: classes.list,
        }}
      >
        {formHistory.map((f) => {
          return (
            <MenuItem
              key={f.route}
              value={f}
              onClick={() => handleClose(f)}
              style={{ width: "100%" }}
            >
              {f?.name && (
                <Icon
                  className={f.name}
                  style={{ ...styles.labelIcon, marginRight: 5 }}
                />
              )}
              {getLocaleText(f.propText, i18n)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
