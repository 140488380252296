import React, { useMemo } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import {
  every,
  filter,
  find,
  get,
  includes,
  isArray,
  isEmpty,
  isNil,
  isString,
  toLower,
} from "lodash";

import {
  formatValuesMultiComboBox,
  getFormSchemaDefinitionByCompleteIdControl,
  getInputType,
  makeEmptyMultiComboValue,
  makeValuesToSetValuesMultiple,
} from "../../../util/UtilForm";
import {
  INPUT_TYPE_INPUT,
  INPUT_TYPE_MULTI_COMBO,
} from "../../../util/Constants";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "flex-start",
    width: "90%",
  },
  fieldBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    marginRight: "5px",
    color: `${theme.palette.form.labelTextColor}BB`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

function isArrayOfArrays(arr) {
  return isArray(arr) && every(arr, isArray);
}

function ShellFieldToPropagate({ completeIdControl, formSchemaDef, children }) {
  const classes = useStyles();
  return (
    <Box className={classes.fieldBox}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={completeIdControl}>
          {formSchemaDef?.title}
        </InputLabel>
        {children}
      </FormControl>
    </Box>
  );
}

function FieldToPropagate({
  completeIdControl,
  formSchema,
  formData,
  propagatedFields,
  changePropagatedFiles,
  setValuesToSetFromPropagatedFields,
  requiredPropagatedFields,
  chooseClicked,
  setSearchAutoFocusState,
}) {
  const formSchemaDef = getFormSchemaDefinitionByCompleteIdControl({
    completeIdControl,
    formSchema,
  });
  const listName = formSchemaDef?.listName;
  const inputType = formSchemaDef?.inputType;
  const inputDataType = formSchemaDef?.type;

  const fieldData = !isNil(listName)
    ? formData?.[listName]?.[formSchemaDef?.field]
    : formData?.[formSchemaDef?.field];
  const multiComboProps = formSchemaDef?.multiComboProps;

  const availableOptions = useMemo(() => {
    if (isNil(multiComboProps) || isNil(multiComboProps?.display)) {
      return [];
    }

    if (
      !isNil(fieldData) &&
      !isNil(fieldData.optionsValues) &&
      !isEmpty(fieldData.optionsValues) &&
      isArray(fieldData.optionsValues)
    ) {
      const display = multiComboProps?.display;
      return fieldData.optionsValues.map((o) => {
        if (isString(o)) {
          return o;
        } else {
          return o[display];
        }
      });
    }

    return [];
  }, [fieldData, multiComboProps]);

  const handleChangeMultiCombo = async (selVal) => {
    changePropagatedFiles({
      name: completeIdControl,
      value: selVal,
    });

    const fieldsToReturn = multiComboProps?.valsToSet;
    const display = multiComboProps?.display;
    const optionsIsArrayOfArrays = isArrayOfArrays(fieldData?.optionsValues);
    const objectSelected = !isEmpty(selVal)
      ? optionsIsArrayOfArrays
        ? find(fieldData?.optionsValues, { [display]: selVal })
        : filter(fieldData?.optionsValues, (item) => item === selVal)
      : makeEmptyMultiComboValue({ optionsValues: fieldData?.optionsValues });

    if (!isNil(fieldsToReturn) && !isNil(objectSelected)) {
      let valuesMade = makeValuesToSetValuesMultiple(
        fieldsToReturn,
        [objectSelected],
        1
      );

      const valuesToSet = formatValuesMultiComboBox(
        valuesMade,
        completeIdControl
      );

      setValuesToSetFromPropagatedFields((prev) => [...prev, ...valuesToSet]);
    }
  };

  const handleChangeNormalInput = async (typedVal, changeFocus) => {
    setSearchAutoFocusState(changeFocus);
    changePropagatedFiles({
      name: completeIdControl,
      value: typedVal,
    });

    const fieldToReturn = formSchemaDef?.propagateTo?.field;

    if (!isNil(fieldToReturn)) {
      const objectToSet = {
        idControl: fieldToReturn,
        value: typedVal,
        line: 1,
        propagated: true,
      };
      setValuesToSetFromPropagatedFields((prev) => [
        ...filter(prev, function (p) {
          return p?.idControl !== fieldToReturn;
        }),
        objectToSet,
      ]);
    }
  };

  const mustShowError =
    !isNil(requiredPropagatedFields) &&
    isArray(requiredPropagatedFields) &&
    !isEmpty(requiredPropagatedFields) &&
    includes(requiredPropagatedFields, completeIdControl) &&
    chooseClicked;

  if (isNil(formSchemaDef) || isNil(fieldData)) {
    return null;
  }

  if (toLower(inputType) === toLower(INPUT_TYPE_MULTI_COMBO)) {
    return (
      <ShellFieldToPropagate
        completeIdControl={completeIdControl}
        formSchemaDef={formSchemaDef}
      >
        <Select
          native
          value={get(propagatedFields, completeIdControl)}
          onChange={(e) => handleChangeMultiCombo(e.target.value)}
          id={completeIdControl}
          inputProps={{
            id: completeIdControl,
          }}
          label={formSchemaDef?.title}
          error={mustShowError}
          onFocus={() => setSearchAutoFocusState(false)}
          onBlur={() => setSearchAutoFocusState(true)}
        >
          <option aria-label="None" value="" />
          {!isNil(availableOptions) &&
            !isEmpty(availableOptions) &&
            availableOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
        </Select>
      </ShellFieldToPropagate>
    );
  } else if (toLower(inputType) === toLower(INPUT_TYPE_INPUT)) {
    const inputTypeToUse = getInputType(inputDataType);
    const inputValue = get(propagatedFields, completeIdControl);
    return (
      <TextField
        id={completeIdControl}
        value={!isNil(inputValue) ? inputValue : ""}
        onChange={(e) => handleChangeNormalInput(e.target.value, false)}
        onBlur={(e) => handleChangeNormalInput(e.target.value, true)}
        label={formSchemaDef?.title}
        type={inputTypeToUse}
        variant="outlined"
        autoComplete={"off"}
        autoCorrect={"off"}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          autoComplete: "off",
          form: {
            autoComplete: "off",
          },
        }}
        error={mustShowError}
      />
    );
  } else {
    return null;
  }
}

export default function HeaderFieldsToPropagate({
  fieldsHeaderToPropagate,
  formSchema,
  formData,
  propagatedFields,
  changePropagatedFiles,
  setValuesToSetFromPropagatedFields,
  requiredPropagatedFields,
  chooseClicked,
  setSearchAutoFocusState,
}) {
  const classes = useStyles();

  if (
    isNil(fieldsHeaderToPropagate) ||
    !isArray(fieldsHeaderToPropagate) ||
    isEmpty(fieldsHeaderToPropagate)
  ) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      {fieldsHeaderToPropagate.map((x, index) => (
        <FieldToPropagate
          key={index}
          completeIdControl={x}
          formSchema={formSchema}
          formData={formData}
          propagatedFields={propagatedFields}
          changePropagatedFiles={changePropagatedFiles}
          setValuesToSetFromPropagatedFields={
            setValuesToSetFromPropagatedFields
          }
          requiredPropagatedFields={requiredPropagatedFields}
          chooseClicked={chooseClicked}
          setSearchAutoFocusState={setSearchAutoFocusState}
        />
      ))}
    </Box>
  );
}
