export async function updateLog(url, method, body, headers) {
  await fetch(url, {
    body: JSON.stringify(body),
    method: method,
    headers: headers,
  });
  return;
}

export async function deleteLog(url, method, body, headers) {
  await fetch(url, {
    body: JSON.stringify(body),
    method: method,
    headers: headers,
  });
  return;
}
