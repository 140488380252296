import { isFunction, isNil } from "lodash";
import {
  API_EMPLOYEE_STORE,
  HTTP_STATUS_UNAUTHORIZED,
  SERVER_HOST,
} from "../../../util/Constants";

export const BASE_EMS_URL = `${SERVER_HOST()}${API_EMPLOYEE_STORE}`;

export const handleApiResponse = async (response, logout) => {
  const status = response?.status;
  const json = await response.json();

  if (
    status === HTTP_STATUS_UNAUTHORIZED &&
    !isNil(logout) &&
    isFunction(logout)
  ) {
    logout(true);
  }

  if (json.ok) {
    return {
      ok: true,
      data: json.dataResponse,
      error: null,
    };
  } else {
    return {
      ok: false,
      data: null,
      error: json.errorMsg || null,
    };
  }
};
