import React, { useMemo } from "react";
import { isEmpty, isNil } from "lodash";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";

import { useFetchGen } from "../useFetchGen";
import { API_HELP, METHOD_GET, SERVER_HOST } from "../../../util/Constants";
import NothingToShow from "../../../components/nothingToShow/NothingToShow";
import { commonIcon, useStyles } from "./styles";
import FilesHelpBody from "./renderTypes/FilesHelpBody";
import { useTranslation } from "react-i18next";

export default function HelpDialogRenderBody({
  open,
  path,
  idForm,
  handleClose,
  nameForm,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [{ status, data }] = useFetchGen(
    `${SERVER_HOST()}${API_HELP}/items?path=${path}&idForm=${idForm}`,
    null,
    METHOD_GET
  );

  const files = useMemo(() => {
    if (!isNil(data) && !isEmpty(data) && !isNil(data?.dataResponse)) {
      return data.dataResponse;
    } else {
      return null;
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      aria-labelledby="draggable-help-dialog-title"
      classes={{ paper: classes.paper }}
    >
      <Box className={classes.dialogTitle} id="draggable-help-dialog-title">
        <Typography variant="h5">{`${t("HELP")}: ${nameForm}`}</Typography>
        <IconButton className={classes.btnClose} onClick={handleClose}>
          <Icon className="fas fa-times" style={commonIcon} />
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent}>
        <Box width="100%" height="initial">
          {status === "fetching" ? (
            <CircularProgress />
          ) : isNil(files) || isEmpty(files) ? (
            <NothingToShow />
          ) : (
            <FilesHelpBody files={files} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
