import { useState, useCallback, useContext } from "react";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { fetchProductDetailsApi } from "../../api/productsApi";

const useProductDetails = () => {
  const { t } = useTranslation();
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [extraImages, setExtraImages] = useState([]);
  const [legalItems, setLegalItems] = useState([]);
  const [regulatoryItems, setRegulatoryItems] = useState([]);

  const fetchProductDetails = useCallback(
    async (product) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchProductDetailsApi(headers, product, logout);
        if (!isNil(response)) {
          const { error, data } = response;
          if (!isNil(error)) {
            setError(error);
          } else {
            const { product, extraImages, legalItems, regulatoryItems } = data;
            setProductDetails(product);
            const currentImage = !isNil(product?.imageUrl)
              ? [product?.imageUrl]
              : [];
            setExtraImages([...currentImage, ...extraImages]);
            setLegalItems(legalItems);
            setRegulatoryItems(regulatoryItems);
          }
        } else {
          setError(t("ERROR_RESOURCE_NOT_FOUND_TEXT"));
        }
      } catch (err) {
        setError(t("ERROR_RESOURCE_NOT_FOUND_TEXT"));
      } finally {
        setLoading(false);
      }
    },
    [headers, logout, t]
  );

  return {
    productDetails,
    extraImages,
    legalItems,
    regulatoryItems,
    loading,
    error,
    fetchProductDetails,
  };
};

export default useProductDetails;
