import React, { useMemo } from "react";
import { isEmpty, isNil, isString, trim } from "lodash";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function DocsViewer({ id, keySet, filePathToSet, ext }) {
  const docs = useMemo(() => {
    if (
      !isNil(filePathToSet) &&
      isString(filePathToSet) &&
      "" !== trim(filePathToSet)
    ) {
      return [
        {
          uri: filePathToSet,
          fileType: ext,
        },
      ];
    } else {
      return [];
    }
  }, [filePathToSet, ext]);

  if (isNil(docs) || isEmpty(docs)) {
    return null;
  }

  return (
    <DocViewer
      id={id}
      key={keySet}
      documents={docs}
      config={{ header: { disableHeader: true } }}
      language="en"
      pluginRenderers={DocViewerRenderers}
    />
  );
}
