import EditableCellInput from "./EditableCellInput";
import EditableCellCheckbox from "./EditableCellCheckbox";
import EditableCellComboBox from "./EditableCellComboBox";
import EditableCellDateTimePicker from "./EditableCellDateTimePicker";
import EditableCellDatePicker from "./EditableCellDatePicker";
import EditableCellFile from "./EditableCellFile";
import EditableCellLaunchExternalForm from "./EditableCellLaunchExternalForm";
import EditableCellMultiComboBox from "./EditableCellMultiComboBox";

import {
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_COMBOBOX,
  INPUT_TYPE_DATEPICKER,
  INPUT_TYPE_DATETIMEPICKER,
  INPUT_TYPE_FILE,
  INPUT_TYPE_INPUT,
  INPUT_TYPE_LAUNCH_EXTERNAL_FORM,
  INPUT_TYPE_MULTI_COMBO,
} from "../../../util/Constants";

// Set our editable cell renderer as the default Cell renderer
export const defaultColumn = {
  [INPUT_TYPE_INPUT]: EditableCellInput,
  [INPUT_TYPE_CHECKBOX]: EditableCellCheckbox,
  [INPUT_TYPE_COMBOBOX]: EditableCellComboBox,
  [INPUT_TYPE_DATEPICKER]: EditableCellDatePicker,
  [INPUT_TYPE_DATETIMEPICKER]: EditableCellDateTimePicker,
  [INPUT_TYPE_FILE]: EditableCellFile,
  [INPUT_TYPE_LAUNCH_EXTERNAL_FORM]: EditableCellLaunchExternalForm,
  [INPUT_TYPE_MULTI_COMBO]: EditableCellMultiComboBox,
  default: EditableCellInput,
};
