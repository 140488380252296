import React from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

const ConfirmationDeleteDialog = (props) => {
  const { t } = useTranslation();
  const { open, handleConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography style={{ fontSize: "25px" }}>{t("WARNING")}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("FORM_TABLE_DELETE_CONF_BODY")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleConfirm(true)}
          color="primary"
          style={{ fontWeight: "bold" }}
          autoFocus
        >
          {t("YES")}
        </Button>
        <Button onClick={() => handleConfirm(false)} color="primary">
          {t("NO")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDeleteDialog;
