// Funciones auxiliares para manejar opciones anidadas
export const findOptionById = (options, id, parentId = null) => {
  for (const option of options) {
    if (option.id === id) {
      return { ...option, parentId };
    }
    if (option.subOptions && option.subOptions.length > 0) {
      const found = findOptionById(option.subOptions, id, option.id);
      if (found) return found;
    }
  }
  return null;
};

export const getAllSubOptionIds = (subOptions) => {
  let ids = [];
  for (const sub of subOptions) {
    ids.push(sub.id);
    if (sub.subOptions && sub.subOptions.length > 0) {
      ids = ids.concat(getAllSubOptionIds(sub.subOptions));
    }
  }
  return ids;
};
