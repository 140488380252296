import { useCallback, useContext } from "react";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { createTransactionApi } from "../../api/paymentsApi";

const usePayments = () => {
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const createPayment = useCallback(
    async ({ paymentSystem, body }) => {
      const response = await createTransactionApi({
        headers: REQUEST_HEADERS,
        logout,
        paymentSystem,
        body,
      });

      return response;
    },
    [REQUEST_HEADERS, logout]
  );

  return { createPayment };
};

export default usePayments;
