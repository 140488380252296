import { makeStyles } from "@material-ui/core/styles";

export const DARK_BLUE = "#0B2549";

export const loginLabels = {
  color: DARK_BLUE,
  fontSize: "1.1em",
};

export const INPUTS_WIDTH = {
  width: "90%",
};

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 10,
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#0A0335",
    position: "relative",
    overflowY: "hidden",
    overflowX: "hidden",
  },
  rootNew: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",

    position: "relative",
    overflowY: "hidden",
    overflowX: "hidden",
    backgroundImage: 'url("/assets/img/login/dsuite-login-back.webp")',
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundColor: ({ isEnvEnabled, selectedEnvInfo }) =>
      isEnvEnabled === true && selectedEnvInfo !== null
        ? selectedEnvInfo.bg
        : "#0A0335",
    backgroundBlendMode: ({ isEnvEnabled, selectedEnvInfo }) =>
      isEnvEnabled === true && selectedEnvInfo !== null ? "multiply" : "unset",
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "left",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  loginBoxContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      height: "60%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "450px",
      height: "450px",
    },
    [theme.breakpoints.up("md")]: {
      width: "500px",
      height: "500px",
    },
    backgroundColor: "rgba(255, 255, 255, 0.9)",

    borderRadius: 10,
    boxShadow: `0 0 3px white !important`,
  },
  loginBoxContainerNew: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: "rgba(199, 194, 194, 0.5)",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(7px)",
    WebkitBackdropFilter: "blur(7px)",
    padding: 16,
    [theme.breakpoints.down(570)]: {
      width: "90% !important",
      maxHeight: "85%",
      minHeight: "70%",
      paddingBlock: "36px",
      marginTop: 30,
      marginBottom: 30,
    },
    [theme.breakpoints.down("sm")]: {
      width: ({ isFull }) => (isFull === true ? "90%" : "50%"),
      height: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: ({ isFull }) => (isFull === true ? 500 : 800),
      height: 580,
    },
    [theme.breakpoints.up("lg")]: {
      width: ({ isFull }) => (isFull === true ? 600 : 900),
      height: 600,
    },
  },
  body: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: (props) => (props.wd && props.wd.height > 500 ? "60%" : "100%"),
    width: "100%",
  },
  bodyNew: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 0.75,
    justifyContent: "space-evenly",
    width: "100%",
    marginTop: 16,
    padding: 16,
    gap: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: 0,
      gap: 10,
    },
    [theme.breakpoints.down("xs")]: {
      gap: 30,
      marginTop: "0",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
    },
  },
  bodyNewOptionBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20%",
    background: "rgb(241 240 240 / 100%)",
    borderRadius: 32,
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    //backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(3px)",
    border: "1px solid rgba(241, 240, 240, 0.3)",
    [theme.breakpoints.up("lg")]: {
      width: 380,
      height: 360,
    },
    [theme.breakpoints.up("md")]: {
      width: 320,
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      width: "320px",
      height: "230px",
      gap: "30px",
    },
    [theme.breakpoints.down(650)]: {
      gap: "30px",
      width: "270px",
      height: "200px",
    },
    [theme.breakpoints.down(400)]: {
      gap: 20,
      width: "100%",
      height: 220,
      borderRadius: 16,
    },
    [theme.breakpoints.down(376)]: {
      gap: 20,
      width: "100%",
      height: 180,
    },
  },
  bodyNewOptionBoxFull: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20%",
    background: "rgb(241 240 240 / 100%)",
    borderRadius: 32,
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    WebkitBackdropFilter: "blur(3px)",
    border: "1px solid rgba(241, 240, 240, 0.3)",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      gap: "30px",
      padding: 16,
    },
    [theme.breakpoints.up("md")]: {
      height: 320,
    },
    [theme.breakpoints.down(650)]: {
      gap: "30px",
      height: "auto",
      padding: 16,
    },
    [theme.breakpoints.down(400)]: {
      gap: 20,
      height: "auto",
      borderRadius: 16,
      padding: 12,
    },
    [theme.breakpoints.down(376)]: {
      gap: 20,
      height: "auto",
      padding: 10,
    },
  },
  lorealImg: {
    width: 200,
    height: 36,
    [theme.breakpoints.down("xs")]: {
      width: "150px",
      height: "30px",
    },
  },
  keepMeLoggedInButton: {
    ...INPUTS_WIDTH,
    color: DARK_BLUE,
    height: "10%",
    marginBottom: 10,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  loginButton: {
    ...INPUTS_WIDTH,
    borderRadius: 10,
    height: "15%",
    backgroundColor: `${DARK_BLUE}`,
    fontWeight: "500",
    color: "white",
    marginTop: "2em",
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: `${DARK_BLUE}`,
    },
    "&:disabled": {
      backgroundColor: `${DARK_BLUE}33`,
    },
  },
  loginButtonMicrosoft: {
    ...INPUTS_WIDTH,
    borderRadius: 10,
    height: "15%",
    backgroundColor: "#FFFFFF",
    fontWeight: "500",
    color: `${DARK_BLUE}BB`,
    marginTop: "2em",
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: "#FFFFFF",
    },
    position: "relative",
  },
  loginButtonMicrosoftNew: {
    height: 50,
    width: "75%",
    borderRadius: 10,
    backgroundColor: `${DARK_BLUE}DD`,
    color: "#FFFFFF",
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: DARK_BLUE,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  circleAstronaut: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 86,
    height: 86,
    backgroundColor: `${DARK_BLUE}DD`,
    color: "#FFFFFF",
    borderRadius: "100%",
    [theme.breakpoints.up("md")]: {
      marginTop: -48,
    },
    [theme.breakpoints.down("xs")]: {
      width: 66,
      height: 66,
    },
  },
  loginButtonNew: {
    height: 50,
    width: ({ isFull }) => (isFull === true ? "100%" : "90%"),
    borderRadius: 10,
    backgroundColor: `${DARK_BLUE}DD`,
    fontWeight: "500",
    color: "#FFFFFF",
    marginTop: -5,
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: `${DARK_BLUE}`,
    },
    "&:disabled": {
      backgroundColor: `${DARK_BLUE}33`,
    },
    [theme.breakpoints.down("sm")]: {
      height: 40,
      marginBlock: ({ isFull }) => (isFull === true ? 16 : undefined),
    },
  },
  loginShowInputsBtn: {
    height: 50,
    width: "75%",
    borderRadius: 10,
    backgroundColor: `${DARK_BLUE}DD`,
    fontWeight: "500",
    color: "#FFFFFF",
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: `${DARK_BLUE}`,
    },
    "&:disabled": {
      backgroundColor: `${DARK_BLUE}33`,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  inputsStyles: {
    ...INPUTS_WIDTH,
    backgroundColor: "rgba(255, 255, 255)",
    border: "none",
    borderRadius: 10,
    margin: "10px 0px",
    outline: "none",
    "&:focus": {
      border: `2px solid ${theme.palette.content.mainColor} !important`,
      boxShadow: `0 0 3px ${theme.palette.content.mainColor} !important`,
    },
    height: "15%",
    padding: 5,
    color: DARK_BLUE,
  },
  inputsStylesNew: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255)",
    border: "1px solid #0B254944",
    borderRadius: 10,
    outline: "none",
    "&:focus": {
      border: `2px solid ${theme.palette.content.mainColor} !important`,
      boxShadow: `0 0 3px ${theme.palette.content.mainColor} !important`,
    },
    padding: 5,
    color: DARK_BLUE,
  },
  forgotPassButton: {
    display: "flex",
    width: "90%",
    flexDirection: "row-reverse",
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    marginTop: 5,
    cursor: "pointer",
    color: DARK_BLUE,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 6,
    },
  },
  loginButtonText: {
    fontSize: "0.75rem",
  },
  typoAndLock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
  inputsEnteringBox: {
    padding: ({ isFull }) => (isFull === true ? 0 : 16),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "15px",
  },
  newInputsContainer: {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "16px",
    },
  },
}));
