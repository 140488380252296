import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  avisStoredFilters: null,
  avisStoredCollapsed: false,
  avisStoredShowBpmDialog: false,
  isEditingAvisForm: false,
  avisStoredBreadcumbRoutes: new Map(),
};

export const createAvisSlice = (set, get) => ({
  ...INITIAL_STATE,
  setAvisStoredFilters: (newState) =>
    set(() => ({ avisStoredFilters: newState })),
  setAvisStoredCollapsed: (newState) =>
    set(() => ({ avisStoredCollapsed: newState })),
  setAvisStoredShowBpmDialog: (newState) =>
    set(() => ({ avisStoredShowBpmDialog: newState })),
  addAvisBreadcumbRoute: (newRoute) =>
    set((state) => ({
      avisStoredBreadcumbRoutes: state.avisStoredBreadcumbRoutes.set(newRoute, {
        avisStoredFilters: state.avisStoredFilters,
        avisStoredCollapsed: state.avisStoredCollapsed,
        avisStoredShowBpmDialog: state.avisStoredShowBpmDialog,
      }),
    })),
  removeAvisBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().avisStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          avisStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          avisStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateAvisBreadcumbRoute: (route, newParams) => {
    const actualBC = get().avisStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      avisStoredBreadcumbRoutes: state.avisStoredBreadcumbRoutes.set(route, {
        ...spreadBC,
        ...newParams,
      }),
    }));
  },
  toggleIsEditingAvisForm: () => {
    set((state) => ({ isEditingAvisForm: !state.isEditingAvisForm }));
  },
  clear: () => set(INITIAL_STATE),
});
