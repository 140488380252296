import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Icon, Typography } from "@material-ui/core";

import { INBOX_TAB } from "../../../../util/Constants";
import { useStyles, iconAuto } from "./styles";

export default function InboxTabButton(props) {
  const { activeTab, handleTabClick, sidebarOpen } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip title={t("TAB_INBOX")} placement="right">
      <div className={sidebarOpen ? classes.envButton : classes.envButtonOpen}>
        <IconButton
          id="tab_inbox_button"
          className={
            activeTab === INBOX_TAB ? classes.activeTab : classes.normalTab
          }
          onClick={() => {
            handleTabClick(INBOX_TAB, false);
          }}
        >
          <Icon className="fas fa-inbox" style={iconAuto} />
        </IconButton>
        {sidebarOpen && <Typography>{t("TAB_INBOX")}</Typography>}
      </div>
    </Tooltip>
  );
}
