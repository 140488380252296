import React, { useContext, useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";

import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XS,
} from "../../../util/Constants";
import { LayoutContext } from "../../../core/providers/LayoutContext";
import { has, lowerCase } from "lodash";
import { Tooltip } from "@material-ui/core";
import { KEY_G } from "../../../util/UtilKeys";

const BUTTONS = [
  //{ break: null, label: "AUTO" },
  { break: BREAKPOINT_LG + 1, label: "LG" },
  { break: BREAKPOINT_MD + 1, label: "MD" },
  { break: BREAKPOINT_SM + 1, label: "SM" },
  { break: BREAKPOINT_XS + 1, label: "XS" },
];

export const useStyles = makeStyles((theme) => ({
  breakpointChooserContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  buttonBreak: {
    padding: 3,
    border: "none",
    borderRadius: 5,
    //cursor: "pointer",
    backgroundColor: `${theme.palette.content.mainColor}77`,
    color: "#FFFFFF",
    "&:hover": {
      textDecoration: "underline",
      textUnderlinePosition: "under",
    },
  },
  selectedButtonBreak: {
    padding: 3,
    border: "none",
    borderRadius: 5,
    //cursor: "pointer",
    backgroundColor: `${theme.palette.content.mainColor}`,
    color: "#FFFFFF",
    "&:hover": {
      textDecoration: "underline",
      textUnderlinePosition: "under",
    },
  },
}));

const GUIDES_ENABLED_BY_ENV = process.env.REACT_APP_SHOW_GUIDES === "y";

export default function BreakpointChooser(props) {
  const {
    actualBreak,
    definedLayouts,
    //handleChangeBreakpoint
  } = props;

  const classes = useStyles();
  const { layoutBreakpoint } = useContext(LayoutContext);
  const [showGuides, setShowGuides] = useState(GUIDES_ENABLED_BY_ENV);

  useEffect(() => {
    const handler = (e) => {
      const { keyCode, altKey } = e;

      if (altKey && keyCode === KEY_G) {
        e.preventDefault();
        setShowGuides((show) => !show);
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, []);

  const renderGuides = useMemo(() => {
    if (!showGuides) {
      return GUIDES_ENABLED_BY_ENV !== showGuides
        ? showGuides
        : GUIDES_ENABLED_BY_ENV;
    } else {
      return true;
    }
  }, [showGuides]);

  if (!renderGuides) {
    return null;
  }

  return (
    <div className={classes.breakpointChooserContainer}>
      {BUTTONS &&
        BUTTONS.length > 0 &&
        BUTTONS.map((b, i) => {
          const isIncludedInForm = has(definedLayouts, lowerCase(b.label));
          return (
            <Tooltip
              key={b.label}
              title="Falta definir breakpoint en layout de formulario"
              disableHoverListener={isIncludedInForm}
            >
              <label
                className={
                  (layoutBreakpoint &&
                    layoutBreakpoint.toUpperCase() === b.label) ||
                  actualBreak === b.break
                    ? classes.selectedButtonBreak
                    : classes.buttonBreak
                }
                style={
                  !isIncludedInForm
                    ? {
                        fontWeight: "bold",
                        border: "2px solid red",
                      }
                    : null
                }
                //onClick={() => handleChangeBreakpoint(b.break)}
              >
                {b.label}
              </label>
            </Tooltip>
          );
        })}
    </div>
  );
}
