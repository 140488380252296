import React from "react";
import { makeStyles } from "@material-ui/core";
import { HEADER_HEIGHT_MOBILE_HOME, HEADER_HEIGHT } from "./header/EmployeeStoreHeader";
import { useTheme, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: "100%",
    paddingTop: ({ isHomePage, isMdUp }) => isHomePage && !isMdUp ? HEADER_HEIGHT_MOBILE_HOME : HEADER_HEIGHT,
    flexGrow: 1,
    overflow: "hidden",
  },
}));

export default function EmployeeStoreMain({ children, isHomePage }) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ isHomePage, isMdUp });

  return <main className={classes.mainContainer}>{children}</main>;
}
