/**
 * This file contains all CORE function that we want to use globally by name
 * WARNING: Avoid completely sensitive information as passwords, keys, etc
 * Convetion Naming: CFFnameOfTheFunction, CFF -> Core Form Functions
 */

//Check if number is positive
window.CFFisPositiveNumber = function (number) {
  return number > 0;
};
