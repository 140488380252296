import { useState } from "react";

export const useContextualMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: null, y: null });

  const toggleContextualMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  const changeMenuPosition = (newPos) => {
    setMenuPosition(newPos);
  };

  return { openMenu, menuPosition, toggleContextualMenu, changeMenuPosition };
};
