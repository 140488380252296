import { msalInstance } from "../instance/msalInstance";
import { loginRequest } from "../config/authConfig";

export async function callMsGraph(params) {
  const { endpoint } = params;

  const account = msalInstance.getActiveAccount();
  if (account) {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(endpoint, options);
  }
}
