import * as Constants from "../../util/Constants";
// diego
//const MAIN_COLOR = "#4D71DC";
//const SECOND_COLOR = "#F2F6FC";

// pruebas
const MAIN_COLOR = "#3c7ee0";
const SECOND_COLOR = "#F6F9FF";
const FORM_BACKGROUND = "#FFFFFF";

const SCROLL_COLOR = "#999596";

const TEXT_PRIMARY = "#333333";
const TEXT_TITLES = "#244c86";

export const light = {
  typography: {
    fontFamily: ["Asap", "sans-serif"].join(" , "),
    fontSize: 11,
  },
  overrides: {
    MuiTablePagination: {
      caption: {
        fontWeight: "bold",
      },
    },
    MuiSelect: {
      filled: {
        backgroundColor: `${MAIN_COLOR}`,
        color: "#FFFFFF",
        borderBottom: "1px solid #FFFFFF",
      },
      iconFilled: {
        color: "#FFFFFF",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-checked": {
          color: "inherit",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "5px",
      },
      sizeSmall: {
        padding: "5px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: "3em",
      },
    },
    MuiTableHead: {
      root: {
        border: `0px solid`,
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover": {
          textDecoration: "underline",
        },
        "&:active": {
          color: "#FFFFFF !important",
          fontWeight: "bold",
          textDecoration: "underline",
        },
      },
      active: {
        fontWeight: "bold",
        textDecoration: "underline",
      },
      iconDirectionDesc: {
        color: "#FFFFFF !important",
        fontSize: 22,
        animation: "moveDown 2s infinite",
      },
      iconDirectionAsc: {
        color: "#FFFFFF !important",
        fontSize: 22,
        animation: "moveUp 2s infinite",
      },
    },
    MuiCssBaseline: {
      "@global": {
        // fieldset: {
        //   borderColor: MAIN_COLOR,
        //   borderRadius: "10px",
        //   height: "-webkit-fill-available",
        // },
        // legend: {
        //   fontSize: "1.25rem",
        //   fontWeight: "500",
        //   lineHeight: "1.6",
        //   letterSpacing: "0.0075em",
        //   color: MAIN_COLOR,
        // },
        // ".form-control": {
        //   border: `0.5px solid ${MAIN_COLOR}`,
        //   borderRadius: "5px",
        //   fontFamily: ["Asap", "sans-serif"].join(" , "),
        // },
        "*::-webkit-scrollbar": {
          height: "1em", //horizontal scroll bar height
          width: "0.7em", // vertical scroll bar width
          backgroundColor: "#D3D3D333",
          borderRadius: 15,
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
          borderRadius: 15,
        },
        "*::-webkit-scrollbar-thumb": {
          //backgroundColor: `${MAIN_COLOR}AA`,
          backgroundColor: SCROLL_COLOR,
          borderRadius: 15,
        },
      },
    },
  },
  palette: {
    type: "light",
    container: {
      backgroundColor: Constants.BACKGROUND_ALWAYS_COLOR,
    },
    form: {
      background: FORM_BACKGROUND,
      inputBorder: MAIN_COLOR,
      inputBorderRadius: "5px",
      inputFontFamily: ["Asap", "sans-serif"].join(" , "),
      selectionItemBorder: MAIN_COLOR,
      selectionItemText: "#FFFFFF",
      labelTextColor: TEXT_PRIMARY,
      borderBlock: `${MAIN_COLOR}77`,
      bgBlock: SECOND_COLOR,
      headerBg: SECOND_COLOR,
    },
    content: {
      backgroundColor: SECOND_COLOR,
      mainColor: MAIN_COLOR,
      oppositeMainColor: "#00b8a9",
      //titleTextColor: "#111634",
      titleTextColor: TEXT_TITLES,
      titleTextShadow: "#aaaaaeb3",
      mainColorContrastText: "#4D5656",
      cardsBackground: "#FFFFFF",
      niceRed: "#d63031",
      dashboardFetchingBackground: "#dfe6e9",
      tabItemBg: "linear-gradient(180deg,  #3c7ee01a 0%, #3c7ee038 90.1% )",
      tabItemActiveBg:
        "linear-gradient(180deg,  #3c7ee01a 0%, #3c7ee057 90.1% )",
    },
    drawer: {
      item: MAIN_COLOR,
      child: MAIN_COLOR,
      grand: MAIN_COLOR,
      text: TEXT_PRIMARY,
      iconSize: 16,
      selectedText: TEXT_TITLES,
      grandSelected: "#FCFCFC",
      backgroundColor: SECOND_COLOR,
      width: 250,
      upGradientColor: SECOND_COLOR,
      downGradientColor: "#FCFCFC",
      refreshButtonBackgroundColor: MAIN_COLOR,
    },
    header: {
      formFlagBackground: "#111634",
      textColor: "#FFFFFF",
      iconColor: "#808080",
      backgroundColor: "#FFFFFF",
    },
    title: {
      formFlagBackground: "#111634",
      textColor: "#FFFFFF",
      iconColor: "#808080",
      backgroundColor: SECOND_COLOR,
    },

    footer: {
      pri: Constants.COLOR_MID_DARK,
    },
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    primary: {
      main: MAIN_COLOR,
      light: Constants.COLOR_LIGHTEST,
      dark: Constants.COLOR_DARKEST,
    },
    secondary: {
      main: MAIN_COLOR,
    },
    children: {
      main: "#D3D3D3",
    },
    text: {
      primary: TEXT_PRIMARY,
      //secondary: "#434340",
      secondary: TEXT_TITLES,
      disabled: "rgba(67, 67, 64, 0.5)",
      hint: "rgba(67, 67, 64, 0.5)",
      icon: "rgba(255, 255, 255, 0.12)",
      hover: "#eff2f7",
    },
    table: {
      header: {
        backgroundColor: "#FFFFFF",
        textColor: TEXT_TITLES,
        borderColor: MAIN_COLOR,
      },
      background: "#FFFFFF",
      selectedBackground: `${MAIN_COLOR}33`,
      selectedBackgroundGrouped: "#D3D3D3",
      totalsRowBg: "#dff1e5",
    },
    genericTablePabel: {
      decisionButtonBackgroundColor: TEXT_PRIMARY,
      panelConfigColumnBackground: "#FFFFFF",
      panelConfigColumnBackgroundS: "#D3D3D3",
    },
    tableNew: {
      header: {
        color: "#FFFFFF",
        backgroundColor: MAIN_COLOR,
        fontWeight: "bold",
        borderBottom: MAIN_COLOR,
        whiteSpace: "nowrap",
      },
    },
    home: {
      fabMainBg: "#00b894",
    },
    card: {
      border: "#f0f0f0",
    },
    semaphore: {
      red: "#EB4747",
      yellow: "#a0a001",
      green: "#00a46e",
      orange: "#F57D1F",
    },
  },
  transitions: {
    duration: {
      leavingScreen: 300,
      enteringScreen: 300,
    },
  },
};
