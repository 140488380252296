import {
  Button,
  Icon,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { filter } from "lodash";
import React, { useState } from "react";
import useEnvironmentStore from "../../../core/stores/EnvironmentStore";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonSelector: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "8px",
    width: 60,
    height: 60,
    borderRadius: "100%",
    right: "0px",
    backgroundColor: "transparent",
    color: "#000000BB",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#000000BB",
      transform: "scale(1.02)",
    },
  },
  popoverPaper: {
    backgroundColor: "#FFF",
    color: "#000000BB",
  },
  menuItem: {
    padding: 6,
    borderBottom: "1px solid lightgray",
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  fontSize: 25,
};

export default function DSuiteEnvSelector() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const envs = useEnvironmentStore((state) => state.envs);
  const selectedEnv = useEnvironmentStore((state) => state.selectedEnv);
  const changeEnv = useEnvironmentStore((state) => state.changeEnv);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectEnv = async (newEnv) => {
    await changeEnv(newEnv);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.container}>
      <Tooltip title="Seleccionar entorno" placement="top">
        <Button
          aria-controls="envs-btn-opener"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.buttonSelector}
        >
          <Icon className="fas fa-cog" style={commonIconStyles} />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        {filter(envs, { enabled: true }).map((env) => {
          return (
            <MenuItem
              key={env.id}
              onClick={() => handleSelectEnv(env.id)}
              className={classes.menuItem}
              style={{ backgroundColor: env.bg || "transparent" }}
            >
              <Icon
                className={env.icon}
                style={{ ...commonIconStyles, fontSize: 18, marginRight: 5 }}
              />
              <Typography>{env.label}</Typography>
              {env.id === selectedEnv ? (
                <Icon
                  className={"fas fa-check"}
                  style={{ ...commonIconStyles, fontSize: 12, marginLeft: 5 }}
                />
              ) : null}
            </MenuItem>
          );
        })}
      </Popover>
    </div>
  );
}
