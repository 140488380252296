import React, { useState, useEffect, useMemo } from "react";
import * as _ from "lodash";
import { Button, useTheme, Tooltip } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useTranslation } from "react-i18next";

import { isJsonFormat } from "../../../util/UtilFormat";
import {
  defineInputEnabledByOperation,
  getCompletePathGridItem,
  resolveFieldAgainstShadowStatus,
} from "../../../util/UtilForm";
import FileChooserDialog from "../../dialogs/fileChooserDialog/FileChooserDialog";
import {
  FORM_OPERATION_VIEW,
  //ERROR_BAD_FORM_DEF_COLOR,
} from "../../../util/Constants";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";

export default function EditableCellFile(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { value, row, updateDataTable, operation, column, formContext } = props;
  const {
    formInstanceId,
    section,
    block,
    listName,
    shadowStatus,
    isBlockDisabledByWizard,
  } = formContext;

  const { id } = column;
  const { index } = row;

  const [fileValue, setFileValue] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const [openFileChooser, setOpenFileChooser] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isEnableAddFile, setEnableAddFile] = useState(true);
  const [calculatedInputProps, setCalculatedInputProps] = useState(null);

  let completeIdControl = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column,
    index + 1
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema: column,
    operation,
    isSavedEntity: value?.isSavedEntity,
  });

  let controlFieldWithoutLine = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column
  );

  const isFilePropPresent =
    !_.isNil(column) && !_.isNil(column.isFile) && column.isFile === true;

  // EXTRA FILE CONFIG
  const extraFileConfig = column?.extraFileConfig;
  const hasExtraFileConfig =
    !_.isNil(extraFileConfig) && !_.isEmpty(extraFileConfig);
  const onlyFirstPagePDFFunctionality =
    hasExtraFileConfig &&
    !_.isNil(extraFileConfig.firstPagePDF) &&
    !_.isEmpty(extraFileConfig.firstPagePDF);
  const onlyFirstPagePDFValue =
    onlyFirstPagePDFFunctionality &&
    resolveFieldAgainstShadowStatus({
      field: extraFileConfig.firstPagePDF,
      shadowStatus,
      completeIdControl: extraFileConfig.firstPagePDF,
      line: index,
    });
  const handleOnlyFirstPDFPage =
    (!_.isNil(onlyFirstPagePDFValue) &&
      !_.isEmpty(onlyFirstPagePDFValue) &&
      _.toUpper(onlyFirstPagePDFValue) === "S") ||
    onlyFirstPagePDFValue === true;

  //Clearing function
  useEffect(() => {
    if (!_.isNil(value) && !_.isNil(value.value)) {
      const realValue = value.value;
      setLoading(false);
      const valueToSet =
        realValue && typeof realValue === "object"
          ? realValue
          : realValue !== "" && isJsonFormat(realValue)
          ? JSON.parse(realValue)
          : [];
      setFileValue(valueToSet);
    } else {
      setFileValue([]);
    }
    checkPresentationInfo(value);
  }, [value]);

  function checkPresentationInfo(value) {
    if (!_.isNil(value) && !_.isNil(value.presentationInfo)) {
      const { presentationInfo } = value;
      if (presentationInfo && presentationInfo.reset) {
        setCalculatedInputProps(null);
      } else {
        setCalculatedInputProps(presentationInfo);
      }
    } else {
      setCalculatedInputProps(null);
    }
  }

  //Check if input is enabled or not
  useEffect(() => {
    if (isBlockDisabledByWizard === true) {
      setIsEnabled(false);
    } else {
      const checkInputEnabled = defineInputEnabledByOperation(
        operation,
        column?.canEdit,
        column?.canNew
      );

      const disabledByCalculatedInputProps =
        calculatedInputProps?.enabled === false;
      const enabledAddFileToSet =
        !disabledByCalculatedInputProps && checkInputEnabled;
      setEnableAddFile(enabledAddFileToSet);

      if (operation === FORM_OPERATION_VIEW) {
        setIsEnabled(true);
      } else {
        if (!_.isNil(checkInputEnabled)) {
          setIsEnabled(checkInputEnabled);
        }
      }
      if (
        !_.isNil(calculatedInputProps) &&
        !_.isNil(calculatedInputProps.enabled)
      ) {
        setIsEnabled(calculatedInputProps.enabled);
      }
    }
  }, [operation, column, calculatedInputProps, isBlockDisabledByWizard]);

  const handleFileChooser = (state) => {
    if (!isFilePropPresent) {
      return;
    }
    setOpenFileChooser(state);
  };

  const handleSetValue = (fileValue) => {
    setFileValue(fileValue);
    if (fileValue && _.isArray(fileValue) && !_.isEmpty(fileValue)) {
      const fileValueToSetMin = fileValue.map((f) => {
        return {
          key: f?.key,
          name: f?.name,
          ext: f?.ext,
          lastDate: f?.lastDate,
          lastUser: f?.lastUser,
        };
      });
      const fileValueSt = JSON.stringify(fileValueToSetMin);
      updateDataTable(index, id, fileValueSt, null, column);
    } else {
      const fileValueSt = JSON.stringify(fileValue);
      updateDataTable(index, id, fileValueSt, null, column);
    }
  };

  const borderBottomButton =
    fileValue.length > 0
      ? `3px solid ${theme.palette.content.mainColor}`
      : "1px solid grey";

  const maxFilesValue = useMemo(() => {
    if (_.isNil(column) || _.isNil(column?.maxFiles) || _.isNil(shadowStatus)) {
      return null;
    }

    const maxFiles = column?.maxFiles;

    if (_.isString(maxFiles)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: maxFiles,
        shadowStatus,
        completeIdControl: maxFiles,
      });
      if (
        !_.isNil(valueInShadowStatus) &&
        !_.isNil(_.toNumber(valueInShadowStatus)) &&
        !_.isNaN(_.toNumber(valueInShadowStatus))
      ) {
        return _.toNumber(valueInShadowStatus);
      } else {
        return null;
      }
    } else {
      return maxFiles;
    }
  }, [column, shadowStatus]);

  const blockAddFilesByMaxFiles = useMemo(() => {
    if (
      _.isNil(fileValue) ||
      _.isEmpty(fileValue) ||
      !_.isArray(fileValue) ||
      _.isNil(maxFilesValue) ||
      !_.isNumber(maxFilesValue)
    ) {
      return false;
    }

    return fileValue?.length >= maxFilesValue;
  }, [maxFilesValue, fileValue]);

  //Input styles
  const controlStyles = {
    borderBottom: borderBottomButton,
    height: "inherit",
    width: "100%",
    backgroundColor:
      calculatedInputProps &&
      calculatedInputProps.backgroundColor &&
      calculatedInputProps.backgroundColor,

    color:
      calculatedInputProps &&
      calculatedInputProps.color &&
      calculatedInputProps.color,
    fontSize:
      calculatedInputProps &&
      calculatedInputProps.fontSize &&
      calculatedInputProps.fontSize,

    fontWeight:
      calculatedInputProps &&
      calculatedInputProps.fontWeight &&
      calculatedInputProps.fontWeight,
    fontStyle:
      calculatedInputProps &&
      calculatedInputProps.fontStyle &&
      calculatedInputProps.fontStyle,
    outline: !isFilePropPresent && "1px solid #d63031",
  };

  // const errorStyles = errorMap.some((e) => e && e.key === completeIdControl)
  //   ? {
  //       color: ERROR_BAD_FORM_DEF_COLOR,
  //     }
  //   : {};

  const canAddFileForRemoval = useMemo(() => {
    if (
      !_.isNil(column) &&
      !_.isNil(column.removeFileFromBucket) &&
      _.isBoolean(column.removeFileFromBucket) &&
      column.removeFileFromBucket === false
    ) {
      return false;
    } else {
      return true;
    }
  }, [column]);

  return (
    <>
      <Tooltip
        title={!isFilePropPresent ? t("FILE_IS_FILE_PROP_NOT_PRESENT") : ""}
        disableHoverListener={isFilePropPresent}
        placement="top"
      >
        <Button
          id={completeIdControl}
          onClick={() => handleFileChooser(true)}
          disabled={
            (!isEnabled && (!fileValue || fileValue?.length === 0)) ||
            disableDueChangeSavedValue
          }
          endIcon={<AttachFileIcon />}
          //style={{ ...controlStyles, ...errorStyles }}
          style={controlStyles}
        >
          {fileValue.length}
        </Button>
      </Tooltip>

      {openFileChooser === true && (
        <FileChooserDialog
          openFileChooser={openFileChooser}
          setFileValue={handleSetValue}
          handleClose={handleFileChooser}
          fileValue={fileValue}
          formInstanceId={formInstanceId}
          setLoading={setLoading}
          isLoading={isLoading}
          enabled={isEnableAddFile}
          operation={operation}
          completeIdControl={controlFieldWithoutLine}
          line={index + 1}
          handleOnlyFirstPDFPage={handleOnlyFirstPDFPage}
          canAddFileForRemoval={canAddFileForRemoval}
          maxFiles={maxFilesValue}
          blockAddFilesByMaxFiles={blockAddFilesByMaxFiles}
        />
      )}
    </>
  );
}
