import React, { useContext, useState } from "react";
import {
  languages,
  TOAST_CONTAINER_LAYOUT,
} from "../../../../../util/Constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Radio,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactCountryFlag from "react-country-flag";
import { isArray, isEmpty, isFunction, isNil, some } from "lodash";
import { toast } from "react-toastify";

import { useLocale } from "../../../../../core/hooks/useLocale";
import { commonIcon, flagIConStart, useStyles } from "./DropDownSettingsStyles";
import LeaveWorkConfirmationDialog from "../../../../../components/dialogs/leaveWork/LeaveWorkConfirmationDialog";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import { refreshPage } from "../../../../../util/util-io";

export default function LanguageSelector({ toggleDropDown, isEms }) {
  const classes = useStyles();
  const [locale] = useLocale();
  const { changeLang } = useContext(AuthContext);

  const [openLeaveWorkDialog, setOpenLeaveWorkDialog] = useState({
    open: false,
    newLang: null,
  });

  function handleOpenLeaveWorkDialog(newLang) {
    setOpenLeaveWorkDialog({ open: true, newLang });
  }
  async function handleOpenLeaveWorkConfDialogDecision(execute) {
    setOpenLeaveWorkDialog({ open: false, newLang: null });
    if (execute && openLeaveWorkDialog && openLeaveWorkDialog?.newLang) {
      handleChangeLang(openLeaveWorkDialog.newLang);
    }
  }

  async function handleChangeLang(valueToSet) {
    const { ok, msg } = await changeLang(valueToSet);
    if (ok) {
      if (!isNil(toggleDropDown) && isFunction(toggleDropDown)) {
        toggleDropDown(false);
      }
      refreshPage({isEms});
    } else {
      toast.error(msg, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <React.Fragment>
      {openLeaveWorkDialog && openLeaveWorkDialog?.open && (
        <LeaveWorkConfirmationDialog
          open={openLeaveWorkDialog?.open}
          handleConf={handleOpenLeaveWorkConfDialogDecision}
        />
      )}
      {languages
        .filter(
          (lang) =>
            lang?.enabled ||
            (!isNil(lang?.sub) && !isEmpty(lang?.sub) && lang?.enabled)
        )
        .map((lang) => {
          const haveSub =
            !isNil(lang?.sub) && isArray(lang.sub) && !isEmpty(lang?.sub);
          if (haveSub) {
            const isDefaultExpanded = some(lang?.sub, { value: locale });
            return (
              <div key={lang.value} className={classes.rootAccordion}>
                <Accordion
                  className={classes.accodtion}
                  elevation={0}
                  defaultExpanded={isDefaultExpanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${lang.value}-content`}
                    id={`${lang.value}-header`}
                  >
                    <div className={classes.iconAndText}>
                      <Typography
                        key={lang.value}
                        variant="h5"
                        style={{ ...flagIConStart, fontWeight: "bold" }}
                      >
                        {lang.letters}
                      </Typography>
                      {lang.label}
                      {isDefaultExpanded && (
                        <Icon
                          className="fas fa-dot-circle"
                          style={{
                            ...commonIcon,
                            marginLeft: 8,
                            fontSize: 12,
                          }}
                        />
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {lang.sub.map((sub) => {
                      return (
                        <div key={sub.value} className={classes.itemDivLang}>
                          <div
                            className={classes.iconAndText}
                            style={{ marginLeft: 20 }}
                          >
                            <ReactCountryFlag
                              svg
                              countryCode={sub?.flag}
                              style={flagIConStart}
                            />
                            {sub.label}
                          </div>
                          <Radio
                            key={`radio_${sub.value}`}
                            checked={locale === sub.value}
                            onChange={() =>
                              handleOpenLeaveWorkDialog(sub.value)
                            }
                            name={`radio_${sub.value}`}
                            inputProps={{ "aria-label": "A" }}
                          />
                        </div>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          } else {
            return (
              <div key={lang.value} className={classes.itemDivLang}>
                <div className={classes.iconAndText}>
                  <ReactCountryFlag
                    svg
                    countryCode={lang?.flag}
                    style={flagIConStart}
                  />
                  {lang.label}
                </div>
                <Radio
                  key={`radio_${lang.value}`}
                  checked={locale === lang.value}
                  onChange={() => handleOpenLeaveWorkDialog(lang.value)}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            );
          }
        })}
    </React.Fragment>
  );
}
