import { BASE_EMS_URL, handleApiResponse } from "./base";

export const fetchFiltersApi = async (headers, logout) => {
  const response = await fetch(`${BASE_EMS_URL}/filters`, {
    method: "GET",
    headers: headers,
  });

  return handleApiResponse(response, logout);
};
