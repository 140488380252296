import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function LogoutConfirmationDialog({ open, handleLogout }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => handleLogout(false)}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {t("LOGOUT_CONFIRM_TITLE")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("LOGOUT_CONFIRM_BODY")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleLogout(true)} color="primary">
          {t("LOGOUT_CONFIRM_YES_BTN_TEXT")}
        </Button>
        <Button
          onClick={() => handleLogout(false)}
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {t("NO")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
