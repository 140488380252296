import React, { useMemo } from "react";
import { Icon, Tooltip, makeStyles, useTheme } from "@material-ui/core";
import { get, has, isEmpty, isNil, head, size } from "lodash";

const iconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  fontSize: "1.15rem",
};

const useStyles = makeStyles(() => ({
  innerSpan: {
    width: "100%",
    padding: 2,
  },
  buttonInter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: 5,
    cursor: "pointer",
    border: "none",
  },
}));

export default function InteractiveInputButton(props) {
  const {
    presentationInfo,
    schema,
    inputValue,
    inputRef,
    handleContextMenu,
    completeIdControl,
    selectionServices,
    disabledSelectionServicesDynamic,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const appTheme = theme?.palette?.type;

  const interactiveBtnDef = schema?.interactiveBtnDef;

  const isInputDisabled =
    presentationInfo && presentationInfo?.enabled === false;

  const btnDefValue = useMemo(() => {
    if (
      !isNil(interactiveBtnDef) &&
      !isNil(inputValue) &&
      !isEmpty(interactiveBtnDef) &&
      !isNil(interactiveBtnDef?.mapValuesIcon) &&
      !isEmpty(interactiveBtnDef?.mapValuesIcon) &&
      has(interactiveBtnDef?.mapValuesIcon, inputValue)
    ) {
      return get(interactiveBtnDef?.mapValuesIcon, inputValue);
    } else {
      return null;
    }
  }, [inputValue, interactiveBtnDef]);

  //Input styles
  const controlStyles = useMemo(() => {
    return {
      fontSize:
        presentationInfo &&
        presentationInfo.fontSize &&
        presentationInfo.fontSize,
      backgroundColor:
        presentationInfo && presentationInfo.backgroundColor
          ? presentationInfo.backgroundColor
          : !isNil(btnDefValue) && has(btnDefValue?.bg, appTheme)
          ? get(btnDefValue?.bg, appTheme)
          : `${theme.palette.content.mainColor}AA`,
      fontWeight:
        presentationInfo &&
        presentationInfo.fontWeight &&
        presentationInfo.fontWeight,
      fontStyle:
        presentationInfo &&
        presentationInfo.fontStyle &&
        presentationInfo.fontStyle,
    };
  }, [presentationInfo, btnDefValue, theme, appTheme]);

  const disabledItem = useMemo(() => {
    const selServicesSize = size(selectionServices);
    const disabledServicesSize = size(disabledSelectionServicesDynamic);

    if (selServicesSize === 1 && disabledServicesSize === 1) {
      return head(disabledSelectionServicesDynamic);
    } else {
      return null;
    }
  }, [disabledSelectionServicesDynamic, selectionServices]);

  if (isNil(btnDefValue)) {
    if (interactiveBtnDef?.hideStringNotMapped === true) {
      return null;
    } else {
      return <span className={classes.innerSpan}>{inputValue}</span>;
    }
  }

  return (
    <Tooltip
      title={
        !isNil(disabledItem)
          ? disabledItem?.disableInteractionText || ""
          : btnDefValue?.tooltip || ""
      }
    >
      <span className={classes.innerSpan}>
        <button
          className={classes.buttonInter}
          onClick={handleContextMenu}
          disabled={isInputDisabled || !isNil(disabledItem)}
          ref={inputRef}
          style={controlStyles}
          id={completeIdControl}
        >
          {!isNil(btnDefValue?.icon) && (
            <Icon
              className={btnDefValue?.icon}
              style={{
                ...iconStyles,
                color:
                  !isNil(btnDefValue?.color) &&
                  has(btnDefValue?.color, appTheme) &&
                  get(btnDefValue?.color, appTheme),
              }}
            />
          )}
        </button>
      </span>
    </Tooltip>
  );
}
