import React from "react";
import { makeStyles } from "@material-ui/core";
import { isEmpty, isNil, isString, toUpper } from "lodash";

import { useLiveClock } from "../../../core/hooks/useLiveClock";

const useStyles = makeStyles(() => ({
  timeLabel: {
    fontSize: "0.65rem",
    fontWeight: "400",
    lineHeight: "1",
    padding: 0,
    margin: 0,
  },
}));

export default function LiveClock({ lang }) {
  const { time, timeOffset } = useLiveClock();
  const classes = useStyles();

  const timeOffsetSt =
    !isNil(timeOffset) && !isEmpty(timeOffset) ? ` (${timeOffset})` : "";

  if (!isNil(lang) && !isEmpty(lang) && isString(lang)) {
    return (
      <p className={classes.timeLabel}>{`(${toUpper(
        lang
      )}) ${time}${timeOffsetSt}`}</p>
    );
  } else {
    return <p className={classes.timeLabel}>{time}</p>;
  }
}
