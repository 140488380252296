import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  CircularProgress,
  Drawer,
  Hidden,
  Icon,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import MenuLabelAndVersion from "./MenuLabelAndVersion";
import MenuDrawer from "../../../components/drawer/MenuDrawer";
import { drawerWidth } from "../LayoutStylesGlobals";

const useStyles = makeStyles((theme) => ({
  mobileDrawerPaper: {
    width: drawerWidth,
    background: `linear-gradient(180deg, ${theme.palette.drawer.upGradientColor} 70%, ${theme.palette.drawer.downGradientColor} 83%)`,
    color: theme.palette.drawer.text,
    boxShadow: "0px 2px 10px 0px rgba(50, 50, 50, 0.65)",
  },
  paperAnchorDockedLeft: {
    borderRight: "none",
  },
  fetchingCont: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

export default function MobileMenu(props) {
  const {
    mobileOpen,
    fetchingMenu,
    menuItems,
    pushPage,
    fetchMenuDef,
    handleClick,
    handleChildClick,
    handleGrandClick,
    handleLastClick,
    handleDrawerOpenState,
    setMobileOpen,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Hidden xsUp implementation="css">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        classes={{
          paper: classes.mobileDrawerPaper,
          paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
        }}
        onClose={() => handleDrawerOpenState(false)}
        SlideProps={{
          timeout: {
            appear: 300,
            enter: 300,
            exit: 400,
          },
        }}
      >
        {mobileOpen && (
          <div
            style={{
              position: "absolute",
              top: 1,
              right: 1,
              zIndex: 1,
            }}
          >
            <Tooltip title={t("TOOLTIP_DRAWER_CLOSE")} placement={"right"}>
              <IconButton onClick={() => setMobileOpen(false)}>
                <Icon
                  style={{ height: "auto", width: "auto" }}
                  className="fas fa-times"
                />
              </IconButton>
            </Tooltip>
          </div>
        )}
        <MenuLabelAndVersion isMobile={true} />

        {fetchingMenu === true ? (
          <div className={classes.fetchingCont}>
            <CircularProgress
              style={{
                color: "white",
                marginBottom: "25px",
              }}
            />
          </div>
        ) : (
          <MenuDrawer
            mode="prod"
            refreshMenu={fetchMenuDef}
            isMobile={true}
            pushPage={pushPage}
            drawerItems={menuItems}
            handleClick={handleClick}
            handleChildClick={handleChildClick}
            handleGrandClick={handleGrandClick}
            handleLastClick={handleLastClick}
          />
        )}
      </Drawer>
    </Hidden>
  );
}
