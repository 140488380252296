import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import useProducts from "../store/hooks/useProducts";
import { EMS_DEFAULT_PAGE_SIZE } from "../util/util-ems";
import usePagination from "../store/hooks/usePagination";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    gap: theme.spacing(1),
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const USER_CAN_CHANGE_PAGE_SIZE_ENABLED = false;

const Paginator = () => {
  const classes = useStyles();

  const { loadingProducts } = useProducts();

  const {
    page,
    size: pageSize,
    totalItems,
    totalPages,
    handlePageChange,
    handlePageSizeChange,
  } = usePagination();

  if (
    loadingProducts ||
    totalItems === 0 ||
    totalItems <= EMS_DEFAULT_PAGE_SIZE
  ) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(_, value) => handlePageChange(value)}
        color="primary"
        variant="outlined"
        shape="rounded"
      />
      {USER_CAN_CHANGE_PAGE_SIZE_ENABLED ? (
        <div className={classes.paginationContainer}>
          <Typography>Productos por página:</Typography>
          <Select
            value={pageSize}
            onChange={(e) => handlePageSizeChange(e.target.value)}
            className={classes.select}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Typography>
            {`${(page - 1) * pageSize + 1}-${Math.min(
              page * pageSize,
              totalItems
            )} de ${totalItems}`}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

export default Paginator;
