import {
  METHOD_POST,
  SERVER_HOST,
  SERVICE_CLI_URL,
} from "../../../../util/Constants";

import { isNil } from "lodash";

export class FenGeneradorJson {
  //Method to implement
  static async execute(props) {
    let toastContent = null;

    const { serviceBody, REQUEST_HEADERS } = props;

    const url = SERVER_HOST() + SERVICE_CLI_URL;
    const response = await fetch(url, {
      method: METHOD_POST,
      headers: REQUEST_HEADERS,
      body: JSON.stringify(serviceBody),
    })
      .then((res) => res.json())
      .then((jsonResult) => {
        return jsonResult;
      })
      .catch((error) => {
        return {
          ok: false,
          errorMessage: error,
          ...error,
        };
      });

    if (response) {
      const { ok, errorMessage, dataResult } = response;
      if (ok) {
        const dataParsed = JSON.parse(dataResult);
        if (dataParsed && dataParsed.length > 0) {
          const { file, fileName, msgError } = dataParsed[0];
          if (file && fileName && isNil(msgError)) {
            this.download(fileName, file);
          } else {
            toastContent = msgError;
          }
        }
      } else {
        toastContent = errorMessage;
      }
    }

    return {
      toastContent,
    };
  }

  static download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
