import React, { Component } from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "../../../routes/PrivateRoute";
import FrontendLogsPage from "../pages/FrontendLogsPage";


class LogRouter extends Component {
  render() {
    return (
      <Switch>
        {/* LOGs DASHBOARD */}
        <PrivateRoute exact path="/logging/list" component={FrontendLogsPage} />
      </Switch>
    );
  }
}

export default LogRouter;
