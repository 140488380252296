import { makeStyles } from "@material-ui/core/styles";
import { appBarHeight } from "../../LayoutStylesGlobals";

export const iconAuto = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 3,
  fontSize: "0.85rem",
};

export const closeIcon = {
  ...iconAuto,
  margin: 0,
  fontSize: 12,
};

const tabItemBase = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxHeight: 30,
  borderRadius: "0px 0px 10px 10px",
};

const tabButtonBase = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none",
  margin: 0,
  color: "inherit",
  backgroundColor: "transparent",
  minHeight: appBarHeight / 2,
};

const tabInnerBtnBase = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  marginRight: 3,
};

export const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    display: "flex",
    width: "100%",
    height: appBarHeight / 2,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 12px",
    gap: 10,
  },
  tabItemsContainer: {
    gap: "10px",
    width: "100%",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "85vw",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  tabItem: {
    ...tabItemBase,
    background: theme.palette.content.tabItemBg,
  },
  tabItemActive: {
    ...tabItemBase,
    background: theme.palette.content.tabItemActiveBg,
    // outline: "1px solid",
    transform: "scale(1.05)",
  },
  tabButton: {
    ...tabButtonBase,
  },
  tabButtonChanged: {
    ...tabButtonBase,
    fontStyle: "italic",
  },
  subTabItemsContainer: {
    display: "flex",
    width: "100%",
    height: appBarHeight / 2,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 12px",
    gap: 10,
  },
  subItem: {
    ...tabItemBase,
    background: theme.palette.content.tabItemBg,
    borderRadius: ({ showSubItemsInForm }) =>
      showSubItemsInForm ? 10 : "10px 10px 0px 0px",
  },
  subItemActive: {
    ...tabItemBase,
    background: theme.palette.content.tabItemActiveBg,
    borderRadius: ({ showSubItemsInForm }) =>
      showSubItemsInForm ? 10 : "5px 5px 0px 0px",
    // outline: "1px solid",
    transform: "scale(1.05)",
  },
  subTabButton: {
    ...tabButtonBase,
    minHeight: appBarHeight / 2 - 5,
  },
  subTabButtonChanged: {
    ...tabButtonBase,
    minHeight: appBarHeight / 2 - 5,
    fontStyle: "italic",
  },
  pinnedFinal: {
    ...tabInnerBtnBase,
    color: `${theme.palette.content.mainColor}`,
  },
  pinnedFinalDisabled: {
    ...tabInnerBtnBase,
    color: `${theme.palette.content.mainColor}`,
    "&:disabled": {
      color: `${theme.palette.content.mainColor}`,
      pointerEvents: "all",
    },
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  pinnedTemporary: {
    ...tabInnerBtnBase,
    transform: "rotate(180deg)",
    marginBottom: 6,
  },
  closeButton: {
    width: 15,
    height: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginInline: 5,
    border: "none",
    borderRadius: 5,
    backgroundColor: "transparent",
    color: theme.palette.content.mainColorContrastText,
    "&:hover": {
      borderRadius: 50,
      backgroundColor: `${theme.palette.content.mainColor}44`,
    },
  },
  favButton: {
    ...tabInnerBtnBase,
  },
  favButtonApplied: {
    ...tabInnerBtnBase,
    color: theme.palette.semaphore.red,
  },
}));
