import React, { useRef, useEffect, useState, useMemo } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import * as _ from "lodash";
import { Icon, IconButton, Typography, makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { copyTextToClipboard } from "../../../util/util-io";
import { createStringDateAsUTC } from "../../../util/UtilDates";
import { evaluateConditions } from "../../../util/UtilTable";

const useStyles = makeStyles((theme) => ({
  tooltipRoot: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.content.mainColor}`,
  },
  tooltipArrow: {
    color: `${theme.palette.content.mainColor}AA`,
  },
  tooltipTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    overflowWrap: "anywhere",
  },
  iconButton: {
    padding: 2,
  },
}));

function getChildStyle({
  column,
  actualHover,
  ignoreWidth,
  marginTop,
  doNotApplyMaxWidth,
  value
}) {

  return {
    textAlign: !_.isNil(column?.align)
      ? column?.align
      : column?.type === "numeric" || column?.type === "number"
        ? "right"
        : "left",
    width:
      doNotApplyMaxWidth === true
        ? undefined
        : !_.isNil(column?.width)
          ? column?.width
          : undefined,
    minWidth:
      doNotApplyMaxWidth === true && !_.isNil(column?.width)
        ? column?.width
        : undefined,
    maxWidth:
      doNotApplyMaxWidth === true
        ? undefined
        : !_.isNil(column?.maxWidth)
          ? column?.maxWidth
          : !_.isNil(column?.width)
            ? column?.width
            : ignoreWidth === true
              ? null
              : 150,
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    userSelect: "none",
    borderRadius: actualHover ? 2 : 0,
    marginTop:
      !_.isNil(marginTop) && _.toNumber(marginTop) > 0 ? marginTop : undefined,
    backgroundColor: actualHover ? "#3C7EE025" : "",
    ...evaluateConditions(value, column?.conditionals)
  };
}

function getTootltipPlacement({
  column,
  hoverStatus,
  value,
  isFirstContentColumn,
}) {
  let result = "left";

  if (hoverStatus === true && isValidLengthValueTooltip(value)) {
    result = "bottom";
  }

  if (
    !_.isNil(column) &&
    !_.isNil(column?.align) &&
    !_.isEmpty(column?.align)
  ) {
    if (_.isEqual(_.toUpper(column?.align), "RIGHT")) {
      result = "right";
    } else if (_.isEqual(_.toUpper(column?.align), "CENTER")) {
      result = "bottom";
    } else {
      result = "left";
    }
  }

  if (isFirstContentColumn) {
    result = "right";
  }

  return result;
}

function isValidLengthValueTooltip(value) {
  let result = false;

  if (!_.isNil(value) && !_.isEmpty(value) && _.toString(value).length < 200) {
    result = true;
  }

  return result;
}

const OverflowTooltip = ({
  value,
  column,
  propagateDoubleClick,
  isFirstContentColumn,
  ignoreWidth,
  overrideValueInCopy,
  ignoreShowTooltipText,
  applyFormatting = true,
  marginTop,
  doNotApplyMaxWidth,
}) => {
  const classes = useStyles();
  const elementRef = useRef();
  const [hoverStatus, setHover] = useState(false);
  const [copied, setCopied] = useState(false);
  const [actualHover, setActualHover] = useState(false);

  const compareSize = () => {
    if (
      !_.isNil(elementRef) &&
      !_.isNil(elementRef.current) &&
      (_.isNil(ignoreShowTooltipText) || ignoreShowTooltipText === false)
    ) {
      const compare =
        elementRef.current.scrollWidth > elementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => window.removeEventListener("resize", compareSize);
    // eslint-disable-next-line
  }, []);

  async function handleCopy() {
    if (!_.isNil(overrideValueInCopy)) {
      const stVal = _.toString(overrideValueInCopy);
      if (!_.isEmpty(stVal)) {
        await copyTextToClipboard(stVal.trim());
      }
    } else if (!_.isNil(value)) {
      const stVal = _.toString(value);
      if (!_.isEmpty(stVal)) {
        await copyTextToClipboard(stVal.trim());
      }
    }
    setCopied(true);
  }

  function doubleClickOnParent() {
    if (
      !_.isNil(elementRef) &&
      !_.isNil(elementRef.current) &&
      !_.isNil(elementRef.current.parentNode)
    ) {
      const doubleClickEvent = new MouseEvent("dblclick", {
        bubbles: true,
        cancelable: true,
      });
      elementRef.current.parentNode.dispatchEvent(doubleClickEvent);
    }
  }

  const displayValue = useMemo(() => {
    if (column?.showTime && applyFormatting === true) {
      try {
        if (!value || value.trim() === "") {
          return value;
        }
        const date = createStringDateAsUTC(value);
        if (!isNaN(date)) {
          return format(date, "yyyy-MM-dd HH:mm:ss");
        } else {
          return value;
        }
      } catch (error) {
        return value;
      }
    } else {
      return value;
    }
  }, [value, column, applyFormatting]);

  return (
    <Tooltip
      arrow
      classes={{ tooltip: classes.tooltipRoot, arrow: classes.tooltipArrow }}
      title={
        <div className={classes.tooltipTitle}>
          <IconButton
            className={classes.iconButton}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleCopy();
            }}
          >
            <Icon
              className={copied ? "fas fa-check-square" : "fas fa-copy"}
              style={{ fontSize: 14 }}
            />
          </IconButton>
          {hoverStatus && isValidLengthValueTooltip(value) && (
            <Typography color="inherit" style={{ marginLeft: 4 }}>
              {value}
            </Typography>
          )}
        </div>
      }
      interactive
      style={{
        fontSize: "2em",
      }}
      placement={getTootltipPlacement({
        column,
        hoverStatus,
        value,
        isFirstContentColumn,
      })}
      onDoubleClick={(e) => {
        if (propagateDoubleClick === true) {
          e.preventDefault();
          e.stopPropagation();
          doubleClickOnParent();
        }
      }}
      onClose={() => setActualHover(false)}
      onOpen={() => setActualHover(true)}
    >
      <div
        ref={elementRef}
        style={getChildStyle({
          column,
          hoverStatus,
          actualHover,
          ignoreWidth,
          marginTop,
          doNotApplyMaxWidth,
          value
        })}
      >
        {displayValue}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
