import React, { useState } from "react";
import usePolicy from "../../../store/hooks/usePolicy";
import { Link, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IconButton, Icon } from "@material-ui/core";
import CustomFileViewer from "../../../../../components/dialogs/fileChooserDialog/CustomFileViewer";



const useStyles = makeStyles((theme) => ({
  box: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9,
  },
  docContainer: {
    background: "white",
    borderRadius: 8, 
    width: "50%",
    height: "70%",
    position: "relative",
    boxShadow: "10px 3px 13px -2px rgba(0, 0, 0, 0.75)",
    zIndex: 9,
  },
  closeButton: {
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#fffff",
    position: "absolute",
    top: 5,
    right: 15,
    zIndex: 1001,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function EmployeeStorePolicy({ footer }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { policyInfo, fetchPolicy } = usePolicy();
  const [isOpen, setIsOpen] = useState(false);

 
  const handleBtnClick = () => {
    fetchPolicy();
    setIsOpen(true);
  };

  const handleBtnCloseClick = () => {
    setIsOpen(false);
  }; 

  return (
    <div className={classes.container}>
      <Link component="button" onClick={handleBtnClick} variant="body2">
        {footer
          ? t("EMPLOYEE_STORE.PRIVACY_POLICY")
          : t("EMPLOYEE_STORE.ACCEPT_PRIVACY_POLICY")}
      </Link>
      {isOpen && policyInfo ? (
        <div className={classes.box}>
          <div className={classes.docContainer}>
          <CustomFileViewer
              isExternalDialog
              id={policyInfo?.[0]?.key}
              keyViewer={policyInfo?.[0]?.key}
              fileType={policyInfo?.[0]?.fileType}
              filePath={policyInfo?.[0]?.uri}
            />  
            <IconButton
              onClick={handleBtnCloseClick}
              className={classes.closeButton}
            >
              <Icon className={"fas fa-times"} />
            </IconButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}
