import * as base64 from "base-64";
import { has, isEmpty, isNil } from "lodash";

import { METHOD_POST, REST_HEADERS } from "../../../../util/Constants";

export class PlvPrintLabelService {
  //Method to implement
  static async execute(props, errorMessage, serviceId, formInstanceId) {
    if (
      !isNil(props) &&
      !isNil(props.serviceBody) &&
      !isNil(props.serviceBody.requestData) &&
      !isEmpty(props.serviceBody.requestData) &&
      has(props.serviceBody.requestData, "params")
    ) {
      const { params } = props.serviceBody.requestData;
      const { printer_ip, printer_port, label_text, pservice_url } = params;
      try {
        const bodyParams = {
          ipPrinter: printer_ip,
          portPrinter: Number.parseInt(printer_port),
          dataToPrint: base64.encode(label_text),
          serviceId,
          formInstId: formInstanceId,
        };
        const response = await fetch(pservice_url, {
          body: JSON.stringify(bodyParams),
          method: METHOD_POST,
          headers: REST_HEADERS,
        });

        if (response) {
          const jsonResponse = await response.json();
          if (jsonResponse) {
            const { ok, msgError } = jsonResponse;
            if (ok) {
              return [true, ""];
            } else {
              return [false, `${errorMessage}: ${msgError}`];
            }
          }
        } else {
          return [
            false,
            `${errorMessage}: No fue posible conectar con servicio de impresión [${pservice_url}]`,
          ];
        }
      } catch (e) {
        return [
          false,
          `${errorMessage}: No fue posible conectar con servicio de impresión [${pservice_url}]`,
        ];
      }
    } else {
      return [false, `Servicio definido incorrectamente. Contactar a Soporte`];
    }
  }
}
