import React from "react";
import { Icon } from "@material-ui/core";

import { iconAuto } from "../TabStyles";

const iconFormHasChanged = {
  ...iconAuto,
  fontSize: 8,
  marginLeft: 3,
  marginRight: 0,
  color: "orange",
};

export default function SubHasChangedIcon() {
  return <Icon className="fas fa-circle" style={iconFormHasChanged} />;
}
