import { makeStyles } from "@material-ui/core/styles";

import { appBarHeight, drawerWidth } from "../../LayoutStylesGlobals";

export const useStyles = makeStyles((theme) => ({
  sidebarClosed: {
    width: appBarHeight,
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    //marginTop: appBarHeight,
    //backgroundColor: `${theme.palette.header.backgroundColor}EE`,
    backgroundColor: `${theme.palette.header.backgroundColor}`,
    alignItems: "center",
    justifyContent: "space-between",
    borderRight: `1px solid ${theme.palette.content.mainColorContrastText}AA`,
    padding: "10px 0px",
    zIndex: 4,
    //exp
    // flexDirection: "row",
    // height: appBarHeight,
    // width: "100%",
    // border: "none",
    // padding: "0px 10px",
  },
  sidebarOpen: {
    width: drawerWidth,
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    marginTop: appBarHeight,
    backgroundColor: `${theme.palette.header.backgroundColor}EE`,
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderRight: `1px solid ${theme.palette.content.mainColorContrastText}AA`,
    padding: 10,
    zIndex: 2,
  },
  titleText: {
    fontWeight: "bold",
  },
  toggleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  toggleContainerClosed: {},
  toggleSideBarButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    border: `1px solid ${theme.palette.content.mainColorContrastText}AA`,
    backgroundColor: `${theme.palette.header.backgroundColor}`,
    borderRadius: 10,
    marginBottom: 20,
  },
  topButtonsClosed: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    //exp
    // flexDirection: "row",
    // gap: 5,
  },
  topButtonsOpen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  bottomButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    //exp
    // flexDirection: "row",
    // gap: 5,
  },
}));
