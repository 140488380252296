import { makeStyles } from "@material-ui/core/styles";

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};
export const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: "100vw",
    height: "100vh",
  },
  dialogTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    cursor: "move",
  },
  dialogTitleContentIcon: {
    display: "flex",
    flexDirection: "row",
  },
  dialogTitleContentText: {
    fontSize: "1.2em",
    marginLeft: 5,
  },
  dialogContent: {
    height: "100%",
    backgroundColor: `${theme.palette.content.mainColor}11`,
  },
  fcc100: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  graphvizContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "red",
  },
}));
