import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "3em",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#close-tab-item-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
export default function CloseTabDialog({ open, handleAction }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => handleAction(false)}
      PaperComponent={PaperComponent}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        className={classes.root}
        id="close-tab-item-dialog-title"
      >
        {t("LEAVE_WORK_CONFIRMATION_DIALOG_TITLE")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ wordBreak: "break-word" }}>
          {t("MULTI_ITEM_MOVE_AWAY")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleAction(true)}
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {t("YES")}
        </Button>
        <Button onClick={() => handleAction(false)} color="primary">
          {t("FORM_CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
