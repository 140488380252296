import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { Link, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 14,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#qa-environment-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function QAEnvDialog({ open, handleClose }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="qa-environment-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="qa-environment-dialog-title">
        {t("LOGIN_QA_DIALOG_TITLE")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("LOGIN_QA_DIALOG_BODY")}{" "}
          <Link
            href="https://lor.dsuite.cloud"
            target="_blank"
            className={classes.link}
          >
            https://lor.dsuite.cloud
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("LOGIN_QA_DIALOG_CONFIRM_BTN")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
