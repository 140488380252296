import React, { useState, useEffect, useRef } from "react";
import { Button, Icon, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  //defineInputEnabledByOperation,
  getCompletePath,
  resolveFieldAgainstShadowStatus,
  resolveValuesFieldAgainsShadowStatus,
} from "../../../util/UtilForm";
import {
  INITIAL_OPEN_EXTERNAL_FORM_STATE,
  FORM_OPERATION_NEW,
  TOAST_CONTAINER_FORM_CONTAINER,
} from "../../../util/Constants";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import ExternalFormContainerValues from "../externalFormContainer/ExternalFormContainerValues";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  margin: 0,
  fontSize: "1rem",
};

const DEFAULT_ICON = "fas fa-external-link-alt";

export default function SimpleLaunchExternalFormField(props) {
  const { t } = useTranslation();
  const { idSchema, schema, formData, formContext } = props;
  const { presentationInfo: pi } = formData || false;
  const {
    section,
    //operation,
    block,
    pre,
    shadowStatus,
    isBlockDisabledByWizard,
  } = formContext;
  const buttonRef = useRef(null);
  const [presentationInfo, setPresentationInfo] = useState(null);
  const theme = useTheme();
  const [openExternalForm, setOpenExternalForm] = useState(
    INITIAL_OPEN_EXTERNAL_FORM_STATE
  );

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  const inputTextAlign = schema?.textAlign || "left";

  //Input styles
  const controlStyles = {
    padding: 3,
    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,
    backgroundColor:
      presentationInfo && presentationInfo.backgroundColor
        ? presentationInfo.backgroundColor
        : `${theme.palette.content.mainColor}AA`,
    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
    width: "100%",
    borderRadius: 5,
    marginBlock: 5,
    "&:hover": {
      backgroundColor: "green",
      color: "#FFF",
    },
  };

  const isInputDisabled =
    (presentationInfo && presentationInfo?.enabled === false) ||
    isBlockDisabledByWizard === true;

  const isOnlyInput =
    schema && !_.isNil(schema.onlyInput) ? schema.onlyInput : false;

  const launcherDef =
    schema && !_.isNil(schema.launcherDef) && !_.isEmpty(schema.launcherDef)
      ? schema.launcherDef
      : {
          openFormConfig: null,
          icon: DEFAULT_ICON,
          label: null,
          tooltip: t("QUERY_OPEN_FORM_ENABLED"),
        };

  const titleToShow = launcherDef?.label || schema?.title;

  const titleTooltip =
    !_.isNil(launcherDef) &&
    !_.isNil(launcherDef.title) &&
    !_.isEmpty(launcherDef.title)
      ? launcherDef?.title
      : null;

  async function handleClick() {
    if (!_.isNil(launcherDef?.openFormConfig)) {
      const {
        idForm,
        path,
        defaulOpertion,
        values,
        overrideOps,
        defaultWidth,
        defaultHeight,
      } = launcherDef?.openFormConfig || {};

      if (_.isNil(idForm) || _.isEmpty(idForm)) {
        const toastText = t("EXTERNAL_FORM_ERROR_OPENING_FIELD_MISSING", {
          q: "idForm",
        });
        toast.error(toastText, {
          containerId: TOAST_CONTAINER_FORM_CONTAINER,
          toastId: "idForm-empty",
          autoClose: false,
        });
        return;
      }

      if (_.isNil(path) || _.isEmpty(path)) {
        const toastText = t("EXTERNAL_FORM_ERROR_OPENING_FIELD_MISSING", {
          q: "path",
        });
        toast.error(toastText, {
          containerId: TOAST_CONTAINER_FORM_CONTAINER,
          toastId: "path-empty",
          autoClose: false,
        });
        return;
      }

      if (_.isNil(values) || _.isEmpty(values)) {
        const toastText = t("EXTERNAL_FORM_ERROR_OPENING_FIELD_MISSING", {
          q: "values",
        });
        toast.error(toastText, {
          containerId: TOAST_CONTAINER_FORM_CONTAINER,
          toastId: "values-empty",
          autoClose: false,
        });
        return;
      }

      const valuesToSet = resolveValuesFieldAgainsShadowStatus({
        values,
        shadowStatus,
        completeIdControl,
      });

      const idFormToSet = resolveFieldAgainstShadowStatus({
        field: idForm,
        shadowStatus,
        completeIdControl,
      });

      const pathToSet = resolveFieldAgainstShadowStatus({
        field: path,
        shadowStatus,
        completeIdControl,
      });

      setOpenExternalForm({
        open: true,
        operation: defaulOpertion,
        values: valuesToSet,
        idForm: idFormToSet,
        path: pathToSet,
        overrideOps,
        defaultWidth,
        defaultHeight,
      });
    }
  }

  function handleBackClick() {
    setOpenExternalForm(INITIAL_OPEN_EXTERNAL_FORM_STATE);
  }

  return (
    <>
      {openExternalForm.open && (
        <ExternalFormContainerValues
          openModal={openExternalForm.open}
          handleOpenModalForm={handleBackClick}
          operation={openExternalForm.operation || FORM_OPERATION_NEW}
          path={openExternalForm.path}
          idForm={openExternalForm.idForm}
          values={openExternalForm.values}
          overrideOps={openExternalForm.overrideOps}
          defaultWidth={openExternalForm.defaultWidth}
          defaultHeight={openExternalForm.defaultHeight}
        />
      )}
      <SimpleFieldContainer
        schema={schema}
        completeIdControl={completeIdControl}
        pre={pre}
        childAlign={inputTextAlign}
        presentationInfo={presentationInfo}
      >
        <Tooltip
          title={titleTooltip || ""}
          disableHoverListener={isInputDisabled || _.isNil(titleTooltip)}
        >
          <Button
            onClick={handleClick}
            disabled={isInputDisabled}
            ref={buttonRef}
            style={controlStyles}
            id={completeIdControl}
          >
            {isOnlyInput ||
            (!_.isNil(launcherDef?.label) && launcherDef?.label !== "")
              ? titleToShow
              : null}
            <Icon
              className={launcherDef?.icon || DEFAULT_ICON}
              style={
                isOnlyInput ||
                (!_.isNil(launcherDef?.label) && launcherDef?.label !== "")
                  ? { ...commonIcon, marginLeft: 5 }
                  : commonIcon
              }
            />
          </Button>
        </Tooltip>
      </SimpleFieldContainer>
    </>
  );
}
