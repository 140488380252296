import React, { useMemo } from "react";
import { Button, Icon } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const ICON_REFRESH = "fas fa-sync";

const iconStyles = { fontSize: "14px", marginRight: "5px" };

function RefreshMenuButton({ handleRefreshMenu }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const buttonStyles = useMemo(() => {
    return {
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.drawer.refreshButtonBackgroundColor,
      borderRadius: 0,
    };
  }, [theme]);

  return (
    <Button onClick={handleRefreshMenu} style={buttonStyles}>
      <Icon className={ICON_REFRESH} style={iconStyles} />
      {t("REFRESH_MENU")}
    </Button>
  );
}

export default RefreshMenuButton;
