import {
  isNil,
  isEmpty,
  filter,
  orderBy,
  toSafeInteger,
  size,
  isArray,
  trim,
  toString,
  toUpper,
} from "lodash";

export const BALANCE_MODE_BY_PRIORITY = "PRIORITY";
export const BALANCE_MODE_BY_AGE = "AGE";
export const BALANCE_MODE_EQUAL_PARTS = "EQUALS";
export const BALANCE_MODE_MANUAL = "MANUAL";

export const FIELD_UNIT = "unitMeasure";

export const COL_ASIGN = "asignar";
export const COL_ASIGN_BY_UNIT = "asignarByUnit";

export const COL_PRIORITY = "prioridad";
export const COL_AGE = "anticipo";
export const COL_CANT_REQUESTED = "cantSolic";
export const COL_FILL_RATE = "fillRate";
export const COL_ORDER_NUMBER = "nroPedido";
export const COL_CRITERIA = "criterio";
export const COL_STATUS = "status";
export const COL_STATUS_CODE = "codestado";

export const COL_STATUS_SKUS = "statusSkus";
export const COL_STATUS_SKUS_ERROR_VALUE = "E";
export const COL_STATUS_SKUS_WARNING_VALUE = "W";
export const COL_STATUS_SKUS_OK_VALUE = "O";

export const MAP_CRITERIA_ICON = {
  [BALANCE_MODE_BY_PRIORITY]: "fas fa-sort-numeric-down",
  [BALANCE_MODE_BY_AGE]: "fas fa-clock",
  [BALANCE_MODE_EQUAL_PARTS]: "fas fa-balance-scale",
  [BALANCE_MODE_MANUAL]: "fas fa-user-edit",
};

export const STATUS_PENDING = "P";
export const STATUS_JUDGED = "A";

export const MAP_STATUS_ICON = {
  [STATUS_PENDING]: { icon: "fas fa-hourglass-half", color: "yellow" },
  [STATUS_JUDGED]: { icon: "fas fa-balance-scale", color: "green" },
};

export const APPLY_UNIT_DISTRIBUTION = true;

export const INITIAL_BALANCE_MODE = {
  counter: 0,
  mode: BALANCE_MODE_MANUAL,
};

export const INITIAL_PRE_PROCESS_JUDGING_DIALOG_STATE = {
  open: false,
  items: new Map(),
  balanceMode: null,
};

export const INITIAL_PROCESSING_JUDGING_STATE = {
  mode: null,
  loading: false,
};

export const INITIAL_PROCESSING_ACTION_JUDGING_STATE = {
  action: null,
  loading: false,
};

export const SHOW_FILTER_BAR_ON_LIST = true;

export function getDistributedValuesInRowsByDistributionMap({
  rows,
  distributionMap,
}) {
  if (!isNil(distributionMap) && !isEmpty(distributionMap)) {
    return filter(rows, function (row) {
      return !isNil(row);
    }).map((row, index) => {
      if (distributionMap.has(index)) {
        if (APPLY_UNIT_DISTRIBUTION) {
          return {
            ...row,
            [COL_ASIGN]: distributionMap.get(index),
            [COL_ASIGN_BY_UNIT]: distributionMap.get(index),
          };
        } else {
          return {
            ...row,
            [COL_ASIGN]: distributionMap.get(index),
          };
        }
      } else {
        return row;
      }
    });
  } else {
    return rows || [];
  }
}

export function distributeByPriority({
  initialBalanceNumber,
  rows,
  rowsCount,
  unitMeasure,
}) {
  if (rowsCount > 0) {
    const distributionMap = new Map();
    // sort by priority
    const sortedRows = orderBy(rows, [COL_PRIORITY], ["asc"]);
    // initialize remining balance as initial
    let remainingBalance = initialBalanceNumber;
    for (let i = 0; i < sortedRows.length; i++) {
      const row = sortedRows[i];
      const assignedAmount = APPLY_UNIT_DISTRIBUTION
        ? getAmountByUnit({
            balance: remainingBalance,
            cantRequested: row?.[COL_CANT_REQUESTED],
            unitMeasure,
          })
        : Math.min(row?.[COL_CANT_REQUESTED], remainingBalance);
      distributionMap.set(rows.indexOf(row), assignedAmount);
      remainingBalance -= assignedAmount;
    }

    return getDistributedValuesInRowsByDistributionMap({
      rows,
      distributionMap,
    });
  } else {
    return rows || [];
  }
}

export function distributeByAge({
  initialBalanceNumber,
  rows,
  rowsCount,
  unitMeasure,
}) {
  if (rowsCount > 0) {
    const distributionMap = new Map();
    const sortedRows = orderBy(rows, [COL_AGE, COL_PRIORITY], ["desc", "asc"]);
    let remainingBalance = initialBalanceNumber;
    for (let i = 0; i < sortedRows.length; i++) {
      const row = sortedRows[i];
      const assignedAmount = APPLY_UNIT_DISTRIBUTION
        ? getAmountByUnit({
            balance: remainingBalance,
            cantRequested: row?.[COL_CANT_REQUESTED],
            unitMeasure,
          })
        : Math.min(row?.[COL_CANT_REQUESTED], remainingBalance);
      distributionMap.set(rows.indexOf(row), assignedAmount);
      remainingBalance -= assignedAmount;
    }
    return getDistributedValuesInRowsByDistributionMap({
      rows,
      distributionMap,
    });
  } else {
    return rows || [];
  }
}

export function getMinimunMultiple(initial, unitMeasure) {
  const rest = Math.ceil(initial / unitMeasure);
  const minMultiplo = rest * unitMeasure;
  return initial <= 0 ? 0 : initial < unitMeasure ? unitMeasure : minMultiplo;
}

export function distributeEquals({
  initialBalanceNumber,
  rows,
  rowsCount,
  unitMeasure,
}) {
  if (rowsCount > 0) {
    const amountPerRow = Math.floor(initialBalanceNumber / rowsCount);
    const extraRows = initialBalanceNumber % rowsCount;
    const distributionMap = new Map();
    let remainingBalance = initialBalanceNumber;

    // First iteration, asing cant equal for all rows that fulfill condition
    for (let i = 0; i < rowsCount; i++) {
      const requestedAmount = rows[i]?.[COL_CANT_REQUESTED] || 0;

      if (requestedAmount >= amountPerRow) {
        const amountToSet = amountPerRow + (i < extraRows ? 1 : 0);
        distributionMap.set(i, amountToSet);
        remainingBalance -= amountToSet;
      }
    }

    // Second iteration, asing remaining to other rows that did not received in first
    for (let i = 0; i < rowsCount; i++) {
      if (!distributionMap.has(i)) {
        const requestedAmount = rows[i]?.[COL_CANT_REQUESTED] || 0;
        const amountToSet = Math.min(requestedAmount, remainingBalance);
        distributionMap.set(i, amountToSet);
        remainingBalance -= amountToSet;
      }
    }

    // Reasing remaining
    if (remainingBalance > 0) {
      for (let i = 0; i < rowsCount; i++) {
        if (distributionMap.has(i)) {
          const assignedAmount = distributionMap.get(i);
          const requestedAmount = rows[i]?.[COL_CANT_REQUESTED] || 0;
          const additionalAmount = Math.min(
            remainingBalance,
            requestedAmount - assignedAmount
          );
          distributionMap.set(i, assignedAmount + additionalAmount);
          remainingBalance -= additionalAmount;
          if (remainingBalance <= 0) break;
        }
      }
    }

    const originalRowsWithoutUnit = getDistributedValuesInRowsByDistributionMap(
      {
        rows,
        distributionMap,
      }
    );

    if (
      APPLY_UNIT_DISTRIBUTION &&
      !isNil(originalRowsWithoutUnit) &&
      isArray(originalRowsWithoutUnit) &&
      !isEmpty(originalRowsWithoutUnit)
    ) {
      const calculatedByUnit = originalRowsWithoutUnit.map((x) => {
        return {
          ...x,
          [COL_ASIGN]: getMinimunMultiple(x?.[COL_ASIGN], unitMeasure),
          [COL_ASIGN_BY_UNIT]: getMinimunMultiple(
            x?.[COL_ASIGN_BY_UNIT],
            unitMeasure
          ),
        };
      });

      return calculatedByUnit;
    } else {
      return originalRowsWithoutUnit || [];
    }
  } else {
    return rows || [];
  }
}

export function applyBalanceMode({
  initialBalance,
  rows: initialRows,
  balanceMode,
  unitMeasure,
}) {
  const rows =
    !isNil(initialRows) && isArray(initialRows) && !isEmpty(initialRows)
      ? filter(initialRows, function (r) {
          return (
            !isNil(r) &&
            toUpper(trim(toString(r?.[COL_STATUS_SKUS]))) !==
              COL_STATUS_SKUS_ERROR_VALUE
          );
        })
      : [];

  const ignoredRowsAtStart =
    !isNil(initialRows) && isArray(initialRows) && !isEmpty(initialRows)
      ? filter(initialRows, function (r) {
          return (
            !isNil(r) &&
            toUpper(trim(toString(r?.[COL_STATUS_SKUS]))) ===
              COL_STATUS_SKUS_ERROR_VALUE
          );
        })
      : [];

  if (!isNil(balanceMode)) {
    const initialBalanceNumber = toSafeInteger(initialBalance);
    const rowsCount = size(rows);
    if (balanceMode === BALANCE_MODE_BY_AGE) {
      const rowsDistributedByAge = distributeByAge({
        initialBalanceNumber,
        rows,
        rowsCount,
        unitMeasure,
      });

      const orderedRowsByAge = orderBy(
        rowsDistributedByAge,
        [COL_AGE, COL_PRIORITY],
        ["desc", "asc"]
      );

      return [...orderedRowsByAge, ...ignoredRowsAtStart];
    } else if (balanceMode === BALANCE_MODE_BY_PRIORITY) {
      const rowsDistributedByPriority = distributeByPriority({
        initialBalanceNumber,
        rows,
        rowsCount,
        unitMeasure,
      });

      const orderedRowsByPriority = orderBy(
        rowsDistributedByPriority,
        [COL_PRIORITY],
        ["asc"]
      );
      return [...orderedRowsByPriority, ...ignoredRowsAtStart];
    } else if (balanceMode === BALANCE_MODE_EQUAL_PARTS) {
      const rowsDistributedByEquals = distributeEquals({
        initialBalanceNumber,
        rows,
        rowsCount,
        unitMeasure,
      });

      const orderedRowsEquals = orderBy(
        rowsDistributedByEquals,
        [COL_ORDER_NUMBER],
        ["asc"]
      );
      return [...orderedRowsEquals, ...ignoredRowsAtStart];
    } else {
      return initialRows;
    }
  } else {
    return initialRows;
  }
}

export function getAmountByUnit({ cantRequested, unitMeasure, balance }) {
  const intermediate = Math.ceil(cantRequested / unitMeasure) * unitMeasure;
  const lower = Math.min(balance, intermediate);
  if (balance < unitMeasure) {
    return balance;
  }
  const finalResult = lower - (lower % unitMeasure);

  return finalResult;
}

export const getIconDefByColStatusSkus = ({ value }) => {
  if (toUpper(trim(toString(value))) === COL_STATUS_SKUS_ERROR_VALUE) {
    return {
      icon: "fas fa-ban",
      color: "red",
    };
  } else if (toUpper(trim(toString(value))) === COL_STATUS_SKUS_WARNING_VALUE) {
    return {
      icon: "fas fa-exclamation-triangle",
      color: "yellow",
    };
  } else {
    return {
      icon: "fas fa-check-circle",
      color: "green",
    };
  }
};
