import React from "react";
import { Icon, IconButton, Typography } from "@material-ui/core";

import useStatusBadge from "../../core/hooks/useStatusBadge";
import { commonIconStyles, useStyles } from "./StatusBadgeStyle";
import { useNetwork } from "../../core/hooks/useNetwork";
import { IS_NEW_LOGIN_ENABLED } from "../../util/Constants";

export default function StatusBadge({ showLabel }) {
  const classes = useStyles();
  const { status, error, getStatusInfo } = useStatusBadge();
  const { online } = useNetwork();

  return (
    <div className={classes.container}>
      <div className={classes.rotateItem}>
        <IconButton className={classes.buttonBadge}>
          <Icon
            className="fas fa-circle"
            style={{
              ...commonIconStyles,
              color: getStatusInfo(status, error, online)?.color,
              fontSize: 14,
            }}
          />
        </IconButton>
      </div>
      {showLabel && (
        <Typography
          className={IS_NEW_LOGIN_ENABLED === true ? classes.typo : ""}
        >
          {status && !error && online ? status : ""}
          {getStatusInfo(status, error, online)?.msg}
        </Typography>
      )}
    </div>
  );
}
