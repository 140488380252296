import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  fridayStoredFilters: null,
  fridayStoredCollapsed: false,
  fridayStoredShowBpmDialog: false,
  isEditingFridayForm: false,
  fridayStoredBreadcumbRoutes: new Map(),
};

export const createFridaySlice = (set, get) => ({
  ...INITIAL_STATE,
  setFridayStoredFilters: (newState) =>
    set(() => ({ fridayStoredFilters: newState })),
  setFridayStoredCollapsed: (newState) =>
    set(() => ({ fridayStoredCollapsed: newState })),
  setFridayStoredShowBpmDialog: (newState) =>
    set(() => ({ fridayStoredShowBpmDialog: newState })),
  addFridayBreadcumbRoute: (newRoute) =>
    set((state) => ({
      fridayStoredBreadcumbRoutes: state.fridayStoredBreadcumbRoutes.set(newRoute, {
        fridayStoredFilters: state.fridayStoredFilters,
        fridayStoredCollapsed: state.fridayStoredCollapsed,
        fridayStoredShowBpmDialog: state.fridayStoredShowBpmDialog,
      }),
    })),
  removeFridayBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().fridayStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          fridayStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          fridayStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateFridayBreadcumbRoute: (route, newParams) => {
    const actualBC = get().fridayStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      fridayStoredBreadcumbRoutes: state.fridayStoredBreadcumbRoutes.set(route, {
        ...spreadBC,
        ...newParams,
      }),
    }));
  },
  toggleIsEditingFridayForm: () => {
    set((state) => ({ isEditingFridayForm: !state.isEditingFridayForm }));
  },
  clear: () => set(INITIAL_STATE),
});
