import * as _ from "lodash";
import { v4 as uuid } from "uuid";
import { format, isValid, parse } from "date-fns";

import {
  API_FORM_SERVICE,
  METHOD_GET,
  METHOD_POST,
  SEARCH_CRITERIA_LIKE,
  SERVER_HOST,
  SEARCH_CRITERIA_EQUAL,
  FRONT_DATE_TIME_PATTERN,
  FORM_OPERATON_EDIT,
  FORM_OPERATION_NEW,
  FORM_OPERATION_EXECUTION,
  FRONT_DATE_PATTERN,
  DATE_PATTERN,
  SEARCH_CRITERIA_BETWEEN,
  SEARCH_CRITERIA_DISTINCT,
  SEARCH_CRITERIA_GREATER,
  SEARCH_CRITERIA_LESS,
} from "./Constants";
import { createStringDateAsUTC } from "./UtilDates";
export const SUBMIT_FIELD = "submitted";

//Handle table labels locales by lang key es, en, etc
export const tableLocales = [
  //ENGLISH
  {
    key: "en",
    properties: {
      body: {
        emptyDataSourceMessage: "There are no records to show",
        addTooltip: "Add",
        deleteTooltip: "Delete",
        editTooltip: "Update",
        filterRow: {
          filterTooltip: "Filter",
        },
        editRow: {
          deleteText: "Do you want to delete this line?",
          cancelTooltip: "Cancel",
          saveTooltip: "Confirm",
        },
      },
      grouping: {
        placeholder: "* Drag column names here to group...",
        groupedBy: "Grouped by:",
      },
      header: {
        actions: "Actions",
      },
      pagination: {
        labelDisplayedRows: "{from}-{to} of {count}",
        labelRowsSelect: "rows",
        labelRowsPerPage: "rows per page:",
        firstAriaLabel: "First page",
        firstTooltip: "First page",
        previousAriaLabel: "Prev. Page",
        previousTooltip: "Prev. Page",
        nextAriaLabel: "Next Page",
        nextTooltip: "Next Page",
        lastAriaLabel: "Last Page",
        lastTooltip: "Last Page",
      },
      toolbar: {
        addRemoveColumns: "Add or remove columns",
        nRowsSelected: "{0} lines (s) selected (s)",
        showColumnsTitle: "See columns",
        showColumnsAriaLabel: "See columns",
        exportTitle: "Export",
        exportAriaLabel: "Export",
        exportName: "Export CSV",
        searchTooltip: "Search",
        searchPlaceholder: "Search",
        exportCSVName: "Export Excel",
        exportPDFName: "Export PDF",
      },
    },
  },
  //SPANISH
  {
    key: "es",
    properties: {
      body: {
        emptyDataSourceMessage: "No hay registros que mostrar",
        addTooltip: "Añadir",
        deleteTooltip: "Borrar",
        editTooltip: "Editar",
        filterRow: {
          filterTooltip: "Filtrar",
        },
        editRow: {
          deleteText: "¿Quieres borrar esta línea?",
          cancelTooltip: "Cancelar",
          saveTooltip: "Confirmar",
        },
      },
      grouping: {
        placeholder:
          "* Arrastre aqui los nombres de las columnas para agrupar...",
        groupedBy: "Agrupado por:",
      },
      header: {
        actions: "Acciones",
      },
      pagination: {
        labelDisplayedRows: "{from}-{to} de {count}",
        labelRowsSelect: "filas",
        labelRowsPerPage: "filas por página:",
        firstAriaLabel: "Primer página",
        firstTooltip: "Primer página",
        previousAriaLabel: "Pagina ant.",
        previousTooltip: "Pagina ant.",
        nextAriaLabel: "Pagina sig.",
        nextTooltip: "Pagina sig.",
        lastAriaLabel: "Última página",
        lastTooltip: "Última página",
      },
      toolbar: {
        addRemoveColumns: "Agregar o quitar columnas",
        nRowsSelected: "{0} líneas (s) seleccionadas (s)",
        showColumnsTitle: "Ver columnas",
        showColumnsAriaLabel: "Ver columnas",
        exportTitle: "Exportar",
        exportAriaLabel: "Exportar",
        exportName: "Exportar en CSV",
        searchTooltip: "Buscar",
        searchPlaceholder: "Buscar",
        exportCSVName: "Exportar a Excel",
        exportPDFName: "Exportar a PDF",
      },
    },
  },
  //PORTUGUESE
  {
    key: "pt",
    properties: {
      body: {
        emptyDataSourceMessage: "Nao hay registros que mostrar",
        addTooltip: "Adicionar",
        deleteTooltip: "Limpar",
        editTooltip: "Editar",
        filterRow: {
          filterTooltip: "Filtro",
        },
        editRow: {
          deleteText: "Você quer apagar esta linha?",
          cancelTooltip: "Cancelar",
          saveTooltip: "Confirme",
        },
      },
      grouping: {
        placeholder: "* Arraste os nomes das colunas aqui para o grupo...",
        groupedBy: "Agrupado por:",
      },
      header: {
        actions: "Ações",
      },
      pagination: {
        labelDisplayedRows: "{from}-{to} de {count}",
        labelRowsSelect: "linhas",
        labelRowsPerPage: "linhas por página:",
        firstAriaLabel: "Primeira página",
        firstTooltip: "Primeira página",
        previousAriaLabel: "Pagina ant.",
        previousTooltip: "Pagina ant.",
        nextAriaLabel: "Pagina sig.",
        nextTooltip: "Pagina sig.",
        lastAriaLabel: "Última página",
        lastTooltip: "Última página",
      },
      toolbar: {
        addRemoveColumns: "Adicionar ou remover colunas",
        nRowsSelected: "{0} linhas (s) selecionadas (s)",
        showColumnsTitle: "Ver colunas",
        showColumnsAriaLabel: "Ver colunas",
        exportTitle: "Exportar",
        exportAriaLabel: "Exportar",
        exportName: "Exportar em CSV",
        searchTooltip: "Pesquisa",
        searchPlaceholder: "Pesquisa",

        exportCSVName: "Exportar Excel",
        exportPDFName: "Exportar PDF",
      },
    },
  },
  //CHINESE
  {
    key: "cn",
    properties: {
      body: {
        emptyDataSourceMessage: "没有可显示的记录",
        addTooltip: "添加",
        deleteTooltip: "删除",
        editTooltip: "更新",
        filterRow: {
          filterTooltip: "筛选",
        },
        editRow: {
          deleteText: "要删除此行吗？",
          cancelTooltip: "取消",
          saveTooltip: "确认",
        },
      },
      grouping: {
        placeholder: "* 将列名拖到此处进行分组...",
        groupedBy: "分组方式：",
      },
      header: {
        actions: "行动",
      },
      pagination: {
        labelDisplayedRows: "{from}-{to} 的 {count}",
        labelRowsSelect: "行",
        labelRowsPerPage: "每页行数：",
        firstAriaLabel: "第一页",
        firstTooltip: "第一页",
        previousAriaLabel: "上一页 页",
        previousTooltip: "上一页 页",
        nextAriaLabel: "下一页",
        nextTooltip: "下一页",
        lastAriaLabel: "最后一页",
        lastTooltip: "最后一页",
      },
      toolbar: {
        addRemoveColumns: "添加或删除列",
        nRowsSelected: "{0} 线条 (s) 选择 (s)",
        showColumnsTitle: "见专栏",
        showColumnsAriaLabel: "见专栏",
        exportTitle: "出口",
        exportAriaLabel: "出口",
        exportName: "导出 CSV",
        searchTooltip: "搜索",
        searchPlaceholder: "搜索",
        exportCSVName: "导出 Excel",
        exportPDFName: "导出 PDF",
      },
    },
  },
];

//Add default row number to table, no matters incoming data, always added
export const addRowNumberToTable = (data, columns, setData, setColumns) => {
  if (!_.isNil(data) && !_.isEmpty(data) && !_.isNil(columns)) {
    const addRowNumberData = data.map((row, index) => {
      const roundUpIndex = index + 1;
      return {
        index: roundUpIndex,
        id: index,
        ...row,
      };
    });
    const addRowNumberColumn = [
      {
        field: "index",
        title: "#",
        type: "numeric",
        searchable: false,
        sorting: false,
        filtering: false,
        align: "center",
      },
      ...columns,
    ];
    setData(addRowNumberData);
    setColumns(addRowNumberColumn);
  }
};

export const getRowId = (row) => {
  if (!_.isNil(row) && !_.isNil(row.id)) {
    return row.id;
  } else {
    return uuid();
  }
};

//Changes all the necessarry properties of v1 column schema to v3
export const formatV1ColumnsToV3 = (v1Columns) => {
  //Change field value to accessor one
  let v3Columns = v1Columns.map(
    ({ field: accessor, title: Header, ...rest }) => ({
      accessor,
      Header,
      ...rest,
    })
  );

  if (v3Columns) {
    const lineColumn = {
      accessor: "line",
      canEdit: false,
      canNew: false,
      Header: "#",
      type: "number",
      width: 40,
      overrideWidth: true,
    };
    v3Columns.unshift(lineColumn);
  }

  return v3Columns;
};

export const getEmptyRowToAdd = (columns, index) => {
  let resultRow = {
    line: index + 1,
  };
  if (!_.isNil(columns) && !_.isEmpty(columns)) {
    for (let i = 0; i < columns.length; i++) {
      const { accessor } = columns[i];
      if (accessor !== "line") {
        //Add
        Object.assign(resultRow, {
          [accessor]: null,
        });
      }
    }
  }
  return resultRow;
};

export const checkAddORDeleteRowEnabled = (toCheck) => {
  let result = true;
  if (!_.isNil(toCheck) && toCheck === false) {
    result = false;
  }
  return result;
};

export function niceBytes(x) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

//Notify server to Delete specific rows
export async function notifyServerDeleteRows(param, requestHeaders) {
  const url = SERVER_HOST() + API_FORM_SERVICE + "/delRows";
  const response = await fetch(url, {
    method: METHOD_POST,
    headers: requestHeaders,
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      const { ok, msg, formStatus } = jsonResult;
      return {
        ok,
        msg,
        formStatus,
      };
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

//Notify server to init row when press New Line button or automatic generation line
export async function notifyServerInitRow(param, requestHeaders) {
  const url = SERVER_HOST() + API_FORM_SERVICE + "/initRow";
  const response = await fetch(url, {
    method: METHOD_POST,
    headers: requestHeaders,
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      const { ok, msg, formStatus } = jsonResult;
      return {
        ok,
        msg,
        formStatus,
      };
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

//Notify server to Save Panel Configuration
export async function notifyServerSavePanelConfig(params, requestHeaders) {
  const { user, path, idForm, panelConfiguration } = params;
  const url = SERVER_HOST() + API_FORM_SERVICE + "/panel";

  const body = {
    pk: {
      user,
      path,
      idForm,
    },
    panelConfiguration: JSON.stringify(panelConfiguration),
  };

  const response = await fetch(url, {
    method: METHOD_POST,
    headers: requestHeaders,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      const { ok, msg, panelConfig } = jsonResult;
      return {
        ok,
        msg,
        panelConfig,
      };
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

//Notify server to Save Records Panel View
export async function notifyServerSaveView(body, requestHeaders) {
  const url = SERVER_HOST() + API_FORM_SERVICE + "/saveView";

  const response = await fetch(url, {
    method: METHOD_POST,
    headers: requestHeaders,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      const { ok, msg, savedView } = jsonResult;
      return {
        ok,
        msg,
        savedView,
      };
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

// Notify server to delete Records Panel View
export async function notifyServerDelteView(body, requestHeaders) {
  const url = SERVER_HOST() + API_FORM_SERVICE + "/deleteView";

  const response = await fetch(url, {
    method: METHOD_POST,
    headers: requestHeaders,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      const { ok, msg, savedView } = jsonResult;
      return {
        ok,
        msg,
        savedView,
      };
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

// Notify server to delete Records Panel View
export async function notifyServerChangeActiveView(body, requestHeaders) {
  const url = SERVER_HOST() + API_FORM_SERVICE + "/setActiveView";

  const response = await fetch(url, {
    method: METHOD_POST,
    headers: requestHeaders,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      const { ok, msg, savedView } = jsonResult;
      return {
        ok,
        msg,
        savedView,
      };
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

// Notify server to move page
export async function notifyServerMovePage(params, requestHeaders) {
  const { solution, controller, page, size, sortBy, sortDir, path, idForm } =
    params;
  const url = `${SERVER_HOST()}${API_FORM_SERVICE}/movePage/${solution}/${controller}?path=${path}&idForm=${idForm}&page=${page}&size=${size}&sortBy=${sortBy}&sortDir=${sortDir}`;

  const response = await fetch(url, {
    method: METHOD_GET,
    headers: requestHeaders,
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

export async function notifyServerSearch(params, requestHeaders) {
  const {
    solution,
    controller,
    global,
    page,
    size,
    body,
    sortBy,
    sortDir,
    path,
    idForm,
  } = params;
  const url = `${SERVER_HOST()}${API_FORM_SERVICE}/search/${solution}/${controller}?path=${path}&idForm=${idForm}&global=${global}&page=${page}&size=${size}&sortBy=${sortBy}&sortDir=${sortDir}`;
  const response = await fetch(url, {
    method: METHOD_POST,
    headers: requestHeaders,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((err) => {
      return {
        failed: true,
        err,
      };
    });
  return response;
}

/**
 * In charge of make gouping table selected row to filter
 * @param {*} groupingTableSelected
 * @returns
 */
export async function makeBodyToGroupedPanelSearch(
  groupingTableSelected,
  transInfo,
  groupingTableColumns
) {
  const result = [];
  if (
    transInfo &&
    transInfo.searchParams &&
    transInfo.searchParams.body &&
    transInfo.searchParams.body.length > 0
  ) {
    const length = transInfo.searchParams.body.length;
    for (let i = 0; i < length; i++) {
      const alreadyFilter = transInfo.searchParams.body[i];
      if (!_.isEmpty(alreadyFilter)) {
        const isGroupingFilter = alreadyFilter?.groupingFilterField;
        const isIndividualFilter = alreadyFilter?.particularFilter;
        const actualValue = groupingTableSelected[alreadyFilter?.field];
        if (
          isGroupingFilter &&
          actualValue &&
          actualValue !== alreadyFilter?.value
        ) {
          result.push(alreadyFilter);
        } else if (!isGroupingFilter && isIndividualFilter) {
          result.push(alreadyFilter);
        }
      }
    }
  }

  Object.keys(groupingTableSelected).forEach((key) => {
    const composedField = key && key.includes(".") ? true : false;
    const toAdd = {
      field: key,
      value: groupingTableSelected[key],
      groupingFilterField: true,
      composedField,
      particularFilter: false,
      globalFilter: false,
      colName: groupingTableColumns.find((x) => x && x.field && x.field === key)
        ?.colName,
      operation: SEARCH_CRITERIA_EQUAL,
    };

    const overrideToGrouping = result.findIndex(
      (x) => x && x.field && x.field === toAdd?.field
    );
    if (overrideToGrouping > -1) {
      result[overrideToGrouping] = toAdd;
    } else {
      result.push(toAdd);
    }
  });
  return result;
}

/**
 * In charge of make body adding global filters to search
 * @param {*} columns
 * @param {*} searchText
 * @param {*} groupingTableSelected
 * @returns
 */
export async function makeBodyToGlobalSearch(
  columns,
  searchText,
  groupingTableSelected,
  searchParams,
  cleanSearch
) {
  //const result = {};
  let result = [];
  const lastBodyMembers =
    searchParams && searchParams.body ? searchParams.body : null;
  if (cleanSearch) {
    const notGlobal =
      lastBodyMembers && lastBodyMembers.length > 0
        ? lastBodyMembers.filter((o) => {
          return _.isNil(o.globalFilter) || o.globalFilter === false;
        })
        : null;
    result = notGlobal && notGlobal.length > 0 ? notGlobal : [];
  } else {
    for (let i = 0; i < columns.length; i++) {
      const { field, hidden, type, canGroup, colName } = columns[i];
      const composedField = field && field.includes(".") ? true : false;
      const indexOfLast =
        lastBodyMembers &&
        lastBodyMembers.length > 0 &&
        lastBodyMembers.find((obj) => {
          return obj.field === field;
        });
      const isParticularField =
        indexOfLast && indexOfLast.particularFilter === true ? true : false;
      //Only add if it is not particular field
      if (isParticularField === false) {
        if (
          (_.isNil(hidden) || hidden === false) &&
          !_.isNil(field) &&
          (canGroup === null || canGroup === undefined || canGroup === true)
        ) {
          const groupingFilterField =
            groupingTableSelected && !_.isNil(groupingTableSelected[field]);
          if (type === "string" || type === "text") {
            const toAdd = {
              field,
              value: groupingFilterField
                ? groupingTableSelected[field]
                : searchText,
              groupingFilterField,
              composedField,
              particularFilter: false,
              globalFilter: groupingFilterField === true ? false : true,
              operation: SEARCH_CRITERIA_LIKE,
              colName,
            };
            result.push(toAdd);
          } else if (type === "number" || type === "numeric") {
            const numericValue = groupingFilterField
              ? _.toNumber(groupingTableSelected[field])
              : _.toNumber(searchText);
            if (numericValue) {
              const toAdd = {
                field,
                value: numericValue,
                groupingFilterField,
                composedField,
                particularFilter: false,
                globalFilter: groupingFilterField === true ? false : true,
                operation: SEARCH_CRITERIA_LIKE,
                colName,
              };
              result.push(toAdd);
            }
          } else if (type === "date" || type === "datetime") {
            const valueToAdd = groupingFilterField
              ? groupingTableSelected[field]
              : searchText;
            const toDate = Date.parse(valueToAdd);
            const isDate = _.isDate(toDate);
            if (isDate) {
              const toAdd = {
                field,
                value: valueToAdd,
                groupingFilterField,
                composedField,
                particularFilter: false,
                globalFilter: groupingFilterField === true ? false : true,
                operation: SEARCH_CRITERIA_LIKE,
                colName,
              };
              result.push(toAdd);
            }
          }
        }
      } else {
        const toAdd = {
          field,
          value: indexOfLast.value,
          groupingFilterField: false,
          composedField,
          particularFilter: true,
          globalFilter: false,
          operation: SEARCH_CRITERIA_LIKE,
          colName,
        };
        result.push(toAdd);
      }
    }
  }

  return result;
}

/**
 * In charge of make body adding individual filters to search
 * @param {*} individualFilters
 * @param {*} searchParams
 * @param {*} cleanIndividual
 * @returns
 */
export function makeBodyToIndividualSearch(
  individualFilters,
  searchParams,
  cleanIndividual
) {
  //IF other filters are setted, include them
  if (searchParams && searchParams.body) {
    const { body } = searchParams;
    if (body && body.length > 0) {
      if (cleanIndividual) {
        const toReturn = body.filter(
          (f) => !individualFilters.some((i) => i.field === f.field)
        );
        return toReturn;
      } else {
        const newArry = [...body];
        if (newArry && newArry.length > 0) {
          for (let i = 0; i < individualFilters.length; i++) {
            const indFilter = individualFilters[i];
            if (indFilter && indFilter.field) {
              const { field } = indFilter;
              const indexOf = newArry.findIndex(
                (o) => o && o.field && o.field === field
              );
              if (indexOf > -1) {
                newArry.splice(indexOf, 1, indFilter);
              } else {
                newArry.push({ ...indFilter, particularFilter: true });
              }
            }
          }
        }
        return newArry;
      }
    } else {
      return individualFilters;
    }
  } else {
    //If it is only particular filtering apply only them
    return individualFilters;
  }
}

export function existInRowValues(objValues, filterValue) {
  let result = false;
  if (objValues && objValues.length > 0) {
    for (let i = 0; i < objValues.length; i++) {
      const ob = objValues[i];
      if (typeof ob === "object") {
        if (ob && ob.value !== undefined) {
          const inc = `${ob.value}`
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          if (inc === true) {
            result = true;
            break;
          }
        }
      } else {
        if (ob !== undefined) {
          const inc = `${ob}`.toLowerCase().includes(filterValue.toLowerCase());
          if (inc === true) {
            result = true;
            break;
          }
        }
      }
    }
  }
  return result;
}

export function formatColumnsType(columns) {
  const result =
    columns && columns.length > 0
      ? columns.map((c) => {
        if (!_.isNil(c?.type) && c?.type === "datetime") {
          return {
            ...c,
            render: (rowData) => {
              const originalDate = rowData[c?.field];
              const dateToFormat = createStringDateAsUTC(originalDate);
              if (isValid(dateToFormat)) {
                return format(dateToFormat, FRONT_DATE_TIME_PATTERN);
              } else {
                return originalDate;
              }
            },
          };
        } else if (!_.isNil(c?.type) && c?.type.toLowerCase() === "number") {
          return {
            ...c,
            type: "numeric",
          };
        } else {
          return c;
        }
      })
      : columns;

  return result;
}

export function getEnabledSelectionRow({ enableDeleteRow, operation }) {
  return (
    (_.isNil(enableDeleteRow) || enableDeleteRow === true) &&
    (operation === FORM_OPERATON_EDIT ||
      operation === FORM_OPERATION_NEW ||
      operation === FORM_OPERATION_EXECUTION)
  );
}

export function handleDeleteRowByValue({ row, colDisableDeleteRow }) {
  let result = false;
  if (
    !_.isNil(row) &&
    !_.isNil(colDisableDeleteRow) &&
    !_.isNil(row.values) &&
    !_.isEmpty(row.values) &&
    !_.isNil(row.values[colDisableDeleteRow]) &&
    _.has(row.values[colDisableDeleteRow], "value")
  ) {
    const valueToLook = row.values[colDisableDeleteRow].value;
    result =
      !_.isNil(valueToLook) &&
      _.isString(valueToLook) &&
      (valueToLook === "S" || valueToLook === "s");
  }
  return result;
}

export // Check if column can be dragged to grouping looking at the data type
  function isColumnAvailableToGroup(item) {
  if (_.isNil(item)) {
    return false;
  } else {
    const { canGroup, type, colName } = item;
    if (canGroup === false) {
      return false;
    } else if (!_.isNil(type) && (type === "boolean" || type === "booleano")) {
      return true;
    } else if (_.isNil(colName) || (!_.isNil(colName) && _.isEmpty(colName))) {
      return false;
    } else {
      return true;
    }
  }
}

export function getSimpleFieldsFromFormSchema({ formSchema }) {
  let simpleFields = [];
  if (
    !_.isNil(formSchema) &&
    !_.isNil(formSchema.sections) &&
    !_.isEmpty(formSchema.sections) &&
    _.isArray(formSchema.sections)
  ) {
    const sections = formSchema.sections;
    for (const sec of sections) {
      if (
        !_.isNil(sec) &&
        !_.isNil(sec.blocks) &&
        !_.isEmpty(sec.blocks) &&
        _.isArray(sec.blocks)
      ) {
        const simpleBlocks = _.filter(sec.blocks, { blockType: "simple" });
        if (!_.isNil(simpleBlocks) && !_.isEmpty(simpleBlocks)) {
          for (const block of simpleBlocks) {
            if (
              !_.isNil(block) &&
              !_.isNil(block.schema) &&
              !_.isNil(block.schema.properties) &&
              _.isObject(block.schema.properties)
            ) {
              const properties = block.schema.properties;

              const propsToHandle = Object.entries(properties).map(
                ([key, value]) => ({
                  field: key,
                  ...value,
                })
              );

              const filteredProps = _.filter(propsToHandle, function (prop) {
                // const isVisible =
                //   _.isNil(prop.hidden) ||
                //   (!_.isNil(prop.hidden) && prop.hidden === false);
                const isValidControlType =
                  _.isNil(prop.controlType) ||
                  (!_.isNil(prop.controlType) && prop.controlType !== "V");
                const isFinalProp =
                  _.isNil(prop.properties) ||
                  (!_.isNil(prop.properties) && _.isEmpty(prop.properties));
                return isValidControlType && isFinalProp;
              });

              if (
                !_.isNil(filteredProps) &&
                !_.isEmpty(filteredProps) &&
                _.isArray(filteredProps)
              ) {
                const mappedOnly = filteredProps
                  .filter(
                    (x) =>
                      !_.isNil(x) && !_.isNil(x.colName) && !_.isNil(x.title)
                  )
                  .map((p) => {
                    return {
                      colName: p?.colName,
                      type: p?.type,
                      title: p?.title,
                      checked: false,
                      field: p?.field,
                      canGroup: isColumnAvailableToGroup(p),
                      hidden: true,
                    };
                  });
                if (
                  !_.isNil(mappedOnly) &&
                  !_.isEmpty(mappedOnly) &&
                  _.isArray(mappedOnly)
                ) {
                  simpleFields.push(...mappedOnly);
                }
              }
            }
          }
        }
      }
    }
  }
  return simpleFields;
}

export function formatDataRowsToExportExcelFromRecordsPanel({
  flattenContent,
  preparedColumns,
}) {
  let preparedContent = [];
  if (flattenContent && flattenContent.length > 0) {
    for (let i = 0; i < flattenContent.length; i++) {
      const row = flattenContent[i];
      const newRow = {};
      Object.entries(row).forEach(([key, value]) => {
        const col = preparedColumns.find((col) => col.value === key);
        if (col) {
          if (col?.type === "date") {
            const dateValue =
              value && value !== "" && parse(value, DATE_PATTERN, new Date());
            Object.assign(newRow, {
              [key]: isValid(dateValue)
                ? format(dateValue, FRONT_DATE_PATTERN)
                : value,
            });
          } else if (col?.type === "datetime") {
            const dateTimeValue =
              value && value !== "" && createStringDateAsUTC(value);
            const offsetDateTime = isValid(dateTimeValue)
              ? format(dateTimeValue, FRONT_DATE_TIME_PATTERN)
              : value;
            Object.assign(newRow, {
              [key]: offsetDateTime,
            });
          } else {
            Object.assign(newRow, {
              [key]: value,
            });
          }
        }
      });
      preparedContent.push(newRow);
    }
  }
  return preparedContent;
}



export const evaluateConditions = (value, conditions) => {
  const validConditions = conditions || [];

  for (const condition of validConditions) {
    const { operation, value1, value2, styles } = condition;

    switch (operation) {
      case SEARCH_CRITERIA_LESS:
        if (value < value1) return styles;
        break;
      case SEARCH_CRITERIA_GREATER:
        if (value > value1) return styles;
        break;
      case SEARCH_CRITERIA_EQUAL:
        if (value === value1) return styles;
        break;
      case SEARCH_CRITERIA_DISTINCT:
        if (value !== value1) return styles;
        break;
      case SEARCH_CRITERIA_BETWEEN:
        if (value > value1 && value < value2) return styles;
        break;
      case SEARCH_CRITERIA_LIKE:
        if (typeof value === 'string' && value.includes(value1)) return styles;
        break;
      default:
        break;
    }
  }
  return {};
};