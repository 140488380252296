import {
  clone,
  isArray,
  isEmpty,
  isNil,
  minBy,
  toNumber,
  uniqBy,
} from "lodash";
import XLSX from "xlsx-js-style";
import { splitArrayIntoChunks } from "./UtilFormat";

const HEADER_ROW_STYLES = {
  fontName: "Asap",
  fontColor: "FFFFFF",
  bg: "3F66A2",
  bold: true,
  fontSize: 12,
};

const MINIMUN_WIDTH_COLUMN = 10;

export const MAX_ROWS_TO_EXPORT = 25 * 1000; //25k

//function to remove keys from object array if there is no present on other array of objects
const removeKeysFromArray = (arr, keys) => {
  return arr.map((obj) => {
    return keys.reduce((acc, key) => {
      if (obj.hasOwnProperty(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  });
};

/**
 * Obtains maximum width of a column-data as value length
 * @param {*} json
 * @returns
 */
function getMaxLengthColumn(json) {
  let objectMaxLength = [];
  for (let i = 0; i < json.length; i++) {
    let value = Object.values(json[i]);
    for (let j = 0; j < value.length; j++) {
      if (typeof value[j]?.v == "number") {
        objectMaxLength[j] = MINIMUN_WIDTH_COLUMN;
      } else {
        const lengthOfValue = value[j] && value[j]?.v && value[j]?.v.length;
        const length =
          lengthOfValue && lengthOfValue >= MINIMUN_WIDTH_COLUMN
            ? lengthOfValue + 5
            : MINIMUN_WIDTH_COLUMN;
        objectMaxLength[j] =
          objectMaxLength[j] >= length ? objectMaxLength[j] : length;
      }
    }
  }

  return objectMaxLength;
}

// function to replace curly brackets with whitespace in a string
const replaceCurlyBrackets = (str) => {
  if (str && str.length > 0) {
    return str.replace(/{/g, "").replace(/}/g, "");
  }

  if (isNil(str)) {
    return "";
  }

  return str;
};

function getExcelCellType(column) {
  let result = "s";

  if (
    (!isNil(column) && column?.type === "number") ||
    column?.type === "numeric"
  ) {
    result = "n";
  }

  return result;
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * In charge of download excel from data and columns
 * Columns: Array of objects with the following structure: {name: 'Desc Material', value: 'desc_material'}
 * Data: Array of object with the following structure: {desc_material: 'Shampoo 10ml', ...}
 * Filename: String with the name of the file
 */
export async function downloadExcelUniqueSheet({
  data,
  columns,
  fileName,
  sheetName,
  aoaOpts = { dense: true },
}) {
  //Remove unnecessary
  const dataToExport = removeKeysFromArray(
    data,
    columns.map((c) => replaceCurlyBrackets(c?.value))
  );

  //Style row of header
  const columnsHeaderRow = columns
    .filter((c) => c?.value)
    .map((c) => {
      return {
        v: replaceCurlyBrackets(c?.name),
        t: "s",
        s: {
          font: {
            name: HEADER_ROW_STYLES.fontName,
            sz: HEADER_ROW_STYLES.fontSize,
            bold: HEADER_ROW_STYLES.bold,
            color: {
              rgb: HEADER_ROW_STYLES.fontColor,
            },
          },
          fill: { fgColor: { rgb: HEADER_ROW_STYLES.bg } },
        },
      };
    });

  // Format data rows
  const dataRowsToExport = dataToExport.map((row) => {
    return Object.keys(row).map((key) => {
      const col = columns.find(function (c) {
        return c?.value === key;
      });
      return {
        v: replaceCurlyBrackets(row[key]),
        t: getExcelCellType(col),
        s: {},
      };
    });
  });

  // Concatenate header and data rows
  const dataRows = [columnsHeaderRow, ...dataRowsToExport];

  //Get dynamic width of columns
  const autoColumnsWidth = getMaxLengthColumn(dataRows).map((wc) => {
    return {
      wch: wc,
    };
  });

  if (dataRows.length > MAX_ROWS_TO_EXPORT) {
    const smallerParts = splitArrayIntoChunks(dataRows, MAX_ROWS_TO_EXPORT);
    for (let i = 0; i < smallerParts.length; i++) {
      const dataToExport = smallerParts[i];
      const fileNameToUse = `${fileName}-(${i + 1}).xlsx`;
      await createAndWriteXLSX({
        dataRows: dataToExport,
        fileName: fileNameToUse,
        aoaOpts,
        sheetName,
        autoColumnsWidth,
      });

      // Wait for 2 seconds before proceeding to the next iteration
      await delay(2000);
    }
  } else {
    const fileNameToUse = `${fileName}.xlsx`;
    await createAndWriteXLSX({
      dataRows,
      fileName: fileNameToUse,
      aoaOpts,
      sheetName,
      autoColumnsWidth,
    });
  }
}

async function createAndWriteXLSX({
  dataRows,
  fileName,
  aoaOpts,
  sheetName,
  autoColumnsWidth,
}) {
  //Initialize workbook
  const workBook = XLSX.utils.book_new();

  //Create Worksheet, add data, set cols widths
  const workSheet = XLSX.utils.aoa_to_sheet(dataRows, aoaOpts);

  workSheet["!cols"] = autoColumnsWidth;

  //Append sheet to file
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);

  //Buffer
  XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

  //Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

  //Download
  XLSX.writeFile(workBook, fileName, { compression: true });
}

export function fixMissingColsInRow({ rows, columns }) {
  if (isNil(rows) || isEmpty(rows) || isNil(columns) || isEmpty(columns)) {
    return rows;
  }

  let colsToHave = [];
  for (let i = 0; i < columns.length; i++) {
    const col = columns[i];
    if (
      !isNil(col) &&
      !isEmpty(col) &&
      !isNil(col["value"]) &&
      !isEmpty(col["value"]) &&
      !colsToHave.includes(col["value"])
    ) {
      colsToHave.push(col["value"]);
    }
  }

  return checkRowsHasAll(rows, colsToHave);
}

function checkRowsHasAll(rows, columns) {
  const newRows = clone(rows);
  for (let i = 0; i < newRows.length; i++) {
    for (let j = 0; j < columns.length; j++) {
      if (!(columns[j] in newRows[i])) {
        newRows[i][columns[j]] = undefined;
      }
    }
  }
  return newRows;
}

export function getColumnIdentifier(columnNumber) {
  let column = "";
  while (columnNumber > 0) {
    const remainder = (columnNumber - 1) % 26;
    column = String.fromCharCode(65 + remainder) + column;
    columnNumber = Math.floor((columnNumber - 1) / 26);
  }
  return column;
}

/**
 * Formato
  {
    "row": 1,
    "col": 2,
    "colId": "B",
    "value": "7451108915728"
  }
 * @param {*} excelData 
 * @returns 
 */
export function validateExcelForCopyPasteFeature(excelData, t) {
  try {
    if (!isNil(excelData) && isArray(excelData) && !isEmpty(excelData)) {
      const minRowObj = minBy(excelData, "row");
      const minColObj = minBy(excelData, "col");
      const rowNumbers = uniqBy(excelData, "row")?.map((x) => x?.row);

      // Validate first row is 1
      if (!isNil(minRowObj)) {
        const { row } = minRowObj;
        if (isNil(row) || toNumber(row) > 1) {
          throw new Error(t("HANDLE_CONTEXT_MENU_SHOULD_START_ROW_1", { row }));
        }
      }

      if (areLineNumbersConsecutives(rowNumbers) === false) {
        throw new Error(t("HANDLE_CONTEXT_MENU_SHOULD_NOT_HAVE_WHITE_LINES"));
      }

      // Validate first column is A
      if (!isNil(minColObj)) {
        const { col, colId } = minColObj;
        if (isNil(col) || toNumber(col) > 1) {
          throw new Error(
            t("HANDLE_CONTEXT_MENU_SHOULD_START_COLUMN_A", { colId })
          );
        }
      }

      return {
        isValid: true,
        msg: null,
      };
    }
    return {
      isValid: false,
      msg: t("HANDLE_CONTEXT_MENU_ERROR_PROCESSING_PRE_EXCEL"),
    };
  } catch (error) {
    return {
      isValid: false,
      msg: error?.message,
    };
  }
}

function areLineNumbersConsecutives(arr) {
  if (isNil(arr) || !isArray(arr) || isEmpty(arr)) {
    return true;
  }

  if (arr.length < 2) return true;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== arr[i - 1] + 1) {
      return false;
    }
  }
  return true;
}
