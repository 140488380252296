import { isArray, isEmpty, isMap, toString, toUpper, uniqBy } from "lodash";
import { filter, isNil } from "lodash";
import create from "zustand";

// KEYS OF THE MAP WILL BE FORMISNTANCEID ALWAYS

const useWizardStore = create((set, get) => ({
  completedSteps: new Map(),
  prevJump: new Map(),
  setCompletedSteps: ({ formInstanceId, blockId, isStepCompleted }) => {
    const actualCompletedStepsMap = get().completedSteps;
    if (!isNil(actualCompletedStepsMap)) {
      const actualCompletedStepsForm =
        actualCompletedStepsMap.get(formInstanceId);
      if (isStepCompleted) {
        if (
          !isNil(actualCompletedStepsForm) &&
          !isEmpty(actualCompletedStepsForm) &&
          isArray(actualCompletedStepsForm)
        ) {
          actualCompletedStepsMap.set(
            formInstanceId,
            uniqBy([...actualCompletedStepsForm, blockId])
          );
        } else {
          actualCompletedStepsMap.set(formInstanceId, [blockId]);
        }
      } else {
        actualCompletedStepsMap.set(
          formInstanceId,
          filter(actualCompletedStepsForm, function (o) {
            return o !== blockId;
          })
        );
      }
      set(() => ({
        completedSteps: actualCompletedStepsMap,
      }));
    }
  },
  getCompletedStepsByFormInstanceId: ({ formInstanceId }) => {
    const actualCompletedStepsMap = get().completedSteps;
    if (
      !isNil(actualCompletedStepsMap) &&
      !isEmpty(actualCompletedStepsMap) &&
      actualCompletedStepsMap.has(formInstanceId)
    ) {
      return actualCompletedStepsMap.get(formInstanceId);
    }
  },
  setPrevJump: ({ formInstanceId, jump }) => {
    const actualMap = get().prevJump;
    actualMap.set(formInstanceId, jump);
    set(() => ({
      prevJump: actualMap,
    }));
  },
  getPrevJump: ({ formInstanceId }) => {
    const actualMap = get().prevJump;
    if (
      !isNil(actualMap) &&
      !isEmpty(actualMap) &&
      actualMap.has(formInstanceId)
    ) {
      return actualMap.get(formInstanceId);
    } else {
      return null;
    }
  },
  badges: new Map(),
  addNewBadge: ({ formInstanceId, sectionName }) => {
    const actualMap = get().badges;
    actualMap.set(formInstanceId, sectionName);
    set(() => ({
      badges: actualMap,
    }));
  },
  shouldRenderBadge: ({ formInstanceId, sectionName }) => {
    if (isNil(formInstanceId) || isNil(sectionName)) {
      return false;
    }
    const actualMap = get().badges;
    if (
      isNil(actualMap) ||
      isEmpty(actualMap) ||
      actualMap.has(formInstanceId) === false
    ) {
      return false;
    }

    return (
      toString(toUpper(actualMap.get(formInstanceId))) ===
      toString(toUpper(sectionName))
    );
  },
  removeBadge: ({ formInstanceId }) => {
    const actualMap = get().badges;
    if (!isNil(actualMap)) {
      actualMap.delete(formInstanceId);
    }
    set(() => ({
      badges: actualMap,
    }));
  },
  disabledBlocks: new Map(),
  handleDisabledBlock: ({ formInstanceId, blockId, isDisabled }) => {
    const actualMap = get().disabledBlocks;
    if (!isNil(actualMap) && !isEmpty(actualMap)) {
      if (actualMap.has(formInstanceId)) {
        const actualBlocks = actualMap.get(formInstanceId);
        if (isDisabled === true) {
          actualMap.set(formInstanceId, uniqBy([...actualBlocks, blockId]));
          set(() => ({
            disabledBlocks: actualMap,
          }));
        } else {
          actualMap.set(
            formInstanceId,
            filter(actualBlocks, function (o) {
              return !isNil(o) && o !== blockId;
            })
          );
          set(() => ({
            disabledBlocks: actualMap,
          }));
        }
      } else {
        if (isDisabled === true) {
          actualMap.set(formInstanceId, [blockId]);
          set(() => ({
            disabledBlocks: actualMap,
          }));
        }
      }
    } else {
      if (isDisabled === true) {
        const newMap = new Map();
        newMap.set(formInstanceId, [blockId]);
        set(() => ({
          disabledBlocks: newMap,
        }));
      }
    }
  },
  getDisabledBlocksByFormInstanceId: ({ formInstanceId }) => {
    const actualMap = get().disabledBlocks;
    if (!isNil(actualMap) && !isEmpty(actualMap) && isMap(actualMap)) {
      const actualBlocks = actualMap.get(formInstanceId);
      if (
        !isNil(actualBlocks) &&
        !isEmpty(actualBlocks) &&
        isArray(actualBlocks)
      ) {
        return actualBlocks;
      }
    }

    return [];
  },
}));

export default useWizardStore;
