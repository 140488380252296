import { useEffect, useRef, useReducer, useContext } from "react";
import {
  HTTP_STATUS_UNAUTHORIZED,
  METHOD_GET,
  REST_HEADERS,
  TOKEN_HEADER,
  TOKEN_INIT,
} from "../../util/Constants";
import { AuthContext } from "../providers/AuthContext";

export const useFetchGen = (url, body, method, cancel = false) => {
  const cache = useRef({});
  const cacheBody = useRef({});
  const { auth, logout } = useContext(AuthContext);

  const initialState = {
    status: "idle",
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  const fetchData = async (cancelRequest) => {
    dispatch({ type: "FETCHING" });
    try {
      const response = await fetch(url, {
        body: METHOD_GET === method ? null : JSON.stringify(body),
        method: method,
        headers: {
          ...REST_HEADERS,
          [TOKEN_HEADER]: `${TOKEN_INIT}${auth.token}`,
        },
      });
      if (response.status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      }
      const data = await response.json();
      cache.current[url] = data;
      cacheBody.current[body] = body;
      if (cancelRequest) return;
      dispatch({ type: "FETCHED", payload: data });
    } catch (error) {
      if (cancelRequest) return;
      dispatch({ type: "FETCH_ERROR", payload: error.message });
    }
  };

  useEffect(() => {
    let cancelRequest = false;
    if (cancel) return;
    if (!url) return;

    fetchData(cancelRequest);

    return function cleanup() {
      cancelRequest = true;
    };
    // eslint-disable-next-line
  }, [url, body]);

  return [state, fetchData];
};
