import React, { useEffect } from "react";
import { loadCSS } from "fg-loadcss";

export default function FontAwesomeCSS() {
  useEffect(() => {
    const node = loadCSS(
      //"https://use.fontawesome.com/releases/v5.15.4/css/all.css",
      "https://d3ob6i1epurqjq.cloudfront.net/css/all.css",
      document.querySelector("#font-awesome-css"),
      null,
      {
        // integrity:
        //   "sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm",
        crossorigin: "anonymous",
      }
    );

    return () => {
      try {
        node.parentNode.removeChild(node);
      } catch (e) {
        console.log(e);
      }
    };
  }, []);

  return <></>;
}
