// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.ACCOUNT_ADDED && event.payload) {
    const account = event.payload;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    //eslint-disable-next-line
    throw null;
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    //eslint-disable-next-line
    throw null;
  }
});
