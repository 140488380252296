import React, { useState } from "react";
import { Fade, Popper, Tooltip } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import { StyledBadge } from "../../../../../components/styledBadge";

import useStatusBadge from "../../../../../core/hooks/useStatusBadge";
import ProfilePopoverContent from "./ProfilePopoverContent";
import UserImage from "./UserImage";
import { useNetwork } from "../../../../../core/hooks/useNetwork";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginRight: 10,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  mainPopper: {
    zIndex: 10,
    marginTop: 5,
    borderRadius: "5px",
    maxWidth: "calc(100% - 30px)",
    maxHeight: "calc(100% - 30px)",
    outline: "0px",
  },
}));

export default function CompanyAndUser({ setOpenLogoutConfirmation }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfilePop, setOpenProfilePop] = useState(false);

  const { status, error, getStatusInfo } = useStatusBadge();

  const { online } = useNetwork();

  const handleBadgeClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenProfilePop((prev) => !prev);
  };

  const statusInfo = getStatusInfo(status, error, online);
  const msT = status && !error && online ? status : "";

  return (
    <>
      <Tooltip
        title={statusInfo?.msg ? `${msT}${statusInfo?.msg}` : ""}
        placement={"left"}
      >
        <div className={classes.container}>
          <StyledBadge
            color={statusInfo?.color}
            handleBadgeClick={handleBadgeClick}
          >
            <UserImage />
          </StyledBadge>
        </div>
      </Tooltip>

      <Popper
        open={openProfilePop}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        transition
        className={classes.mainPopper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ProfilePopoverContent
              setOpenLogoutConfirmation={setOpenLogoutConfirmation}
              setOpenProfilePop={setOpenProfilePop}
            />
          </Fade>
        )}
      </Popper>
    </>
  );
}
