import React, { useState, useEffect, useMemo } from "react";
import * as _ from "lodash";
import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { v4 as uuid } from "uuid";

import {
  defineInputEnabledByOperation,
  getCompletePath,
  resolveFieldAgainstShadowStatus,
} from "../../../util/UtilForm";
import FileChooserDialog from "../../../components/dialogs/fileChooserDialog/FileChooserDialog";
import SimpleFieldContainer from "./container/SimpleFieldContainer";
import * as Constants from "../../../util/Constants";
import CustomFileViewer from "../../../components/dialogs/fileChooserDialog/CustomFileViewer";
import { AVAILABLE_FORMATS } from "../../dialogs/fileChooserDialog/formats";
import { isJsonFormat } from "../../../util/UtilFormat";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";

const useStyles = makeStyles(() => ({
  chooserOpened: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      borderRadius: 0,
    },
  },
  previewContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  footerActions: {
    width: "100%",
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 3,
  },
}));

export default function SimpleFileField(props) {
  //#region [Definitions]
  const { idSchema, schema, formData, formContext } = props;
  //Destr important attrs
  const { value, presentationInfo: pi, isSavedEntity } = formData || {};
  const {
    operation,
    section,
    block,
    formInstanceId,
    notifySetValueToServer,
    pre,
    //globalFormData,
    //errorMap,
    shadowStatus,
    isBlockDisabledByWizard,
  } = formContext;

  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isEnableAddFile, setEnableAddFile] = useState(true);

  const [openFileChooser, setOpenFileChooser] = useState(false);
  const [fileValue, setFileValue] = useState([]);
  const theme = useTheme();
  const [actualFilePreview, setActualFilePreview] = useState(0);
  const [presentationInfo, setPresentationInfo] = useState(null);
  //#endregion
  const { t } = useTranslation();
  const classes = useStyles();

  const AVAILABLE_PREVIEW_FORMATS = Object.keys(AVAILABLE_FORMATS).filter(
    (x) => {
      const format = AVAILABLE_FORMATS[x];
      if (format && format.desc && format.canPreview) {
        return x;
      } else {
        return null;
      }
    }
  );

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema,
    operation,
    isSavedEntity,
  });

  const isFilePropPresent =
    !_.isNil(schema) && !_.isNil(schema.isFile) && schema.isFile === true;

  // EXTRA FILE CONFIG
  const extraFileConfig = schema?.extraFileConfig;
  const hasExtraFileConfig =
    !_.isNil(extraFileConfig) && !_.isEmpty(extraFileConfig);
  const onlyFirstPagePDFFunctionality =
    hasExtraFileConfig &&
    !_.isNil(extraFileConfig.firstPagePDF) &&
    !_.isEmpty(extraFileConfig.firstPagePDF);
  const onlyFirstPagePDFValue =
    onlyFirstPagePDFFunctionality &&
    resolveFieldAgainstShadowStatus({
      field: extraFileConfig.firstPagePDF,
      shadowStatus,
      completeIdControl: extraFileConfig.firstPagePDF,
    });
  const handleOnlyFirstPDFPage =
    (!_.isNil(onlyFirstPagePDFValue) &&
      !_.isEmpty(onlyFirstPagePDFValue) &&
      _.toUpper(onlyFirstPagePDFValue) === "S") ||
    onlyFirstPagePDFValue === true;
  const cancelPDFPreview =
    AVAILABLE_FORMATS?.pdf?.desc ===
      _.toLower(fileValue[actualFilePreview]?.ext) &&
    handleOnlyFirstPDFPage === true;

  //Clearing function
  useEffect(() => {
    if (_.isNil(value)) {
      setFileValue([]);
      setActualFilePreview(0);
    } else {
      setLoading(false);
      const valueToSet =
        value && typeof value === "object"
          ? value
          : value !== "" && isJsonFormat(value)
          ? JSON.parse(value)
          : [];
      setFileValue(valueToSet);
      setActualFilePreview(0);
    }
  }, [value]);

  //Check if input is enabled or not
  useEffect(() => {
    if (isBlockDisabledByWizard === true) {
      setIsEnabled(false);
    } else {
      const checkInputEnabled = defineInputEnabledByOperation(
        operation,
        schema?.canEdit,
        schema?.canNew
      );

      const disabledByCalculatedInputProps =
        presentationInfo?.enabled === false;
      const enabledAddFileToSet =
        !disabledByCalculatedInputProps && checkInputEnabled;
      setEnableAddFile(enabledAddFileToSet);

      if (operation === Constants.FORM_OPERATION_VIEW) {
        setIsEnabled(true);
      } else {
        if (!_.isNil(checkInputEnabled)) {
          setIsEnabled(checkInputEnabled);
        }
      }
    }
  }, [operation, schema, presentationInfo, isBlockDisabledByWizard]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  const handleFileChooser = (state) => {
    if (!isFilePropPresent) {
      return;
    }
    setOpenFileChooser(state);
  };

  const handleSetValue = (fileValue) => {
    setFileValue(fileValue);
    if (fileValue && _.isArray(fileValue) && !_.isEmpty(fileValue)) {
      const fileValueToSetMin = fileValue.map((f) => {
        return {
          key: f?.key,
          name: f?.name,
          ext: f?.ext,
          lastDate: f?.lastDate,
          lastUser: f?.lastUser,
        };
      });
      const fileValueSt = JSON.stringify(fileValueToSetMin);
      notifySetValueToServer(formInstanceId, completeIdControl, fileValueSt, 0);
    } else {
      const fileValueSt = JSON.stringify(fileValue);
      notifySetValueToServer(formInstanceId, completeIdControl, fileValueSt, 0);
    }
  };

  const borderBottomButton =
    fileValue.length > 0
      ? `3px solid ${theme.palette.content.mainColor}`
      : "1px solid grey";

  const previewObject =
    schema && !_.isNil(schema.filePreview) ? schema.filePreview : null;

  const previewHeight =
    previewObject && previewObject.lines && previewObject.lines * 30;

  const isOnlyInput =
    schema && !_.isNil(schema.onlyInput) ? schema.onlyInput : false;

  function handlePrevFile(e) {
    e.preventDefault();
    setActualFilePreview((prev) => prev - 1);
  }

  function handleNextFile(e) {
    e.preventDefault();
    setActualFilePreview((prev) => prev + 1);
  }

  function handleGoFirst(e) {
    e.preventDefault();
    setActualFilePreview(0);
  }

  function handleGoLast(e) {
    e.preventDefault();
    setActualFilePreview(fileValue.length - 1);
  }

  const canAddFileForRemoval = useMemo(() => {
    if (
      !_.isNil(schema) &&
      !_.isNil(schema.removeFileFromBucket) &&
      _.isBoolean(schema.removeFileFromBucket) &&
      schema.removeFileFromBucket === false
    ) {
      return false;
    } else {
      return true;
    }
  }, [schema]);

  const fieldsetContainer = useMemo(() => {
    return {
      width: "100%",
      border: `1px solid ${theme.palette.content.mainColor}`,
      margin: "5px 0px",
      position: "relative",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      height: previewHeight,
    };
  }, [previewHeight, theme]);

  const maxFilesValue = useMemo(() => {
    if (_.isNil(schema) || _.isNil(schema?.maxFiles) || _.isNil(shadowStatus)) {
      return null;
    }

    const maxFiles = schema?.maxFiles;

    if (_.isString(maxFiles)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: maxFiles,
        shadowStatus,
        completeIdControl: maxFiles,
      });
      if (
        !_.isNil(valueInShadowStatus) &&
        !_.isNil(_.toNumber(valueInShadowStatus)) &&
        !_.isNaN(_.toNumber(valueInShadowStatus))
      ) {
        return _.toNumber(valueInShadowStatus);
      } else {
        return null;
      }
    } else {
      return maxFiles;
    }
  }, [schema, shadowStatus]);

  const blockAddFilesByMaxFiles = useMemo(() => {
    if (
      _.isNil(fileValue) ||
      _.isEmpty(fileValue) ||
      !_.isArray(fileValue) ||
      _.isNil(maxFilesValue) ||
      !_.isNumber(maxFilesValue)
    ) {
      return false;
    }

    return fileValue?.length >= maxFilesValue;
  }, [maxFilesValue, fileValue]);

  //Input styles
  const controlStyles = {
    borderBottom: borderBottomButton,
    height: "inherit",
    width: "100%",
    backgroundColor:
      presentationInfo &&
      presentationInfo.backgroundColor &&
      presentationInfo.backgroundColor,

    color: presentationInfo && presentationInfo.color && presentationInfo.color,
    fontSize:
      presentationInfo &&
      presentationInfo.fontSize &&
      presentationInfo.fontSize,

    fontWeight:
      presentationInfo &&
      presentationInfo.fontWeight &&
      presentationInfo.fontWeight,
    fontStyle:
      presentationInfo &&
      presentationInfo.fontStyle &&
      presentationInfo.fontStyle,
    outline: !isFilePropPresent && "1px solid #d63031",
  };

  // const errorStyles = errorMap.some((e) => e && e.key === completeIdControl)
  //   ? {
  //       color: Constants.ERROR_BAD_FORM_DEF_COLOR,
  //     }
  //   : {};

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      presentationInfo={presentationInfo}
    >
      {previewObject &&
      fileValue &&
      fileValue.length > 0 &&
      !cancelPDFPreview ? (
        <fieldset
          style={fieldsetContainer}
          key={`preview_${uuid()}_form`}
          id={`preview_${uuid()}_form`}
        >
          {isOnlyInput && schema.title && schema.title !== "" && (
            <legend style={{ display: "block" }}>{schema.title}</legend>
          )}

          {fileValue[actualFilePreview] && (
            <button
              onClick={() => {
                handleFileChooser(true);
              }}
              className={classes.previewContainer}
            >
              {fileValue[actualFilePreview]?.ext &&
              fileValue[actualFilePreview].ext !== "" &&
              AVAILABLE_PREVIEW_FORMATS.includes(
                fileValue[actualFilePreview].ext.toLowerCase()
              ) ? (
                <CustomFileViewer
                  key={`preview_${uuid()}_form`}
                  id={`preview_${uuid()}_form`}
                  fileType={fileValue[actualFilePreview]?.ext.toLowerCase()}
                  bg={(previewObject && previewObject.bg) || "transparent"}
                  fileValue={fileValue[actualFilePreview]}
                  isFieldPreview={true}
                />
              ) : fileValue[actualFilePreview]?.ext &&
                fileValue[actualFilePreview].ext !== "" &&
                !AVAILABLE_PREVIEW_FORMATS.includes(
                  fileValue[actualFilePreview].ext.toLowerCase()
                ) ? (
                <IconButton
                  className={classes.chooserOpened}
                  onClick={() => {
                    handleFileChooser(true);
                  }}
                >
                  <Icon
                    className={
                      fileValue[actualFilePreview].ext === "xlsx" ||
                      fileValue[actualFilePreview].ext === "xls"
                        ? "fas fa-file-excel"
                        : fileValue[actualFilePreview].ext === "pdf"
                        ? "fas fa-file-pdf"
                        : fileValue[actualFilePreview].ext === "ppt" ||
                          fileValue[actualFilePreview].ext === "pptx"
                        ? "fas fa-file-powerpoint"
                        : "fas fa-file-alt"
                    }
                    style={{
                      width: "auto",
                      height: "auto",
                      padding: 1,
                      fontSize: previewHeight * 0.3,
                    }}
                  />
                </IconButton>
              ) : (
                <div className={classes.chooserOpened}>
                  <Icon
                    className="fas fa-object-group"
                    style={{ fontSize: previewHeight * 0.5 }}
                  />
                </div>
              )}
            </button>
          )}

          <div className={classes.footerActions}>
            <IconButton
              disabled={actualFilePreview <= 0}
              onClick={handleGoFirst}
            >
              <Icon className="fas fa-angle-double-left" />
            </IconButton>
            <IconButton
              disabled={actualFilePreview <= 0}
              onClick={handlePrevFile}
            >
              <Icon className="fas fa-angle-left" />
            </IconButton>

            {fileValue[actualFilePreview]?.name && (
              <Typography
                style={{
                  maxHeight: 30,
                  overflow: "auto",
                }}
              >
                {fileValue[actualFilePreview]?.name}
              </Typography>
            )}

            <IconButton
              disabled={actualFilePreview + 1 >= fileValue.length}
              onClick={handleNextFile}
            >
              <Icon className="fas fa-angle-right" />
            </IconButton>
            <IconButton
              disabled={actualFilePreview + 1 >= fileValue.length}
              onClick={handleGoLast}
            >
              <Icon className="fas fa-angle-double-right" />
            </IconButton>
          </div>
        </fieldset>
      ) : (
        <Tooltip
          title={!isFilePropPresent ? t("FILE_IS_FILE_PROP_NOT_PRESENT") : ""}
          disableHoverListener={isFilePropPresent}
          placement="top"
        >
          <Button
            id={completeIdControl}
            onClick={() => handleFileChooser(true)}
            disabled={
              ((!isEnabled || !isEnableAddFile) &&
                (!fileValue || fileValue?.length === 0)) ||
              (presentationInfo && presentationInfo?.enabled === false) ||
              disableDueChangeSavedValue
            }
            endIcon={<AttachFileIcon style={{ fontSize: 16 }} />}
            //style={{ ...controlStyles, ...errorStyles }}
            style={controlStyles}
          >
            {/* 1) si hay un solo archivo: mostrar el nombre del archivo (sin la ruta anterior)
          2) si hay más de un archivo, mostrar: "(2) archivos" */}
            {fileValue.length === 0 &&
              isOnlyInput &&
              schema.title &&
              schema.title !== "" &&
              `${schema.title} - `}

            {`(${fileValue.length || 0}) ` + t("FILE")}
          </Button>
        </Tooltip>
      )}

      {openFileChooser === true && (
        <FileChooserDialog
          openFileChooser={openFileChooser}
          setFileValue={handleSetValue}
          handleClose={handleFileChooser}
          fileValue={fileValue}
          setLoading={setLoading}
          isLoading={isLoading}
          enabled={isEnableAddFile}
          operation={operation}
          formInstanceId={formInstanceId}
          completeIdControl={completeIdControl}
          line={0}
          handleOnlyFirstPDFPage={handleOnlyFirstPDFPage}
          canAddFileForRemoval={canAddFileForRemoval}
          blockAddFilesByMaxFiles={blockAddFilesByMaxFiles}
          maxFiles={maxFilesValue}
        />
      )}
    </SimpleFieldContainer>
  );
}
