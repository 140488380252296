import React, { useState, useEffect, useRef, useMemo } from "react";
import * as _ from "lodash";
import {
  defineInputEnabledByOperation,
  getCompletePathGridItem,
  resolveFieldAgainstShadowStatus,
} from "../../../util/UtilForm";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useCanChangeSaved } from "../../../core/hooks/useCanChangeSaved";
import { InputLabel } from "@material-ui/core";

export default function EditableCellComboBox(props) {
  const {
    value,
    row: { index },
    updateDataTable,
    operation,
    column,
    formContext,
  } = props;

  const { id } = column;
  const { section, block, listName, shadowStatus, isBlockDisabledByWizard } =
    formContext;

  // We need to keep and update the state of the cell normally
  const [selectValue, setSelectValue] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  const [availableOptions, setAvailableOptions] = useState([]);
  const comboRef = useRef(null);
  const [calculatedInputProps, setCalculatedInputProps] = useState(null);

  let completeIdControl = getCompletePathGridItem(
    section,
    block,
    `_${id}`,
    listName,
    column,
    index + 1
  );

  const { disableDueChangeSavedValue } = useCanChangeSaved({
    schema: column,
    operation,
    isSavedEntity: value?.isSavedEntity,
  });

  //Charge available options
  useEffect(() => {
    setAvailableOptions(
      value && value.optionsValues && value.optionsValues.length > 0
        ? value.optionsValues
        : []
    );
  }, [value]);

  useEffect(() => {
    if (!_.isNil(value) && !_.isNil(value.value)) {
      const realValue = value.value;
      setSelectValue(realValue);
      if (
        (_.isNil(availableOptions) || _.isEmpty(availableOptions)) &&
        realValue &&
        realValue !== ""
      ) {
        const optionsToSet = value.optionsValues;
        if (optionsToSet && optionsToSet.length > 0) {
          setAvailableOptions(optionsToSet);
        } else {
          setAvailableOptions([realValue]);
        }
      }
    } else {
      setSelectValue("");
    }
    checkPresentationInfo(value);
  }, [value, availableOptions]);

  function checkPresentationInfo(value) {
    if (!_.isNil(value) && !_.isNil(value.presentationInfo)) {
      const { presentationInfo } = value;
      if (presentationInfo && presentationInfo.reset) {
        setCalculatedInputProps(null);
      } else {
        setCalculatedInputProps(presentationInfo);
      }
    } else {
      setCalculatedInputProps(null);
    }
  }

  //Check if input is enabled or not
  useEffect(() => {
    const checkInputEnabled = defineInputEnabledByOperation(
      operation,
      column?.canEdit,
      column?.canNew
    );
    if (!_.isNil(checkInputEnabled)) {
      setIsEnabled(checkInputEnabled);
    }
    if (_.isNil(availableOptions) || _.isEmpty(availableOptions)) {
      setIsEnabled(false);
    }
    if (
      !_.isNil(calculatedInputProps) &&
      !_.isNil(calculatedInputProps.enabled)
    ) {
      setIsEnabled(calculatedInputProps.enabled);
    }
  }, [operation, column, availableOptions, calculatedInputProps]);

  const handleChange = (value) => {
    setSelectValue(value);

    const valueToUpdateInTable = _.isEqual(value, "") ? null : value;
    updateDataTable(index, id, valueToUpdateInTable, comboRef, column);
  };

  //Input styles
  const controlStyles = {
    color:
      calculatedInputProps &&
      calculatedInputProps.color &&
      calculatedInputProps.color,
    fontSize:
      calculatedInputProps &&
      calculatedInputProps.fontSize &&
      calculatedInputProps.fontSize,
    backgroundColor:
      calculatedInputProps &&
      calculatedInputProps.backgroundColor &&
      calculatedInputProps.backgroundColor,
    fontWeight:
      calculatedInputProps &&
      calculatedInputProps.fontWeight &&
      calculatedInputProps.fontWeight,
    fontStyle:
      calculatedInputProps &&
      calculatedInputProps.fontStyle &&
      calculatedInputProps.fontStyle,
  };

  const isClearabale = useMemo(() => {
    if (
      _.isNil(column) ||
      _.isNil(value) ||
      _.isNil(value?.value) ||
      _.isNil(column?.isClearable) ||
      _.trim(_.toString(value)) === ""
    ) {
      return true;
    }

    const definedIsClearable = column?.isClearable;
    if (_.isBoolean(definedIsClearable)) {
      return definedIsClearable;
    } else if (_.isString(definedIsClearable)) {
      const valueInShadowStatus = resolveFieldAgainstShadowStatus({
        field: definedIsClearable,
        shadowStatus,
        completeIdControl: definedIsClearable,
        line: index,
      });

      return (
        _.isEqual("S", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("TRUE", _.toUpper(_.toString(valueInShadowStatus))) ||
        _.isEqual("Y", _.toUpper(_.toString(valueInShadowStatus)))
      );
    } else {
      return true;
    }
  }, [column, value, shadowStatus, index]);

  return (
    <>
      <InputLabel htmlFor={completeIdControl} />
      <NativeSelect
        value={selectValue}
        onChange={(e) => handleChange(e.target.value)}
        disabled={
          !isEnabled ||
          disableDueChangeSavedValue ||
          isBlockDisabledByWizard === true
        }
        inputRef={comboRef}
        id={completeIdControl}
        style={controlStyles}
        error={false}
        inputProps={{
          id: completeIdControl,
        }}
        //error={errorMap.some((e) => e && e.key === completeIdControl)}
      >
        {isClearabale && <option aria-label="None" value="" />}
        {!_.isNil(availableOptions) &&
          !_.isEmpty(availableOptions) &&
          availableOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </NativeSelect>
    </>
  );
}
