import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { isNil } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  btn: {
    border: "1px solid",
  },
}));

const FilterRange = ({ filter, selectedRange, onChange }) => {
  const classes = useStyles();
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  const handleApply = () => {
    onChange({ min: Number(min), max: Number(max) });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleApply();
    }
  };

  useEffect(() => {
    // min
    if (!isNil(selectedRange?.min)) {
      setMin(selectedRange?.min);
    } else {
      setMin(filter?.min);
    }

    // max
    if (!isNil(selectedRange?.max)) {
      setMax(selectedRange?.max);
    } else {
      setMax(filter?.max);
    }
  }, [filter, selectedRange]);

  const isFillterApplied = useMemo(() => {
    return selectedRange?.min !== "" || selectedRange?.max !== "";
  }, [selectedRange]);

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {filter?.name} {isFillterApplied ? "" : " (all)"}
      </Typography>
      <Box display="flex" alignItems="center">
        <TextField
          margin="dense"
          variant="outlined"
          size="small"
          label="Mínimo"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          style={{ marginRight: 8, width: 100 }}
          value={min}
          onChange={(e) => setMin(e.target.value)}
          onKeyDown={handleEnter}
        />
        <TextField
          margin="dense"
          variant="outlined"
          size="small"
          label="Máximo"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          style={{ marginRight: 8, width: 100 }}
          value={max}
          onChange={(e) => setMax(e.target.value)}
          onSubmit={handleApply}
          onKeyDown={handleEnter}
        />
        <IconButton
          color="primary"
          size="small"
          className={classes.btn}
          onClick={handleApply}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FilterRange;
