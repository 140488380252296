import { fetchHistoryItems } from "../../api/historyApi";

const createHistorySlice = (set) => ({
  loadingHistoryItems: false,
  errorLoadingHistoryItems: null,
  historyItems: [],
  loadedHistoryItems: false,

  fetchHistoryItems: async (headers, logout) => {
    set({ loadingHistoryItems: true, errorLoadingHistoryItems: null });
    try {
      const { data, error } = await fetchHistoryItems(headers, logout);
      if (data) {
        set({
          historyItems: data?.historyItems,
          loadingHistoryItems: false,
          loadedHistoryItems: true,
        });
      } else {
        set({
          errorLoadingHistoryItems: error || "Error",
          loadingHistoryItems: false,
          historyItems: [],
        });
      }
    } catch (error) {
      set({
        errorLoadingHistoryItems: error.message || "Error",
        loadingHistoryItems: false,
        historyItems: [],
      });
    }
  },
});

export default createHistorySlice;
