import { useEffect, useState } from "react";
import { isNil } from "lodash";

export function useRefWidth({ ref, minusOffset }) {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    const updateWidth = () => {
      if (!isNil(ref.current)) {
        setWidth(ref.current.offsetWidth - minusOffset);
      }
    };

    // Initial width calculation
    updateWidth();

    // Recalculate width if the element might change dynamically
    if (ref.current) {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
    }
  }, [ref, minusOffset]);

  return width;
}
