import React, { useMemo, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Pagination } from "@material-ui/lab";
import { SizeMe } from "react-sizeme";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const useStyles = makeStyles((theme) => ({
  containerBox: {
    position: "relative",
    width: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "hidden",
    padding: 10,
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "15px",
    backgroundColor: `${theme.palette.content.mainColor}`,
    zIndex: 1,
    position: "sticky",
    top: 0,
    padding: 5
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
  },
}));

export default function PdfHelpBody({ filePath, isBytes }) {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const handleChange = (event, value) => {
    setPageNumber(value);
  };

  const file = useMemo(() => {
    if (isBytes === true) {
      return { data: filePath };
    } else {
      return filePath;
    }
  }, [filePath, isBytes]);

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  return (
    <Box className={classes.containerBox}>
      <SizeMe>
        {({ size }) => {
          return (
            <>
              <Box className={classes.headerBox}>
                <Pagination
                  count={numPages}
                  page={pageNumber || 1}
                  onChange={handleChange}
                  size="small"
                  variant="outlined"
                />
              </Box>
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{ renderer: "canvas" }}
                onItemClick={onItemClick}
              >
                <Page pageNumber={pageNumber} width={size.width} />
              </Document>
            </>
          );
        }}
      </SizeMe>
    </Box>
  );
}
