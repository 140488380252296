import { METHOD_GET, METHOD_POST, METHOD_DELETE } from "../../../util/Constants";
import { BASE_EMS_URL, handleApiResponse } from "./base";

const FAVORITES_ENDPOINT = "/favorites";
const FAVORITES_ADD_ENDPOINT = "/favorites/add";
const FAVORITES_REMOVE_ENDPOINT = "/favorites/remove";

export const getFavoritesRequestApi = async ({ headers, logout }) => {
  const response = await fetch(`${BASE_EMS_URL}${FAVORITES_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};

export const addFavoriteRequestApi = async ({
  headers,
  logout,
  body,
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${FAVORITES_ADD_ENDPOINT}`,
    {
      method: METHOD_POST,
      headers: headers,
      body: JSON.stringify(body),
    }
  );

  return handleApiResponse(response, logout);
};

export const removeFavoriteRequestApi = async ({
  headers,
  logout,
  body,
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${FAVORITES_REMOVE_ENDPOINT}`,
    {
      method: METHOD_DELETE,
      headers: headers,
      body: JSON.stringify(body),
    }
  );

  return handleApiResponse(response, logout);
};
