/* eslint-disable react-hooks/rules-of-hooks */
import { isNil } from "lodash";
import { getConfig } from "../config/dynamicConfig";
import getConfigEnvStore from "../config/getConfigEnvStore";

export const IS_ENV_SELECTOR_ENABLED =
  process.env.REACT_APP_SHOW_ENV_SELECTOR === "y";

const { selectedEnvInfo } = getConfigEnvStore();

export const getDynamicPortHost = () => {
  if (IS_ENV_SELECTOR_ENABLED === true) {
    const config = getConfig();

    const portInConfig = config?.REACT_APP_PORT_HOST;
    const portToUse = !isNil(portInConfig)
      ? portInConfig
      : selectedEnvInfo?.defaultPort;

    return !isNil(portToUse) && portToUse > 0 ? `:${portToUse}` : "";
  } else {
    return !isNil(process.env.REACT_APP_PORT_HOST) &&
      process.env.REACT_APP_PORT_HOST > 0
      ? `:${process.env.REACT_APP_PORT_HOST}`
      : "";
  }
};

export const getDynamicServerHost = () => {
  if (IS_ENV_SELECTOR_ENABLED === true) {
    const config = getConfig();
    const portHost = getDynamicPortHost();

    const protocolInConfig = config?.REACT_APP_PROTOCOL_HOST;
    const urlHostInConfig = config?.REACT_APP_URL_HOST;

    const protocolToUse = !isNil(protocolInConfig)
      ? protocolInConfig
      : selectedEnvInfo?.defaultProtocol;
    const hostToUse = !isNil(urlHostInConfig)
      ? urlHostInConfig
      : selectedEnvInfo?.defaultHost;

    return `${protocolToUse}://${hostToUse}${portHost}/`;
  } else {
    return `${process.env.REACT_APP_PROTOCOL_HOST}://${
      process.env.REACT_APP_URL_HOST
    }${getDynamicPortHost()}/`;
  }
};

//REST config
export const PORT_HOST = () => getDynamicPortHost();
export const SERVER_HOST = () => getDynamicServerHost();

export const API_ = "/api/";
//export const API_TOOLKIT = "form/api/toolkit";
export const API_FORM_SERVICE = "form/api/engine";
export const API_FORM_SECURITY = "form/api/sec";
export const API_FORM_MAKER = "form/api/maker";
export const API_MENU_MAKER = "form/api/menu";
export const API_TICKETS_SERVICE = "tickets/api/tickets";
export const API_QFORM = "form/api/engine/getQForm";
export const API_QFORM_PATH = "Querys";
export const API_INBOX = "form/api/inbox";
export const API_PANEL = "form/api/panel";
export const API_DASHBOARD = "form/api/dashboard";
export const API_FORM_QUERY = "form/api/query";
export const API_FORM_STATUS = "form/api/status";
export const API_FORM_AWS = "form/api/aws";
export const API_FORM_LOG = "form/api/log";
export const API_FORM_JASPER = "form/api/jasper";
export const API_KPI = "form/api/kpi";
export const API_ANALYSYS = "form/api/analysis";
export const API_APO_SINCRO = "form/api/apo/sincro";
export const API_DOCS = "form/api/docs";
export const API_PDF = "form/api/pdf";
export const API_CONTROLS = "form/api/controls";
export const API_SOLUTIONS = "form/api/solutions";
export const API_USER_CONFIG = "form/api/user/config";
export const API_GEN_WF = "form/api/gen-wf";
export const API_USER_SUBSTITUTION = "form/api/gen-user-substitution";
export const API_HELP = "form/api/help";
export const API_NOTIFICATIONS = "form/api/notifications";
export const API_USER_PRIVATE = "/form/api/user-private";
export const API_CUSTOMIZATION = "form/api/customization";
export const API_EMPLOYEE_STORE = "form/api/employee-store"

export const MUST_ASK_CLOUD_VERSION =
  process.env.REACT_APP_ASK_VERSION_CLOUD === "y";
export const PROXY_HOST = process.env.REACT_APP_PROXY_HOST;

export const ENV_PAGE_TITLE = process.env.REACT_APP_PAGE_TITLE || "";

export const NEED_ENGINE_ON = process.env.REACT_APP_NEED_ENGINE === "y";

export const CHINESE_ENABLED = process.env.REACT_APP_CHINESE_ENABLED === "y";

export const ENCA_ENABLED = process.env.REACT_APP_ENCA_ENABLED === "y";

export const MULTI_ITEM_ENABLED = process.env.REACT_APP_MULTI_ENABLED === "y";

export const DOCS_TAB_ENABLED = process.env.REACT_APP_DOCS_ENABLED === "y";

export const KEY_ENCRIPTION = "AzS899D3Jsdasd85";

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const PUBLIC_FOLDER = process.env.PUBLIC_URL;

//REST methods
export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PUT = "PUT";
export const METHOD_DELETE = "DELETE";
export const REST_HEADERS = {
  "Content-Type": "application/json; charset=utf8",
  Accept: "application/json",
};
export const TOKEN_HEADER = "SecurityToken";
export const TIME_OFFSET_HEADER = "TimeOffset";
export const TOKEN_INIT = "Bearer ";
export const USER_LOCALE_HEADER = "UserLocale";
export const SERVICE_SEL_URL = "form/api/service/sel/execute";
export const SERVICE_VAL_URL = "form/api/service/val/execute";
export const SERVICE_CLI_URL = "form/api/service/cli/execute";
export const SERVICE_EXE_URL = "form/api/service/exe/execute";

export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_TIMEOUT = 408;
export const HTTP_STATUS_TOO_MANY_REQUEST = 429;
export const HTTP_STATUS_INTERNAL_SERVER_ERROR = 500;
export const HTTP_STATUS_UNAVAILABLE_SERVICE= 503;

// Languages to be shown in the header -> combo box lang
export const languages = [
  {
    value: "es",
    label: "Español",
    enabled: true,
    flag: "es",
  },
  {
    value: "en",
    label: "English",
    enabled: !ENCA_ENABLED,
    flag: "us",
  },
  {
    value: "en",
    label: "English",
    letters: "EN",
    enabled: ENCA_ENABLED,
    sub: [
      {
        value: "en",
        enabled: true,
        label: "English (US)",
        flag: "us",
      },
      {
        value: "en-ca",
        enabled: true,
        label: "English (CA)",
        flag: "ca",
      },
    ],
  },
  {
    value: "pt",
    label: "Português",
    enabled: true,
    flag: "br",
  },
  {
    value: "cn",
    label: "简体中文",
    enabled: CHINESE_ENABLED,
    flag: "cn",
  },
  {
    value: "fr",
    label: "Français",
    enabled: true,
    flag: "fr",
  },
];

//Local Storage props
export const USER_PROP = "key_user";

//Main tabs codes
export const HOME_TAB = "home";
export const INBOX_TAB = "inbox";
export const WORKSPACE_TAB = "ws";
export const INDICATORS_TAB = "ind";
export const DOCS_TAB = "docs";
export const TAB_MAPPING = {
  home: "/",
  inbox: "/inbox",
  ws: "/",
  docs: "/docs",
};

export const FORM_PRESENTATION_INFO_PARAM = "presentationInfo";
export const FORM_OPTIONS_VALUES_PARAM = "optionsValues";
export const FORM_VALUE_PARAM = "value";

//Form operations keys
export const FORM_OPERATION_GET = "get";
export const FORM_OPERATION_NEW = "new";
export const FORM_OPERATON_EDIT = "upd";
export const FORM_OPERATION_DELETE = "del";
export const FORM_OPERATION_VIEW = "vie";
export const FORM_OPERATION_EXECUTION = "exe";

//Extra Features
export const EXTRA_FEATURE_PDF = "pdf";
export const EXTRA_FEAUTRE_EXCEL = "exc";
export const EXTRA_FEATURE_IMPORT_DATA = "imp";

export const AUDIT_OPERATION_NEW = "N";
export const AUDIT_OPERATION_EDIT = "E";
export const AUDIT_OPERATION_DELETE = "D";
export const AUDIT_OPERATION_VIEW = "V";

//FORM SERVICES TYPES
export const FORM_SERVICE_SELECTION = "S";
export const FORM_SERVICE_VALIDATION = "V";
export const FORM_SERVICE_CALCULATION = "C";

export const FORM_SERVICE_ACTION_CONTROL = "C";
export const FORM_SERVICE_ACTION_PRESENTATION = "P";

//INPUT FORM FINAL TYPES
export const FORM_INPUT_TEXT = "text";
export const FORM_INPUT_NUMBER = "number";

export const TYPE_FIELD = "F";
export const TYPE_VARIABLE = "V";
export const PREFIX_FIELD = "#";
export const PREFIX_VARIABLE = "$";

//Errors settings
//--Bad Form
export const ERROR_BAD_FORM_DEF_COLOR = "#BF0202";
export const ERROR_BAD_FORM_DEF_TEXT =
  "Problemas en definición de formulario. Por favor contactar a Soporte.";
export const ERROR_BAD_FORM_DEF_TEXT_SIZE = "1.4em";
export const ERROR_BAD_FORM_DEF_TEXT_WEIGHT = "bold";
export const ERROR_BAD_FORM_DEF_ICON = "fas fa-dizzy";
export const ERROR_BAD_FORM_DEF_ICON_SIZE = "10em";
//--Nothing to show
export const ERROR_NOTHING_TO_SHOW_DEF_COLOR = "#026dad";
export const ERROR_NOTHING_TO_SHOW_DEF_TEXT =
  "No hay nada por aquí para mostrar. ";
export const ERROR_NOTHING_TO_SHOW_DEF_TEXT_SIZE = "1.4em";
export const ERROR_NOTHING_TO_SHOW_DEF_TEXT_WEIGHT = "bold";
export const ERROR_NOTHING_TO_SHOW_DEF_ICON = "fas fa-grin-beam-sweat";
export const ERROR_NOTHING_TO_SHOW_DEF_ICON_SIZE = "10em";
//--Resource
export const ERROR_RESOURCE_NOT_FOUND_TEXT =
  "Recurso no encontrado o no disponible temporalmente. Si el problema persiste contacar con soporte.";
export const ERROR_RESOURCE_NOT_FOUND_ICON = "fas fa-unlink";

//Block types
export const BLOCK_TYPE_SIMPLE = "simple";
export const BLOCK_TYPE_LIST = "list";

//Color Palette
export const BACKGROUND_BLUE = "#026dad";

//Theme mode constants
export const THEME_PROP = "theme";
export const LIGHT_MODE = "light";
export const DARK_MODE = "dark";
export const COLOR_LIGHT_MODE_ICON = "#FFE240";
export const COLOR_DARK_MODE_ICON = "#199CD8";

//DARK PALLETE, not depends on theme mode
export const COLOR_DARKEST = "#324148";
export const COLOR_MID_DARK = "#026dad";
export const COLOR_MID_LIGHT = "#199CD8";
export const COLOR_LIGHTEST = "#00b8a9";

//LIGHT PALLETE, not depends on theme mode
export const DARK_COLOR_DARKEST = "#fff";
export const DARK_COLOR_MID_DARK = "#133b5c";
export const DARK_COLOR_MID_LIGHT = "#dcd6f7";
export const DARK_COLOR_LIGHTEST = "#f4eeff";

//export const BACKGROUND_ALWAYS_COLOR = "#0B2549";
export const BACKGROUND_ALWAYS_COLOR = "#53576D";

export const STATUSTASK_COLORS = {
  NEW: "#fdcb6e",
  ASSIGNED: "#009432",
  UNASSIGNED: "#d63031",
  DELEGATED: "#d2dae2",
  COMPLETED: "#74b9ff",
  CANCELLED: "#808080",
};

//Locales
export const LOCALE_PROP = "locale";
export const LOCALE_SPANISH = "es";
export const LOCALE_ENGLISH = "en";
export const LOCALE_PORTUGUES = "pt";
export const LOCALE_CHINESE = "cn";
export const LOCALE_ENGLISH_CA = "en-ca";
export const LOCALE_FRENCH = "fr";

//DATE constants
//export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_PATTERN = "yyyy-MM-dd";
export const TIME_PATTERN = "HH:mm";
export const DATE_TIME_PATTERN = "yyyy-MM-dd HH:mm:ss";

export const FRONT_DATE_PATTERN = "dd/MM/yyyy";
export const FRONT_TIME_PATTERN = "HH:mm";
export const FRONT_DATE_TIME_PATTERN = "dd/MM/yyyy HH:mm:ss";
export const FRONT_TIME_SECONDS_PATTERN = "HH:mm:ss";

//Breakpoints constants
export const BREAKPOINT_XS = 400;
export const BREAKPOINT_SM = 576;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;

//Spacing and padding constants
export const PADDING_ICON_BUTTONS_FORMS = 5;

//SQL functions
export const SQL_FUNCTION_MAX = "MAX";
export const SQL_FUNCTION_MIN = "MIN";
export const SQL_FUNCTION_COUNT = "COUNT";
export const SQL_FUNCTION_SUM = "SUM";
export const SQL_FUNCTION_AVG = "AVG";

export const SQL_SORT_ASC = "asc";
export const SQL_SORT_DESC = "desc";
export const SQL_SORT_NONE = "none";

//Pagination
export const PAGINATION_ALL = "all";
export const PAGINATION_DEFAULT_PAGE = "0";
export const PAGINATION_DEFAULT_PAGE_SIZE = 20;
export const PAGINATION_DEFAULT_PZ_VALUES = [20, 50, 100];
export const PAGINATION_DEFAULT_MAX_SIZE = 500;
export const PAGINATION_ROWS_TO_SHOW = 1000;

export const PAGINATION_DEFAULT_INBOX_PAGE_SIZE = 5;
export const PAGINATION_DEFAULT_INBOX_VALUES = [5, 20, 50, 100];

export const PAGINATION_DEFAULT_MASSIVE_PAGE_SIZE = 20;
export const PAGINATION_DEFAULT_MASSIVE_VALUES = [5, 20, 50];

// Constants to enable/disable limit max rows than a user can select in each seleccion_generica
export const DEFAULT_MAX_ROWS_RETURN_GENERIC_SELECTION = 100;
export const THROW_ERROR_WHEN_MAX_ROWS_PASSED_ENABLED = true;

export const SEARCH_ROOT_CONSTANTS = "SEARCHROOTCONSTANT";

export const SEARCH_CRITERIA_GREATER = ">";
export const SEARCH_CRITERIA_LESS = "<";
export const SEARCH_CRITERIA_LIKE = ":";
export const SEARCH_CRITERIA_EQUAL = "=";
export const SEARCH_CRITERIA_BETWEEN = "x";
export const SEARCH_CRITERIA_DISTINCT = "!";
export const SEARCH_GROUPING_CONST = "GP";
export const SEARCH_GLOBAL_CONST = "GL";
export const SEARCH_PARTICULAR_CONST = "PT";
export const SEARCH_CRITERIA_IN = "IN";
export const SEARCH_CRITERIA_NOT_IN = "NIN";

export const DOUBLE_LAYOUT = 0;
export const ONLY_GROUP_LAYOUT = 1;
export const ONLY_DETAIL_LAYOUT = 2;

export const TOAST_CONTAINER_LAYOUT = "TOAST_CONTAINER_LAYOUT";
export const TOAST_CONTAINER_LOGIN = "TOAST_CONTAINER_LOGIN";
export const TOAST_CONTAINER_INBOX = "TOAST_CONTAINER_INBOX";
export const TOAST_CONTAINER_QUERY = "TOAST_CONTAINER_QUERY";
export const TOAST_CONTAINER_JSONEDITOR = "TOAST_CONTAINER_JSONEDITOR";
export const TOAST_CONTAINER_MENUEDITOR = "TOAST_CONTAINER_MENUEDITOR";
export const TOAST_CONTAINER_FORM_CONTAINER = "TOAST_CONTAINER_FORM_CONTAINER";
export const TOAST_CONTROLS_VIS_CONTAINER = "TOAST_CONTROLS_VIS_CONTAINER";
export const TOAST_USER_CONFIGS_CONTAINER = "TOAST_USER_CONFIGS_CONTAINER";
export const TOAST_NOTIFICATIONS_CONFIG_CONTAINER =
  "TOAST_NOTIFICATIONS_CONFIG_CONTAINER";

export const TOAST_ID_SERVER_OFF = 1;

export const DASHBOARD_CARD_SMALL = "S";
export const DASHBOARD_CARD_MEDIUM = "M";
export const DASHBOARD_CARD_LARGE = "L";
export const DASHBOARD_CARD_EXTRA = "X";
export const DASHBOARD_CARD_EXTRA_LARGE = "XL";

export const ALIGNMENT_RIGHT_ = "right";
export const ALIGNMENT_LEFT = "left";
export const ALIGNMENT_CENTER = "center";
export const DIRECTION_REVERSE = "reverse";

export const SEL_BTN_SUFFIX = "_sel_btn";
export const CALENDAR_BTN_SUFFIX = "_cal_btn";

export const INPUT_TYPE_INPUT = "Input";
export const INPUT_TYPE_CHECKBOX = "CheckBox";
export const INPUT_TYPE_COMBOBOX = "ComboBox";
export const INPUT_TYPE_DATEPICKER = "DatePicker";
export const INPUT_TYPE_DATETIMEPICKER = "DateTimePicker";
export const INPUT_TYPE_FILE = "File";
export const INPUT_TYPE_PASSWORD = "PasswordInput";
export const INPUT_TYPE_TIMEPICKER = "TimePicker";
export const INPUT_TYPE_MULTI_COMBO = "MultipleComboBox";
export const INPUT_TYPE_BUTTON = "Button";
export const INPUT_TYPE_MULTI_PART = "MultiPartInput";
export const INPUT_TYPE_HELPER_INPUT = "HelperInput";
export const INPUT_TYPE_LAUNCH_EXTERNAL_FORM = "LaunchExternalFormButton";
export const INPUT_TYPE_CHIPS_FIELD = "ChipsInput";
export const INPUT_TYPE_PASTE_AREA = "PasteArea";
export const INPUT_TYPE_DATE_RANGE = "DateRange";

export const TOAST_TYPE_ERROR = "error";
export const TOAST_TYPE_WARNING = "warning";
export const TOAST_TYPE_INFO = "info";
export const TOAST_TYPE_SUCCESS = "success";

export const MAX_DATE_FORM = new Date("2100-01-01");
export const MIN_DATE_FORM = new Date("1900-01-01");

export const INITIAL_OPEN_EXTERNAL_FORM_STATE = {
  open: false,
  formKey: null,
  idForm: null,
  path: null,
  operation: FORM_OPERATION_NEW,
  values: null,
  overrideOps: null,
  defaultWidth: null,
  defaultHeight: null,
  ukey: null,
};

export const INITIAL_OPEN_EXTERNAL_QUERY_STATE = {
  open: false,
  queryKey: null,
  defaultWidth: null,
  defaultHeight: null,
  coordinationFilters: [],
};

export const LINE_SEPARATOR = "\n";
export const FIELD_SEPARATOR = "\t";

export const IS_FORM_BUILDING_HELP_ENABLED =
  process.env.REACT_APP_SHOW_FORM_BUILDING_HELP === "y";

export const IS_NEW_LOGIN_ENABLED = process.env.REACT_APP_USE_NEW_LOGIN === "y";
export const IS_LOREAL_LOGIN_BUTTON_ENABLED =
  process.env.REACT_APP_LOGIN_SHOW_LOREAL_BUTTON === "y";

export const NEW_GRID_DISTRIBUTION_SOLUTIONS = ["all"];

export const NEW_CLEANING_AFTER_NEW_SOLUTIONS = ["all"];

export const REPORTS_FEATURE_LAZY_LOADING_ENABLED =
  process.env.REACT_APP_REPORTS_LAZY_LOADING_ENABLED === "y";

export const REPORTS_FEATURE_EXPORT_GROUPED_ENABLED =
  process.env.REACT_APP_REPORTS_EXPORT_GROUPED === "y";
