import React, { useContext } from "react";
import { Icon, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ThemeContext } from "../../../../../core/providers/ThemeContext";
import { AuthContext } from "../../../../../core/providers/AuthContext";

const useStyles = makeStyles(() => ({
  backLogo: {
    transform: "rotateY(180deg)",
  },
}));

export default function EmployeeStoreBack() {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  const { recoverThemeMode } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);

  const handleClick = () => {
    recoverThemeMode({ isEmployeeStore: false });
    history.push("/");
  };

  if (auth?.isOnlyEmsUser === true) {
    return null;
  }

  return (
    <Tooltip title={t("EMPLOYEE_STORE.BACK_TO_DSUITE")}>
      <IconButton
        className={classes.backLogo}
        onClick={() => handleClick()}
        aria-label="back-to-dsuite-button"
      >
        <Icon className="fas fa-sign-out-alt" />
      </IconButton>
    </Tooltip>
  );
}
