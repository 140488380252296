import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Icon } from "@material-ui/core";

import { iconAuto } from "./styles";

export default function ToggleMenuButton(props) {
  const { handleDrawerOpenState, openDrawer } = props;
  const { t } = useTranslation();

  return (
    <Tooltip
      title={openDrawer ? t("TOOLTIP_DRAWER_CLOSE") : t("TOOLTIP_DRAWER_OPEN")}
      placement="bottom"
    >
      <IconButton onClick={() => handleDrawerOpenState(!openDrawer)}>
        <Icon style={iconAuto} className={"fas fa-bars"} />
      </IconButton>
    </Tooltip>
  );
}
