import React, { useContext, useMemo } from "react";
import {
  IconButton,
  Badge,
  Icon,
  makeStyles,
  Popover,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import { useTranslation } from "react-i18next";

import { useMenuAnchor } from "../../../../core/hooks/useMenuAnchor";
import { useAccessesStore } from "../../../../core/stores/AccessesStore";
import { getLocaleText } from "../../../../util/UtilTraduction";
import { ThemeContext } from "../../../../core/providers/ThemeContext";

const MAX_BADGE = 5 * 1000; //5K

const SHOW_BUTTON = process.env.REACT_APP_PROCESSES_TASKS_ENABLED === "y";

const useStyles = makeStyles((theme) => ({
  mainButton: {
    margin: 6,
  },
  customBadge: {
    backgroundColor: theme.palette.content.mainColor,
    color: "white",
    padding: 3,
  },
  badgeAnchor: {
    top: "0",
    right: "0",
    transform: "scale(1) translate(45%, -70%)",
    transformOrigin: "100% 0%",
  },
  listItemIcon: {
    minWidth: "auto",
    marginRight: 10,
  },
  itemsTitle: {
    padding: "20px 15px 0px 20px",
    fontWeight: "bold",
  },
  boldTypo: {
    fontWeight: "bold",
  },
  listItemText: {
    marginRight: 10,
  },
  taskImg: {
    maxHeight: 26,
  },
}));

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export default function ProcessesButton({ handleProcessClick, isMobile }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [anchorEl, openMenuAnchor, closeMenuAnchor] = useMenuAnchor();
  const { processesList } = useAccessesStore((state) => ({
    processesList: state.processesList,
  }));
  const { theme } = useContext(ThemeContext);

  const open = Boolean(anchorEl);
  const id = open ? "processes_popover" : undefined;
  const totalNumber =
    !isNil(processesList) && !isEmpty(processesList)
      ? processesList.reduce((sum, item) => sum + item.number, 0)
      : 0;

  function handleItemClick(item) {
    closeMenuAnchor();
    handleProcessClick({
      defaultPinned: true,
      name: item.icon,
      propText: item.label,
      route: item.route,
    });
  }

  const taskNumberCalculated = useMemo(() => {
    return `${t("MY_TASKS")} (${totalNumber})`;
  }, [t, totalNumber]);

  return (
    <>
      <IconButton
        aria-label="process_tasks˝"
        className={classes.mainButton}
        onClick={openMenuAnchor}
        disabled={totalNumber === 0 || !SHOW_BUTTON}
      >
        <Badge
          badgeContent={totalNumber}
          classes={{
            badge: classes.customBadge,
            anchorOriginTopRightRectangular: classes.badgeAnchor,
          }}
          max={MAX_BADGE}
          showZero={false}
        >
          <Icon className="fas fa-tasks" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenuAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: isMobile ? "left" : "right",
        }}
        transformOrigin={{
          vertical: isMobile ? "'top" : "center",
          horizontal: isMobile ? "right" : "left",
        }}
      >
        <div>
          <Typography variant="h6" className={classes.itemsTitle}>
            {taskNumberCalculated}
          </Typography>
          <List>
            {processesList
              .filter((x) => !isNil(x) && !isNil(x?.number))
              .map((x) => {
                const hasImg = !isNil(x?.img) && !isEmpty(x?.img);
                const isDarkMode = theme === "dark";
                return (
                  <ListItem
                    button
                    key={x?.route}
                    onClick={() => handleItemClick(x)}
                  >
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      {hasImg ? (
                        <img
                          alt="solution-tasks-logo"
                          src={
                            isDarkMode
                              ? `${process.env.PUBLIC_URL}/assets/icons/${x.img}_dark.png`
                              : `${process.env.PUBLIC_URL}/assets/icons/${x.img}.png`
                          }
                          className={classes.taskImg}
                        />
                      ) : (
                        <Icon className={x?.icon} style={commonIcon} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={getLocaleText(x?.label, i18n)}
                      className={classes.listItemText}
                    />
                    <ListItemSecondaryAction>
                      <Typography className={classes.boldTypo}>
                        {x?.number}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </div>
      </Popover>
    </>
  );
}
