import React, { useState, useCallback, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const SPACE_ITEMS = 10;

const useStyles = makeStyles((theme) => ({
  dialogContentContaier: {
    display: "flex",
    flexDirection: "column",
    gap: SPACE_ITEMS,
  },
  title: {
    cursor: "move",
    padding: SPACE_ITEMS * 2,
  },
  qrImage: {
    width: 200,
    height: 200,
    marginTop: SPACE_ITEMS,
  },
  stepIndex: {
    color: "white",
    borderRadius: "100%",
    backgroundColor: theme.palette.content.mainColor,
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  step: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  mfaCodeInput: {
    marginTop: SPACE_ITEMS,
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  notAccount: {
    color: theme.palette.content.mainColor,
    textDecoration: "underline",
    textAlign: "center",
    cursor: "pointer",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function MFADialog({ open, handleAccept, handleCancel }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [mfaCode, setMfaCode] = useState("");

  const handleChange = useCallback((e) => {
    setMfaCode(e.target.value);
  }, []);

  useEffect(() => {
    setMfaCode("");
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disableEscapeKeyDown={true}
    >
      <div className={classes.title} id="draggable-dialog-title">
        <Typography variant="h5">{t("MFA_DIALOG_TITLE")}</Typography>
      </div>

      <DialogContent className={classes.dialogContentContaier}>
        <Alert severity="success" className={classes.dialogInput}>
          {t("FORGOT_PASS_DIALOG_CODE_SENT")}
        </Alert>
        <div className={classes.step}>
          <Typography variant="h6">{t("MFA_DIALOG_STEP_TWO")}</Typography>
          <TextField
            id="mfa-code-input"
            label={t("MFA_DIALOG_INPUT_LABEL")}
            variant="outlined"
            fullWidth
            className={classes.mfaCodeInput}
            value={mfaCode}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                handleAccept({ mfaCode: mfaCode });
              }
            }}
            inputProps={{ maxLength: 6 }}
            autoFocus
            autoComplete="off"
            type="number"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleAccept({ mfaCode })}
          color="primary"
          disabled={mfaCode.length < 6}
        >
          {t("MFA_DIALOG_VALIDATE_CODE")}
        </Button>

        <Button onClick={handleCancel}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
