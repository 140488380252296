export const usePinnedColumns = (hooks) => {

  hooks.visibleColumns.push((columns) => {
    const orderedSelectionColumns = columns.filter((col) => col.isSelectionColumn);
    const orderedActionColumns = columns.filter((col) => col.isActionColumn);

    const pinnedLeftColumns = columns.filter(
      (col) => col.pinned === 'start' && !col.isSelectionColumn
    );
    const pinnedRightColumns = columns.filter(
      (col) => col.pinned === 'end' && !col.isActionColumn
    );
    const nonPinnedColumns = columns.filter(
      (col) => (!col.pinned || col.pinned === 'center')
    );

    return [
      ...orderedSelectionColumns,  // Siempre al principio
      ...pinnedLeftColumns,        // Luego las pinned a la izquierda
      ...nonPinnedColumns,         // Después las no pinned
      ...pinnedRightColumns,       // Luego las pinned a la derecha
      ...orderedActionColumns,     // Siempre al final
    ];

  })

  hooks.getTableProps.push((props) => [
    props,
    {
      style: {
        position: 'relative',
      },
    },
  ])

  hooks.getHeaderProps.push((props, { column }) => {

    const style = {
      ...props.style,
    };

    if (column.pinned === 'start' || !!column.isSelectionColumn) {
      style.position = 'sticky';
      style.left = `${column.totalLeft}px`;
      style.zIndex = 3;
    }

    if (column.pinned === 'end' || !!column.isActionColumn) {
      style.position = 'sticky';
      style.right = `${column.totalRight}px`;
      style.zIndex = 3;
    }

    return { ...props, style };
  });


  hooks.getCellProps.push((props, { cell }) => {

    const style = {
      ...props.style,
    };

    if (cell.column.pinned === 'start' || !!cell.column.isSelectionColumn) {
      style.position = 'sticky';
      style.left = `${cell.column.totalLeft}px`;
      style.zIndex = 1;

    }

    if (cell.column.pinned === 'end' || !!cell.column.isActionColumn) {
      style.position = 'sticky';
      style.right = `${cell.column.totalRight}px`;
      style.zIndex = 1;

    }

    return { ...props, style };
  });
}
