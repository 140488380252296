import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useStyles, iconStyles } from "../FilterDialogsStlyes";
import {
  LINE_SEPARATOR,
  SEARCH_CRITERIA_IN,
  SEARCH_CRITERIA_NOT_IN,
} from "../../../../../util/Constants";
import { isEmpty, isNil, toString, trim } from "lodash";

export default function FilterFieldPaseAreaTextDialog({
  open,
  handleFilterDialog,
  inheritedOperation,
  inheritedValue,
  deleteFilterConfig,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [operation, setOperation] = useState(SEARCH_CRITERIA_IN);
  const [filterValue, setFilterValue] = useState(inheritedValue || "");

  useEffect(() => {
    if (inheritedOperation && inheritedOperation !== "") {
      setOperation(inheritedOperation);
    }
  }, [inheritedOperation]);

  function handleConvertAndFilterDialog() {
    const newFilterValueToSet = filterValue
      .split(LINE_SEPARATOR)
      ?.filter((x) => !isNil(x) && !isEmpty(x))
      ?.map((x) => trim(toString(x).replace(",", "")))
      ?.join(",");
    handleFilterDialog(newFilterValueToSet, false, operation);
  }

  const handleDeleteConfig = (e) => {
    deleteFilterConfig(e);
    setFilterValue("");
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleFilterDialog(null, true)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography style={{ fontSize: "20px" }}>
          {t("SEARCH_FILTER_DEF_TITLE")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.filterDialogLine}>
          <Typography>{t("SEARCH_CRITERIA_LABEL")}</Typography>
          <Select
            value={operation}
            onChange={(e) => {
              setOperation(e.target.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "operation select" }}
            style={{ width: "100%", marginLeft: 10 }}
          >
            <MenuItem value={SEARCH_CRITERIA_IN}>
              {t("SEARCH_CRITERIA_IN")}
            </MenuItem>
            <MenuItem value={SEARCH_CRITERIA_NOT_IN}>
              {t("SEARCH_CRITERIA_NOT_IN")}
            </MenuItem>
          </Select>
        </div>

        <div className={classes.filterDialogLine}>
          <TextField
            multiline
            maxRows={5}
            autoFocus={true}
            margin="dense"
            id="filterValue"
            label={t("SEARCH_CRITERIA_VALUES")}
            type="text"
            fullWidth
            value={filterValue}
            onChange={(evt) => {
              setFilterValue(evt.target.value);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleConvertAndFilterDialog()}
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {t("SEARCH_FILTER_APPLY")}
        </Button>
        {inheritedOperation && inheritedValue && (
          <IconButton
            aria-label={`remove filter button`}
            onClick={handleDeleteConfig}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {t("FORM_DELETE").toUpperCase()}
            <Icon
              className="fas fa-trash"
              style={{ ...iconStyles, marginLeft: 3 }}
            />
          </IconButton>
        )}
        <Button onClick={() => handleFilterDialog(null, true)} color="primary">
          {t("FORM_CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
