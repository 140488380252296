import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  IS_LOREAL_LOGIN_BUTTON_ENABLED,
  IS_NEW_LOGIN_ENABLED,
} from "../../../util/Constants";

const useStyles = makeStyles((theme) => ({
  dsuiteLogoImg: {
    width: "auto",
    maxHeight: 100,
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "80%",
    },
  },
  dsuiteLogoImgNew: {
    width: "auto",
    maxWidth: ({ isFull }) => (isFull === true ? "85%" : "60%"),
    filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.5))",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 350,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      maxWidth: "100%",
    },
  },
}));

export default function DSuiteLogoNew() {
  const classes = useStyles({
    isFull: IS_LOREAL_LOGIN_BUTTON_ENABLED === false,
  });

  return (
    <img
      src={
        IS_NEW_LOGIN_ENABLED
          ? `${process.env.PUBLIC_URL}/assets/img/DSUITE_logo_new.png`
          : `${process.env.PUBLIC_URL}/assets/img/DSUITE_logo.png`
      }
      className={
        IS_NEW_LOGIN_ENABLED === true
          ? classes.dsuiteLogoImgNew
          : classes.dsuiteLogoImg
      }
      alt="DSuite Logo"
    />
  );
}
