//Input field for text and number
import SimpleCheckboxField from "./SimpleCheckboxField";
import SimpleComboBoxField from "./SimpleComboBoxField";
import SimpleDatePickerField from "./SimpleDatePickerField";
import SimpleDateTimePickerField from "./SimpleDateTimePickerField";
import SimpleFileField from "./SimpleFileField";
import SimpleInputField from "./SimpleInputField";
import SimplePasswordInputField from "./SimplePasswordInputField";
import SimpleTimePickerField from "./SimpleTimePickerField";
import SimpleMultiPartInput from "./SimpleMultiPartInput";
import SimpleButtonField from "./SimpleButtonField";
import SimpleLaunchExternalFormField from "./SimpleLaunchExternalFormField";
import SimpleMultiComboBoxField from "./SimpleMultiComboBoxField";
import SimpleChipField from "./SimpleChipField";

import {
  INPUT_TYPE_BUTTON,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_COMBOBOX,
  INPUT_TYPE_DATEPICKER,
  INPUT_TYPE_DATETIMEPICKER,
  INPUT_TYPE_FILE,
  INPUT_TYPE_INPUT,
  INPUT_TYPE_MULTI_PART,
  INPUT_TYPE_PASSWORD,
  INPUT_TYPE_TIMEPICKER,
  INPUT_TYPE_LAUNCH_EXTERNAL_FORM,
  INPUT_TYPE_MULTI_COMBO,
  INPUT_TYPE_CHIPS_FIELD
} from "../../../util/Constants";

export const customSimpleFields = {
  //Overrides
  textField: SimpleInputField,
  TextField: SimpleInputField,
  StringField: SimpleInputField,
  NumberField: SimpleInputField,
  BooleanField: SimpleCheckboxField,
  //Own defs
  [INPUT_TYPE_INPUT]: SimpleInputField,
  [INPUT_TYPE_CHECKBOX]: SimpleCheckboxField,
  [INPUT_TYPE_DATEPICKER]: SimpleDatePickerField,
  [INPUT_TYPE_DATETIMEPICKER]: SimpleDateTimePickerField,
  [INPUT_TYPE_COMBOBOX]: SimpleComboBoxField,
  [INPUT_TYPE_FILE]: SimpleFileField,
  [INPUT_TYPE_PASSWORD]: SimplePasswordInputField,
  [INPUT_TYPE_TIMEPICKER]: SimpleTimePickerField,
  [INPUT_TYPE_MULTI_PART]: SimpleMultiPartInput,
  [INPUT_TYPE_BUTTON]: SimpleButtonField,
  [INPUT_TYPE_LAUNCH_EXTERNAL_FORM]: SimpleLaunchExternalFormField,
  [INPUT_TYPE_MULTI_COMBO]: SimpleMultiComboBoxField,
  [INPUT_TYPE_CHIPS_FIELD]: SimpleChipField
};
