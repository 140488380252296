import React from "react";

import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { Box, Divider, Icon } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";
import {
  head,
  isArray,
  isEmpty,
  isNil,
  last,
  sortBy,
  toLower,
  toString,
  trim,
} from "lodash";
import { getFormattedValueWithObjectDef } from "../../../util/UtilFormat";
import {
  getCorrectStyleVersionToUse,
  QUERIES_STYLE_VERSION_3,
} from "../util-reports";
import {
  SQL_FUNCTION_AVG,
  SQL_FUNCTION_COUNT,
  SQL_FUNCTION_MAX,
  SQL_FUNCTION_MIN,
  SQL_FUNCTION_SUM,
} from "../../../util/Constants";

const CommonIcon = withStyles(() => ({
  root: {
    fontSize: 12,
  },
}))(Icon);

// function that recive the column and the mask of string to convert
// function convertMask(mask, value) {
//   try {
//     const position = mask.toString().split(".").length;
//     return value.toFixed(position);
//   } catch (error) {
//     return value;
//   }
// }

function HeaderMenu({
  title,
  tableProps,
  updateColumn,
  handleToggleGroupBy,
  disableShowMenu,
  stylesVersion,
}) {
  const { t } = useTranslation();
  const { column, columns, canSort } = tableProps;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const muiTheme = useTheme();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: ["number", "numeric"].includes(column.type)
        ? "end"
        : "space-between",
      "& .appear-item": {
        visibility: "hidden",
      },
      "&:hover .appear-item": {
        visibility: "visible",
      },
      "& .appear-item-colored": {
        visibility: "hidden",
      },
      "&:hover .appear-item-colored": {
        visibility: "visible",
        color: theme.palette.content.mainColor,
      },
    },
  }));

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGroupBy = () => {
    handleToggleGroupBy(column);
    setAnchorEl(null);
  };

  const handleSortBy = (function () {
    return {
      asc: function () {
        column.toggleSortBy(false, false);
        setAnchorEl(null);
      },
      desc: function () {
        column.toggleSortBy(true, false);
        setAnchorEl(null);
      },
    };
  })();

  // Chequea si hay elementos null o undefined y los cambia por string vacío para evitar errores al usar sortBy
  const checkIsNil = (arr) =>
    arr.map((value) => {
      if (isNil(value)) {
        return typeof value === "number" ? 0 : "";
      }
      return value;
    });

  const maxValue = (leafValues) => {
    const modifiedLeafValues = checkIsNil(leafValues);
    return isNaN(Math.max(...modifiedLeafValues))
      ? 0
      : Math.max(...modifiedLeafValues);
  };

  const maxValueSt = (leafValues) => {
    const modifiedLeafValues = checkIsNil(leafValues);
    return !isEmpty(modifiedLeafValues) && isArray(modifiedLeafValues)
      ? last(sortBy(modifiedLeafValues))
      : "";
  };

  const minValue = (leafValues) => {
    const modifiedLeafValues = checkIsNil(leafValues);
    return isNaN(Math.min(...modifiedLeafValues))
      ? 0
      : Math.min(...modifiedLeafValues);
  };

  const minValueSt = (leafValues) => {
    const modifiedLeafValues = checkIsNil(leafValues);
    return !isEmpty(modifiedLeafValues) && isArray(modifiedLeafValues)
      ? head(sortBy(modifiedLeafValues))
      : "";
  };

  const handleUpdateColumn = () =>
    updateColumn(columns.map((c) => (c.id === column.id ? column : c)));

  const handleAggregated = (function () {
    return {
      sum: function () {
        column.aggregateSqlFunction = SQL_FUNCTION_SUM;
        column.aggregate = "sum";
        column.Aggregated = ({ value }) =>
          getFormattedValueWithObjectDef({ obj: column, value });
        handleUpdateColumn();
        setAnchorEl(null);
      },
      avg: function () {
        column.aggregateSqlFunction = SQL_FUNCTION_AVG;
        column.aggregate = "average";
        column.Aggregated = ({ value }) =>
          getFormattedValueWithObjectDef({ obj: column, value });
        handleUpdateColumn();
        setAnchorEl(null);
      },
      count: function () {
        column.aggregateSqlFunction = SQL_FUNCTION_COUNT;
        column.aggregate = "uniqueCount";
        column.Aggregated = ({ value }) => value;
        handleUpdateColumn();
        setAnchorEl(null);
      },
      max: function () {
        column.aggregateSqlFunction = SQL_FUNCTION_MAX;
        column.aggregate =
          toLower(column.type) === "string" ? maxValueSt : maxValue;
        column.Aggregated = ({ value }) =>
          getFormattedValueWithObjectDef({ obj: column, value });
        handleUpdateColumn();
        setAnchorEl(null);
      },
      min: function () {
        column.aggregateSqlFunction = SQL_FUNCTION_MIN;
        column.aggregate =
          toLower(column.type) === "string" ? minValueSt : minValue;
        column.Aggregated = ({ value }) =>
          getFormattedValueWithObjectDef({ obj: column, value });
        handleUpdateColumn();
        setAnchorEl(null);
      },
      blank: function () {
        column.aggregateSqlFunction = null;
        column.aggregate = null;
        column.Aggregated = null;
        handleUpdateColumn();
        setAnchorEl(null);
      },
    };
  })();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stylesVersionToApply = getCorrectStyleVersionToUse({ stylesVersion });

  const isNumericColumn =
    toLower(trim(toString(column?.type))) === "numeric" ||
    toLower(trim(toString(column?.type))) === "number";

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span {...column.getSortByToggleProps()} title="">
          {title}
          {column.isSorted ? (column.isSortedDesc ? " 🔽 " : " 🔼 ") : ""}
          {column.aggregate ? (
            <CommonIcon
              style={{ marginLeft: 5, paddingTop: 1 }}
              className={`${
                column.aggregate?.name === "maxValue" ||
                column.aggregateSqlFunction === SQL_FUNCTION_MAX
                  ? "fas fa-angle-double-up"
                  : column.aggregate?.name === "minValue" ||
                    column.aggregateSqlFunction === SQL_FUNCTION_MIN
                  ? "fas fa-angle-double-down"
                  : column.aggregate === "sum"
                  ? "fas fa-plus"
                  : column.aggregate === "average"
                  ? "fas fa-balance-scale"
                  : column.aggregate === "uniqueCount"
                  ? "fas fa-hashtag"
                  : "blank"
              }`}
            />
          ) : (
            ""
          )}
        </span>

        {column?.isGrouped ? (
          <Icon
            className="far fa-object-group"
            style={{ fontSize: 12, marginLeft: 5, cursor: "pointer" }}
            onClick={() => handleToggleGroupBy(column)}
          />
        ) : null}
      </div>

      {disableShowMenu !== true && (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
          className={
            stylesVersionToApply === QUERIES_STYLE_VERSION_3
              ? "appear-item-colored"
              : "appear-item"
          }
        >
          <MenuIcon
            style={{
              color:
                stylesVersionToApply === QUERIES_STYLE_VERSION_3
                  ? muiTheme.palette.content.mainColor
                  : "white",
            }}
          />
        </IconButton>
      )}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "30ch",
          },
        }}
      >
        {/* [SANTI] 2024-08-07 Oculto el ordenamiento si no se puede ordenar */}
        {canSort && (
          <Box>
            <MenuItem
              key="order-asc"
              onClick={handleSortBy.asc}
              disabled={column.isSorted && !column.isSortedDesc}
            >
              <ListItemText primary={t("REPORTS_MENU_SORT_ASC")} />
              <ListItemIcon>
                <CommonIcon className="fas fa-sort-alpha-down" />
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              key="order-desc"
              onClick={handleSortBy.desc}
              disabled={column.isSorted && column.isSortedDesc}
            >
              <ListItemText primary={t("REPORTS_MENU_SORT_DESC")} />
              <ListItemIcon>
                <CommonIcon className="fas fa-sort-alpha-down-alt" />
              </ListItemIcon>
            </MenuItem>
            <Divider light />
          </Box>
        )}
        <MenuItem
          key="agrupar"
          onClick={handleGroupBy}
          disabled={column?.isGrouped}
        >
          <ListItemText primary={t("REPORTS_MENU_GROUP")} />
          <ListItemIcon>
            <CommonIcon className="far fa-object-group" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="desagrupar"
          onClick={handleGroupBy}
          disabled={!column?.isGrouped}
        >
          <ListItemText primary={t("REPORTS_MENU_UNGROUP")} />
          <ListItemIcon>
            <CommonIcon className="far fa-object-ungroup" />
          </ListItemIcon>
        </MenuItem>
        <Divider light />
        {isNumericColumn ? (
          <MenuItem
            key="aggr-sum"
            onClick={handleAggregated.sum}
            selected={column.aggregate === "sum"}
          >
            <ListItemText primary={t("REPORTS_MENU_ADD")} />
            <ListItemIcon>
              <CommonIcon className="fas fa-plus" />
            </ListItemIcon>
          </MenuItem>
        ) : null}
        <MenuItem
          key="aggr-count"
          onClick={handleAggregated.count}
          selected={column.aggregate === "uniqueCount"}
        >
          <ListItemText primary={t("REPORTS_MENU_COUNT")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-hashtag" />
          </ListItemIcon>
        </MenuItem>
        {isNumericColumn ? (
          <MenuItem
            key="aggr-avg"
            onClick={handleAggregated.avg}
            selected={column.aggregate === "average"}
          >
            <ListItemText primary={t("REPORTS_MENU_AVG")} />
            <ListItemIcon>
              <CommonIcon className="fas fa-sliders-h" />
            </ListItemIcon>
          </MenuItem>
        ) : null}

        <MenuItem
          key="aggr-max"
          onClick={handleAggregated.max}
          selected={
            column.aggregate?.name === "maxValue" ||
            column.aggregateSqlFunction === SQL_FUNCTION_MAX
          }
        >
          <ListItemText primary={t("REPORTS_MENU_MAX")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-angle-double-up" />
          </ListItemIcon>
        </MenuItem>
        
        <MenuItem
          key="aggr-min"
          onClick={handleAggregated.min}
          selected={
            column.aggregate?.name === "minValue" ||
            column.aggregateSqlFunction === SQL_FUNCTION_MIN
          }
        >
          <ListItemText primary={t("REPORTS_MENU_MIN")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-angle-double-down" />
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          key="aggr-blank"
          onClick={handleAggregated.blank}
          selected={column.aggregate === null}
        >
          <ListItemText primary={t("REPORTS_MENU_NONE")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-ban" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default React.memo(HeaderMenu);
