import { isNil } from "lodash";
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_DELETE,
} from "../../../util/Constants";
import { BASE_EMS_URL, handleApiResponse } from "./base";

const CART_GET_ITEMS_ENDPOINT = "/cart/get-cart-items";
const CART_ADD_ITEM_ENDPOINT = "/cart/add-cart-item";
const CART_REMOVE_ITEM_ENDPOINT = "/cart/remove-cart-item";
const CART_CLEAR_ENDPOINT = "/cart/clear-cart-items";

export const getCartProductsApi = async ({ headers, logout }) => {
  const response = await fetch(`${BASE_EMS_URL}${CART_GET_ITEMS_ENDPOINT}`, {
    method: METHOD_GET,
    headers: headers,
  });

  return handleApiResponse(response, logout);
};

export const addCartProductApi = async ({
  headers,
  logout,
  params: { emsIdCompra, ean, catalogCode, quantity },
}) => {
  const emsIdCompraQP = !isNil(emsIdCompra)
    ? `emsIdCompra=${emsIdCompra}&`
    : "";
  const response = await fetch(
    `${BASE_EMS_URL}${CART_ADD_ITEM_ENDPOINT}?${emsIdCompraQP}ean=${ean}&catalogCode=${catalogCode}&quantity=${quantity}`,
    {
      method: METHOD_POST,
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};

export const removeCartProductApi = async ({
  headers,
  logout,
  params: { emsIdCompra, ean },
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${CART_REMOVE_ITEM_ENDPOINT}?emsIdCompra=${emsIdCompra}&ean=${ean}`,
    {
      method: METHOD_DELETE,
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};

export const clearCartApi = async ({
  headers,
  logout,
  params: { emsIdCompra },
}) => {
  const response = await fetch(
    `${BASE_EMS_URL}${CART_CLEAR_ENDPOINT}?emsIdCompra=${emsIdCompra}`,
    {
      method: METHOD_DELETE,
      headers: headers,
    }
  );

  return handleApiResponse(response, logout);
};
