import React from "react";
import { makeStyles } from "@material-ui/core";
import { CircularProgress, Drawer, Hidden } from "@material-ui/core";

import MenuLabelAndVersion from "./MenuLabelAndVersion";
import MenuDrawer from "../../../components/drawer/MenuDrawer";
import { appBarHeight, drawerWidth } from "../LayoutStylesGlobals";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    background: `linear-gradient(180deg, ${theme.palette.drawer.upGradientColor} 70%, ${theme.palette.drawer.downGradientColor} 83%)`,
    color: theme.palette.drawer.text,
    top: appBarHeight,
    left: appBarHeight - 2,
    zIndex: 1,
    height: `calc(100% - ${appBarHeight}px)`,
    boxShadow: `0px 2px 10px 0px ${theme.palette.content.mainColor}AA`,
  },
  paperAnchorDockedLeft: {
    borderRight: "none",
  },
  fetchingCont: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

export default function DesktopMenu(props) {
  const {
    fetchingMenu,
    menuItems,
    openDrawer,
    pushPage,
    fetchMenuDef,
    handleClick,
    handleChildClick,
    handleGrandClick,
    handleLastClick,
  } = props;

  const classes = useStyles();

  return (
    <Hidden xsDown implementation="css">
      <Drawer
        classes={{
          paper: classes.drawerPaper,
          paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
        }}
        variant="persistent"
        open={openDrawer}
        PaperProps={{
          style: {
            position: "absolute",
          },
        }}
        BackdropProps={{
          style: {
            position: "absolute",
          },
        }}
        ModalProps={{
          container: document.getElementById("root-content"),
          style: { position: "absolute" },
        }}
      >
        <MenuLabelAndVersion />

        {fetchingMenu === true ? (
          <div className={classes.fetchingCont}>
            <CircularProgress
              style={{
                color: "white",
                marginBottom: "25px",
              }}
            />
          </div>
        ) : (
          <MenuDrawer
            mode="prod"
            isMobile={false}
            pushPage={pushPage}
            refreshMenu={fetchMenuDef}
            drawerItems={menuItems}
            handleClick={handleClick}
            handleChildClick={handleChildClick}
            handleGrandClick={handleGrandClick}
            handleLastClick={handleLastClick}
          />
        )}
      </Drawer>
    </Hidden>
  );
}
