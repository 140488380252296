import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { isNil } from "lodash";
import { saveAs } from "file-saver";

import { AuthContext } from "../../providers/AuthContext";
import { useRequestHeaders } from "../useRequestHeaders";
import * as API from "./api";
import {
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import CustomBodyToast from "../../../components/toast/CustomBodyToast";

export const ACTION_VIEW = "V";
export const ACTION_DOWNLOAD = "D";

export default function useFileCellRender() {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const REQUEST_HEADERS = useRequestHeaders();

  const [isFetchinView, setIsFetchinView] = useState(false);
  const [isFetchinDownload, setIsFetchinDownload] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [preview, setPreview] = useState(null);

  async function fetchUrl({ key, ext, fileName, action }) {
    if (action === ACTION_VIEW) {
      setIsFetchinView(true);
    } else {
      setIsFetchinDownload(true);
    }

    let toastContent = null;
    const response = await API.generatePreSignedUrlOnDemand({
      key,
      REQUEST_HEADERS,
    });
    if (!isNil(response)) {
      const { status, ok, errorMsg, dataResponse } = response;
      if (status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else {
        if (ok) {
          if (action === ACTION_VIEW) {
            setOpenViewer(true);
            setPreview({ url: dataResponse, ext });
          } else {
            saveAs(dataResponse, fileName);
          }
        } else {
          toastContent = <CustomBodyToast title={errorMsg} msg={null} />;
        }
      }
    } else {
      toastContent = (
        <CustomBodyToast
          title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
          msg={null}
        />
      );
    }

    if (toastContent) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
      });
    }

    setTimeout(() => {
      if (action === ACTION_VIEW) {
        setIsFetchinView(false);
      } else {
        setIsFetchinDownload(false);
      }
    }, 300);
  }

  function closeOpenViewer() {
    setOpenViewer(false);
  }

  return {
    isFetchinView,
    isFetchinDownload,
    openViewer,
    preview,
    fetchUrl,
    closeOpenViewer,
  };
}
