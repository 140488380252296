import React, { useMemo } from "react";
import { Icon, Tooltip, makeStyles, useTheme } from "@material-ui/core";
import { get, has, isEmpty, isNil } from "lodash";

const iconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  fontSize: "1.15rem",
};

const useStyles = makeStyles(() => ({
  innerSpan: {
    width: "100%",
    padding: 2,
  },
  buttonInter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: 5,
    cursor: "pointer",
    border: "none",
  },
}));

export default function InteractiveCellInputButton(props) {
  const {
    completeIdControl,
    calculatedInputProps,
    column,
    inputValue,
    inputRef,
    handleContextMenu,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const appTheme = theme?.palette?.type;

  const interactiveBtnDef = column?.interactiveBtnDef;

  const isInputDisabled =
    calculatedInputProps && calculatedInputProps?.enabled === false;

  const btnDefValue = useMemo(() => {
    if (
      !isNil(interactiveBtnDef) &&
      !isNil(inputValue) &&
      !isEmpty(interactiveBtnDef) &&
      !isNil(interactiveBtnDef?.mapValuesIcon) &&
      !isEmpty(interactiveBtnDef?.mapValuesIcon) &&
      has(interactiveBtnDef?.mapValuesIcon, inputValue)
    ) {
      return get(interactiveBtnDef?.mapValuesIcon, inputValue);
    } else {
      return null;
    }
  }, [inputValue, interactiveBtnDef]);

  //Input styles
  const controlStyles = useMemo(() => {
    return {
      fontSize:
        calculatedInputProps &&
        calculatedInputProps.fontSize &&
        calculatedInputProps.fontSize,
      backgroundColor:
        calculatedInputProps && calculatedInputProps.backgroundColor
          ? calculatedInputProps.backgroundColor
          : !isNil(btnDefValue) && has(btnDefValue?.bg, appTheme)
          ? get(btnDefValue?.bg, appTheme)
          : `${theme.palette.content.mainColor}AA`,
      fontWeight:
        calculatedInputProps &&
        calculatedInputProps.fontWeight &&
        calculatedInputProps.fontWeight,
      fontStyle:
        calculatedInputProps &&
        calculatedInputProps.fontStyle &&
        calculatedInputProps.fontStyle,
    };
  }, [calculatedInputProps, btnDefValue, theme, appTheme]);

  if (isNil(btnDefValue)) {
    if (interactiveBtnDef?.hideStringNotMapped === true) {
      return null;
    } else {
      return <span className={classes.innerSpan}>{inputValue}</span>;
    }
  }

  return (
    <Tooltip title={btnDefValue?.tooltip || ""}>
      <span className={classes.innerSpan}>
        <button
          className={classes.buttonInter}
          onClick={handleContextMenu}
          disabled={isInputDisabled}
          ref={inputRef}
          style={controlStyles}
          id={completeIdControl}
        >
          {!isNil(btnDefValue?.icon) && (
            <Icon
              className={btnDefValue?.icon}
              fontSize="small"
              style={{
                ...iconStyles,
                color:
                  !isNil(btnDefValue?.color) &&
                  has(btnDefValue?.color, appTheme) &&
                  get(btnDefValue?.color, appTheme),
              }}
            />
          )}
        </button>
      </span>
    </Tooltip>
  );
}
