import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { HOME_TAB, TAB_MAPPING } from "../../util/Constants";
import { LayoutContext } from "../providers/LayoutContext";

export function useNavigation() {
  const history = useHistory();
  const { changeActiveTab } = useContext(LayoutContext);

  function navigateToHome() {
    history.push(TAB_MAPPING[HOME_TAB]);
    changeActiveTab(HOME_TAB);
  }

  return { navigateToHome };
}
