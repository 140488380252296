import { useState, useCallback, useEffect } from "react";

export function useHorizontalScroll({ horizontalRef }) {
  /**
   * event handler when click left arrow.
   *
   * we want to scroll horozontally and programatically.
   **/
  const moveLeft = useCallback(
    (e) => {
      if (horizontalRef.current) {
        horizontalRef.current.scrollBy({
          behavior: "smooth",
          left: -300,
        });
      }
    },
    [horizontalRef]
  );

  /**
   * event handler when click right arrow.
   *
   * we want to scroll horozontally and programatically.
   **/
  const moveRight = useCallback(
    (e) => {
      if (horizontalRef.current) {
        horizontalRef.current.scrollBy({
          behavior: "smooth",
          left: 300,
        });
      }
    },
    [horizontalRef]
  );

  /**
   * side effect to close unnecessary arrows
   *
   *  - if scrollbar, need to show arrows, otherwise, no arrows
   *  - ref: https://stackoverflow.com/questions/4880381/check-whether-html-element-has-scrollbars
   **/
  const [isNeedArrows, setNeedArrows] = useState(true);

  /**
   * remove arrows if scroll position is on the edge
   *
   *  - use 'scroll' event handler to detect cur scroll position and remove unnecessary arrow
   *  - initial scroll position is 0 so default values for its state is 'false' and 'true'
   **/
  const [isNeedLeftArrow, setNeedLeftArrow] = useState(false);
  const [isNeedRightArrow, setNeedRightArrow] = useState(false);

  useEffect(() => {
    if (horizontalRef.current) {
      /**
       * this condition shows if there is any overflow of the innerBox component.
       */
      if (
        horizontalRef.current.scrollWidth > horizontalRef.current.clientWidth
      ) {
        // need to have scroll
        setNeedArrows(true);
        setNeedRightArrow(true);
      } else {
        // don't need it
        setNeedArrows(false);
      }
    }
  }, [
    horizontalRef,
    // eslint-disable-next-line
    ...(horizontalRef.current ? [horizontalRef.current.scrollWidth] : [null]),
    // eslint-disable-next-line
    ...(horizontalRef.current ? [horizontalRef.current.clientWidth] : [null]),
    isNeedArrows,
    setNeedArrows,
  ]);

  const handleScrollChangeEvent = (e) => {
    const curScrollPos = e.currentTarget.scrollLeft;
    const maxScrollPos =
      e.currentTarget.scrollWidth - e.currentTarget.clientWidth;
    if (curScrollPos === 0) {
      setNeedLeftArrow(false);
    } else if (curScrollPos === maxScrollPos) {
      setNeedRightArrow(false);
    } else {
      setNeedLeftArrow(true);
      setNeedRightArrow(true);
    }
  };

  return {
    isNeedLeftArrow,
    isNeedRightArrow,
    handleScrollChangeEvent,
    moveLeft,
    moveRight,
  };
}
