import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../../core/providers/ThemeContext";
import { useTheme } from "@material-ui/core";
import useWizard from "../../../../core/hooks/useWizard";
import StatusWizardIndicator from "./StatusWizardIndicator";
import { includes } from "lodash";

const StyledBlock = styled.div`
  height: inherit;
  width: inherit;
  overflow-y: auto;
  transform: ${({ hasLegend }) =>
    hasLegend ? "translateY(0px)" : "translateY(8px)"};

  pointer-events: ${({ blockShouldBeDisabled }) =>
    blockShouldBeDisabled ? "none" : "auto"};

  cursor: ${({ blockShouldBeDisabled }) =>
    blockShouldBeDisabled ? "not-allowed" : "auto"};

  .rjsf {
    height: inherit;
  }

  .rjsf > div > fieldset {
    overflow-y: auto;
  }

  /* TO HIDE RJSF OWN LABEL, AND ONLY SHOW OUR CUSTOM LABELS*/
  .control-label {
    display: none;
  }

  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 2;
    width: auto;
  }

  .form-control {
    border-width: 0.5px;
    border-style: solid;
    border-color: ${({ pre, themeType, defValues }) =>
      pre?.borderC && pre?.borderC[themeType]
        ? pre?.borderC[themeType]
        : defValues?.mainColor};
    border-radius: 5px;
    font-family: "Asap", "sans-serif";
  }

  .form-control:active {
    border: 1px solid
      ${({ defValues }) =>
        defValues?.mainColor ? defValues.mainColor : "#026dad"};
  }

  .field-object {
    height: inherit;
  }

  .field-boolean {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: flex-end;
  }

  fieldset {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: "space-between";
    background-color: ${({ pre, themeType, defValues }) =>
      pre?.bgC && pre?.bgC[themeType]
        ? pre?.bgC[themeType]
        : defValues?.blockBackground};
    border-width: ${({ pre, defValues, blockShouldBeDisabled }) =>
      blockShouldBeDisabled === true
        ? "1px"
        : pre && pre.borderW
        ? pre.borderW
        : defValues?.borderBlockWidth || "1px"};
    border-style: ${({ pre }) => (pre && pre.borderS ? pre.borderS : "solid")};
    border-color: ${({ pre, themeType, defValues, blockShouldBeDisabled }) =>
      blockShouldBeDisabled === true
        ? null
        : pre && pre?.borderC && pre?.borderC[themeType]
        ? pre?.borderC[themeType]
        : defValues?.borderBlockColor};
    border-radius: ${({ pre }) => (pre && pre.borderR ? pre.borderR : "10px")};
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    pointer-events: ${({ blockShouldBeDisabled }) =>
      blockShouldBeDisabled ? "none" : "auto"};

    padding-block-start: ${({ blockShouldBeFocused }) =>
      blockShouldBeFocused ? "0.2em" : "inherit"};

    padding-bottom: ${({ blockShouldBeFocused }) =>
      blockShouldBeFocused ? "2px" : "inherit"};
  }

  fieldset fieldset {
    border: none;
    margin: 0;
    padding: 0;
    height: min-content;
    background: inherit;
    pointer-events: ${({ blockShouldBeDisabled }) =>
      blockShouldBeDisabled ? "none" : "auto"};
  }

  /* TO QUIT INNER LEGENDS */
  fieldset fieldset legend {
    display: none;
  }

  fieldset .field-object {
    height: min-content;
  }

  legend {
    font-size: ${({ pre }) => (pre && pre.titleW ? pre.titleW : "1rem")};
    font-weight: ${({ pre }) =>
      pre && pre.titleWeight ? pre.titleWeight : "500"};
    line-height: 1;
    letter-spacing: 0.0075em;
    color: ${({ pre, themeType, defValues, blockShouldBeDisabled }) =>
      blockShouldBeDisabled === true
        ? "grey"
        : pre?.borderC && pre?.borderC[themeType]
        ? pre?.borderC[themeType]
        : defValues?.mainColor};
  }

  input:disabled {
    background-color: inherit;
  }

  .switch-wrapper {
    position: relative;
  }

  .switch-wrapper > div {
    position: absolute;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .inputTextUpperCase > input {
    text-transform: uppercase;
  }

  /* .MuiInputBase-root {
    color: ${({ blockShouldBeDisabled, defValues }) =>
    blockShouldBeDisabled
      ? `${defValues?.defaultDisabledColor} !important`
      : null};
  } */

  .field #label-field {
    color: ${({ blockShouldBeDisabled, defValues }) =>
      blockShouldBeDisabled
        ? `${defValues?.defaultDisabledColor} !important`
        : `${defValues?.labelTextColor} !important`};
  }
`;

export default function SimpleBlock(props) {
  const {
    pre,
    children,
    hasLegend,
    wizardConfig,
    shadowStatus,
    id: blockId,
    formSchema,
    selectedTab,
    moveToAnotherSectionByIndex,
    formInstanceId,
    isListBlock,
    operation,
  } = props;
  const blockIsDisabledByForm = pre?.enabled === false;

  const { theme } = useContext(ThemeContext);
  const muiTheme = useTheme();

  const {
    isWizardEnabled,
    blockShouldBeFocused,
    blockShouldBeDisabled,
    blockWasCompletedBefore,
    blockWizardStep,
    blockIsIncludedInWizardSteps,
    isBlockDisabledByProperty,
    currentStepColor,
    blockListShouldBeBordered,
    listBlocksInWizard,
    blockHasAtLeastSomethingEnabledAndVisible,
  } = useWizard({
    wizardConfig,
    shadowStatus,
    blockId,
    formSchema,
    selectedTab,
    moveToAnotherSectionByIndex,
    formInstanceId,
    isListBlock,
    blockIsDisabledByForm,
    operation,
  });

  const simpleBlockShouldBeBorderFocused =
    blockShouldBeFocused || (isListBlock === true && blockListShouldBeBordered);

  const mainColor = muiTheme?.palette?.content?.titleTextColor;
  const borderBlockColor = simpleBlockShouldBeBorderFocused
    ? currentStepColor
    : muiTheme?.palette?.form?.borderBlock;
  const borderBlockWidth = simpleBlockShouldBeBorderFocused ? "3px" : null;
  const blockBackground = muiTheme?.palette?.form?.bgBlock;

  const blockShouldBeDisabledCalculated =
    blockShouldBeDisabled ||
    isBlockDisabledByProperty ||
    (isListBlock === true &&
      includes(listBlocksInWizard, blockId) &&
      simpleBlockShouldBeBorderFocused === false);

  return (
    <StyledBlock
      pre={pre}
      themeType={theme}
      defValues={{
        mainColor,
        borderBlockColor,
        borderBlockWidth,
        blockBackground,
        defaultDisabledColor: muiTheme.palette.text.disabled,
        labelTextColor: `${muiTheme.palette.form.labelTextColor}BB`,
      }}
      hasLegend={hasLegend}
      blockShouldBeDisabled={blockShouldBeDisabledCalculated}
      blockShouldBeFocused={simpleBlockShouldBeBorderFocused}
      tabIndex={blockShouldBeDisabledCalculated ? -1 : undefined}
    >
      <StatusWizardIndicator
        blockIsIncludedInWizardSteps={blockIsIncludedInWizardSteps}
        isWizardEnabled={isWizardEnabled}
        blockWizardStep={blockWizardStep}
        blockShouldBeDisabled={blockShouldBeDisabled}
        blockWasCompletedBefore={blockWasCompletedBefore}
        blockShouldBeFocused={blockShouldBeFocused}
        blockId={blockId}
        blockIsDisabledByForm={blockIsDisabledByForm}
        listBlocksInWizard={listBlocksInWizard}
        simpleBlockShouldBeBorderFocused={simpleBlockShouldBeBorderFocused}
        isBlockDisabledByProperty={isBlockDisabledByProperty}
        blockHasAtLeastSomethingEnabledAndVisible={
          blockHasAtLeastSomethingEnabledAndVisible
        }
      />

      {children}
    </StyledBlock>
  );
}
