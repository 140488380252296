import { useEffect, useRef, useReducer, useContext } from "react";
import { HTTP_STATUS_UNAUTHORIZED } from "../../util/Constants";
import { getPropertyValue } from "../../util/UtilFormat";
import { AuthContext } from "../providers/AuthContext";
import { useRequestHeaders } from "./useRequestHeaders";

export const useFetch = (
  url,
  body,
  method,
  responseKey = "body.dataResponse",
  cancel = false
) => {
  const cache = useRef({});
  const cacheBody = useRef({});
  const { logout } = useContext(AuthContext);
  const requestHeaders = useRequestHeaders();

  const initialState = {
    status: "idle",
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url) return;
    if (cancel) return;

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      // if (cacheBody.current[body] && cache.current[url]) {
      //   const data = cache.current[url];
      //   dispatch({ type: 'FETCHED', payload: data });
      // } else {
      try {
        const response = await fetch(url, {
          body: JSON.stringify(body),
          method: method,
          headers: requestHeaders,
        });
        if (response.status === HTTP_STATUS_UNAUTHORIZED) {
          logout(true);
        }
        if (response.status >= 300) {
          throw new Error(response.statusText);
        }
        const data = await response.json();
        const returnedData = getPropertyValue(data, responseKey);
        cache.current[url] = returnedData;
        cacheBody.current[body] = body;
        if (cancelRequest) return;
        dispatch({ type: "FETCHED", payload: returnedData });
      } catch (error) {
        if (cancelRequest) return;
        dispatch({ type: "FETCH_ERROR", payload: error.message });
      }
    };
    // };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
    // eslint-disable-next-line
  }, [url, body]);

  return state;
};
