import create from "zustand";
import { isNil, isEmpty } from "lodash";

const useRecordPanelStore = create((set) => ({
  recordsData: [],
  recordsDataList: [],
  setRecordsData: (newRecordsData) => set({ recordsData: newRecordsData }),
  selectedRow: null,
  setSelectedRow: (nsr) => set({ selectedRow: nsr }),
  clearSelectedRow: () => set({ selectedRow: null }),
  transInfo: null,
  setTransInfo: (newTransInfo) => set({ transInfo: newTransInfo }),
  groupingTableData: null,
  originalPanelConfig: null,
  setOriginalPanelConfig: (nopc) => set({ originalPanelConfig: nopc }),
  savedViews: [],
  setSavedViews: (nsv) => set({ savedViews: nsv }),
  comboViewsValue: null,
  setComboViewsValue: (ncvv) => set({ comboViewsValue: ncvv }),
  actualView: null,
  setActualView: (nav) => set({ actualView: nav }),
  layoutElection: 2,
  setLayoutElection: (nle) => set({ layoutElection: nle }),
  setGroupingTableData: (newGroupingTableData) =>
    set({ groupingTableData: newGroupingTableData }),
  groupingTableColumns: null,
  setGroupingTableColumns: (newGroupingTableColumns) =>
    set({ groupingTableColumns: newGroupingTableColumns }),
  selectedGroupingRow: null,
  setSelectedGroupingRow: (nsgr) => set({ selectedGroupingRow: nsgr }),
  isFetchingGroupingTable: false,
  setIsFetchingGroupingTable: (nifgp) =>
    set({ isFetchingGroupingTable: nifgp }),
  mobileView: "table",
  setMobileView: (view) => set({ mobileView: view }),
  setRecordListData: (newRecordsData, key, transInfo) => {
    if (key) {
      set((state) => {
        let isFound = false;

        let i = 0;
        for (let row of state.recordsDataList) {
          if (row.key === key) {
            isFound = true;
            break;
          }
          i++;
        }

        let _arr = [...state.recordsDataList];
        if (isFound) {
          if (_arr[i].key === key) {
            _arr[i].data = newRecordsData;
            _arr[i].transInfo = transInfo;
          }
        } else {
          _arr.push({ key: key, data: newRecordsData, transInfo: transInfo });
        }

        return {
          recordsDataList: [..._arr],
        };
      });
    }
  },
  clearKeyFromRecordListData: (key) => {
    if (key) {
      set((state) => ({
        recordsDataList:
          !isNil(state.recordsDataList) && !isEmpty(state.recordsDataList)
            ? state.recordsDataList.filter((x) => x?.key !== key)
            : state.recordsDataList,
      }));
    }
  },
}));

export { useRecordPanelStore };
