import { makeStyles } from "@material-ui/core/styles";

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 5,
  fontSize: "1.2rem",
};

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {},
  dialogTitleContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.1rem",
  },
  passNotMatch: {
    color: theme.palette.content.niceRed,
    fontStyle: "italic",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  dialogInput: {
    marginBottom: 10,
    width: 304,
  },
}));
