import React from "react";
import * as _ from "lodash";
import { v4 as uuid } from "uuid";

import * as Constants from "./Constants";
import { ID_FORM_CONTAINER_ACTION_BUTTON } from "../components/forms/formContainer/FormContainer";
import { EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON } from "../components/forms/externalFormContainer/BaseForm";

/* eslint-disable no-useless-escape */

//Used to get initial form data and schema in Recordas Panel and Form in NEW OPERATION
const SERVICE_GET_FORM_NEW = "/get/";

//Used to get initial form data and schema in VIEW, EDIT and DELETE OPERATIONS
const SERVICE_GET_FORM_VED = "/getVEDForm/";

//Set only ONE CONTROL VALUE
const SERVICE_SET_VALUE = "/set";

//Set MULTIPLE CONTROL VALUES (E.g: for selection services return)
const SERVICE_SET_MULTIPLE_VALUES = "/setValues";

const SERVICE_SET_MULTIPLE_VALUES_COMPLETED = "/setValuesCompleted";

const SERVICE_CONFIRM_FORM = "/confirm";

const SERVICE_CHANGE_OPERATION = "/changeOperation";

const SERVICE_OPEN_EXTERNAL_FORM = "/openExternalForm";

const SERVICE_OPEN_EXTERNAL_FORM_WITHVALUE = "/openFormWithSetValue";

const SERVICE_JASPER_EXPORT = "/export";

const SERVICE_CLEAN_FORM = "/clean";

//Get schema form calling server
export async function fetchFormSchemaFromServer(
  path,
  idForm,
  operation,
  key,
  audit,
  requestHeaders,
  formInstanceId
) {
  let url;
  let method = Constants.METHOD_GET;
  let body = key;
  const fidParam =
    formInstanceId && formInstanceId !== "" && formInstanceId !== "-"
      ? `?formInstanceId=${formInstanceId}`
      : "";
  if (Constants.FORM_OPERATION_NEW === operation || _.isNil(operation)) {
    url =
      Constants.SERVER_HOST() +
      Constants.API_FORM_SERVICE +
      SERVICE_GET_FORM_NEW +
      path +
      "/" +
      idForm +
      fidParam;
    body = {
      ...audit,
    };
  } else if (
    Constants.FORM_OPERATION_EXECUTION === operation &&
    !_.isNil(operation)
  ) {
    url =
      Constants.SERVER_HOST() +
      Constants.API_FORM_SERVICE +
      SERVICE_GET_FORM_NEW +
      path +
      "/" +
      idForm +
      fidParam;
    body = {
      ...audit,
    };
  } else {
    url =
      Constants.SERVER_HOST() +
      Constants.API_FORM_SERVICE +
      SERVICE_GET_FORM_VED +
      path +
      "/" +
      idForm +
      "/" +
      operation +
      fidParam;
    body = {
      pk: JSON.stringify(key),
      audit,
    };
  }

  method = Constants.METHOD_POST;

  const bodyToSet = !_.isNil(body) ? JSON.stringify(body) : null;
  const response = await fetch(url, {
    body: bodyToSet,
    method: method,
    headers: requestHeaders,
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      if (!_.isNil(jsonResult)) {
        if (!_.isNil(jsonResult._formInstanceId)) {
          return {
            failed: !_.isNil(jsonResult.ok) && jsonResult.ok === false,
            formInstanceId: jsonResult._formInstanceId,
            formSchema: jsonResult,
            formStatus: jsonResult?.formStatus,
            msg: jsonResult?.msg,
            ...jsonResult,
          };
        } else {
          return {
            failed: !_.isNil(jsonResult.ok) && jsonResult.ok === false,
            msg: jsonResult?.msg,
            ...jsonResult,
          };
        }
      }
    })
    .catch((err) => {
      return {
        failed: true,
        msg: err,
        ...err,
      };
    });

  return response;
}

export async function callOpenExternalForm(params, requestHeaders, audit) {
  //Get form instance id param
  const formInstanceIdParam =
    !_.isNil(params) &&
    !_.isNil(params.fidToRecover) &&
    params.fidToRecover !== ""
      ? `?formInstanceId=${params?.fidToRecover}`
      : "";

  // Make url
  const url = `${Constants.SERVER_HOST()}${Constants.API_FORM_SERVICE}${
    !!params.ukey
      ? SERVICE_OPEN_EXTERNAL_FORM
      : params?.openFormLikeRecordsPanel === true
      ? `/get/${params?.path}/${params?.idForm}`
      : SERVICE_OPEN_EXTERNAL_FORM_WITHVALUE
  }${formInstanceIdParam}`;

  const bodyToSet = params?.openFormLikeRecordsPanel === true ? audit : params;
  const response = await fetch(url, {
    body: JSON.stringify(bodyToSet),
    method: Constants.METHOD_POST,
    headers: requestHeaders,
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((error) => {
      return {
        failed: true,
        error,
        ...error,
      };
    });
  return response;
}

//In charge of transforming server form status to frontend form status
export const transformFormStatusFromServer = (formStatus) => {
  const newFormStatus = {};
  if (!_.isNil(formStatus)) {
    const { simpleControls, tableControls, formConfig, formChanged } =
      formStatus;

    Object.assign(newFormStatus, { formChanged });

    if (!_.isNil(formConfig)) {
      Object.assign(newFormStatus, { formConfig });
    }

    //Handling simple controls
    if (!_.isNil(simpleControls)) {
      const keysOfSimpleControls = Object.keys(simpleControls);
      for (let i = 0; i < keysOfSimpleControls.length; i++) {
        const key = keysOfSimpleControls[i];
        //Control Status
        //#G.G1....
        let {
          completeId,
          value,
          presentationInfo,
          optionsValues,
          isSavedEntity,
        } = simpleControls[key];
        //OLD WAY TO HANDLE ATTRIBUTES - COMMENTED 14/10/2021
        const initialPos = getPositionOfString(completeId, ".", 2);
        const attributeToSet = completeId.substring(initialPos + 1);
        _.set(newFormStatus, attributeToSet, {
          value,
          presentationInfo,
          optionsValues,
          isSavedEntity,
        });

        //NEW WAY TO HANDLE ATTRIBUTES - ADDED 14/10/2021
        // Object.assign(newFormStatus, {
        //   [completeId]: {
        //     value,
        //     presentationInfo,
        //     optionsValues,
        //   },
        // });
      }
    }

    //Handling table controls
    if (!_.isNil(tableControls)) {
      const keysOfTableControls = Object.keys(tableControls);
      for (let j = 0; j < keysOfTableControls.length; j++) {
        const key = keysOfTableControls[j];
        //#G.G1.paises....
        const { rows } = tableControls[key];
        const keyAttrPos = getPositionOfString(key, ".", 2);
        const keyAttr = key.substring(keyAttrPos + 1);
        if (rows) {
          //If it is empty, then clear grid items
          if (rows.length === 0) {
            _.set(newFormStatus, keyAttr, []);
          }
          //If it is not, iterate over rows and transform
          for (let i = 0; i < rows.length; i++) {
            const rowObj = rows[i];
            if (rowObj) {
              const { columns } = rowObj;
              if (columns) {
                const dataToSetInRow = {};
                for (let keyCol in columns) {
                  const columnObj = columns[keyCol];
                  if (columnObj) {
                    //Control Status
                    let {
                      completeId,
                      value,
                      presentationInfo,
                      optionsValues,
                      isSavedEntity,
                    } = columnObj;

                    //OLD WAY TO HANDLE ATTRIBUTES - COMMENTED 14/10/2021
                    const initialPos = getPositionOfString(completeId, ".", 2);
                    const path = completeId.substring(initialPos + 1);
                    _.set(dataToSetInRow, path, {
                      value,
                      presentationInfo,
                      optionsValues,
                      isSavedEntity,
                    });

                    //NEW WAY TO HANDLE ATTRIBUTES - ADDED 14/10/2021
                    // Object.assign(dataToSetInRow, {
                    //   [completeId]: {
                    //     value,
                    //     presentationInfo,
                    //     optionsValues,
                    //   },
                    // });
                  }
                }
                if (!_.isNil(dataToSetInRow) && !_.isEmpty(dataToSetInRow)) {
                  const rowToSet = {
                    ...dataToSetInRow[keyAttr],
                    line: i + 1,
                  };
                  const oldData = newFormStatus[keyAttr];
                  if (!_.isNil(oldData) && !_.isEmpty(oldData)) {
                    const newData = [...oldData, rowToSet];
                    //OLD WAY TO HANDLE ATTRIBUTES - COMMENTED 14/10/2021
                    _.set(newFormStatus, keyAttr, newData);

                    //NEW WAY TO HANDLE ATTRIBUTES - ADDED 14/10/2021
                    // Object.assign(newFormStatus, {
                    //   [keyAttr]: newData,
                    // });
                  } else {
                    //OLD WAY TO HANDLE ATTRIBUTES - COMMENTED 14/10/2021
                    _.set(newFormStatus, keyAttr, [rowToSet]);

                    //NEW WAY TO HANDLE ATTRIBUTES - ADDED 14/10/2021
                    // Object.assign(newFormStatus, {
                    //   [keyAttr]: [rowToSet],
                    // });
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return newFormStatus;
};

//SET VALUE OF FORM STATE -- ONE FIELD
export async function callServerToSetValue(
  formInstanceId,
  idControl,
  value,
  line,
  requestHeaders
) {
  if (
    !_.isNil(idControl) &&
    // && !_.isNil(value)
    !_.isNil(line)
  ) {
    const setControlValue = {
      procesarDependencias: true,
      formInstanceId,
      cv: {
        idControl,
        value,
        line,
      },
    };
    const url =
      Constants.SERVER_HOST() + Constants.API_FORM_SERVICE + SERVICE_SET_VALUE;
    const body = JSON.stringify(setControlValue);
    const response = await fetch(url, {
      body,
      method: Constants.METHOD_POST,
      headers: requestHeaders,
    })
      .then((res) => res.json())
      .then((jsonResult) => {
        return jsonResult;
      })
      .catch((error) => {
        return {
          failed: true,
          error,
        };
      });
    return response;
  } else {
    return null;
  }
}

//SET VALUES OF FORM STATE -- MULTIPLE FIELDS
export async function callServerToSetMultipleValues(
  params,
  requestHeaders,
  sendCompletedEndpoint
) {
  const { values, formInstanceId } = params;
  if (_.isNil(params) || _.isNil(values) || _.isEmpty(values)) {
    return;
  }

  const setValuesToCall =
    !_.isNil(sendCompletedEndpoint) && sendCompletedEndpoint === true
      ? SERVICE_SET_MULTIPLE_VALUES_COMPLETED
      : SERVICE_SET_MULTIPLE_VALUES;

  const url =
    Constants.SERVER_HOST() + Constants.API_FORM_SERVICE + setValuesToCall;

  const multipleValues = {
    procesarDependencias: true,
    formInstanceId,
    values,
  };

  const response = await fetch(url, {
    body: JSON.stringify(multipleValues),
    method: Constants.METHOD_POST,
    headers: requestHeaders,
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((error) => {
      return {
        failed: true,
        error,
      };
    });
  return response;
}

export async function callExportJasper(params, requestHeaders) {
  // Make url
  const url = `${Constants.SERVER_HOST()}${
    Constants.API_FORM_JASPER
  }${SERVICE_JASPER_EXPORT}`;

  const response = await fetch(url, {
    body: JSON.stringify(params),
    method: Constants.METHOD_POST,
    headers: requestHeaders,
  })
    .then(async (res) => {
      const data = await res.json();
      if (!_.isNil(data) && !_.isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return {
        ok: false,
        errorMsg: error?.message,
        dataResponse: null,
        status: 500,
      };
    });
  return response;
}

//FRONTEND FORM DATA -> ENTITY
export const normalizeFormData = (formData) => {
  let formDataClone = _.clone(formData);
  let result = {};
  for (const key in formDataClone) {
    const valueToAnalyze = formDataClone[key];
    if (_.isArray(valueToAnalyze)) {
      const newArrayToSet = [];
      for (let i = 0; i < valueToAnalyze.length; i++) {
        const row = {};
        const obj = valueToAnalyze[i];
        if (obj) {
          for (const objKey in obj) {
            const gridObj = obj[objKey];
            if (_.isObject(gridObj)) {
              _.set(row, objKey, gridObj?.value);
            } else {
              _.set(row, objKey, gridObj);
            }
          }
        }
        const isValid = isValidRow(row);
        if (isValid) {
          newArrayToSet.push(row);
        }
      }
      if (newArrayToSet && !_.isEmpty(newArrayToSet)) {
        _.set(result, key, newArrayToSet);
      } else {
        _.set(result, key, valueToAnalyze);
      }
    } else if (_.isObject(valueToAnalyze)) {
      for (const objKey in valueToAnalyze) {
        if (
          objKey !== Constants.FORM_PRESENTATION_INFO_PARAM &&
          objKey !== Constants.FORM_OPTIONS_VALUES_PARAM
        ) {
          const inner = valueToAnalyze[objKey];
          if (_.isObject(inner)) {
            const newVal = {
              [objKey]: inner.value,
            };
            _.set(result, key, newVal);
          } else {
            _.set(result, key, inner);
          }
        }
      }
    } else {
      _.set(result, key, valueToAnalyze);
    }
  }
  if (result && !_.isEmpty(result)) {
    return result;
  } else {
    return formDataClone;
  }
};

//ENTITY => FRONTEND FORM DATA
export const upsetEntityData = (entityData) => {
  let entityDataClone = _.clone(entityData);
  let result = {};
  for (const key in entityDataClone) {
    const valueToAnalyze = entityDataClone[key];
    if (_.isArray(valueToAnalyze)) {
      const newArrayToSet = [];
      for (let i = 0; i < valueToAnalyze.length; i++) {
        const row = {};
        const obj = valueToAnalyze[i];
        if (obj) {
          for (const objKey in obj) {
            const gridObj = obj[objKey];
            _.set(row, objKey, { value: gridObj, presentationInfo: null });
          }
        }
        if (row) newArrayToSet.push(row);
      }
      if (newArrayToSet && !_.isEmpty(newArrayToSet)) {
        _.set(result, key, newArrayToSet);
      } else {
        _.set(result, key, valueToAnalyze);
      }
    } else if (_.isObject(valueToAnalyze)) {
      for (const objKey in valueToAnalyze) {
        if (
          objKey !== Constants.FORM_PRESENTATION_INFO_PARAM &&
          objKey !== Constants.FORM_OPTIONS_VALUES_PARAM &&
          objKey !== "tableData"
        ) {
          const inner = valueToAnalyze[objKey];
          _.set(result, `${key}.${objKey}`, {
            value: inner,
            presentationInfo: null,
          });
        }
      }
    } else {
      _.set(result, key, { value: valueToAnalyze, presentationInfo: null });
    }
  }
  if (result && !_.isEmpty(result)) {
    return result;
  } else {
    return entityDataClone;
  }
};

export async function confirmForm(params, requestHeaders) {
  const { formInstanceId, audit } = params;

  // Make url
  const url =
    Constants.SERVER_HOST() + Constants.API_FORM_SERVICE + SERVICE_CONFIRM_FORM;

  //Construct body apart for future new attributes
  const body = {
    formInstanceId,
    audit,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: Constants.METHOD_POST,
    headers: requestHeaders,
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((error) => {
      return {
        failed: true,
        error,
        ...error,
      };
    });
  return response;
}

export async function notifyServerChangeOperation(params, requestHeaders) {
  const { formInstanceId, operation } = params;

  // Make url
  const url =
    Constants.SERVER_HOST() +
    Constants.API_FORM_SERVICE +
    SERVICE_CHANGE_OPERATION;

  //Construct body apart for future new attributes
  const body = {
    formInstanceId,
    operation,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: Constants.METHOD_POST,
    headers: requestHeaders,
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((error) => {
      return {
        ok: false,
        msg: error,
      };
    });
  return response;
}

export function notifyServerCleanFormInstanceId({
  formInstanceId,
  requestHeaders,
}) {
  if (
    _.isNil(formInstanceId) ||
    _.isEmpty(formInstanceId) ||
    formInstanceId === " " ||
    formInstanceId === "-"
  ) {
    return;
  }

  let url =
    Constants.SERVER_HOST() + Constants.API_FORM_SERVICE + SERVICE_CLEAN_FORM;
  // Make url
  if (_.isArray(formInstanceId)) {
    for (let i = 0; i < formInstanceId.length; i++) {
      const fid = formInstanceId[i];
      if (i === 0) {
        url = url + "?";
      }
      url = url + `formInstanceId=${fid}`;
      if (i + 1 < formInstanceId.length) {
        url = url + "&";
      }
    }
  } else {
    url = url + `?formInstanceId=${formInstanceId}`;
  }

  fetch(url, {
    method: Constants.METHOD_POST,
    headers: requestHeaders,
  });
}

export async function callServerToGetTrackingServicesList(
  urlParams,
  requestHeaders
) {
  // Make url
  const url = `${Constants.SERVER_HOST()}${
    Constants.API_FORM_SERVICE
  }/getServicesTracking?${urlParams}`;

  const response = await fetch(url, {
    method: Constants.METHOD_GET,
    headers: requestHeaders,
  })
    .then((res) => res.json())
    .then((jsonResult) => {
      return jsonResult;
    })
    .catch((error) => {
      return {
        ok: false,
        msg: error,
      };
    });
  return response;
}

//Get HTTP url by props for execute action new, edit, delete
export const getHTTPUrlByProps = (props) => {
  let url =
    Constants.SERVER_HOST() +
    props?.solution +
    Constants.API_ +
    props?.formName +
    "/" +
    props?.operation;
  return url;
};

//Get the correct http method by operation
export const getHTTPMethodByOperaton = (operation) => {
  const method =
    operation === Constants.FORM_OPERATON_EDIT
      ? Constants.METHOD_PUT
      : operation === Constants.FORM_OPERATION_NEW
      ? Constants.METHOD_POST
      : Constants.METHOD_DELETE;
  return method;
};

//Check type of inputs
export const getInputType = (value) => {
  const lowerValue =
    value !== null && value !== undefined ? value.toLowerCase() : value;
  if (lowerValue === "string" || lowerValue === "text") {
    return "text";
  } else if (lowerValue === "numeric" || lowerValue === "number") {
    return "number";
  } else {
    return lowerValue;
  }
};

//Generates Random ID
export const generateRandomID = (fixedPart) => {
  const randomPart = replaceAllDots(uuid().toString());
  if (!_.isNil(fixedPart)) {
    return `${fixedPart}_${randomPart}`;
  } else {
    return `_${randomPart}`;
  }
};

//Return SELECTION services
export const getSelectionServices = (schema, isGrid) => {
  if (
    !_.isNil(schema) &&
    !_.isNil(schema.services) &&
    !_.isEmpty(schema.services)
  ) {
    const result = schema.services
      .filter(function (s) {
        if (isGrid === true) {
          return (
            s.serviceType === Constants.FORM_SERVICE_SELECTION &&
            s.enabled === true &&
            (_.isNil(s?.hideRowButtonInGrid) ||
              s?.hideRowButtonInGrid === false)
          );
        } else {
          return (
            s.serviceType === Constants.FORM_SERVICE_SELECTION &&
            s.enabled === true
          );
        }
      })
      .sort((a, b) => a.order - b.order); //asc
    return result;
  }
};

//Return disabled selection services
export const getDisabledSelectionServices = (schema) => {
  if (
    !_.isNil(schema) &&
    !_.isNil(schema.services) &&
    !_.isEmpty(schema.services)
  ) {
    const result = schema.services
      .filter(
        (s) =>
          s.serviceType === Constants.FORM_SERVICE_SELECTION &&
          s.enabled === false
      )
      .sort((a, b) => a.order - b.order); //asc
    return result || [];
  } else {
    return [];
  }
};

//Return VALIDATION services
export const getValidationServices = (schema) => {
  if (
    !_.isNil(schema) &&
    !_.isNil(schema.services) &&
    !_.isEmpty(schema.services)
  ) {
    const result = schema.services
      .filter(
        (s) =>
          s.serviceType === Constants.FORM_SERVICE_VALIDATION &&
          s.enabled === true
      )
      .sort((a, b) => a.order - b.order); //asc
    return result;
  }
};

//Return CALCULATOR services
export const getCalculationServices = (schema) => {
  if (
    !_.isNil(schema) &&
    !_.isNil(schema.services) &&
    !_.isEmpty(schema.services)
  ) {
    const result = schema.services
      .filter(
        (s) =>
          s.serviceType === Constants.FORM_SERVICE_CALCULATION &&
          s.enabled === true
      )
      .sort((a, b) => a.order - b.order); //asc
    return result;
  }
};

//DANGER, SENSITIVE, Handle return values of selection by parametrized names on service
export const getReturnValuesByFields = (
  object,
  fieldsToReturn,
  formContextData
) => {
  if (!_.isNil(object) && !_.isNil(fieldsToReturn)) {
    //CLONE REQUIRED! NOT ACCESS THE OBJECT DIRECTLY, JSRF BUG
    let objCopy = clone(object);
    let objKeys = Object.keys(object);
    const keysToDelete = [];
    objKeys.forEach(function (item) {
      let i = 0;
      let len = fieldsToReturn.length;
      while (i < len) {
        const field = fieldsToReturn[i];
        const from = field?.from;
        const to = field?.to;
        if (!_.isNil(from) && !_.isNil(to)) {
          if (from === item) {
            //Remove from keys from delete if it exists
            if (keysToDelete.includes(item)) {
              const indexOfKeyToRemove = keysToDelete.indexOf(item);
              keysToDelete.splice(indexOfKeyToRemove, 1);
            }
            const valueToSet = objCopy[item];
            delete objCopy[item];
            if (to.includes(".")) {
              const parts = to.split(".");
              if (!_.isNil(parts) && parts.length > 0) {
                const name = parts[0];
                if (formContextData.hasOwnProperty(name)) {
                  const actualStateOfInnerObject = formContextData[name];
                  objCopy = {
                    ...objCopy,
                    [name]: actualStateOfInnerObject,
                  };
                }
                //set valueToSet in object by key (to)
                const indexOf = getPositionOfString(to, ".", 2);
                const newTo = to.substring(indexOf + 1);
                if (newTo.includes(".")) {
                  _.set(objCopy, newTo, valueToSet);
                } else {
                  objCopy[newTo] = valueToSet;
                }
              }
            } else {
              objCopy[to] = valueToSet;
            }
          } else {
            //add to keys to delete at the end
            keysToDelete.push(item);
          }
        }
        i++;
      }
    });
    if (!_.isNil(objCopy) && !_.isEmpty(objCopy)) {
      for (let key of keysToDelete) {
        delete objCopy[key];
      }
      return objCopy;
    } else return null;
  }
};

//Change parametrized fields with its values
export const changeParametrizedFields = (params, formContextData) => {
  const characterToFind = "#";
  //Finds all words that starts with #, including dots, #audit.username OK
  let regexToHashtag = /(?:^|\s)(#[a-z0-9]\S*)/gi;
  let match;
  let fields = [];
  while ((match = regexToHashtag.exec(params))) {
    fields.push(match[1]);
  }
  let paramsResult = clone(params);
  let consultingFields = clone(fields);
  for (let i = 0; i < fields.length; i++) {
    const fieldToReplace = fields[i];
    const indexOf = getPositionOfString(fieldToReplace, ".", 2);
    const parsedMatch = fieldToReplace.substring(indexOf + 1);
    const fieldToReplaceWithoutHash = parsedMatch.replace(characterToFind, "");
    let valueToSet = _.get(formContextData, fieldToReplaceWithoutHash);
    if (typeof valueToSet === "object" && valueToSet !== null) {
      // I can access directly becayse _.get gives me the directly parent object
      const parts = fieldToReplaceWithoutHash.split(".");
      if (!_.isNil(parts) && !_.isEmpty(parts)) {
        const lastParg = parts[parts.length - 1];
        const valueByKey = valueToSet[lastParg];
        if (!_.isNil(valueByKey)) {
          paramsResult = paramsResult.replace(
            fieldToReplace,
            `'${valueByKey}'`
          );
          const index = consultingFields.indexOf(fieldToReplace);
          consultingFields.splice(index, 1);
        }
      }
    } else {
      if (!_.isNil(valueToSet)) {
        paramsResult = paramsResult.replace(fieldToReplace, `'${valueToSet}'`);
        const index = consultingFields.indexOf(fieldToReplace);
        consultingFields.splice(index, 1);
      }
    }
  }
  if (_.isEmpty(consultingFields)) {
    return paramsResult;
  } else {
    return false;
  }
};

//Function to clone object safely
export function clone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

//In charge of making unique ID for forms
export const makeUniqueFormID = (id) => {
  return id + "_" + replaceAllDots(uuid().toString());
};

//Replace ocurrences of dtos
export const replaceAllDots = (txt) => {
  if (txt) {
    return _.replace(txt, /\./g, "");
  } else {
    return txt;
  }
};

//Push item to array cloning it first
export const pushItemToArray = (array, item) => {
  const arrayClone = clone(array);
  arrayClone.push(item);
  return arrayClone;
};

//Remove item from array by key
export const removeItemArrayByKey = (array, key) => {
  const arrayClone = clone(array);
  let index = arrayClone
    .map((x) => {
      return x.key;
    })
    .indexOf(key);
  if (index >= 0) arrayClone.splice(index, 1);
  return arrayClone;
};

//Get the actual state of the error list
export const getErrorList = (errorMap) => {
  if (!_.isEmpty(errorMap)) {
    let errorList = [
      "No podra continuar hasta resolver los siguientes errores: ",
    ];
    for (let i = 0; i < errorMap.length; i++) {
      const errorMessage = errorMap[i]?.error;
      //const key = errorMap[i]?.key;
      if (!_.isNil(errorMessage) && errorMessage !== "") {
        errorList.push(`• ${errorMessage}`);
        //errorList.push(`• ${key} [${errorMessage}]`);
      }
    }
    return (
      <div>
        {errorList.map((e, index) => {
          return <p key={index}>{e}</p>;
        })}
      </div>
    );
  } else {
    return "";
  }
};

//Decision if input is enabled or not considering the actual operation that the form is
//and the schema props canNew and canEdit
export const defineInputEnabledByOperation = (operation, canEdit, canNew) => {
  let result = true;
  if (
    operation === Constants.FORM_OPERATION_DELETE ||
    operation === Constants.FORM_OPERATION_VIEW
  ) {
    //In delete and view operation ALWAYS be disbaled
    result = false;
  } else if (operation === Constants.FORM_OPERATON_EDIT && !_.isNil(canEdit)) {
    result = canEdit;
  } else if (
    (operation === Constants.FORM_OPERATION_NEW ||
      operation === Constants.FORM_OPERATION_EXECUTION) &&
    !_.isNil(canNew)
  ) {
    result = canNew;
  }
  return result;
};

export const makeErrorMapToSet = (errorMap, completeIdControl, errorText) => {
  const errorToSet = makeErrorToSetErrorMap(completeIdControl, errorText);
  let result = _.clone(errorMap);
  const alreadyOnMap = controlHasError(errorMap, completeIdControl);
  //If it is already in the map, remove it to push the new error asosiated with the control
  //ONLY ONE OBJECT IN ERROR PER completeIdControl
  if (alreadyOnMap) {
    result = result.filter(function (obj) {
      return obj.key !== completeIdControl;
    });
  }
  result.push(errorToSet);
  return result;
};

export const controlHasError = (errorMap, completeIdControl) => {
  return !_.isNil(errorMap.find((e) => e.key === completeIdControl));
};

//Makes error to set to errorMap
export const makeErrorToSetErrorMap = (id, error) => {
  const result = {
    key: id,
    error: error,
  };
  return result;
};

//Handle error map
//Add or remove object from array by remove params
export const pushOrRemoveObjectFromArrayByKey = (array, value, toRemove) => {
  let newArray = clone(array);
  if (toRemove === true) {
    if (newArray && newArray.length > 0) {
      const key = value?.key;
      if (!_.isNil(key)) {
        newArray = removeItemArrayByKey(newArray, key);
      }
    }
  } else {
    newArray = pushItemToArray(newArray, value);
  }
  return newArray;
};

//Decide if the current field has to validate or not by operation, last and current value to set
export const decideIfHasToValidate = (operation, lastValue, valueToSet) => {
  let result = true;
  if (operation === Constants.FORM_OPERATION_DELETE) {
    result = false;
  } else if (
    operation === Constants.FORM_OPERATON_EDIT &&
    !_.isNil(lastValue) &&
    !_.isNil(valueToSet) &&
    lastValue === valueToSet
  ) {
    result = false;
  }
  return result;
};

//Get index of as integer of position of string in other
export function getPositionOfString(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

//Get Complete path of field of form
export const getCompletePath = (section, blockId, idSchemaPath, schema) => {
  const positionOfRoot = getPositionOfString(idSchemaPath, "_", 1);
  const controlName = idSchemaPath
    .substring(positionOfRoot + 1)
    .replace(/\_/g, ".");

  if (schema && schema.controlType) {
    const resolvedPrefix =
      schema.controlType === Constants.TYPE_VARIABLE
        ? Constants.PREFIX_VARIABLE
        : Constants.PREFIX_FIELD;
    return `${resolvedPrefix}${section?.name}.${blockId}.${controlName}`;
  } else {
    return `${Constants.PREFIX_FIELD}${section?.name}.${blockId}.${controlName}`;
  }
};

export const getCompletePathGridItem = (
  section,
  blockId,
  idSchemaPath,
  listName,
  schema,
  rowIndex
) => {
  const positionOfRoot = getPositionOfString(idSchemaPath, "_", 1);
  const controlName = idSchemaPath
    .substring(positionOfRoot + 1)
    .replace(/\_/g, ".");

  const addedRowIndex = !_.isNil(rowIndex) ? `-${rowIndex}` : "";
  if (schema && schema.controlType) {
    const resolvedPrefix =
      schema.controlType === Constants.TYPE_VARIABLE
        ? Constants.PREFIX_VARIABLE
        : Constants.PREFIX_FIELD;
    return `${resolvedPrefix}${section?.name}.${blockId}.${listName}.${controlName}${addedRowIndex}`;
  } else {
    return `${Constants.PREFIX_FIELD}${section?.name}.${blockId}.${listName}.${controlName}${addedRowIndex}`;
  }
};

export const makeGridItem = (line, fieldName, value, action) => {
  const { type } = action;
  let gridItem;
  if (Constants.FORM_SERVICE_ACTION_PRESENTATION === type) {
    const {
      backgroundColor,
      color,
      enabled,
      fontSize,
      fontStyle,
      fontWeight,
      reset,
    } = action;
    if (reset) {
      if (gridItem && gridItem.hasOwnProperty("behave")) {
        delete gridItem["behave"];
      }
    } else {
      Object.assign(gridItem, {
        behave: {
          backgroundColor,
          color,
          enabled,
          fontSize,
          fontStyle,
          fontWeight,
          idControl: fieldName,
          line,
        },
      });
    }
  } else {
    gridItem = {
      line,
      [fieldName]: value,
      // tableData: {
      //   id: line - 1,
      // },
    };
  }

  return gridItem;
};

export const makeActionsByLine = (actions) => {
  var actionsByLine = {};
  if (!_.isNil(actions) && !_.isEmpty(actions)) {
    for (var i = 0, len = actions.length; i < len; i++) {
      const { completeId, value, line, serviceId, serviceKey } = actions[i];
      const lineAlreadyInActions = actionsByLine.hasOwnProperty([line]);
      if (lineAlreadyInActions) {
        var actualArrayOfLine = actionsByLine[line];
        actualArrayOfLine.push({
          completeId,
          value,
          serviceId,
          serviceKey,
        });
        Object.assign(actionsByLine, {
          [line]: actualArrayOfLine,
        });
      } else {
        Object.assign(actionsByLine, {
          [line]: [
            {
              completeId,
              value,
              serviceId,
              serviceKey,
            },
          ],
        });
      }
    }
  }
  return actionsByLine;
};

export const isServerResponseValid = (setValueResponse) => {
  if (
    !_.isNil(setValueResponse) &&
    ((!_.isNil(setValueResponse.error) && setValueResponse.error === false) ||
      (!_.isNil(setValueResponse.ok) && setValueResponse.ok === true))
  ) {
    return true;
  } else {
    return false;
  }
};

export const getValueOfObjectByPosition = (obj, position) => {
  return obj[Object.keys(obj)[position]];
};

export const extractErrorMessageFromResponse = (response) => {
  let errorMsg;
  if (
    !_.isNil(response) &&
    (!_.isNil(response.error) || !_.isNil(response.ok)) &&
    !_.isNil(response.msg) &&
    (response.error === true || response.ok === false)
  ) {
    errorMsg = response.msg;
  } else {
    errorMsg = "Error!";
  }
  return errorMsg;
};

//Function to return focus to ref passes by props
export function returnFocusToRef(inputRef) {
  if (!_.isNil(inputRef) && !_.isNil(inputRef.current)) {
    inputRef.current.focus();
  }
}

export function obtainPositionOfEvent(completeIdControl) {
  const element = document?.getElementById(completeIdControl);
  const clientRect = element?.getBoundingClientRect();
  return { clientX: clientRect?.x, clientY: clientRect?.y };
}

//Function to get position of element
export const findTopLeft = (element) => {
  var rec = document.getElementById(element).getBoundingClientRect();
  return { top: rec.top + window.scrollY, left: rec.left + window.scrollX };
};

export const extractKeyFromSchemaAndValues = (formSchema, values) => {
  const formKey = _.get(formSchema, "formKey");
  if (!_.isNil(formKey)) {
    const index = getPositionOfString(formKey, ".", 2);
    const field = formKey.substring(index + 1);
    const keyValue = _.get(values, field);
    return keyValue;
  } else {
    return null;
  }
};

// Used to make values to call /setValues
export const makeValuesToSetValues = (fieldsToReturn, dataOfRow, line) => {
  const objWithKeys = {};

  for (let i = 0; i < fieldsToReturn.length; i++) {
    const { from, to } = fieldsToReturn[i];
    const objToSet = {
      [to]: dataOfRow[from],
    };
    if (objToSet && !_.isEmpty(objToSet)) {
      Object.assign(objWithKeys, objToSet);
    }
  }

  const valuesToCallService = Object.keys(objWithKeys).map((key) => {
    return {
      idControl: key,
      value: objWithKeys[key],
      line,
    };
  });

  return valuesToCallService;
};

// Used to make values to call /setValues where multilple rows were selected
export const makeValuesToSetValuesMultiple = (
  fieldsToReturn,
  multipleRowsSelected,
  rowNumber
) => {
  let result = [];

  if (!_.isNil(multipleRowsSelected) && !_.isArray(multipleRowsSelected)) {
    return null;
  }

  for (let r = 0; r < multipleRowsSelected.length; r++) {
    const dataOfRow = multipleRowsSelected[r];

    if (!_.isNil(dataOfRow)) {
      const lineToSet = rowNumber + r;
      const valuesToCallService = makeValuesToSetValues(
        fieldsToReturn,
        dataOfRow,
        lineToSet
      );

      if (valuesToCallService != null && valuesToCallService.length > 0) {
        result.push(...valuesToCallService);
      }
    }
  }

  return result;
};

//Check if row is not null, not empty and has at least one value (excluding line)
export const isValidRow = (row) => {
  let result = false;

  if (_.isNil(row) || _.isEmpty(row)) {
    return result;
  } else {
    for (const key in row) {
      if (key !== "line") {
        const obj = row[key];
        if (!_.isNil(obj) && !_.isEmpty(obj) && obj !== "") {
          result = true;
          break;
        }
      }
    }
    return result;
  }
};

export const isLastRowAuto = (row) => {
  let result = true;

  if (_.isNil(row) || _.isEmpty(row)) {
    return result;
  } else {
    for (const key in row) {
      if (key !== "line") {
        const obj = row[key];
        if (
          !_.isNil(obj) &&
          !_.isEmpty(obj) &&
          obj !== "" &&
          !_.isNil(obj.value) &&
          !_.isEmpty(obj.value) &&
          obj.value !== ""
        ) {
          result = false;
          break;
        }
      }
    }
    return result;
  }
};

export function makePKObject(formSchema, entityData) {
  let result = null;
  const pkComposition = formSchema?.formKey;
  if (pkComposition && _.isObject(pkComposition)) {
    const pkToReturn = {};
    for (const part in pkComposition) {
      Object.assign(pkToReturn, {
        [part]: entityData[part],
      });
    }
    if (pkToReturn && !_.isEmpty(pkToReturn)) {
      result = pkToReturn;
    } else {
      result = entityData?.id;
    }
  } else {
    result = entityData?.id;
  }
  return result;
}

/**
 * Checks in shadowstatus if value has truely changed its value before invoke set value on server
 * @returns Boolean
 */
export function valueHasChanged(shadowStatus, { newValue, field, line, list }) {
  //Check if shadow status is not null and field is valid
  if (_.isNil(shadowStatus) && (_.isNil(field) || field === "")) {
    return true;
  }

  if (!_.isNil(shadowStatus)) {
    const { tableControls, simpleControls } = shadowStatus;

    //Check if form has changed according to its object value
    if (line > 0) {
      //Grid items
      const rows = tableControls[list]?.rows;
      if (!_.isNil(rows) && !_.isEmpty(rows)) {
        const columns = rows[line - 1]?.columns;
        if (!_.isNil(columns)) {
          const objValue = columns[field];
          if (!_.isNil(objValue)) {
            const { value } = objValue;
            return newValue !== value;
          }
        }
      }
    } else {
      //Simple items
      const shadowField = simpleControls[field];
      if (!_.isNil(shadowField)) {
        const { value: shadowValue } = shadowField;
        return shadowValue !== newValue;
      }
    }
  }

  return true;
}

export function getPkToSearch(pk) {
  if (_.isNil(pk) || (!_.isNil(pk) && _.isEmpty(pk))) {
    return pk;
  }

  let result = {};
  for (const [k, v] of Object.entries(pk)) {
    Object.assign(result, { [k]: { value: v } });
  }

  return _.isNil(result) || (!_.isNil(result) && _.isEmpty(result))
    ? pk
    : result;
}

export function resolveFieldAgainstShadowStatus({
  field,
  shadowStatus,
  line,
  completeIdControl,
}) {
  if (
    _.isNil(field) ||
    _.isEmpty(field) ||
    !_.isString(field) ||
    _.isNil(shadowStatus) ||
    _.isEmpty(shadowStatus)
  ) {
    return field;
  }

  const { simpleControls, tableControls } = shadowStatus;
  if (field.includes("#") || field.includes("$")) {
    const calculatedListName = field
      .substring(0, field.lastIndexOf("."))
      .replace("$", "#");
    const actualListName = completeIdControl
      .substring(0, field.lastIndexOf("."))
      .replace("$", "#");
    if (
      !_.isNil(line) &&
      line >= 0 &&
      !_.isNil(actualListName) &&
      calculatedListName === actualListName
    ) {
      //grid items
      if (
        !_.isNil(tableControls) &&
        !_.isEmpty(tableControls) &&
        _.has(tableControls, calculatedListName)
      ) {
        const fieldRows = _.get(tableControls, [calculatedListName, "rows"]);
        if (
          !_.isNil(fieldRows) &&
          !_.isEmpty(fieldRows) &&
          _.isArray(fieldRows) &&
          fieldRows.length >= line
        ) {
          const columnsObject = fieldRows[line]?.columns;
          if (
            !_.isNil(columnsObject) &&
            !_.isEmpty(columnsObject) &&
            _.has(columnsObject, field)
          ) {
            const fieldObject = _.get(columnsObject, field);
            return fieldObject?.value;
          }
        }
      }
    } else {
      //simple items
      if (
        !_.isNil(simpleControls) &&
        !_.isEmpty(simpleControls) &&
        _.has(simpleControls, field)
      ) {
        const fieldObject = _.get(simpleControls, field);
        return fieldObject?.value;
      }
    }
  } else {
    return field;
  }
}

export function resolveValuesFieldAgainsShadowStatus({
  values,
  shadowStatus,
  line,
  completeIdControl,
}) {
  const clonedValues = _.clone(values);
  Object.keys(clonedValues).forEach(function (key) {
    clonedValues[key] = resolveFieldAgainstShadowStatus({
      field: clonedValues[key],
      shadowStatus,
      line,
      completeIdControl,
    });
  });
  return clonedValues;
}

export function makeEmptyMultiComboValue({ optionsValues }) {
  if (
    _.isNil(optionsValues) ||
    (!_.isNil(optionsValues) && _.isEmpty(optionsValues))
  ) {
    return null;
  }

  const array = _.clone(optionsValues);
  const obj = array[0];
  Object.keys(obj).forEach((key) => {
    obj[key] = "";
  });
  return obj;
}

export function transformValuesForSetChips({ valuesFromSelection }) {
  if (
    !_.isNil(valuesFromSelection) &&
    !_.isEmpty(valuesFromSelection) &&
    _.isArray(valuesFromSelection)
  ) {
    const map = new Map();
    valuesFromSelection.forEach((obj) => {
      if (!_.isNil(obj) && !_.isNil(obj.idControl) && !_.isNil(obj.value)) {
        const { idControl, value } = obj;
        if (map.has(idControl)) {
          const actualArr = map.get(idControl);
          map.delete(idControl);
          map.set(idControl, [...actualArr, value]);
        } else {
          map.set(idControl, [value]);
        }
      }
    });

    let res = [];
    for (let [idControl, arrValue] of map) {
      if (!_.isNil(arrValue) && !_.isEmpty(arrValue) && _.isArray(arrValue)) {
        res.push({
          idControl,
          value: arrValue.toString(),
        });
      }
    }

    return res;
  }
  return [];
}

export const preserveFormEvent = ({ event, lastWasTab, formInstanceId }) => {
  let id = null;
  let doClick = false;
  let doFocus = false;

  if (!_.isNil(event) && !_.isNil(event.relatedTarget)) {
    const relatedTarget = event.relatedTarget;
    const targetAttrType =
      relatedTarget.getAttribute("type") || relatedTarget.getAttribute("id");
    if (!_.isNil(targetAttrType)) {
      if ("button" === targetAttrType || "checkbox" === targetAttrType) {
        id = relatedTarget.getAttribute("id");
        doFocus = lastWasTab;
        doClick = !_.isNil(id) && !lastWasTab;
      } else if (ID_FORM_CONTAINER_ACTION_BUTTON === targetAttrType) {
        id = targetAttrType;
        doFocus = lastWasTab;
        doClick = !_.isNil(id) && !lastWasTab;
      } else if (
        `${EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON}-${formInstanceId}` ===
        targetAttrType
      ) {
        id = targetAttrType;
        doFocus = lastWasTab;
        doClick = !_.isNil(id) && !lastWasTab;
      } else if ("text" === targetAttrType) {
        id = relatedTarget.getAttribute("id");
        doFocus = true;
      }
    }
  }

  const preservedEvent = {
    id,
    doClick,
    doFocus,
  };

  return preservedEvent;
};

export const applyPreservedEvent = ({ preservedEvent }) => {
  if (!_.isNil(preservedEvent)) {
    const { id, doClick, doFocus } = preservedEvent;

    if (doClick) {
      if (document && document.getElementById(id)) {
        document.getElementById(id).click();
      }
    }

    if (doFocus) {
      if (document && document.getElementById(id)) {
        document.getElementById(id).focus();
      }
    }
  }
};

export const formatValuesMultiComboBox = (values, completeIdControl) => {
  return !_.isNil(values) && !_.isEmpty(values) && _.isArray(values)
    ? values.map((x) => {
        if (!_.isNil(x) && !_.isEmpty(x) && _.isObject(x)) {
          const initialValue = x.value;
          const control = x.idControl;

          const valueToSet =
            control === completeIdControl &&
            _.isString(initialValue) &&
            !_.isNull(initialValue) &&
            (initialValue === "" || _.trim(initialValue) === "")
              ? null
              : initialValue;
          return {
            ...x,
            value: valueToSet,
          };
        } else {
          return x;
        }
      })
    : values;
};

export function getDisabledSelectionServicesByDisabledInteractionProp({
  selectionServices,
  shadowStatus,
}) {
  const withDisabledInteractionProp =
    !_.isNil(selectionServices) &&
    !_.isEmpty(selectionServices) &&
    _.some(selectionServices, function (o) {
      return !_.isNil(o) && !_.isEmpty(o) && _.has(o, "disableInteraction");
    });

  if (withDisabledInteractionProp) {
    return _.filter(selectionServices, function ({ disableInteraction }) {
      if (_.isBoolean(disableInteraction)) {
        return disableInteraction;
      } else if (_.isString(disableInteraction)) {
        const valueInShadowStatus = resolveFieldAgainstShadowStatus({
          field: disableInteraction,
          shadowStatus,
          completeIdControl: disableInteraction,
        });
        return (
          _.isEqual("S", _.toUpper(_.toString(valueInShadowStatus))) ||
          _.isEqual("TRUE", _.toUpper(_.toString(valueInShadowStatus))) ||
          _.isEqual("Y", _.toUpper(_.toString(valueInShadowStatus)))
        );
      } else {
        return false;
      }
    });
  } else {
    return [];
  }
}

export function getFormSchemaDefinitionByCompleteIdControl({
  completeIdControl,
  formSchema,
}) {
  if (_.isNil(completeIdControl) || _.isNil(formSchema)) {
    return null;
  }

  try {
    const parts = _.split(completeIdControl.substring(1), ".");
    if (!_.isNil(parts) && !_.isEmpty(parts) && _.size(parts) > 2) {
      const sectionId = parts[0];
      const blockId = parts[1];

      const sectionItem = _.find(formSchema?.sections, function (o) {
        return !_.isNil(o) && o.name === sectionId;
      });

      if (!_.isNil(sectionItem)) {
        const blockItem = _.find(sectionItem?.blocks, function (o) {
          return !_.isNil(o) && o.id === blockId;
        });

        if (!_.isNil(blockItem)) {
          const blockType = blockItem?.blockType;

          if (blockType === "list") {
            const columns = blockItem?.columns;
            if (
              !_.isNil(columns) &&
              !_.isEmpty(columns) &&
              _.size(parts) >= 4
            ) {
              const listName = parts[2];
              const fieldName = parts[3];
              const column = _.find(columns, function (c) {
                return !_.isNil(c) && c?.field === fieldName;
              });
              if (!_.isNil(column)) {
                return { ...column, listName };
              } else {
                return null;
              }
            }
          } else {
            const schemaProperties = blockItem?.schema?.properties;
            const uiSchemaProperties = blockItem?.uiSchema;
            if (!_.isNil(schemaProperties) && !_.isEmpty(schemaProperties)) {
              const fieldName = parts[2];
              if (_.has(schemaProperties, fieldName)) {
                const definitionField = _.get(schemaProperties, fieldName);
                const hasUiSchema = _.has(uiSchemaProperties, fieldName);
                return {
                  ...definitionField,
                  field: fieldName,
                  inputType: hasUiSchema
                    ? _.get(_.get(uiSchemaProperties, fieldName), "ui:field") ||
                      definitionField?.inputType
                    : definitionField?.inputType,
                };
              } else {
                return null;
              }
            } else {
              return null;
            }
          }
        }
      }
    }
  } catch (e) {
    return null;
  }
}

export function getSafeArraySizeForForm({
  array = [],
  maxSize = Constants.DEFAULT_MAX_ROWS_RETURN_GENERIC_SELECTION,
}) {
  if (
    !_.isNil(array) &&
    !_.isEmpty(array) &&
    !_.isNil(maxSize) &&
    _.toNumber(maxSize) > 0 &&
    _.size(array) > _.toNumber(maxSize) &&
    Constants.THROW_ERROR_WHEN_MAX_ROWS_PASSED_ENABLED === true
  ) {
    return _.slice(array, 0, _.toNumber(maxSize));
  } else {
    return array;
  }
}

export function mustThrowErrorByMaxSizeRows({
  array = [],
  maxSize = Constants.DEFAULT_MAX_ROWS_RETURN_GENERIC_SELECTION,
  uniqueByLine = false,
}) {
  if (
    !_.isNil(array) &&
    !_.isEmpty(array) &&
    !_.isNil(maxSize) &&
    _.toNumber(maxSize) > 0 &&
    Constants.THROW_ERROR_WHEN_MAX_ROWS_PASSED_ENABLED === true
  ) {
    if (uniqueByLine === true) {
      const someGridLine = _.some(array, function (o) {
        return !_.isNil(o) && _.toNumber(o.line) > 0;
      });

      if (someGridLine) {
        const maxLine = _.maxBy(array, "line")?.line || 0;
        const throwErrorMaxLine = _.toNumber(maxLine) > _.toNumber(maxSize);
        return { throwErrorMaxLine, selectedSize: _.toNumber(maxLine) };
      }
      return { throwErrorMaxLine: false };
    } else {
      return {
        throwErrorMaxLine: _.size(array) > _.toNumber(maxSize),
        selectedSize: _.size(array),
      };
    }
  } else {
    return { throwErrorMaxLine: false };
  }
}

export function checkIsPkControl({ completeIdControl }) {
  return (
    !_.isNil(completeIdControl) &&
    _.isString(completeIdControl) &&
    _.includes(_.trim(_.toLower(completeIdControl)), ".pk.")
  );
}
