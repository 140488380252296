import React from "react";
import { Icon, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  ERROR_RESOURCE_NOT_FOUND_ICON,
  ERROR_BAD_FORM_DEF_ICON_SIZE,
  ERROR_BAD_FORM_DEF_TEXT_SIZE,
  ERROR_BAD_FORM_DEF_TEXT_WEIGHT,
} from "../../util/Constants";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  textParagraph: {
    color: theme.palette.content.mainColor,
    fontSize: ERROR_BAD_FORM_DEF_TEXT_SIZE,
    fontWeight: ERROR_BAD_FORM_DEF_TEXT_WEIGHT,
  },
}));

export default function NotFound() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.rootContainer}>
      <Icon
        className={ERROR_RESOURCE_NOT_FOUND_ICON}
        style={{
          fontSize: ERROR_BAD_FORM_DEF_ICON_SIZE,
          color: theme.palette.content.mainColor,
        }}
      />
      <p className={classes.textParagraph}>
        {t("ERROR_RESOURCE_NOT_FOUND_TEXT")}
      </p>
    </div>
  );
}
