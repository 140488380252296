import { makeStyles } from "@material-ui/core/styles";

const DEFAULT_FONT_SIZE = 16;

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 5,
  fontSize: "1.2rem",
};

export const useStyles = makeStyles(() => ({
  dialogTitleContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.1rem",
  },
  innerTitleHtml: {
    fontSize: DEFAULT_FONT_SIZE,
  },
  nestedParagraphs: {
    "& p": {
      fontSize: DEFAULT_FONT_SIZE,
    },
  },
  nestedSpan: {
    "& span": {
      fontSize: DEFAULT_FONT_SIZE,
    },
  },
  nestedDiv: {
    "& div": {
      fontSize: DEFAULT_FONT_SIZE,
    },
  },
}));
