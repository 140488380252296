import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt-BR";

//Import all new contexts here
import { AuthContextProvider } from "./AuthContext";
import { DeviceContextProvider } from "./DeviceContext";
import { DrawerContextProvider } from "./DrawerContext";
import { ThemeContextProvider } from "./ThemeContext";
import { LayoutContextProvider } from "./LayoutContext";
import { CssBaseline } from "@material-ui/core";
import { NotificationsContextProvider } from "./NotificationsContext";
import { AADContextProvider } from "./AADContext";
import { WorkspaceContextProvider } from "./WorkspaceContext";
import { useTranslation } from "react-i18next";

const localeMap = {
  en: enLocale,
  es: esLocale,
  pt: ptLocale,
};

export default function RootContextProvider({ children }) {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <ThemeContextProvider>
      <CssBaseline />
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={localeMap[language]}
      >
        <LayoutContextProvider>
          <DrawerContextProvider>
            <WorkspaceContextProvider>
              <AADContextProvider>
                <AuthContextProvider>
                  <NotificationsContextProvider>
                    <DeviceContextProvider>{children}</DeviceContextProvider>
                  </NotificationsContextProvider>
                </AuthContextProvider>
              </AADContextProvider>
            </WorkspaceContextProvider>
          </DrawerContextProvider>
        </LayoutContextProvider>
      </MuiPickersUtilsProvider>
    </ThemeContextProvider>
  );
}
