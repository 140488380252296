import React, { useState, useContext, useMemo, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Icon } from "@material-ui/core";
import Modal from "react-modal";
import { Resizable } from "re-resizable";

import { DrawerContext } from "../../core/providers/DrawerContext";
import useWindowDimensions from "../../core/hooks/useWindowDimensions";
import {
  customModalStylesDark,
  customModalStylesLight,
} from "../forms/externalFormContainer/ExternalFormContainerStyles";
import { ExternalFormShemaProvider } from "./hooks/useExternalFormShema";
import { ExternalResultQueryProvider } from "./hooks/useExternalResultQuery";
import ExternalReportsContainer from "./ExternalReportsContainer";
import { useExternalReportsStore } from "../../core/stores/ExternalReportsStore";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";

export const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const useStyles = makeStyles((theme) => ({
  closeModalButton: {
    padding: 4,
    position: "absolute",
    top: 5,
    right: 5,
  },
  closeModalButtonBack: {
    bottom: 10,
    right: 10,
    padding: "4px 12px",
    position: "absolute",
    backgroundColor: theme.palette.content.mainColor,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: theme.palette.content.mainColor,
      color: "#FFFFFF",
    },
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    padding: "42px 0px",
  },
}));

const HEIGHT = 70;
const WIDTH = 80;

export default function ExternalReportsContainerWrapper() {
  Modal.setAppElement("#reactapp-root");

  const { t } = useTranslation();
  const { drawerOpen } = useContext(DrawerContext);
  const [isResizing, setIsResizing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const wd = useWindowDimensions();
  const theme = useTheme();
  const classes = useStyles({ wd, openDrawer: drawerOpen });

  const openExternalQuery = useExternalReportsStore(
    (state) => state.openExternalQuery
  );
  const closeOpenExternalQuery = useExternalReportsStore(
    (state) => state.closeOpenExternalQuery
  );

  useEffect(() => {
    if (openExternalQuery?.open && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [openExternalQuery, isModalOpen]);

  function onResizeStart() {
    setIsResizing(true);
  }

  function onResizeStop() {
    setIsResizing(false);
  }

  const modalStyles = useMemo(() => {
    if (theme?.palette?.type === "dark") {
      return {
        ...customModalStylesDark,
        content: {
          ...customModalStylesDark?.content,
          transform: "translate(-50%, -55%)",
        },
      };
    } else {
      return {
        ...customModalStylesLight,
        content: {
          ...customModalStylesLight?.content,
          transform: "translate(-50%, -55%)",
        },
      };
    }
  }, [theme]);

  const calculatedWidth = useMemo(() => {
    if (!isNil(openExternalQuery) && !isNil(openExternalQuery.defaultWidth)) {
      return openExternalQuery.defaultWidth;
    } else {
      return `${WIDTH}vw`;
    }
  }, [openExternalQuery]);

  const calculatedHeight = useMemo(() => {
    if (!isNil(openExternalQuery) && !isNil(openExternalQuery.defaultHeight)) {
      return openExternalQuery.defaultHeight;
    } else {
      return `${HEIGHT}vh`;
    }
  }, [openExternalQuery]);

  return (
    <ExternalFormShemaProvider>
      <ExternalResultQueryProvider>
        <Modal isOpen={isModalOpen} style={modalStyles}>
          <Resizable
            defaultSize={{
              width: calculatedWidth,
              height: calculatedHeight,
            }}
            handleStyles={{
              right: {
                backgroundColor:
                  isResizing && `${theme.palette.content.mainColor}`,
              },
              left: {
                backgroundColor:
                  isResizing && `${theme.palette.content.mainColor}`,
              },
            }}
            maxHeight={"100vh"}
            maxWidth={"100vw"}
            minHeight={`30vh`}
            minWidth={`30vw`}
            style={{ overflow: "hidden" }}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
          >
            <div className={classes.contentContainer}>
              <ExternalReportsContainer />
            </div>

            <Button
              variant="contained"
              color="default"
              className={classes.closeModalButtonBack}
              endIcon={<Icon className="fas fa-arrow-circle-left" />}
              onClick={() => {
                setIsModalOpen(false);
                closeOpenExternalQuery();
              }}
            >
              {t("FORM_BACK")}
            </Button>
          </Resizable>
        </Modal>
      </ExternalResultQueryProvider>
    </ExternalFormShemaProvider>
  );
}
