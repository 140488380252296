import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

import { useStyles } from "./DeleteFormConfirmDialogStyles";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DeleteFormConfirmDialog({ open, confirm }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => confirm(false)}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: "move" }}
        className={classes.root}
        id="draggable-dialog-title"
      >
        {t("DELETE_CONFIRM_DIALOG_TITLE")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("DELETE_CONFIRM_DIALOG_BODY")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => confirm(true)}
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {t("YES")}
        </Button>
        <Button onClick={() => confirm(false)} color="primary">
          {t("NO")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
