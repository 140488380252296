import { isEqual, isMap, isNil } from "lodash";

const INITIAL_STATE = {
  apoStoredFilters: null,
  apoStoredCollapsed: false,
  apoStoredShowBpmDialog: false,
  isEditingForm: false,
  apoStoredBreadcumbRoutes: new Map(),

};

export const createApolloSlice = (set, get) => ({
  ...INITIAL_STATE,
  setApoStoredFilters: (newState) =>
    set(() => ({ apoStoredFilters: newState })),
  setApoStoredCollapsed: (newState) =>
    set(() => ({ apoStoredCollapsed: newState })),
  setApoStoredShowBpmDialog: (newState) =>
    set(() => ({ apoStoredShowBpmDialog: newState })),
  addApoBreadcumbRoute: (newRoute) =>
    set((state) => ({
      apoStoredBreadcumbRoutes: state.apoStoredBreadcumbRoutes.set(newRoute, {
        apoStoredFilters: state.apoStoredFilters,
        apoStoredCollapsed: state.apoStoredCollapsed,
        apoStoredShowBpmDialog: state.apoStoredShowBpmDialog,
      }),
    })),
  removeApoBreadcumbRoute: (routeToDelete, onlyWithFid) => {
    const actualMap = get().apoStoredBreadcumbRoutes;
    if (!isNil(routeToDelete) && isMap(actualMap)) {
      if (onlyWithFid) {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(([key, value]) => {
            const shoudlReturn =
              !isEqual(key, routeToDelete) && isNil(value?.formInstanceId);
            return shoudlReturn;
          })
        );
        set(() => ({
          apoStoredBreadcumbRoutes: newMapToSet,
        }));
      } else {
        const newMapToSet = new Map(
          Array.from(actualMap.entries()).filter(
            ([key]) => !key.startsWith(routeToDelete)
          )
        );
        set(() => ({
          apoStoredBreadcumbRoutes: newMapToSet,
        }));
      }
    }
  },
  updateApoBreadcumbRoute: (route, newParams) => {
    const actualBC = get().apoStoredBreadcumbRoutes;
    const spreadBC = actualBC.has(route) ? { ...actualBC.get(route) } : {};

    set((state) => ({
      apoStoredBreadcumbRoutes: state.apoStoredBreadcumbRoutes.set(route, {
        ...spreadBC,
        ...newParams,
      }),
    }));
  },
  toggleIsEditingForm: () => {
    set((state) => ({ isEditingForm: !state.isEditingForm }));
  },
  clear: () => set(INITIAL_STATE),
});
