import { makeStyles } from "@material-ui/core/styles";

const marginBetweenIconAndText = 15;

export const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};

export const useStyles = makeStyles((theme) => ({
  emptyListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "space-between ",
    flex: 1,
    backgroundImage: "url(/assets/img/menu_back_1.png)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    overflowY: "auto",
  },
  listItemIconContainer: {
    minWidth: theme.palette.drawer.iconSize + marginBetweenIconAndText,
  },
  parentSelected: {
    borderLeft: "3px solid white",
    borderImage: "linear-gradient(to top, #FFF 80%, rgba(0,0,0,0) 50%)",
    borderImageSlice: 1,
  },
  childSelected: {
    borderLeft: "3px solid white",
    borderImage: "linear-gradient(to top, #FFF 80%, rgba(0,0,0,0) 50%)",
    borderImageSlice: 1,
  },
  parentTextSelected: {
    textDecoration: "underline",
    fontSize: "1em",
    color: theme?.palette?.drawer?.item,
    fontWeight: "bold",
  },
  childTextSelected: {
    textDecoration: "underline",
    fontSize: "1em",
    color: theme?.palette?.drawer?.item,
    fontWeight: "bold",
  },
  finalItemSelected: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.drawer.grandSelected,
    fontSize: "1em",
    //borderRadius: "50px",
    fontWeight: "bold",
    color: theme.palette.drawer.selectedText,
    "&:hover": {
      backgroundColor: theme.palette.drawer.grandSelected,
    },
  },
  item: {
    fontSize: "1em",
    //borderBottom: "0.5px solid rgba(227, 227, 227, .3)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItemText: {
    fontSize: "1em",
  },
  childIdentedIconText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "10px",
  },
  grandIdentedIconText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "20px",
  },
  lastIdentedIconText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "30px",
  },
  commonDisplayRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  menuItem: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: theme.palette.drawer.backgroundColor,
    "&:hover": {
      backgroundColor: `${theme.palette.drawer.grandSelected} !important`,
      color: `${theme.palette.drawer.selectedText} !important`,
    },
  },
  menuItemText: {
    color: theme.palette.drawer.text,
    "&:hover": {
      backgroundColor: `${theme.palette.drawer.grandSelected} !important`,
      color: `${theme.palette.drawer.selectedText} !important`,
      border: "3px solid  blue",
    },
  },
  menuItemSelected: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: `${theme.palette.drawer.grandSelected} !important  `,
    fontSize: "1em",
    //borderRadius: "50px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: `${theme.palette.drawer.grandSelected} !important`,
      color: theme.palette.drawer.selectedText,
    },
  },
  menuItemTextSelected: {
    color: theme.palette.drawer.selectedText,
    "&:hover": {
      backgroundColor: `${theme.palette.drawer.grandSelected} !important`,
      color: theme.palette.drawer.selectedText,
    },
  },
  subMenuItem: {
    marginTop: 4,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: theme.palette.drawer.backgroundColor,
    "&:hover": {
      backgroundColor: `${theme.palette.drawer.grandSelected} !important`,
      color: `${theme.palette.drawer.selectedText} !important`,
    },
  },
  subMenuItemSelected: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: `${theme.palette.drawer.grandSelected} !important  `,
    fontSize: "1em",
    //borderRadius: "50px",
    fontWeight: "bold",
    color: `${theme.palette.drawer.selectedText} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.drawer.grandSelected} !important`,
      color: `${theme.palette.drawer.selectedText} !important`,
      border: "3px solid yellow",
    },
  },
  resultParagraph: {
    textAlign: "right",
    padding: "0px 10px",
    margin: 0,
  },
  paragraphServerOff: {
    width: "75%",
    color: theme.palette.content.mainColor,
    fontSize: "1.5em",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 5,
  },
}));
