import { isNil, isNaN, toNumber, isArray, isEmpty, size, round } from "lodash";

export const CALCULATION_TYPE_EQUALS = "EQUALS_DISTRIBUTION";
export const CALCULATION_TYPE_PROPORTIONAL = "PROPORTIONAL_DISTRIBUTION";

function calculateColumnWidthsEqualsDistribution({
  columns,
  totalTableWidth,
  defaultColumnWidth,
}) {
  const finalWidths = new Map();
  let totalDefinedColumnWidths = 0;

  // Filter columns that have a defined width greater than or equal to the default width, or have an overrideWidth
  columns.forEach((column) => {
    if (
      column.width &&
      (column.width >= defaultColumnWidth || column.overrideWidth === true)
    ) {
      finalWidths.set(column.id, column.width);
      totalDefinedColumnWidths += column.width;
    }
  });

  // Calculate remaining space for columns without defined width
  const remainingSpace = totalTableWidth - totalDefinedColumnWidths;

  // Calculate the number of columns without defined width
  const numColumnsWithoutWidth = columns.length - finalWidths.size;

  // Calculate the default width for columns without defined width
  const defaultWidth =
    numColumnsWithoutWidth > 0 ? remainingSpace / numColumnsWithoutWidth : 0;

  // Assign final widths for columns without defined width
  columns.forEach((column) => {
    if (!finalWidths.has(column.id)) {
      let assignedWidth = defaultWidth;
      if (column.overrideWidth === true) {
        assignedWidth = column.width;
      }
      finalWidths.set(column.id, assignedWidth);
    }
  });

  return finalWidths;
}

function calculateColumnWidthsProportionalDistribution({
  columns,
  totalTableWidth,
}) {
  const finalWidths = new Map();
  if (!isNil(columns) && isArray(columns) && !isEmpty(columns)) {
    let totalDefinedColumnWidths = 0;
    let fixedColumnsWidth = 0;
    let hasSelection = false;
    let hasLine = false;

    // Calcular el ancho total de las columnas definidas y el ancho de las columnas fijas
    for (let i = 0; i < size(columns); i++) {
      const column = columns[i];
      if (!isNil(column) && !isNil(column.id)) {
        const columnWidth = column?.width || 150;
        totalDefinedColumnWidths += columnWidth;

        if (column.id === "selection") {
          fixedColumnsWidth += columnWidth;
          hasSelection = true;
        } else if (column.id === "line") {
          fixedColumnsWidth += columnWidth;
          hasLine = true;
        }
      }
    }

    // Si el ancho total especificado es suficiente para las columnas fijas
    if (toNumber(totalTableWidth) >= fixedColumnsWidth) {
      const proportionalWidths = new Map();

      // Calcular los anchos proporcionales para las columnas no fijas
      const flexibleColumnsWidth = totalTableWidth - fixedColumnsWidth;
      if (totalDefinedColumnWidths - fixedColumnsWidth > 0) {
        for (let i = 0; i < columns.length; i++) {
          const column = columns[i];
          if (!isNil(column) && !isNil(column.id)) {
            const columnWidth = column?.width || 150;
            if (column.id !== "selection" && column.id !== "line") {
              proportionalWidths.set(
                column.id,
                round(
                  (columnWidth /
                    (totalDefinedColumnWidths - fixedColumnsWidth)) *
                    flexibleColumnsWidth,
                  3
                )
              );
            }
          }
        }
      }

      // Asignar los anchos finales para las columnas
      for (const [columnId, proportionalWidth] of proportionalWidths) {
        finalWidths.set(columnId, proportionalWidth);
      }

      // Añadir anchos fijos para las columnas "selection" y "line"
      if (hasSelection) {
        finalWidths.set("selection", 50);
      }

      if (hasLine) {
        finalWidths.set("line", 40);
      }

      let sumFinalWidths = 0;
      finalWidths.forEach((value) => (sumFinalWidths = sumFinalWidths + value));
    }
  }
  return finalWidths;
}

export function calculateColumnWidths(
  type,
  { columns, totalTableWidth, defaultColumnWidth }
) {
  if (CALCULATION_TYPE_EQUALS === type) {
    return calculateColumnWidthsEqualsDistribution({
      columns,
      totalTableWidth,
      defaultColumnWidth,
    });
  } else if (CALCULATION_TYPE_PROPORTIONAL === type) {
    return calculateColumnWidthsProportionalDistribution({
      columns,
      totalTableWidth,
      defaultColumnWidth,
    });
  } else {
    return new Map();
  }
}

export function getSumColumnsWidth({ columns }) {
  let result = 0;
  if (!isNil(columns) && isArray(columns) && !isEmpty(columns)) {
    columns.forEach((column) => {
      if (
        !isNil(column) &&
        !isNil(column.width) &&
        !isNaN(column.width) &&
        toNumber(column.width) > 0
      ) {
        result += toNumber(column.width);
      }
    });
  }
  return result;
}
