import { isArray, isEmpty, isNil, map } from "lodash";
import * as _ from "lodash";

export function resolveCoordtinationFiltersForExternalQuery({
  row,
  coordinationFiltersDef = [],
}) {
  if (
    !isNil(row) &&
    !isNil(coordinationFiltersDef) &&
    isArray(coordinationFiltersDef) &&
    !isEmpty(coordinationFiltersDef)
  ) {
    const result = map(coordinationFiltersDef, ({ from, to }) => ({
      owner: to,
      value: row[from],
      field: from,
    }));
    return result;
  }

  return [];
}

export const QUERIES_STYLE_VERSION_OLD = 1;
export const QUERIES_STYLE_VERSION_2 = 2;
export const QUERIES_STYLE_VERSION_3 = 3;

export function getCorrectStyleVersionToUse({ stylesVersion }) {
  if (!isNil(stylesVersion) && stylesVersion === QUERIES_STYLE_VERSION_2) {
    return QUERIES_STYLE_VERSION_2;
  } else if (
    !isNil(stylesVersion) &&
    stylesVersion === QUERIES_STYLE_VERSION_3
  ) {
    return QUERIES_STYLE_VERSION_3;
  } else {
    return QUERIES_STYLE_VERSION_OLD;
  }
}

export function resolveIfQueryActionBtnIsDisabled({
  rowValuesToUse,
  btnDisabled,
  selectedFlatRows,
  rowId,
  multipleExecutionEnabled,
}) {
  let result = false;

  if (
    !_.isNil(selectedFlatRows) &&
    _.isArray(selectedFlatRows) &&
    !_.isEmpty(selectedFlatRows) &&
    !_.isNil(rowId)
  ) {
    result =
      multipleExecutionEnabled === true
        ? _.some(selectedFlatRows, { id: rowId }) === false
        : true;
  }

  if (
    result === false &&
    !_.isNil(rowValuesToUse) &&
    !_.isEmpty(rowValuesToUse) &&
    !_.isNil(btnDisabled) &&
    _.isObject(btnDisabled) &&
    !_.isEmpty(btnDisabled)
  ) {
    for (const field in btnDisabled) {
      const valueToDisable = _.get(btnDisabled, field);
      if (
        _.has(rowValuesToUse, field) &&
        _.get(rowValuesToUse, field) === valueToDisable
      ) {
        result = true;
      } else {
        result = false;
        break;
      }
    }
  }
  return result;
}

export function applyAggreationFunction({ fun, column, rows = [] }) {
  if (_.isNil(fun) || _.isNil(column) || _.isEmpty(rows)) {
    return null;
  }

  const trimmed = _.trim(_.toLower(_.toString(fun)));

  if (trimmed === "sum") {
    const totalSum = _.sumBy(rows, (row) => _.get(row.values, column, 0));
    return {
      val: totalSum,
      label: "REPORTS_MENU_ADD",
      icon: "fas fa-plus",
    };
  } else if (trimmed === "count") {
    const totalCount = _.size(rows);
    return {
      val: totalCount,
      label: "REPORTS_MENU_COUNT",
      icon: "fas fa-hashtag",
    };
  } else if (trimmed === "avg") {
    const totalAvg = _.meanBy(rows, (row) => _.get(row.values, column, 0));
    return {
      val: totalAvg,
      label: "REPORTS_MENU_AVG",
      icon: "fas fa-balance-scale",
    };
  } else if (trimmed === "min") {
    const totalMin = _.minBy(rows, (row) => _.get(row.values, column, Infinity))
      ?.values[column];
    return {
      val: totalMin,
      label: "REPORTS_MENU_MIN",
      icon: "fas fa-angle-double-down",
    };
  } else if (trimmed === "max") {
    const totalMax = _.maxBy(rows, (row) =>
      _.get(row.values, column, -Infinity)
    )?.values[column];
    return {
      val: totalMax,
      label: "REPORTS_MENU_MAX",
      icon: "fas fa-angle-double-up",
    };
  } else {
    return null;
  }
}
