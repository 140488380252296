/**
 * Creates line, for table with returnSize number of lines
 */
export const getMockDataTable = (returnSize) => {
  let result = [];
  for (let i = 0; i < returnSize; i++) {
    const toPush = {
      countryId: `${i}UY`,
      countryName: `${i}Uruguay`,
      line: i,
    };
    result.push(toPush);
  }
  return result;
};