import React, { useContext, useReducer } from "react";
import {
  REST_HEADERS,
  TOKEN_HEADER,
  TOKEN_INIT,
  SERVER_HOST,
  API_FORM_QUERY,
  METHOD_POST,
  INPUT_TYPE_DATE_RANGE,
  SEARCH_CRITERIA_BETWEEN,
} from "../../../util/Constants";
import { get, has, isArray, isEmpty, isNil, isObject } from "lodash";
import { isJsonFormat } from "../../../util/UtilFormat";

const ExternalResultQueryContext = React.createContext(undefined);

const SERVICE_GET_RESULT = "/getResult";

const API_URL = `${SERVER_HOST()}${API_FORM_QUERY}${SERVICE_GET_RESULT}`;

const initialState = {
  status: "idle",
  error: null,
  data: [],
  listCounts: [],
  lastSearchs: [],
  lastUpdate: [],
};

const formatExternalBodyToSend = ({ body }) => {
  if (
    isNil(body) ||
    isEmpty(body) ||
    isNil(body?.queryFilters) ||
    isEmpty(body?.queryFilters) ||
    !isArray(body?.queryFilters)
  ) {
    return body;
  }

  const idForm = body?.idForm;
  const queryFilters = body?.queryFilters.map((x) => {
    if (
      x?.input === INPUT_TYPE_DATE_RANGE &&
      !isNil(x?.defaultValue) &&
      !isEmpty(x?.defaultValue) &&
      isObject(x?.defaultValue) &&
      has(x?.defaultValue, "from") &&
      has(x?.defaultValue, "to")
    ) {
      if (x?.omitDefaultValues === true) {
        return x;
      } else {
        return {
          ...x,
          value: get(x?.defaultValue, "from"),
          valueTwo: get(x?.defaultValue, "to"),
          operation: SEARCH_CRITERIA_BETWEEN,
        };
      }
    } else {
      return x;
    }
  });

  return {
    ...body,
    idForm,
    queryFilters,
  };
};

const externalQueryReducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, status: "fetching" };
    case "FETCHED":
      return {
        ...state,
        status: "fetched",
        data: action.payload,
        listCounts: action.listCounts,
        lastSearchs: action.lastSearchs,
        lastUpdate: action.lastUpdate,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        status: "error",
        error: action.payload,
      };
    case "REFRESH":
      return {
        ...initialState,
        status: state.status,
      };
    default:
      return state;
  }
};

const ExternalResultQueryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(externalQueryReducer, initialState);
  const data = [state, dispatch];
  return (
    <ExternalResultQueryContext.Provider value={data}>
      {children}
    </ExternalResultQueryContext.Provider>
  );
};

const useExternalResultQuery = () => {
  const context = useContext(ExternalResultQueryContext);
  if (context === undefined) {
    throw new Error(
      "useExternalResultQuery can only be used inside ExternalResultQueryProvider"
    );
  }
  return context;
};

const executeExternalQuery = async (
  dispatch,
  body,
  token,
  lazyLoading,
  selectedTab
) => {
  try {
    dispatch({ type: "FETCHING" });

    const bodyFormatted = formatExternalBodyToSend({ body });
    const urlToSend =
      lazyLoading === true
        ? `${API_URL}?lazyLoading=true&tab=${selectedTab}`
        : API_URL;

    const response = await fetch(urlToSend, {
      body: JSON.stringify(bodyFormatted),
      method: METHOD_POST,
      headers: {
        ...REST_HEADERS,
        [TOKEN_HEADER]: `${TOKEN_INIT}${token}`,
      },
    });
    const data = await response.json();
    if (!isNil(data) && !isNil(data.result)) {
      const isJson = isJsonFormat(data.result);
      if (isJson) {
        dispatch({
          type: "FETCHED",
          payload: JSON.parse(data.result),
          listCounts: data?.count,
          lastSearchs: data?.lastSearchs,
          lastUpdate: data?.lastUpdate,
        });
        return data;
      } else {
        dispatch({ type: "FETCH_ERROR", payload: "error" });
        return Promise.reject("error");
      }
    } else {
      dispatch({ type: "FETCH_ERROR", payload: "error" });
      return Promise.reject("error");
    }
  } catch (error) {
    dispatch({ type: "FETCH_ERROR", payload: error.message });
    return Promise.reject(error);
  }
};

export {
  ExternalResultQueryProvider,
  useExternalResultQuery,
  executeExternalQuery,
};
